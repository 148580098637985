import { EmissionsRecordsTab } from "#batteries-included-components/Tabs/Emissions/EmissionsRecordsTab";
import DeleteModal from "#common/DeleteModal/DeleteModal";
import FormTable from "#common/Forms/FormTable";
import { ChangeLogTab } from "#components/Organization/Facilities/Detail/ChangeLogTab";
import { FacilityOverviewTab } from "#components/Organization/Facilities/Detail/OverviewTab/FacilityOverviewTab";
import { useGetOneFacility } from "#hooks/adapters/useFacilities";
import { useFormCategories } from "#hooks/useForms";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams, useSearchParams } from "#routers/hooks";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import {
  FACILITIES_LIST_BREADCRUMB,
  FacilitiesListRoute,
} from "#routes/organization/facilities";
import { FACILITY_DETAIL_BREADCRUMB } from "#routes/organization/facilities/[facilityId]/index";
import FacilityService from "#services/FacilityService";
import EditFacilityDialog from "#src/batteries-included-components/Dialogs/EditFacilityDialog";
import { PageErrorContent } from "#src/batteries-included-components/Layouts/Errors/PageError";
import { EntityMeasurementsTab } from "#src/batteries-included-components/Tabs/EntityMeasurementsTab/EntityMeasurementsTab";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { FacilityRecordsTabContent } from "#src/routes/organization/facilities/[facilityId]/FacilityRecordsTabContent";
import { Button, Page, Tab } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import React, { useState } from "react";

const DELETE_FACILITY_MODAL_KEY = "Delete";
const DEFAULT_TAB_KEY = "overview";
const ENTITY_TYPE = AssetType.FACILITY;

export const FacilityDetailPage = () => {
  const navigate = useNavigate();
  const { localize, isLoading: isMappingLoading } = useLocalization();
  const [isChangeLogAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:activity_logs",
    permissionQuery: "activities:read",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { facilityId } = useParams<{ facilityId: string }>();
  const { viewConfigStorageKey } = useStorageKey("assets-shared");
  const [filters] = useSessionStickyState<{ period: { from?: string } }>(
    {},
    viewConfigStorageKey
  );
  const activePeriod = getYearMonthFromDateRange(filters.period) ?? "";
  const [formCategories] = useFormCategories({
    relatedEntityId: facilityId,
    relatedEntityType: ENTITY_TYPE,
  });
  const {
    data: facilityDetail,
    isLoading,
    error,
  } = useGetOneFacility(facilityId, {
    period: activePeriod,
  });
  const facilityDetailBreadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      FACILITIES_LIST_BREADCRUMB,
      FACILITY_DETAIL_BREADCRUMB,
    ],
    {
      2: facilityDetail?.name,
    }
  );
  const [modalKey, setModalKey] = useState<string | null>(null);
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();

  const handleEditFacilityClick = () => setAssetIdToEdit(facilityId);

  const handleDeleteFacilityClick = () => {
    setModalKey(DELETE_FACILITY_MODAL_KEY);
  };

  const handleCloseModal = () => {
    setModalKey(null);
  };

  const handleActiveTabKeyChange = (tabKey: string) => {
    setSearchParams({ tab: tabKey });
  };

  const actionRow = (
    <>
      <Button
        variant="error-outline"
        onClick={handleDeleteFacilityClick}
      >
        Delete
      </Button>

      <Button onClick={handleEditFacilityClick}>Edit</Button>
    </>
  );

  return (
    <>
      <Page
        category={localize("Facility")}
        title={facilityDetail?.name ?? "Detail"}
        breadcrumbs={facilityDetailBreadcrumbs}
        isLoading={isLoading || isMappingLoading || !facilityDetail}
        actionRow={actionRow}
        onActiveTabKeyChange={handleActiveTabKeyChange}
        activeTabKey={
          !isChangeLogAvailable && searchParams?.tab === "change-log"
            ? DEFAULT_TAB_KEY
            : searchParams?.tab ?? DEFAULT_TAB_KEY
        }
        error={error}
        onErrorRender={({ error }) => <PageErrorContent error={error} />}
      >
        <Tab
          tabKey="overview"
          title="Overview"
        >
          <FacilityOverviewTab
            facilityDetail={facilityDetail}
            viewConfigStorageKey={viewConfigStorageKey}
          />
        </Tab>
        <Tab
          title="Records"
          tabKey="records"
        >
          <FacilityRecordsTabContent />
        </Tab>
        <Tab
          tabKey="emissions"
          title="Emissions"
        >
          <EmissionsRecordsTab
            assetType={AssetType.FACILITY}
            assetId={facilityId}
          />
        </Tab>
        <Tab
          tabKey="measurements"
          title="Measurements"
        >
          <EntityMeasurementsTab
            entityId={facilityId}
            entityType={AssetType.FACILITY}
          />
        </Tab>
        <Tab
          tabKey="change-log"
          title="Change Log"
          isDisabled={!isChangeLogAvailable}
          iconVariant={!isChangeLogAvailable ? "lock" : ""}
        >
          {isChangeLogAvailable ? (
            <ChangeLogTab resourceId={facilityId} />
          ) : null}
        </Tab>
        {formCategories.map((formCategory) => (
          <Tab
            key={formCategory?.id}
            tabKey={formCategory?.id}
            title={formCategory.name}
          >
            <FormTable
              formCategoryId={formCategory?.id}
              relatedEntityId={facilityId}
              relatedEntityType={ENTITY_TYPE}
            />
          </Tab>
        ))}
      </Page>
      <DeleteModal
        open={!!modalKey}
        instanceName={facilityDetail?.name ?? ""}
        entityName={localize("Facility")}
        onClose={handleCloseModal}
        onDelete={() => {
          navigate(FacilitiesListRoute.toLinkParts());
        }}
        doDelete={() => FacilityService.deleteFacility(facilityDetail?.id)}
      />
      <EditFacilityDialog
        facilityId={assetIdToEdit}
        onClose={() => setAssetIdToEdit(undefined)}
        defaultPeriod={activePeriod}
      />
    </>
  );
};
