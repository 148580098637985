import React, { useEffect } from "react";

import { useCreateCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import {
  Button,
  Dialog,
  DropdownInput,
  Form,
  RadioInput,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  AttributeDataType,
  CustomAttributeSchema,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import snakeCase from "lodash/snakeCase";
import { FlowTypeDropdownInput } from "../Dropdowns/FlowTypeDropdownInput";
import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";

export const AddCustomAttributeDialog = ({
  isOpen,
  onClose,
  assetType,
}: {
  isOpen: boolean;
  onClose: () => void;
  assetType: AssetTypeType;
}) => {
  const form = useForm({});
  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];

  const onSuccess = () => {
    onClose?.();
  };

  const isPicklist = form.watch("data_type") == AttributeDataType.PICK_LIST;
  const isLookup = form.watch("data_type") == AttributeDataType.LOOKUP;
  const isNumber = form.watch("data_type") == AttributeDataType.NUMBER;

  const createCustomAttribute = useCreateCustomAttributes({ onSuccess });
  const handleSubmit = form.handleSubmit((values) => {
    const {
      display_name,
      description,
      data_type,
      pick_list_values,
      lookup_entity_type,
      is_required,
      entity_subtype,
    } = values;
    createCustomAttribute.mutate({
      data: {
        entity_type: assetType,
        entity_subtype,
        field_name: `${snakeCase(values.display_name)}`,
        display_name,
        description,
        is_required,
        data_type,
        lookup_entity_type,
        pick_list_values: pick_list_values
          ? pick_list_values.split(",").map((item) => item.trim())
          : undefined,
      },
    });
  });

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  const dataTypeOptions = Object.entries(AttributeDataType).map(
    ([key, value]) => ({
      label: toStartCaseString(value),
      name: value,
      id: key,
    })
  );
  const assetTypeOptions = Object.entries(AssetType).map(([key, value]) => ({
    label: toStartCaseString(value),
    name: value,
    id: key,
  }));

  return (
    <Dialog
      title="Add Custom Attribute"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="add-custom-attribute"
          variant="primary"
          onClick={handleSubmit}
        >
          Add
        </Button>,
      ]}
    >
      <Form {...form}>
        <TextInput
          name={CustomAttributeSchema.keyof().Enum.display_name}
          label="Display Name"
          isRequired
          type="text"
        />
        <TextInput
          name={CustomAttributeSchema.keyof().Enum.description}
          label="Description"
          type="text"
        />
        {assetType === AssetType.EQUIPMENT ? (
          <DropdownInput
            label="Equipment Type"
            name={CustomAttributeSchema.keyof().Enum.entity_subtype}
            options={equipmentTypes}
            labelKey="name"
            valueKey="id"
            isFluid
            isSearchable
            isOptionalTextShown
          />
        ) : null}

        {assetType === AssetType.FLOW ? (
          <FlowTypeDropdownInput
            name={CustomAttributeSchema.keyof().Enum.sub_type}
            label="Flow Type"
            isRequired={false}
            isOptionalTextShown={true}
            isMulti={false}
            description="If no flow type is specified the custom attribute will be applicable for all flow types."
          />
        ) : null}
        <RadioInput
          inputId="is_required"
          name={CustomAttributeSchema.keyof().Enum.is_required}
          label="Is Required"
          isRequired
          options={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          valueKey="value"
          labelKey="label"
        />
        <DropdownInput
          name={CustomAttributeSchema.keyof().Enum.data_type}
          label="Data Type"
          options={dataTypeOptions}
          valueKey="name"
          labelKey="label"
          isRequired
        />
        {isPicklist ? (
          <TextInput
            name={CustomAttributeSchema.keyof().Enum.pick_list_values}
            label="Pick List Values"
            isRequired
            description="The values must be comman seperated values. e.g: Option 1, Option 2"
            type="text"
          />
        ) : (
          []
        )}
        {isLookup ? (
          <DropdownInput
            name={CustomAttributeSchema.keyof().Enum.lookup_entity_type}
            label="Lookup Asset"
            options={assetTypeOptions}
            valueKey="name"
            labelKey="label"
            isRequired
          />
        ) : (
          []
        )}
        {isNumber ? (
          <TextInput
            name={CustomAttributeSchema.keyof().Enum.unit}
            label="Unit"
            isRequired
            type="text"
          />
        ) : (
          []
        )}
      </Form>
    </Dialog>
  );
};
