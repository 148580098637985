import { EstimationMethodConfigurationFilterPanel } from "#batteries-included-components/Layouts/RecordConfiguration/EstimationMethodConfigurationFilterPanel";
import { FunctionSelector } from "#batteries-included-components/Layouts/RecordConfiguration/FunctionSelector";
import { RecordValueConfigurationContext } from "#batteries-included-components/Layouts/RecordConfiguration/RecordConfigurationContext";
import { useParams } from "#routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useStorageKey } from "#src/hooks/useStorageKey";
import useTableState from "#src/hooks/useTableState";
import {
  linkToAssetDetailPage,
  linkToCalculationResultDetailPage,
} from "#utils/links";
import { useQuery } from "@tanstack/react-query";
import {
  Column,
  DataTable,
  DataTablePanel,
  HeaderType,
  Row,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  CalculatorResultType,
  CalculatorResultsDomain,
  EquipmentDomain,
  EquipmentType,
  FlowDomain,
  FlowType,
} from "@validereinc/domain";
import {
  stringToArray,
  yearMonthFormatter,
  yearMonthName,
  yearMonthParser,
} from "@validereinc/utilities";
import React, { useContext, useEffect, useState } from "react";

const getAssetIdByType = (
  data: CalculatorResultType[],
  assetType: AssetTypeType
) =>
  data
    .filter(({ entity_type }) => entity_type === assetType)
    .map(({ entity_id }) => entity_id)
    .filter((id): id is string => !!id);

export const EstimationMethodConfiguration = () => {
  const { filterConfigStorageKey, tableConfigStorageKey } = useStorageKey(
    "record-source-configuration-panel-estimation-method-tab"
  );

  const [storedFilters] = useFilters<{
    from: string;
    to: string;
    page: number;
    pageSize: number;
    facility?: string;
    flow?: string;
    equipment?: string;
  }>(filterConfigStorageKey);

  const params = useParams<{ measurementType: string }>();

  const { measurementType, form, record } =
    useContext(RecordValueConfigurationContext) || {};

  const [selected, setSelectedState] = useState<
    Record<string, CalculatorResultType>
  >({});

  const setSelected = (
    selectedValues: Record<string, CalculatorResultType>
  ) => {
    setSelectedState(selectedValues);
    form?.setValue("calculator_result_ids", Object.keys(selectedValues));
  };

  const estimationMethodParams = {
    page: storedFilters.page,
    pageSize: storedFilters.pageSize,
    filters: {
      measurement_type: { $exact: params.measurementType },
      entity_id: [
        ...stringToArray(storedFilters.facility),
        ...stringToArray(storedFilters.equipment),
        ...stringToArray(storedFilters.flow),
      ],
      $and: [
        {
          year_month: {
            $gte: storedFilters.from
              ? yearMonthFormatter(yearMonthParser(storedFilters.from))
              : record?.year_month,
          },
        },
        {
          year_month: {
            $lte: storedFilters.to
              ? yearMonthFormatter(yearMonthParser(storedFilters.to))
              : record?.year_month,
          },
        },
      ],
    },
    groupBy: ["estimation_method_id", "id", "entity_id", "year_month"],
  };

  const estimationMethodQuery = useQuery({
    queryKey: ["estimationMethods", estimationMethodParams],
    queryFn: async () =>
      CalculatorResultsDomain.getList(estimationMethodParams),
    enabled: !!record,
  });
  const estimationMethods = estimationMethodQuery.data?.data ?? [];

  useEffect(() => {
    if (estimationMethodQuery.isLoading) return;
    const initialSelection = Object.fromEntries(
      (
        record?.values.find((v) => v.measurement_type === measurementType?.id)
          ?.configuration.calculator_result_ids ?? []
      )
        .map((selectedId: string) => {
          const findEstimationMethod = estimationMethods.find(
            ({ id }) => id === selectedId
          );
          return findEstimationMethod
            ? [selectedId, findEstimationMethod]
            : null;
        })
        .filter((e) => !!e)
    );
    setSelected(initialSelection);
  }, [
    record,
    measurementType,
    estimationMethodQuery.isLoading,
    storedFilters.from,
    storedFilters.to,
  ]);

  /** TODO: Remove these lookups when the backend can provide the entity name as well */
  const flowIds = getAssetIdByType(estimationMethods, AssetType.FLOW);
  const flowQuery = useQuery({
    queryKey: ["flows", { id: flowIds }],
    queryFn: async () => FlowDomain.getFlows({ filters: { id: flowIds } }),
    enabled: !!flowIds.length,
    select: ({ data }) => data,
  });

  const equipmentIds = getAssetIdByType(estimationMethods, AssetType.EQUIPMENT);
  const equipmentQuery = useQuery({
    queryKey: ["equipment", { id: equipmentIds }],
    queryFn: async () =>
      EquipmentDomain.getEquipment({ filters: { id: equipmentIds } }),
    enabled: !!equipmentIds.length,
    select: ({ data }) => data,
  });

  const findAsset = ({ entity_id, entity_type }: CalculatorResultType) => {
    if (entity_type === AssetType.EQUIPMENT) {
      return equipmentQuery.data?.find(
        ({ id }: EquipmentType) => entity_id === id
      );
    }
    return flowQuery.data?.find(({ id }: FlowType) => entity_id === id);
  };
  /** End asset name lookup */

  const headers: Array<HeaderType<CalculatorResultType>> = [
    {
      key: "estimation_method_name",
      label: "Name",
      renderComponent: ({
        item: {
          estimation_method_name,
          estimation_method_id,
          entity_id,
          entity_type,
          year_month,
        },
      }) =>
        entity_type && entity_id && estimation_method_id && year_month ? (
          <RoutingLink
            to={linkToCalculationResultDetailPage(
              entity_type,
              entity_id,
              estimation_method_id,
              year_month
            )}
          >
            {estimation_method_name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      key: "entity_id",
      label: "Asset",
      renderComponent: ({ item }) =>
        item.entity_type && item.entity_id ? (
          <RoutingLink
            to={linkToAssetDetailPage(item.entity_type, item.entity_id)}
          >
            {findAsset(item)?.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      key: "year_month",
      label: "Period",
      renderComponent: ({ item }) =>
        item.year_month ? yearMonthName(item.year_month) : "-",
    },
    {
      key: `measurement.${params.measurementType}`,
      label: measurementType?.name,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.NumberCell
          value={item.measurement[params.measurementType]}
        />
      ),
    },
  ];

  const { tableProps } = useTableState({
    onFetchData: () => estimationMethodQuery.data,
    isEnabled: !estimationMethodQuery.isLoading,
    itemsKey: "data",
  });

  const dataTableProps = {
    ...tableProps, // Really just scraping pagination props out of here
    items: estimationMethods,
    isLoading: estimationMethodQuery.isLoading,
    headers: estimationMethods.length ? headers : [], // Hide the headers in the empty state
    selected: estimationMethods.length ? selected : undefined, // Hide the select-all button in the empty state
    onSelectionChange: (newSelection: Record<string, any>) => {
      setSelected(newSelection);
    },
    // REVIEW: Does this unique identifier exist?
    getItemId: (item: CalculatorResultType) => item.id,
    emptyStateProps: {
      title: `There are no estimation method results of type ${measurementType?.name} in this period`,
      suggestion:
        "Try running some estimations methods, or changing your source type",
    },
  };

  return (
    <>
      <EstimationMethodConfigurationFilterPanel
        storageKey={filterConfigStorageKey}
      />
      <Row>
        <Column variant={18}>
          <DataTablePanel
            panelProps={{ title: "Estimation Methods" }}
            dataTableProps={dataTableProps}
            storageKey={tableConfigStorageKey}
          />
        </Column>
        <Column variant={6}>
          <FunctionSelector
            values={Object.values(selected).map(
              ({ measurement }) => measurement[params.measurementType] ?? 0
            )}
            isLoading={estimationMethodQuery.isLoading}
          />
        </Column>
      </Row>
    </>
  );
};
