import { AssetTypeType, DeviceType } from "../schemas";
import {
  CreateOneRequestType,
  DeleteOneRequestType,
  DomainModelType,
  GetListResponseType,
  GetOneRequestType,
  ResourceServiceType,
  UpdateOneRequestType,
} from "../util";
import { restAPI } from "./api";

export type MeasurementSeriesSubjectType = {
  entity_id: string;
  entity_type: AssetTypeType;
  start_time: string;
};

export type MeasurementSeriesType = DomainModelType & {
  description: string;
  device_id: string;
  measurement_type: string;
  unit: string;
  company_id: string;
  device: DeviceType;
  measurement_type_name: string;
  subjects: MeasurementSeriesSubjectType[];
};

export type MeasurementSeriesServiceType = Pick<
  ResourceServiceType<MeasurementSeriesType>,
  "getList" | "getOne" | "createOne" | "updateOne" | "deleteOne"
>;

export const MeasurementSeriesAdapter: MeasurementSeriesServiceType = {
  /** Get a list of measurement series
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_series/list_measurement_series
   */

  getList: async ({ page, pageSize, sortBy, sortDirection, filters }) =>
    await restAPI.nodeAPI.GET<GetListResponseType<MeasurementSeriesType>>({
      endpoint: "/measurement_series",
      query: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        ...(filters?.deviceId ? { device_id: filters.deviceId } : {}),
        ...(filters?.subjectId ? { subject_id: filters.subjectId } : {}),
        ...(filters?.subjectType ? { subject_type: filters.subjectType } : {}),
      },
    }),

  /** Get a measurement series
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_series/get_measurement_series
   */
  getOne: ({ id }: GetOneRequestType<MeasurementSeriesType>) =>
    restAPI.nodeAPI
      .GET<MeasurementSeriesType>({
        endpoint: `/measurement_series/${id}`,
      })
      .then((data) => ({ data })),

  /** Create a measurement series
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_series/get_measurement_series
   */
  createOne: ({ data }: CreateOneRequestType<MeasurementSeriesType>) =>
    restAPI.nodeAPI
      .POST<MeasurementSeriesType>({
        endpoint: `/measurement_series/`,
        body: data,
      })
      .then((data) => ({ data })),

  /** Update a measurement series
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_series/update_measurement_series
   */
  updateOne: ({ id, data }: UpdateOneRequestType<MeasurementSeriesType>) =>
    restAPI.nodeAPI
      .PUT<MeasurementSeriesType>({
        endpoint: `/measurement_series/${id}`,
        body: data,
      })
      .then((data) => ({ data })),

  /** Delete a measurement series
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_series/delete_measurement_series
   */

  deleteOne: ({ id }: DeleteOneRequestType<MeasurementSeriesType>) =>
    restAPI.nodeAPI
      .DELETE<MeasurementSeriesType>({
        endpoint: `/measurement_series/${id}`,
      })
      .then(() => ({ status: 200 })),
};
