import { RequestCallbackType, setupRestAPI } from ".";
import packageDetails from "../package.json";
import { buildRestAPIHandlers } from "./mocks";

const LOG_NAME = packageDetails.name;
// IMPROVE: both of these constants can be rolled up specific to the API they're for
const MAX_PAGE_SIZE = 500;
export const MAX_PAGE_SIZE_DATA_PLATFORM = 100;
const API_DOMAINS = {
  /** the public node back-end */
  PUBLIC_NODE: "publicNode",
  /** the node back-end */
  NODE: "node",
  /** the elixir back-end */
  ELIXIR: "elixir",
  /** the data-eng back-end */
  DATA_ENG: "dataEng",
  /** the alerting back-end */
  ALERTING: "alerting",
  /** the insights back-end */
  INSIGHTS: "insights",
  /** the data platform back-end, sources */
  DATA_PLATFORM_SOURCE: "dataPlatformSource",
  /** the data platform back-end, admin */
  DATA_PLATFORM_ADMIN: "dataPlatformAdmin",
} as const;
const config: DomainConfig = {
  publicNodeURL: "",
  nodeURL: "",
  elixirURL: "",
  dataEngURL: "",
  alertingURL: "",
  insightsURL: "",
  dataPlatformSourceURL: "",
  dataPlatformAdminURL: "",
  bearer: "",
  maxPageSize: MAX_PAGE_SIZE,
} as const;

/**
 * Initialize the domain package's API adapters and mocks.
 * @see {@link setupAPIAdapters} Setup the API adapters for use. The API adapters will be un-authenticated by default.
 * @see {@link authorize} Authenticate the API adapter by calling this wherever the authentication with the auth provider happens in your front-end app.
 * @param param0 all of the back-end endpoints that this adapter needs
 * @param param0.nodeURL the node back-end host URL
 * @param param0.elixirURL the elixir back-end host URL
 * @param param0.dataEngURL the data-eng back-end host URL
 * @param param0.alertingURL the alerting back-end host URL
 * @param param0.insightsURL the insights back-end host URL
 * @param param0.dataPlatformSourceURL the data platform sources back-end host URL
 * @param param0.dataPlatformAdminURL the data platform admin back-end host URL
 */
const initialize = ({
  publicNodeURL,
  nodeURL,
  elixirURL,
  dataEngURL,
  alertingURL,
  insightsURL,
  dataPlatformSourceURL,
  dataPlatformAdminURL,
  maxPageSize,
  requestCallback,
}: Omit<DomainConfig, "bearer" | "maxPageSize"> &
  Partial<Pick<DomainConfig, "maxPageSize">>) => {
  config.publicNodeURL = publicNodeURL;
  config.nodeURL = nodeURL;
  config.elixirURL = elixirURL;
  config.dataEngURL = dataEngURL;
  config.alertingURL = alertingURL;
  config.insightsURL = insightsURL;
  config.dataPlatformSourceURL = dataPlatformSourceURL;
  config.dataPlatformAdminURL = dataPlatformAdminURL;
  config.maxPageSize = maxPageSize ?? MAX_PAGE_SIZE;
  config.requestCallback = requestCallback;

  setupRestAPI(config);
  buildRestAPIHandlers(config);
};

/**
 * Authorize the domain package with an auth token, which will be used as the bearer token for requests from the API adapter
 * @param authToken encrypted token from an external auth provider, usually received upon authentication
 */
const authorize = (authToken: string) => {
  config.bearer = authToken;
};

type APIDomainURL = `${(typeof API_DOMAINS)[keyof typeof API_DOMAINS]}URL`;
type DomainAPIConfig = {
  [key in APIDomainURL]: string;
};

type DomainConfig = {
  /** the bearer token to use in an Authorization header */
  bearer: string;
  /** the maximum page size of back-end data fetchable */
  maxPageSize: number;
  /** callback to be invoked on all requests */
  requestCallback?: RequestCallbackType;
} & DomainAPIConfig;

export { API_DOMAINS, LOG_NAME, authorize, config, initialize };
export type { DomainAPIConfig, DomainConfig };
