import { linkToFormCategoryDetails } from "../..";

export const linkToEditFormCategoryFormTemplate = (
  formCategoryId = ":categoryId",
  formSchemaId = ":schemaId"
) =>
  `${linkToFormCategoryDetails(formCategoryId)}/edit-form-template/${formSchemaId}`;

export const EDIT_FORM_CATEGORY_FORM_TEMPLATE_PAGE_TITLE_PLACEHOLDER =
  "Edit Template";

export const EDIT_FORM_CATEGORY_FORM_TEMPLATE_BREADCRUMB = {
  title: EDIT_FORM_CATEGORY_FORM_TEMPLATE_PAGE_TITLE_PLACEHOLDER,
  path: linkToEditFormCategoryFormTemplate,
};

export * from "./EditFormTemplatePage";
