import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#src/Routers/hooks";
import {
  CreateRoleDetailsStep,
  CreateRolePermissionsStep,
} from "#src/batteries-included-components";
import { MutateRoleFooter as CreateRoleFooter } from "#src/batteries-included-components/Forms/Roles/MutateRoleFooter";
import { MutateRoleStepper } from "#src/batteries-included-components/Forms/Roles/MutateRoleStepper";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { useIsOpsHubExperience } from "#src/contexts/AuthenticatedContext.helpers";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { linkToRoleDetailPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import { CreateRoleAssignMembersStep } from "#src/routes/settings/roles-and-permissions/roles/create/CreateRoleAssignMembersStep";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Page, useAlert } from "@validereinc/common-components";
import {
  RoleCreateSchema,
  RolePermissionType,
  RolesAdapter,
  UserSchema,
} from "@validereinc/domain";
import React from "react";
import { z } from "zod";
import { CREATE_ROLE_BREADCRUMB, CREATE_ROLE_PAGE_TITLE } from ".";
import { ROLES_BREADCRUMB, linkToRoles } from "..";
import { ROLES_AND_PERMISSIONS_BREADCRUMB } from "../..";
import { SETTINGS_BREADCRUMB } from "../../..";

export const RoleCreateFormSchema = RoleCreateSchema.merge(
  z.object({
    selected_users: z.array(UserSchema).optional(),
    selected_permissions: z.array(z.string()).optional(),
  })
);

type RoleCreateFormType = z.infer<typeof RoleCreateFormSchema>;

export const CreateRolePageContent = () => {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs([
    SETTINGS_BREADCRUMB,
    ROLES_AND_PERMISSIONS_BREADCRUMB,
    ROLES_BREADCRUMB,
    CREATE_ROLE_BREADCRUMB,
  ]);
  const { getValues, isFirstStep } = useMultiStepFormContext();
  const roleDetails = getValues()[0] as Pick<RoleCreateFormType, "name">;

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={CREATE_ROLE_PAGE_TITLE}
      category={!isFirstStep ? roleDetails.name : ""}
      actionRow={<MutateRoleStepper />}
      footer={
        <CreateRoleFooter
          submissionActionText="Create"
          onCancel={() =>
            navigate({
              pathname: linkToRoles().split("?")[0],
              query: {
                tab: "roles",
              },
            })
          }
        />
      }
    >
      <CreateRoleDetailsStep />
      <CreateRoleAssignMembersStep />
      <CreateRolePermissionsStep />
    </Page>
  );
};

export const CreateRolePageForm = () => {
  const { addAlert } = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const createRoleMutation = useMutation({
    mutationFn: async (params: RoleCreateFormType) => {
      RoleCreateFormSchema.parse(params);

      const { data: createdRole } = await RolesAdapter.createOne({
        data: {
          name: params.name,
          description: params.description,
        },
      });

      if (!createdRole.id) {
        throw new Error("Role was not created.");
      }

      if (params.selected_users) {
        await Promise.all(
          params.selected_users.map((user) =>
            RolesAdapter.members.update({
              roleId: createdRole.id,
              userId: user.id,
            })
          )
        );
      }

      if (params.selected_permissions) {
        // TODO: configuring each permission deeply will come in a future update
        const selectedPermsConfigured =
          params.selected_permissions.map<RolePermissionType>((permName) => ({
            name: permName,
            active: true,
          }));

        await RolesAdapter.permissions.update({
          id: createdRole.id,
          data: selectedPermsConfigured,
        });
      }

      return createdRole;
    },
    onSuccess: (createdRole, mutationData) => {
      queryClient.invalidateQueries({ queryKey: ["roles"] });
      mutationData.selected_users?.forEach((userId) =>
        queryClient.invalidateQueries({ queryKey: ["users", userId] })
      );
      addAlert?.({
        variant: "success",
        message: "Successfully created role",
      });
      navigate({
        pathname: linkToRoleDetailPage(createdRole.id),
      });
    },
    onError: (err) => {
      console.error(err);
      addAlert?.({
        variant: "error",
        message: "Failed to create role",
      });
    },
  });

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Details",
        },
        {
          label: "Assign Users (optional)",
        },
        {
          label: "Configure Permissions (optional)",
        },
      ]}
      onSubmit={(values) => {
        // merge the 3-step form...
        const [details, users, permissions] = values as [
          Pick<RoleCreateFormType, "name" | "description">,
          Pick<RoleCreateFormType, "selected_users">,
          Pick<RoleCreateFormType, "selected_permissions">,
        ];

        createRoleMutation.mutate({
          ...details,
          ...users,
          ...permissions,
        });
      }}
      onSubmitMutationState={{
        isSubmitting: createRoleMutation.isLoading,
      }}
    >
      <CreateRolePageContent />
    </MultiStepFormProvider>
  );
};

export const CreateRolePage = () => {
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  if (isOpsExperienceEnabled) {
    return <AccessDeniedLayout />;
  }

  return <CreateRolePageForm />;
};
