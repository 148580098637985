import { CustomAttributeTablePanel } from "#src/batteries-included-components/Panels/TablePanels/CustomAttributeTablePanel";
import { useSearchParams } from "#src/Routers/hooks";
import { AssetType } from "@validereinc/domain";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { CustomAttributeRoute } from "#src/routes/settings/custom-attributes";
import useLocalization from "#src/hooks/useLocalization";

export const CustomAttributePage = () => {
  const { localize } = useLocalization();
  const [searchParams, setSearchParams] = useSearchParams();
  const DEFAULT_TAB_KEY = AssetType.FACILITY;
  const [isFacilitiesEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:facilities",
    permissionQuery: "facilities:read",
  });
  const [isEquipmentEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:equipment",
    permissionQuery: "equipment:read",
  });
  const [isflowsEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:flows",
    permissionQuery: "flows:read",
  });
  const [isDevicesEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:devices",
    permissionQuery: "devices:read",
  });
  const [isAssetGroupsEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:networks",
    permissionQuery: "networks:read",
  });

  const [breadcrumbs] = useBreadcrumbsFromRoute(CustomAttributeRoute);

  return (
    <Page
      title={CustomAttributeRoute.title}
      category={CustomAttributeRoute.previous.title}
      breadcrumbs={breadcrumbs}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      {isFacilitiesEnabled ? (
        <Tab
          title={`${localize("Facility")}`}
          tabKey={AssetType.FACILITY}
        >
          <CustomAttributeTablePanel assetType={AssetType.FACILITY} />
        </Tab>
      ) : null}

      {isEquipmentEnabled ? (
        <Tab
          title={`${localize("Equipment")}`}
          tabKey={AssetType.EQUIPMENT}
        >
          <CustomAttributeTablePanel assetType={AssetType.EQUIPMENT} />
        </Tab>
      ) : null}
      {isflowsEnabled ? (
        <Tab
          title={`${localize("Flow")}`}
          tabKey={AssetType.FLOW}
        >
          <CustomAttributeTablePanel assetType={AssetType.FLOW} />
        </Tab>
      ) : null}
      {isDevicesEnabled ? (
        <Tab
          title={`${localize("Device")}`}
          tabKey={AssetType.DEVICE}
        >
          <CustomAttributeTablePanel assetType={AssetType.DEVICE} />
        </Tab>
      ) : null}
      {isAssetGroupsEnabled ? (
        <Tab
          title={`${localize("Network")}`}
          tabKey={AssetType.ASSET_GROUP}
        >
          <CustomAttributeTablePanel assetType={AssetType.ASSET_GROUP} />
        </Tab>
      ) : null}
    </Page>
  );
};
