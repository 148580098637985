import {
  FilterAreaContextProps,
  useFilterAreaContext,
} from "#src/components/FilterArea";
import {
  convertSavedFiltersToStoredFilters,
  convertStoredFiltersToSavedFilters,
  countAppliedFilters,
  getFilterDisplayValue,
  reduceStoredFiltersToAppliedFilters,
} from "#src/components/FilterArea/FilterArea.helpers";
import {
  SavedFilterTag,
  useSavedFiltersV2,
} from "#src/components/hooks/FilterPanel/useSavedFilters";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { ExceptionUtils } from "#src/utils/exception";
import { getPropertyAsMap } from "#src/utils/objectFormatter";
import { useQuery } from "@tanstack/react-query";
import {
  Accordion,
  Button,
  ButtonWithPopover,
  DataTable,
  Dialog,
  Drawer,
  DrawerProps,
  DropdownInput,
  EmptyState,
  Form,
  Icon,
  RadioInput,
  TextInput,
  useAlert,
  useForm,
  UseFormReturn,
} from "@validereinc/common-components";
import {
  NodeAPISavedFilterType,
  ResponseErrorType,
  ResponseStatusType,
  SavedFilterStatus,
  SavedFilterType,
  UsersAdapter,
  UserType,
} from "@validereinc/domain";
import { toFlattenedObject } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import styles from "./FilterDrawer.module.css";

const cx = classNames.bind(styles);

type FilterDrawerContextProps = {
  /** is the drawer open? */
  isOpen: boolean;
  /** set if the filter drawer is open */
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const FilterDrawerContext = createContext<FilterDrawerContextProps | null>(
  null
);

const useFilterDrawerContext = () => {
  const ctx = useContext(FilterDrawerContext);

  if (!ctx) {
    throw new Error("Must be used within a FilterDrawerProvider");
  }

  return ctx;
};

export type FilterDrawerRootProps = PropsWithChildren<any>;

/**
 * The base component of the FilterDrawer. Renders children as-is wrapped in a
 * context.
 */
const FilterDrawerRoot = ({ children }: FilterDrawerRootProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FilterDrawerContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </FilterDrawerContext.Provider>
  );
};

export type FilterDrawerTriggerProps<TFilters extends Record<string, any>> = {
  children?: (
    props: Pick<FilterAreaContextProps<TFilters>, "numOfFiltersApplied"> &
      Pick<FilterDrawerContextProps, "isOpen" | "setIsOpen"> & {
        label?: string;
        isLoading?: boolean;
      }
  ) => ReactNode;
  /** optionally, provide a label for the trigger of the default UI */
  label?: string;
  /** optionally, define if the trigger of the default UI is in a loading state */
  isLoading?: boolean;
};

/**
 * The trigger for the filter drawer that opens it. Also used to show the number
 * of filters applied. Default UI is provided but can be completely overriden.
 */
const FilterDrawerTrigger = <TFilters extends Record<string, any>>({
  children,
  label,
  isLoading = false,
}: FilterDrawerTriggerProps<TFilters>) => {
  const { isOpen, setIsOpen } = useFilterDrawerContext();
  const { numOfFiltersApplied } = useFilterAreaContext();

  if (!children || typeof children !== "function") {
    return (
      <div className={cx("trigger-container")}>
        <ButtonWithPopover
          label={label ? "" : "Filters"}
          buttonProps={{
            icon: "funnel",
            disabled: isOpen,
            onClick: () => setIsOpen((open) => !open),
            title: label ? "Open Filter Drawer" : "",
            isLoading,
          }}
        >
          {label}
        </ButtonWithPopover>
        {numOfFiltersApplied ? (
          <div className={cx("filters-count")}>{numOfFiltersApplied}</div>
        ) : null}
      </div>
    );
  }

  return (
    <>
      {children({
        isOpen,
        setIsOpen,
        numOfFiltersApplied,
        label,
        isLoading,
      })}
    </>
  );
};

export type FilterDrawerContentProps = PropsWithChildren<{
  /** optionally, override the props of the rendered Drawer component */
  drawerProps?: DrawerProps;
}>;

/**
 * Drawer component's content. Children provided are the drawer's content.
 * Default UI is provided but can be entirely overriden.
 * @see {@link Drawer}
 */
const FilterDrawerContent = <TFilters extends Record<string, any>>({
  children,
  drawerProps,
}: FilterDrawerContentProps) => {
  const { isOpen, setIsOpen } = useFilterDrawerContext();
  const { form, handleClearFilters, handleSubmitFilters } =
    useFilterAreaContext<TFilters>();

  const handleClear = () => {
    handleClearFilters();
    setIsOpen(false);
  };

  const handleSubmit = () => {
    handleSubmitFilters();
    setIsOpen(false);
  };

  const stagedFilters = form.watch();
  const numOfFiltersStaged = useMemo(
    () =>
      countAppliedFilters(reduceStoredFiltersToAppliedFilters(stagedFilters)),
    [stagedFilters]
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Filters"
      actionRow={[
        <Button
          key="reset"
          onClick={handleClear}
        >
          Clear
        </Button>,
        <Button
          key="submit"
          onClick={handleSubmit}
          variant="primary"
        >
          Apply{numOfFiltersStaged ? ` (${numOfFiltersStaged})` : ""}
        </Button>,
      ]}
      {...drawerProps}
    >
      {children}
    </Drawer>
  );
};

export type FilterDrawerSavedFiltersAppliedIndicator<
  TFilters extends Record<string, any>,
> = {
  children?: ({
    isApplied,
  }: {
    /** is a saved filter applied? */
    isApplied: boolean;
    /** details of the applied saved filter */
    appliedSavedFilter: FilterAreaContextProps<TFilters>["appliedSavedFilter"];
  }) => ReactNode;
};

/**
 * Part of the Saved Filters module. Shows what the applied saved filter is, if
 * any. Default UI is provided but can be entirely overriden.
 */
const FilterDrawerSavedFiltersAppliedIndicator = <
  TFilters extends Record<string, any>,
>({
  children,
}: FilterDrawerSavedFiltersAppliedIndicator<TFilters>) => {
  const { appliedSavedFilter, form } = useFilterAreaContext<TFilters>();

  const isApplied =
    !!appliedSavedFilter?.id && !Object.keys(form.formState.dirtyFields).length;

  if (typeof children === "function") {
    return <>{children({ isApplied, appliedSavedFilter })}</>;
  }

  if (!isApplied) return null;

  return (
    <div className={cx("applied-saved-filter-indicator")}>
      <Icon
        variant="archive"
        size={16}
      />
      <p className={cx("applied-saved-filter-text")}>
        Loaded Saved Filters: <strong>{appliedSavedFilter.name}</strong>
      </p>
    </div>
  );
};

export type FilterDrawerFilterKeyValueDisplayProps = {
  /** the label of the filter */
  label: string;
  /** the raw value of the filter. Will be automatically formatted to be
   * displayed. */
  value: any;
  /** optionally, override the default UI */
  children?: (props: {
    label: string;
    value: any;
    displayValue: ReactNode;
  }) => ReactNode;
};

/**
 * Shows a single filter's name and value. Default UI is configured but can be
 * entirely overriden.
 */
const FilterDrawerFilterKeyValueDisplay = ({
  label,
  value,
  children,
}: FilterDrawerFilterKeyValueDisplayProps) => {
  const displayValue = getFilterDisplayValue(value);

  if (typeof children === "function") {
    return <>{children({ label, value, displayValue })}</>;
  }

  return (
    <p className={cx("tag-with-label")}>
      <span
        className={cx("tag-label")}
        id={`${label}-display`}
      >
        {label}
      </span>
      <span
        className={cx("tag-content")}
        aria-describedby={`${label}-display`}
      >
        {displayValue}
      </span>
    </p>
  );
};

export type FilterDrawerSavedFiltersContentProps<
  TFilters extends Record<string, any>,
> = {
  /** configuration to use for saved filters */
  config: Parameters<typeof useSavedFiltersV2>[0];
  /** optionally, override the entire UI for saved filters */
  children?: (
    props: {
      config: Parameters<typeof useSavedFiltersV2>[0];
      /** are any of the filter inputs dirtied? */
      anyDirtyFilters: boolean;
      /** are any filters applied after being converted to be save-ready?  */
      anyFiltersStagedForSaving: boolean;
      /** applied filters converted to be save-ready */
      filtersStagedForSaving: NodeAPISavedFilterType;
      handleSavedFilterSelectionChange: (
        newSavedFilter?: SavedFilterType<NodeAPISavedFilterType>
      ) => Promise<void>;
      handleOverwriteSavedFilter: (
        filterToUpdate: SavedFilterType<NodeAPISavedFilterType> | null
      ) => Promise<ResponseStatusType | ResponseErrorType | undefined>;
      handleDeleteSavedFilter: (
        filterToDelete: SavedFilterType<NodeAPISavedFilterType> | null
      ) => Promise<ResponseStatusType | ResponseErrorType | undefined>;
      isCreateDialogOpen: boolean;
      setIsCreateDialogOpen: Dispatch<SetStateAction<boolean>>;
      isManageDialogOpen: boolean;
      setIsManageDialogOpen: Dispatch<SetStateAction<boolean>>;
      savedFilterToEdit: SavedFilterType<NodeAPISavedFilterType> | null;
      setSavedFilterToEdit: Dispatch<
        SetStateAction<SavedFilterType<NodeAPISavedFilterType> | null>
      >;
      savedFilterToDelete: SavedFilterType<NodeAPISavedFilterType> | null;
      setSavedFilterToDelete: Dispatch<
        SetStateAction<SavedFilterType<NodeAPISavedFilterType> | null>
      >;
      selectionForm: UseFormReturn<{
        savedFilter: string;
      }>;
      saveFiltersForm: UseFormReturn<
        Pick<SavedFilterType<TFilters>, "name" | "status">
      >;
      editFiltersForm: UseFormReturn<
        Pick<SavedFilterType<TFilters>, "name" | "status">
      >;
      savedFiltersMetaUsersMap: Record<string, UserType>;
    } & ReturnType<typeof useSavedFiltersV2>
  ) => ReactNode;
};

/**
 * Part of the Saved Filters module. Default UI is provided but can be entirely overriden.
 * Default UI is meant to be used at the top of the FilterDrawerContent
 * @see {@link FilterDrawerContent}
 */
const FilterDrawerSavedFilters = <TFilters extends Record<string, any>>({
  config,
  children,
}: FilterDrawerSavedFiltersContentProps<TFilters>) => {
  const { addAlert } = useAlert();
  const {
    form,
    handleResetFilters,
    appliedSavedFilter,
    setAppliedSavedFilter,
    filterComparisonOperatorConfig,
  } = useFilterAreaContext<TFilters>();
  const {
    savedFiltersQuery,
    saveFiltersMutation,
    updateFiltersMutation,
    deleteFiltersMutation,
    getSavedFiltersAvailable,
  } = useSavedFiltersV2(config);
  const [listSavedFiltersTableState, updateListSavedFiltersTableState] =
    useTableSortingAndPagination({
      page: 1,
      itemsPerPage: 5,
      sortBy: "name",
      sortDirection: "asc",
    });
  const listSavedFiltersQueryPayload: Parameters<
    typeof config.savedFilterResourceAdapter.getList
  >[0] = {
    filters: {
      entity_type: config.resourceType,
      tag: config.tag ?? SavedFilterTag.LIST,
    },
    page: listSavedFiltersTableState.page,
    pageSize: listSavedFiltersTableState.itemsPerPage,
    sortBy: listSavedFiltersTableState.sortBy,
    sortDirection: listSavedFiltersTableState.sortDirection,
  };
  const listSavedFiltersQuery = useQuery({
    queryKey: ["savedFilters", listSavedFiltersQueryPayload],
    queryFn: () =>
      config.savedFilterResourceAdapter.getList(listSavedFiltersQueryPayload),
    enabled: !children,
  });
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isManageDialogOpen, setIsManageDialogOpen] = useState(false);
  const [savedFilterToEdit, setSavedFilterToEdit] =
    useState<SavedFilterType<NodeAPISavedFilterType> | null>(null);
  const [savedFilterToDelete, setSavedFilterToDelete] =
    useState<SavedFilterType<NodeAPISavedFilterType> | null>(null);
  const selectionForm = useForm({
    values: {
      savedFilter: appliedSavedFilter?.id ?? "",
    },
  });
  const saveFiltersForm = useForm<
    Pick<SavedFilterType<TFilters>, "name" | "status">
  >({
    defaultValues: {
      name: "",
      status: "draft",
    },
  });
  const editFiltersForm = useForm<
    Pick<SavedFilterType<TFilters>, "name" | "status">
  >({
    ...(savedFilterToEdit
      ? {
          defaultValues: {
            name: savedFilterToEdit.name,
            status: savedFilterToEdit.status,
          },
        }
      : {}),
  });
  const metaUserIds = useMemo(() => {
    return Array.from(
      new Set(
        savedFiltersQuery.data?.data.flatMap((sf) => [
          sf.updated_by,
          sf.created_by,
        ])
      )
    );
  }, [savedFiltersQuery.data]);
  const metaUsersQuery = useQuery({
    queryKey: ["users", { filters: { id: metaUserIds } }],
    queryFn: () => UsersAdapter.getList({ filters: { id: metaUserIds } }),
    enabled: !!metaUserIds?.length,
  });
  const metaUsersMap = useMemo(() => {
    return getPropertyAsMap(
      metaUsersQuery.data?.data ?? [],
      "",
      "id"
    ) as Record<string, UserType>;
  }, [metaUsersQuery.data?.data]);

  const filtersStagedForSaving = useMemo(() => {
    return convertStoredFiltersToSavedFilters(
      form.getValues(),
      filterComparisonOperatorConfig
    );
  }, [form]);

  const handleSavedFilterSelectionChange = (
    newSavedFilter?: SavedFilterType<NodeAPISavedFilterType>
  ) => {
    if (!newSavedFilter) {
      selectionForm.reset({ savedFilter: "" });
      return handleResetFilters();
    }

    return handleResetFilters().then(() => {
      setAppliedSavedFilter(newSavedFilter);
      Object.entries(
        convertSavedFiltersToStoredFilters(newSavedFilter.filter, (fieldName) =>
          Object.keys(
            toFlattenedObject(form.getValues(), {
              ignoreUndefined: false,
            })
          ).includes(fieldName)
        )
      ).forEach(([k, v]) => {
        form.setValue(k, v, {
          shouldDirty: false,
          shouldTouch: false,
          shouldValidate: true,
        });
      });
    });
  };

  const handleOverwriteSavedFilter = async (
    savedFilterToUpdate: SavedFilterType<NodeAPISavedFilterType> | null
  ) => {
    if (!savedFilterToUpdate) {
      selectionForm.reset({ savedFilter: "" });
      return Promise.reject();
    }

    try {
      const newSavedFilter = await saveFiltersMutation.mutateAsync({
        name: savedFilterToUpdate.name,
        status: savedFilterToUpdate.status,
        filters: filtersStagedForSaving,
      });
      const resp = await deleteFiltersMutation.mutateAsync({
        id: savedFilterToUpdate.id,
      });
      setAppliedSavedFilter(newSavedFilter.data);
      addAlert({
        message: `Successfully overwrote filters "${savedFilterToUpdate.name}"`,
        variant: "success",
      });

      return resp;
    } catch (err) {
      addAlert({
        message: `Failed to overwrite filters "${savedFilterToUpdate.name}"`,
        variant: "error",
      });

      throw err;
    }
  };

  const handleDeleteSavedFilter = (
    savedFilterToDelete: SavedFilterType<NodeAPISavedFilterType> | null
  ) => {
    if (!savedFilterToDelete) return Promise.reject();

    return deleteFiltersMutation.mutateAsync(
      {
        id: savedFilterToDelete.id,
      },
      {
        onSuccess: () => {
          setAppliedSavedFilter(null);
          addAlert({
            message: `Successfully deleted saved filters "${savedFilterToDelete.name}"`,
            variant: "success",
          });
          updateListSavedFiltersTableState({ page: 1 });
        },
        onError: () => {
          addAlert({
            message: "Failed to delete saved filters",
            variant: "error",
          });
        },
      }
    );
  };

  const anyDirtyFilters = Object.keys(form.formState.dirtyFields).length > 0;
  const anyFiltersStagedForSaving =
    Object.keys(filtersStagedForSaving).length > 0;

  if (typeof children === "function") {
    return (
      <>
        {children({
          config,
          anyFiltersStagedForSaving,
          anyDirtyFilters,
          filtersStagedForSaving,
          savedFiltersQuery,
          savedFiltersMetaUsersMap: metaUsersMap,
          saveFiltersMutation,
          updateFiltersMutation,
          deleteFiltersMutation,
          getSavedFiltersAvailable,
          handleSavedFilterSelectionChange,
          handleOverwriteSavedFilter,
          handleDeleteSavedFilter,
          isCreateDialogOpen,
          setIsCreateDialogOpen,
          isManageDialogOpen,
          setIsManageDialogOpen,
          savedFilterToEdit,
          setSavedFilterToEdit,
          savedFilterToDelete,
          setSavedFilterToDelete,
          selectionForm,
          saveFiltersForm,
          editFiltersForm,
        })}
      </>
    );
  }

  return (
    <>
      <Accordion>
        <Accordion.AccordionPanel
          dataKey="saved-filters"
          title="Saved Filters"
        >
          <div className={cx("card", "inset")}>
            <Form {...selectionForm}>
              <DropdownInput
                label="Saved Filters"
                name="savedFilter"
                options={getSavedFiltersAvailable(
                  savedFiltersQuery.data?.data ?? []
                ).map((f) => ({
                  label: f.name ?? "",
                  value: f.id ?? "",
                }))}
                isSortedAlphabetically
                labelKey="label"
                valueKey="value"
                placeholder="Select a Saved Filter..."
                description="Saved Filters store many filter values for re-use. To save a set of filters, you must click 'Apply Filters' and then 'Save Applied Filters' will be available to do so as well. To load a set of saved filters, choose a Saved Filter here and click 'Apply Filters'."
                isOptionalTextShown={false}
                value={appliedSavedFilter?.id ?? ""}
                onChange={(newId) => {
                  if (!newId) {
                    setAppliedSavedFilter(null);
                    return;
                  }

                  const allSavedFilters = savedFiltersQuery.data?.data ?? [];
                  const actualSavedFilter = allSavedFilters.find(
                    (f) => f.id === newId
                  );

                  handleSavedFilterSelectionChange(actualSavedFilter);
                }}
                isBusy={
                  saveFiltersMutation.isLoading ||
                  updateFiltersMutation.isLoading ||
                  deleteFiltersMutation.isLoading
                }
                isLoading={savedFiltersQuery.isLoading}
              />
            </Form>
            <div className={cx("actions")}>
              {appliedSavedFilter &&
              anyFiltersStagedForSaving &&
              anyDirtyFilters ? (
                <Button
                  variant="outline"
                  icon="floppy-disk"
                  size="small"
                  onClick={() => {
                    handleOverwriteSavedFilter(appliedSavedFilter);
                  }}
                  isLoading={
                    saveFiltersMutation.isLoading &&
                    deleteFiltersMutation.isLoading
                  }
                  disabled={
                    saveFiltersMutation.isLoading ||
                    deleteFiltersMutation.isLoading
                  }
                >
                  Overwrite Saved Filter
                </Button>
              ) : null}
              <Button
                variant="outline"
                icon="floppy-disk"
                size="small"
                onClick={() => setIsCreateDialogOpen(true)}
                disabled={!anyFiltersStagedForSaving}
              >
                Save Applied Filters
              </Button>
              <Button
                variant="outline"
                icon="gear-six"
                size="small"
                onClick={() => setIsManageDialogOpen(true)}
              >
                Manage Saved Filters
              </Button>
            </div>
          </div>
        </Accordion.AccordionPanel>
      </Accordion>
      <Dialog
        title="Save Filters"
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        actionRow={[
          <Button
            key="save"
            variant="primary"
            disabled={!anyFiltersStagedForSaving}
            onClick={saveFiltersForm.handleSubmit((values) => {
              saveFiltersMutation.mutate(
                {
                  name: values.name,
                  status: values.status,
                  filters: filtersStagedForSaving,
                },
                {
                  onSuccess: (data) => {
                    setIsCreateDialogOpen(false);
                    saveFiltersForm.reset();
                    addAlert({
                      message: `Successfully saved filters as "${data.data.name}"`,
                      variant: "success",
                    });
                    form.reset(undefined, {
                      keepValues: true,
                      keepDirty: false,
                    });
                    setAppliedSavedFilter(data.data);
                  },
                  onError: (err) => {
                    addAlert({
                      message: "Failed to save filters",
                      variant: "error",
                    });
                    ExceptionUtils.reportException(err, "error", {
                      hint: "Failed to save filters",
                    });
                  },
                }
              );
            })}
          >
            Save
          </Button>,
        ]}
      >
        <Form {...saveFiltersForm}>
          <div className={cx("saved-filters-preview")}>
            {anyFiltersStagedForSaving ? (
              <>
                <p className={cx("saved-filters-preview-title")}>
                  The following filter configurations will be saved:
                </p>
                <div className={cx("saved-filters-preview-content")}>
                  {Object.entries(filtersStagedForSaving)
                    .filter(([k]) =>
                      config.filterBlacklist?.length
                        ? !config.filterBlacklist.includes(k)
                        : true
                    )
                    .map(([k, v]) => (
                      <FilterDrawerFilterKeyValueDisplay
                        key={k}
                        label={k}
                        value={v}
                      />
                    ))}
                </div>
              </>
            ) : (
              <EmptyState
                title="No filters applied to save"
                suggestion="Set some filters and apply them, to then save them."
              />
            )}
          </div>
          <TextInput
            name="name"
            label="Name"
            isRequired
          />
          <RadioInput
            name="status"
            inputId="status"
            label="Access"
            isOptionalTextShown={false}
            options={[
              {
                label: "Just for me",
                value: SavedFilterStatus.DRAFT,
              },
              {
                label: "For the whole company",
                value: SavedFilterStatus.ACTIVE,
              },
            ]}
            labelKey="label"
            valueKey="value"
          />
        </Form>
      </Dialog>
      <Dialog
        title="Edit Saved Filters"
        isOpen={!!savedFilterToEdit}
        onClose={() => setSavedFilterToEdit(null)}
        actionRow={[
          <Button
            key="edit"
            variant="primary"
            disabled={!savedFilterToEdit}
            onClick={editFiltersForm.handleSubmit((values) => {
              if (!savedFilterToEdit) return;

              updateFiltersMutation.mutate(
                {
                  id: savedFilterToEdit.id,
                  name: values.name,
                  status: values.status,
                },
                {
                  onSuccess: (data) => {
                    if (
                      appliedSavedFilter &&
                      data.data.id === appliedSavedFilter.id
                    ) {
                      setAppliedSavedFilter(data.data);
                    }

                    setSavedFilterToEdit(null);
                    addAlert({
                      message: `Successfully updated saved filter from "${savedFilterToEdit.name}" to "${data.data.name}"`,
                      variant: "success",
                    });
                    editFiltersForm.reset(undefined, {
                      keepDefaultValues: true,
                    });
                  },
                  onError: (err) => {
                    addAlert({
                      message: "Failed to update saved filters",
                      variant: "error",
                    });
                    ExceptionUtils.reportException(err, "error", {
                      hint: "Failed to update saved filters",
                    });
                  },
                }
              );
            })}
          >
            Save
          </Button>,
        ]}
      >
        <Form {...editFiltersForm}>
          <div className={cx("saved-filters-preview")}>
            {Object.keys(savedFilterToEdit?.filter ?? {}).length ? (
              <>
                <p className={cx("saved-filters-preview-title")}>
                  The following filter configurations are saved:
                </p>
                <div className={cx("saved-filters-preview-content")}>
                  {Object.entries(savedFilterToEdit?.filter ?? {}).map(
                    ([k, v]) => (
                      <FilterDrawerFilterKeyValueDisplay
                        key={k}
                        label={k}
                        value={v}
                      />
                    )
                  )}
                </div>
              </>
            ) : (
              <EmptyState title="No filters saved" />
            )}
          </div>
          <TextInput
            name="name"
            label="Name"
            isRequired
          />
          <RadioInput
            name="status"
            inputId="status"
            label="Access"
            isOptionalTextShown={false}
            options={[
              {
                label: "Just for me",
                value: SavedFilterStatus.DRAFT,
              },
              {
                label: "For the whole company",
                value: SavedFilterStatus.ACTIVE,
              },
            ]}
            labelKey="label"
            valueKey="value"
          />
        </Form>
      </Dialog>
      <Dialog
        title="Manage Saved Filters"
        isOpen={isManageDialogOpen}
        onClose={() => setIsManageDialogOpen(false)}
        actionRow={[
          <Button
            key="done"
            onClick={() => setIsManageDialogOpen(false)}
          >
            Done
          </Button>,
        ]}
      >
        <DataTable
          items={getSavedFiltersAvailable(
            listSavedFiltersQuery.data?.data ?? []
          )}
          headers={[
            {
              key: "name",
              label: "Name",
            },
            {
              key: "status",
              label: "Access",
              renderComponent: ({ value }) => {
                return value === "active" ? "Whole Company" : "Only Me";
              },
            },
            {
              key: "filter",
              label: "Filters",
              tooltip:
                "Only the results that satisfy ALL of the filters in a set are returned, unless the feature you're using overrides that behavior under-the-hood.",
              renderComponent: ({ value }) => {
                return (
                  <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
                    {Object.entries(value ?? {}).map(([k, v]) => (
                      <FilterDrawerFilterKeyValueDisplay
                        key={k}
                        label={k}
                        value={v}
                      />
                    ))}
                  </div>
                );
              },
            },
            {
              key: "updated_at",
              label: "Updated At",
              renderComponent: ({ value }) => (
                <DataTable.DataRow.DateCell
                  withTime
                  value={value}
                />
              ),
            },
            {
              key: "updated_by",
              label: "Updated By",
              renderComponent: ({ value }) => (
                <DataTable.DataRow.TextCell
                  value={metaUsersMap[value]?.name ?? "n/a"}
                />
              ),
            },
            {
              key: "created_by",
              label: "Created By",
              renderComponent: ({ value }) => (
                <DataTable.DataRow.TextCell
                  value={metaUsersMap[value]?.name ?? "n/a"}
                />
              ),
            },
          ]}
          getItemId={(item) => item?.id}
          isLoading={listSavedFiltersQuery.isLoading}
          emptyStateProps={{
            title: "There are no Saved Filters",
            suggestion:
              "Try applying some filters and saving them as a Saved Filter",
          }}
          pagination={{
            ...listSavedFiltersTableState,
            page: listSavedFiltersTableState.page,
            itemsPerPage: listSavedFiltersTableState.itemsPerPage,
            total: listSavedFiltersQuery.data?.total_entries ?? 0,
          }}
          sorting={{
            sortBy: listSavedFiltersTableState.sortBy,
            sortDirection: listSavedFiltersTableState.sortDirection,
          }}
          onSortChange={updateListSavedFiltersTableState}
          onPaginationChange={updateListSavedFiltersTableState}
          getItemActions={({ item }) => [
            {
              label: "Apply",
              buttonProps: {
                icon: "check-circle",
                onClick: () => {
                  handleSavedFilterSelectionChange(item);
                  setIsManageDialogOpen(false);
                },
              },
            },
            {
              label: "Edit",
              buttonProps: {
                icon: "pencil-simple",
                onClick: () => {
                  setSavedFilterToEdit(item);
                },
              },
            },
            {
              label: "Delete",
              buttonProps: {
                icon: "trash",
                variant: "error-outline",
                onClick: () => {
                  setSavedFilterToDelete(item);
                },
              },
            },
          ]}
        />
      </Dialog>
      <Dialog
        isOpen={savedFilterToDelete}
        onClose={() => setSavedFilterToDelete(null)}
        title="Delete Saved Filter"
        actionRow={[
          <Button
            key="delete"
            variant="error"
            onClick={() => {
              handleDeleteSavedFilter(savedFilterToDelete);
              setSavedFilterToDelete(null);
            }}
          >
            Yes
          </Button>,
        ]}
      >
        Are you sure you want to delete Saved Filter
        {savedFilterToDelete?.name ? ` "${savedFilterToDelete.name}"` : ""}?
      </Dialog>
    </>
  );
};

const Root = FilterDrawerRoot;
const Trigger = FilterDrawerTrigger;
const Content = FilterDrawerContent;
const SavedFilters = FilterDrawerSavedFilters;
const SavedFiltersAppliedIndicator = FilterDrawerSavedFiltersAppliedIndicator;
const FilterKeyValueDisplay = FilterDrawerFilterKeyValueDisplay;
const FilterDrawer = {
  Root,
  Trigger,
  Content,
  SavedFilters,
  SavedFiltersAppliedIndicator,
  FilterKeyValueDisplay,
};

export {
  FilterDrawer,
  FilterDrawerContent,
  FilterDrawerFilterKeyValueDisplay,
  FilterDrawerRoot,
  FilterDrawerSavedFilters,
  FilterDrawerSavedFiltersAppliedIndicator,
  FilterDrawerTrigger,
  useFilterDrawerContext,
};
