import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { useNavigate, useParams } from "#routers/hooks";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import { useNetworkCalculationResult } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultPage.helpers";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import useLocalization from "#src/hooks/useLocalization";
import { linkToAssetDetailPage } from "#src/utils/links";
import { Panel, StatusPanelItem } from "@validereinc/common-components";
import {
  AssetGroupAssetType,
  AssetType,
  AssetTypeType,
  AttributeDataType,
  AttributeDataTypeType,
} from "@validereinc/domain";
import isNull from "lodash/isNull";
import React from "react";

export const NetworkCalculationResultDetailPanel = () => {
  const { localize } = useLocalization();
  const navigate = useNavigate();
  const { networkId } = useParams<{ networkId: string }>();

  const customAttributeQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.ASSET_GROUP },
  });
  const customAttributes = customAttributeQuery.data?.data ?? [];

  const { network } = useNetworkCalculationResult();

  const pathname = NetworkDetailRoute.toLink({ pathParams: { networkId } });

  const getAssetTypeCount = (assetType: AssetTypeType) =>
    network?.assets?.filter(
      ({ asset_type }: AssetGroupAssetType) => asset_type === assetType
    )?.length ?? "-";

  const items = [
    {
      label: "Network",
      value: (
        <RoutingLink
          to={pathname}
          onClick={() => {
            navigate({
              pathname,
            });
          }}
        >
          {network?.name}
        </RoutingLink>
      ),
    },
    {
      label: localize("flow_plural"),
      value: getAssetTypeCount(AssetType.FLOW),
    },

    ...customAttributes.map(
      ({
        field_name,
        display_name,
        data_type,
      }: {
        field_name: string;
        display_name: string;
        data_type: AttributeDataTypeType;
      }) => {
        const value = network?.custom_attributes?.[field_name];

        if (typeof value === "undefined" || isNull(value)) {
          return {
            label: display_name,
            value: "-",
          };
        }

        switch (data_type) {
          case AttributeDataType.LOOKUP:
            if (
              typeof value !== "object" ||
              !("id" in value) ||
              !("name" in value)
            ) {
              return {
                label: display_name,
                value: "-",
              };
            }
            switch (value.entity_type) {
              case AssetType.FACILITY:
              case AssetType.EQUIPMENT:
              case AssetType.FLOW:
              case AssetType.DEVICE:
              case AssetType.ASSET_GROUP:
                return {
                  label: display_name,
                  value: (
                    <RoutingLink
                      to={linkToAssetDetailPage(value.entity_type, value.id)}
                    >
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              default:
                return {
                  label: display_name,
                  value: "-",
                };
            }
          default: {
            return {
              label: display_name,
              value: Array.isArray(value) ? value.join(",") : value,
            };
          }
        }
      }
    ),
  ];

  return (
    <Panel
      title={"Network Details"}
      isFluidY={false}
    >
      {items.map((item) => (
        <StatusPanelItem
          key={item.label}
          item={item}
        />
      ))}
    </Panel>
  );
};
