import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useGetOneUser } from "#src/components/hooks/adapters/useUsers";
import config from "#src/config";
import { linkToAssetDetailPage } from "#src/utils/links";
import { useQuery } from "@tanstack/react-query";
import {
  Accordion,
  Banner,
  Drawer,
  EmptyState,
  Icon,
  KeyValueList,
  KeyValueTileGrid,
  Pill,
  type PillProps,
} from "@validereinc/common-components";
import {
  ResourceDefinitions,
  Resources,
  TemplatedConfigurationRunAdapter,
  type TemplatedConfigurationResourceStatus,
  type TemplatedConfigurationRunCreatedType,
  type TemplatedConfigurationRunStatus,
} from "@validereinc/domain";
import {
  booleanFormatter,
  convertUnderscoresToSpaces,
  dateFormatter,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import startCase from "lodash/startCase";
import React from "react";
import styles from "./TemplatedConfigurationRunDrawer.module.css";

const cx = classNames.bind(styles);

export const TemplatedConfigurationRunDrawer = ({
  onClose,
  runDetails,
}: {
  onClose: () => void;
  runDetails: Pick<
    TemplatedConfigurationRunCreatedType,
    "id" | "templated_configuration_name"
  > | null;
}) => {
  const templatedConfigurationRunQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      runDetails?.templated_configuration_name,
      runDetails?.id,
    ],
    queryFn: () => {
      if (!runDetails?.templated_configuration_name || !runDetails?.id) return;

      return TemplatedConfigurationRunAdapter.getOne({
        id: runDetails.id,
        meta: {
          templated_configuration_name: runDetails.templated_configuration_name,
        },
      });
    },
    enabled: !!runDetails?.templated_configuration_name && !!runDetails.id,
    staleTime: 5 * 60 * 1000,
  });

  const createdByUserQuery = useGetOneUser({
    id: templatedConfigurationRunQuery.data?.data.created_by ?? undefined,
  });

  const resultsHasError =
    !!templatedConfigurationRunQuery.data?.data.results?.error;

  return (
    <Drawer
      title="Configuration Template Submission"
      isOpen={!!runDetails}
      onClose={onClose}
      size="md"
      actionRow={[]}
    >
      <h1 className={cx("title")}>
        {
          templatedConfigurationRunQuery.data?.data.templated_configuration
            .display_name
        }
        {templatedConfigurationRunQuery.data?.data.status ? (
          <Pill
            variant={
              (
                {
                  partial: "warning",
                  failed: "error",
                  submitted: "info",
                  success: "success",
                } satisfies Record<
                  (typeof TemplatedConfigurationRunStatus)[keyof typeof TemplatedConfigurationRunStatus],
                  PillProps["variant"]
                >
              )[templatedConfigurationRunQuery.data.data.status]
            }
          >
            {startCase(templatedConfigurationRunQuery.data.data.status)}
          </Pill>
        ) : null}
      </h1>
      <KeyValueTileGrid
        size="small"
        isLoading={templatedConfigurationRunQuery.isLoading}
        data={[
          {
            title: "Created At",
            value: templatedConfigurationRunQuery.data?.data.created_at
              ? dateFormatter(
                  new Date(templatedConfigurationRunQuery.data.data.created_at)
                )
              : "-",
            isLoading: templatedConfigurationRunQuery.isLoading,
          },
          {
            title: "Created By",
            value: createdByUserQuery.data?.name ?? "-",
            isLoading:
              templatedConfigurationRunQuery.isLoading ||
              createdByUserQuery.isLoading,
          },
        ]}
      />
      <div className={cx("section")}>
        <h2 className={cx("sectionHeading", "h2")}>Inputs</h2>
        <KeyValueList
          variant="shaded"
          data={Object.entries(
            templatedConfigurationRunQuery.data?.data.inputs ?? {}
          ).map(([key, val]) => ({
            title: startCase(key),
            value: val,
          }))}
          isLoading={templatedConfigurationRunQuery.isLoading}
        />
        {!templatedConfigurationRunQuery.data?.data.inputs ? (
          <EmptyState
            title="No Resources were created"
            icon={<Icon variant="stack" />}
          />
        ) : null}
        {templatedConfigurationRunQuery.data?.data.custom_attribute_inputs ? (
          <>
            <h3 className={cx("sectionHeading", "h3", "closeToHeadingBelow")}>
              Custom Attributes
            </h3>
            {Object.entries(
              templatedConfigurationRunQuery.data.data
                .custom_attribute_configuration
            ).map(([key, val]) => {
              const associatedResource =
                templatedConfigurationRunQuery.data?.data.resources?.find(
                  (r) => r.template_resource_id === key
                );

              return (
                <div
                  className={cx("section")}
                  key={key}
                >
                  <h4 className={cx("sectionHeading", "h4")}>
                    For {associatedResource?.name ?? key}
                  </h4>
                  <KeyValueList
                    variant="shaded"
                    data={Object.entries(val ?? {}).map(([key, val]) => ({
                      title: startCase(key),
                      value: val,
                    }))}
                    isLoading={templatedConfigurationRunQuery.isLoading}
                  />
                </div>
              );
            })}
          </>
        ) : null}
      </div>
      <div className={cx("section")}>
        <h2 className={cx("sectionHeading", "h2", "closeToHeadingBelow")}>
          Results
        </h2>
        {resultsHasError ? (
          <Banner
            variant="error"
            titleText={
              templatedConfigurationRunQuery.data?.data.results?.error?.message
            }
            className={cx("ctxBanner")}
          />
        ) : null}
        <h3 className={cx("sectionHeading", "h3", "closeToHeadingAbove")}>
          Resources
        </h3>
        {!templatedConfigurationRunQuery.data?.data.resources?.length ? (
          <EmptyState
            title="No Resources were created"
            icon={<Icon variant="stack" />}
          />
        ) : null}
        {templatedConfigurationRunQuery.data?.data.resources.length ? (
          <Accordion defaultActiveKeys={[]}>
            {[...(templatedConfigurationRunQuery.data?.data.resources ?? [])]
              .sort((resA, resB) => {
                if (resA.is_primary) {
                  return -1;
                } else if (resB.is_primary) {
                  return 1;
                } else {
                  const titleA = `${
                    ResourceDefinitions[resA.type]?.label.singular ??
                    (resA.type
                      ? convertUnderscoresToSpaces(resA.type)
                      : null) ??
                    `ID: ${resA.template_resource_id}`
                  }`;
                  const titleB = `${
                    ResourceDefinitions[resB.type]?.label.singular ??
                    (resB.type
                      ? convertUnderscoresToSpaces(resB.type)
                      : null) ??
                    `ID: ${resB.template_resource_id}`
                  }`;

                  return titleA.localeCompare(titleB, config.locale);
                }
              })
              .map((resource) => (
                <Accordion.AccordionPanel
                  key={resource.template_resource_id}
                  title={`${
                    ResourceDefinitions[resource.type]?.label.singular ??
                    (resource.type
                      ? convertUnderscoresToSpaces(resource.type)
                      : null) ??
                    `ID: ${resource.template_resource_id}`
                  }${resource.is_primary ? " (Primary)" : ""}`}
                  dataKey={resource.template_resource_id}
                >
                  {resource.status === "failed" && resource.errors?.message ? (
                    <Banner
                      variant="error"
                      titleText={resource.errors.message}
                    />
                  ) : null}
                  <KeyValueList
                    data={[
                      {
                        title: "Status",
                        value: (
                          <Pill
                            variant={
                              (
                                {
                                  failed: "error",
                                  submitted: "info",
                                  success: "success",
                                } satisfies Record<
                                  (typeof TemplatedConfigurationResourceStatus)[keyof typeof TemplatedConfigurationResourceStatus],
                                  PillProps["variant"]
                                >
                              )[resource.status]
                            }
                          >
                            {startCase(resource.status)}
                          </Pill>
                        ),
                      },
                      {
                        title: "Is Primary?",
                        value: booleanFormatter(resource.is_primary),
                      },
                      {
                        title: "ID",
                        value: resource.id,
                      },
                      {
                        title: "Name",
                        value:
                          resource.id && resource.type && resource.name ? (
                            <RoutingLink
                              to={linkToAssetDetailPage(
                                resource.type,
                                resource.id
                              )}
                            >
                              {resource.name}
                            </RoutingLink>
                          ) : (
                            "-"
                          ),
                      },
                      {
                        title: "Type",
                        value: resource.type
                          ? ResourceDefinitions[resource.type]?.label
                              .singular ??
                            resource.type ??
                            "n/a"
                          : "-",
                      },
                    ]}
                    isLoading={templatedConfigurationRunQuery.isLoading}
                  />
                </Accordion.AccordionPanel>
              ))}
          </Accordion>
        ) : null}
      </div>
    </Drawer>
  );
};
