import { FormCategoryType, type FormSchemaType } from "../schemas";
import type {
  DomainModelType,
  FilterObjectType,
  GetListRequestType,
  GetListResponseType,
} from "../util";
import { getFilterObject } from "../util";
import { restAPI } from "./api";

const eventSchemaEndpoint = "/event_schemas";

export type EventSchemaGetOneLookupType = {
  eventSchemaId: string;
};

export type EventSchemaGetListFilterType = FilterObjectType<{
  name: string;
  version: string;
  status: "active" | "inactive";
}>;

export type EventAssociatedFormSchemaType = Pick<
  FormSchemaType,
  "id" | "name" | "description"
> & { required: boolean; form_category: Pick<FormCategoryType, "id" | "name"> };

export type EventSchemaType = {
  name: string;
  version: string;
  priority: string;
  status: "active" | "inactive";
  description: string;
  config: {
    has_due_date: boolean;
    due_date: {
      relative_time: boolean;
      time_period: string;
      duration: number;
    };
    has_forms: boolean;
    forms: {
      required_templates?: string[];
      suggested_templates?: string[];
    };
    has_alerts: boolean;
    has_equipment: boolean;
    equipment: {
      is_mandatory: boolean;
      has_source: boolean;
    };
    has_devices: boolean;
  };
  form_schemas: EventAssociatedFormSchemaType[];
  event_category_id: string;
  company_id: string;
} & DomainModelType;

export const EventSchemaDomain = {
  getList: async ({
    filters,
    page,
    pageSize,
    sortBy,
    sortDirection,
  }: GetListRequestType<EventSchemaGetListFilterType>): Promise<
    GetListResponseType<EventSchemaType>
  > =>
    await restAPI.nodeAPI.POST({
      endpoint: `${eventSchemaEndpoint}/search`,
      body: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    }),

  getOne: async ({
    eventSchemaId,
  }: EventSchemaGetOneLookupType): Promise<EventSchemaType> =>
    await restAPI.nodeAPI.GET({
      endpoint: `${eventSchemaEndpoint}/${eventSchemaId}`,
    }),
};
