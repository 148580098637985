import {
  DEFAULT_QUERY_OPTIONS,
  UseQueryOptionsType,
  useGetOne,
  useUpdateOne,
} from "#hooks/adapters/adapterUtils";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import { Device, DeviceType, Resources } from "@validereinc/domain";
import { dateFormatter, downloadLink } from "@validereinc/utilities";
import { DEFAULT_INVALIDATE_OPTIONS } from "../adapterUtils";

export const DEVICE_QUERY_KEY = "device";

export const useListDevices = (
  apiParams: Parameters<typeof Device.getList>[0] = {},
  options: UseQueryOptionsType<Awaited<ReturnType<typeof Device.getList>>> = {}
) =>
  useQuery({
    queryKey: [DEVICE_QUERY_KEY, apiParams] as const,
    queryFn: ({ queryKey: [_, params] }) => Device.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useExportDevices = (apiParams = {}, exportName?: string) => {
  const { addAlert } = useAlert();
  const {
    v2: { companyInfo },
  } = useAuthenticatedContext();

  const getFileName = () =>
    [
      companyInfo?.company?.name,
      exportName,
      "Device_List",
      dateFormatter(new Date()),
    ]
      .filter((part) => !!part)
      .join("_");

  return useMutation({
    mutationFn: async () => {
      const report = await Device.exportList(apiParams);
      downloadLink(report.s3_download_link ?? "", getFileName());
    },
    onError: (error) => {
      console.error(error);
      addAlert?.({
        variant: "error",
        message: "Unable to export device list",
      });
    },
  });
};

export const useDeleteDevice = (options?: {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}) => {
  const { onSuccess, ...otherOptions } = options ?? {};
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (deviceId: string) => {
      try {
        await Device.deleteOne({ id: deviceId });
      } catch (caught) {
        console.error("Error deleting form", caught);

        throw caught;
      }
    },
    onSuccess: () => {
      if (onSuccess) onSuccess();
      return queryClient.invalidateQueries({
        queryKey: [DEVICE_QUERY_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
    ...otherOptions,
  });
};

export const useGetOneDevice = useGetOne<DeviceType>(
  Device.getOne,
  Resources.DEVICE
);

export const useUpdateDevice = useUpdateOne<DeviceType>(
  Device.updateOne,
  Resources.DEVICE
);
