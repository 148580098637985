import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns";
import { FilterArea } from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { SavedFilterTag } from "#src/components/hooks/FilterPanel/useSavedFilters";
import {
  EQUIPMENT_STATUS_OPTIONS,
  FACILITY_STATUS_OPTIONS,
} from "#src/constants";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  EquipmentDomain,
  EquipmentSchema,
  Resources,
} from "@validereinc/domain";
import React from "react";

export const EquipmentViewFilterArea = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { localize } = useLocalization();

  return (
    <FilterArea.Root<{
      period: { from: Date; to: Date };
    }>
      storageKey={viewConfigStorageKey}
      defaultValues={{
        period: DEFAULT_DATE_RANGES.currentWholeMonth,
      }}
    >
      <FilterArea.Container
        aria-label={`View Filters for ${localize(`${AssetType.EQUIPMENT}_plural`)}`}
      >
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginBottom: 16,
              }}
            >
              <DateSelectorInput
                name={"period"}
                variant="month"
                label="Period"
                isInline
                isLabelShown={false}
                onChange={(val) => handleOnChange(val, "period")}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const EquipmentTableFilterAreaContent = ({
  hasCustomAttributeFilters,
  hasFacilityFilters,
}: {
  hasCustomAttributeFilters?: boolean;
  hasFacilityFilters?: boolean;
}) => {
  const { localize } = useLocalization();
  const { customAttributeFilters: equipmentCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.EQUIPMENT,
      enabled: hasCustomAttributeFilters,
    });
  const { customAttributeFilters: facilityCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.FACILITY,
      prefix: "facility.custom_attributes",
      enabled: hasCustomAttributeFilters,
    });
  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];

  return (
    <>
      <Accordion defaultActiveKeys={[AssetType.EQUIPMENT]}>
        <Accordion.AccordionPanel
          dataKey={AssetType.EQUIPMENT}
          title={localize(`${AssetType.EQUIPMENT}_plural`)}
        >
          <DropdownInput
            label={`${localize("Equipment")} Type`}
            name="type.id"
            options={equipmentTypes}
            placeholder="Select Type..."
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          <DropdownInput
            label="Status"
            name="status"
            options={EQUIPMENT_STATUS_OPTIONS}
            placeholder="Select Status..."
            labelKey="label"
            valueKey="value"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          {hasCustomAttributeFilters ? equipmentCustomAttributeFilters : null}
        </Accordion.AccordionPanel>
        {hasFacilityFilters ? (
          <Accordion.AccordionPanel
            dataKey={AssetType.FACILITY}
            title={localize(`${AssetType.FACILITY}_plural`)}
          >
            <FacilityDropdownInput
              name="facility.id"
              placeholder={`Select ${localize(`${AssetType.FACILITY}_plural`)}...`}
              isMulti
              isFluid
              isOptionalTextShown={false}
            />
            <DropdownInput
              name="facility.status"
              label={`${localize(AssetType.FACILITY)} Status`}
              placeholder="Select Status..."
              options={FACILITY_STATUS_OPTIONS}
              labelKey="label"
              valueKey="value"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
            {hasCustomAttributeFilters ? facilityCustomAttributeFilters : null}
          </Accordion.AccordionPanel>
        ) : null}
      </Accordion>
    </>
  );
};

export const EquipmentTableFilterArea = ({
  filterConfigStorageKey,
  hasCustomAttributeFilters = true,
  hasFacilityFilters = true,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  hasCustomAttributeFilters?: boolean;
  hasFacilityFilters?: boolean;
}) => {
  const { localize } = useLocalization();
  const equipmentSchemaKeys = EquipmentSchema.keyof().Enum;

  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container
        aria-label={`Filters for ${localize(`${AssetType.EQUIPMENT}_plural`)}`}
      >
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, marginBottom: 0 }}>
                <TextInput
                  name={equipmentSchemaKeys.name}
                  label="Search"
                  isLabelShown={false}
                  placeholder={`Search ${localize(`${AssetType.EQUIPMENT}_plural`)}...`}
                  type="search"
                  isInline
                  onChange={(val) =>
                    handleOnChange(val, equipmentSchemaKeys.name)
                  }
                />
              </div>
            )}
          </FilterArea.Content>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <FilterDrawer.SavedFilters
              config={{
                resourceType: Resources.EQUIPMENT,
                savedFilterResourceAdapter: EquipmentDomain.savedFilters,
                tag: SavedFilterTag.LIST,
                filterBlacklist: ["period.from", "period.to"],
              }}
            />
            <FilterDrawer.SavedFiltersAppliedIndicator />
            <EquipmentTableFilterAreaContent
              hasCustomAttributeFilters={hasCustomAttributeFilters}
              hasFacilityFilters={hasFacilityFilters}
            />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
