import {
  AssetTypeType,
  FormSchemaQuestionType,
  FormSchemaSectionType,
  FormSubmissionAnswerType,
  FormSubmissionType,
  type FormSchemaQuestionAnswerType,
  type FormSchemaType,
  type FormSubmissionAnswersBySectionMapType,
  type FormSubmissionAnswersInFormType,
} from "@validereinc/domain";
import parseISO from "date-fns/parseISO";
import get from "lodash/get";
import isObject from "lodash/isObject";
import isPlainObject from "lodash/isPlainObject";
import { UseFormReturn } from "react-hook-form";

export const $in = ({
  value,
  comparator,
}: {
  value: FormSchemaQuestionAnswerType;
  comparator: Array<string | number | boolean>;
}) =>
  Array.isArray(value)
    ? comparator.some(
        (comparatorValue) =>
          (value as number[]).includes(comparatorValue as number) ||
          (value as string[]).includes(comparatorValue as string)
      )
    : comparator.includes(value);

export const $eq = ({
  value,
  comparator,
}: {
  value: FormSchemaQuestionAnswerType | Date;
  comparator: FormSchemaQuestionAnswerType | Date;
}) => {
  switch (typeof value) {
    case "boolean":
      return value === comparator;
    case "string":
      return value === String(comparator);
    case "number":
      return value === Number(comparator);
    case "object": {
      if (Array.isArray(value)) {
        return value.every((v, idx) => {
          if (Array.isArray(comparator)) {
            return v === comparator[idx];
          } else {
            return v === comparator;
          }
        });
      } else if (value instanceof Date) {
        if (comparator instanceof Date) {
          return value.getTime() === comparator.getTime();
        } else if (typeof comparator === "string") {
          return value.getTime() === parseISO(comparator).getTime();
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    default:
      return false;
  }
};

export const $lt = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value < comparator;

export const $lte = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value <= comparator;

export const $gt = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value > comparator;

export const $gte = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value >= comparator;

export const ConditionFunctions = {
  $in,
  $eq,
  $lt,
  $lte,
  $gt,
  $gte,
};

export type ConditionFunctionTypes = keyof typeof ConditionFunctions;

type ConditionStatement =
  | FormSchemaQuestionAnswerType
  | Date
  | Record<ConditionFunctionTypes, FormSchemaQuestionAnswerType | Date>;

export type QuestionConditionType = Record<string, ConditionStatement>;

type FormQuestionConditions =
  | QuestionConditionType
  | { $or: QuestionConditionType[] }
  | object;

const isQuestionMatched = ({
  values,
  fieldName,
  key,
  value,
}: {
  values: { answers: FormSubmissionAnswersInFormType };
  fieldName: string;
  key: string;
  value: ConditionStatement;
}) => {
  const parentFieldName = getParentQuestionName({
    currentQuestionId: fieldName,
    formValues: values,
    questionIdToFind: key,
  });
  return !isPlainObject(value)
    ? $eq({
        value: value as FormSchemaQuestionAnswerType | Date,
        comparator: get(values, getValuePathFromQuestionPath(parentFieldName)),
      })
    : Object.entries(value).every(([condition, comparator]) => {
        return ConditionFunctions?.[condition as ConditionFunctionTypes]({
          value: get(
            values,
            getValuePathFromQuestionPath(parentFieldName)
          ) as number & (FormSchemaQuestionAnswerType | Date),
          comparator,
        });
      });
};

export const areConditionsSatisfied = ({
  conditions,
  values,
  fieldName,
}: {
  conditions?: FormQuestionConditions | string;
  values: { answers: FormSubmissionAnswersInFormType };
  fieldName: string;
}) => {
  if (!conditions) {
    return true;
  }

  let formattedConditions: FormQuestionConditions;

  if (typeof conditions === "string") {
    formattedConditions = JSON.parse(conditions);
  } else {
    formattedConditions = conditions;
  }

  return Object.entries(formattedConditions).every(
    ([key, questionConditions]) => {
      if (key === "$or")
        return questionConditions.find(
          (questionCondition: QuestionConditionType) =>
            Object.entries(questionCondition).every(
              ([questionToFind, conditionStatement]) =>
                isQuestionMatched({
                  values,
                  fieldName,
                  key: questionToFind,
                  value: conditionStatement,
                })
            )
        );
      else
        return isQuestionMatched({
          values,
          fieldName,
          key,
          value: questionConditions,
        });
    }
  );
};

export const getParentQuestionName = ({
  currentQuestionId,
  formValues,
  questionIdToFind,
}: {
  currentQuestionId: string;
  formValues: Record<string, unknown>;
  questionIdToFind: string;
}) => {
  if (currentQuestionId && questionIdToFind && formValues) {
    /**
     * Split a question name from "answers.sectionOne.0.question1"
     * to: ["answers", sectionId, sectionIndex, questionId]
     */
    const [_, sectionId, sectionIndex, __] =
      currentQuestionId?.split(".") ?? [];

    // Try finding a matching parent from the same section:
    const value = get(
      formValues,
      `answers.${sectionId}.${sectionIndex}.${questionIdToFind}`
    );

    if (value) {
      return `answers.${sectionId}.${sectionIndex}.${questionIdToFind}`;
    }

    // If parent is not on the same section,
    // try finding a matching parent by searching all questions
    // using questionId that's not in the same section:
    const fieldName = Object.entries(formValues?.answers ?? {}).find(
      ([key, objectValue]) =>
        key !== sectionId && objectValue?.[0]?.[questionIdToFind]
    )?.[0];

    return `answers.${fieldName}.0.${questionIdToFind}`;
  }
  return "";
};

export const getSmartDefaultValues = (
  formSchema?: FormSchemaType,
  contextForDefaults: {
    now?: string;
    currentUserName?: string;
    associatedAssetId?: string;
    associatedAssetType?: AssetTypeType;
    defaultValues?: Record<string, string>;
  } = { defaultValues: {} }
) => {
  if (!formSchema) return {};

  return {
    answers: formSchema?.config?.sections.reduce(
      (
        total: Record<string, Array<Record<string, FormSubmissionAnswerType>>>,
        { id, questions }: FormSchemaSectionType
      ) => ({
        ...total,
        [id]: [
          questions.reduce<Record<string, FormSubmissionAnswerType>>(
            (questionDefaults, qid) => {
              const question = formSchema?.config?.questions[qid];

              if (!question) return questionDefaults;

              let defaultAnswer =
                contextForDefaults?.defaultValues?.[
                  `$.questions.${qid}.default_answer`
                ] ??
                question?.default_answer ??
                "";

              /**
               * If the default answer type is an object (in this case, CHB-3964),
               * don't use it as a default value at all. Otherwise it will render
               * as [object Object].
               */
              if (
                isObject(defaultAnswer) &&
                "lookup_attribute" in defaultAnswer &&
                "lookup_question_id" in defaultAnswer
              ) {
                defaultAnswer = "";
              }

              questionDefaults[qid] = {
                value: defaultAnswer,
              };

              switch (question.type) {
                case FormSchemaQuestionType.QUESTION:
                  switch (question.data_type) {
                    case "date-time":
                    case "date": {
                      if (
                        questionDefaults[qid]?.value === "$now" &&
                        contextForDefaults.now
                      )
                        questionDefaults[qid] = {
                          value: contextForDefaults.now,
                        };
                      break;
                    }
                    case "string": {
                      if (
                        questionDefaults[qid]?.value === "$user_name" &&
                        contextForDefaults.currentUserName
                      )
                        questionDefaults[qid] = {
                          value: contextForDefaults.currentUserName,
                        };
                      break;
                    }
                    case "lookup": {
                      if (
                        !contextForDefaults.associatedAssetId ||
                        !contextForDefaults.associatedAssetType ||
                        contextForDefaults.associatedAssetType !==
                          question.lookup_entity_type
                      )
                        break;

                      questionDefaults[qid] = {
                        value: contextForDefaults.associatedAssetId,
                      };
                    }
                  }
              }
              return questionDefaults;
            },
            {}
          ),
        ],
      }),
      {}
    ),
  };
};

export const getErrorCount = (
  form: UseFormReturn<Pick<FormSubmissionType, "answers">>
): number =>
  Object.values(form.formState?.errors?.answers ?? {}).reduce(
    (total, value) => {
      if (!Array.isArray(value)) return total;
      const newErrors = value.map((value) => Object.keys(value ?? {})).flat();
      return (total += newErrors.length);
    },
    0
  );

/**
 * Takes a question path in the form of:
 * "answers.<sectionId>.<sectionIndex>.<questionId>"
 * and returns the same path with ".value" appended:
 * "answers.<sectionId>.<sectionIndex>.<questionId>.value"
 */
export const getValuePathFromQuestionPath = (questionPath: string): string =>
  `${questionPath}.value`;

/**
 * Find the answer for a given form question in a form submission's answers data
 * @param allAnswers the form submission's `answers` object
 * @param questionId the question ID to find
 * @param sectionId the section ID that the question actually belongs in
 * @param sectionIdx the section instance (when repeatable can be greater than 0) to find question in first
 * @returns the answer if found, null otherwise
 */
export const findAnswerForQuestion = (
  allAnswers: FormSubmissionAnswersBySectionMapType,
  questionId: string,
  sectionId: string,
  sectionIdx: number,
  providedOpts: {
    /** don't search in repeated sections? */
    ignoreRepeatedSections?: boolean;
  }
) => {
  const opts = Object.assign({ ignoreRepeatedSections: true }, providedOpts);
  // look first in the specified section for the question's answer
  let sourceAnswer = allAnswers[sectionId][sectionIdx][questionId];

  if (sourceAnswer) return sourceAnswer;

  // not found in the same section so look across other sections...
  for (const sectionIdToCheck of Object.keys(allAnswers)) {
    if (sectionId === sectionIdToCheck) {
      // Ignore other possible duplicate sections of the same section ID as the measurement.
      continue;
    }

    if (
      opts.ignoreRepeatedSections &&
      allAnswers[sectionIdToCheck]?.length !== 1
    ) {
      // sections that are repeated are ignored
      continue;
    }

    // when section is not repeated, we just want the first instance (which is
    // the only instance) if ignoreRepeatedSections if off, we still use only
    // the first instance. this is a limitation.
    sourceAnswer = allAnswers[sectionIdToCheck]?.[0]?.[questionId];

    if (sourceAnswer) return sourceAnswer;
  }

  return null;
};
