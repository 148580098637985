import { type UseQueryResult } from "@tanstack/react-query";
import {
  Device,
  EquipmentDomainV2,
  FacilityDomain,
  FlowDomain,
  FormSchemaType,
  GetOneResponseType,
  Resources,
  ResourceServiceType,
  ResourceType,
  type FormSchemaNormalQuestionLookupType,
  type FormSchemaQuestionAnswerType,
  type FormSubmissionAnswersInFormType,
  type ResourceLookupType,
} from "@validereinc/domain";
import { type RecordValueType } from "@validereinc/utilities/lib/types/type";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import { useEffect, useMemo, useState } from "react";
import {
  areConditionsSatisfied,
  findAnswerForQuestion,
  getParentQuestionName,
  getValuePathFromQuestionPath,
} from "./util";
import { usePrevious } from "../../utilities";
import type { UseFormReturn } from "react-hook-form";
import {
  CalculatedFieldService,
  FormCalculatedFieldService,
} from "./calculated_fields";

export type WatchedFields = Record<
  string,
  {
    questionId: string;
    sectionId: string;
    sectionIdx: number;
    lookup?: {
      fieldsToOverride: Array<{
        lookupAttribute: string;
        fieldName: string;
      }>;
      entityType?: ResourceLookupType;
    };
    calculation?: {
      sourceFields: string[];
      sourceFieldValues: Record<string, { value: any; unit?: string }>;
    };
  }
>;

/**
  Get a record of form fields to watch which map to a record of configurations that describe why the field is being watched for value changes.

  The key of the return record is the name of the form field to watch. (e.g. "answers.sectionName.sectionIndex.questionId")
  The value is another record where each key is a feature configuration which has associated logic downstream that will use it to process the watched form field.

  One example of this is a lookup field. The key of this configuration is just "lookup".
  e.g. here's what that would look like...
  {
    "answers.sectionName.sectionIndex.questionId": {
      "lookup": {
        fieldsToOverride: { lookupAttribute: string; fieldName: string; }[];
        entityType: AssetTypeType;
      }
    }
  }

  `entityType` is the lookup entity type of the lookup field. @see {@link ResourceLookupType}
  `fieldsToOverride` is an array of all the question form fields that are affected when the watched lookup form field changes. When the lookup form field changes, we resolve it to the entity's details.
  `lookupAttribute` is the specific attribute name on the resolved entity that needs to be extracted.
  `fieldName` is the name of the form field that needs to be updated with the `lookupAttribute`'s value on the resolved entity.

  e.g. here's a complete example...
  {
    "answers.section1.0.facilityQuestion": {
      fieldsToOverride: [
        {lookupAttribute: 'country', fieldName: 'answers.section1.0.facilityCountry'},
        {lookupAttribute: 'is_facility_clean', fieldName: 'answers.section1.0.facilityCleannessBoolean'},
      ],
      entityType: 'facility'
    },
    ...
  }

  so when a user changes the value of `facilityQuestion` form field, the associated facility is fetched (let's call it `fetchedFacility`) and:
    - value of "facilityCountry" is set fetchedFacility.custom_attributes.country
    - value of "facilityCleannessBoolean" is set fetchedFacility.custom_attributes.is_facility_clean
 */
export const useGenerateFieldsToWatch = ({
  formSchema,
  formValues,
}: {
  formSchema?: FormSchemaType;
  formValues: { answers: FormSubmissionAnswersInFormType };
}): { fieldsToWatch: WatchedFields; evaluationOrder: string[] } =>
  useMemo(() => {
    const fieldsToWatch: WatchedFields = {};
    const evaluationOrder: string[] = [];

    if (isEmpty(formValues) || isEmpty(formValues?.answers))
      return { fieldsToWatch, evaluationOrder: [] };

    formSchema?.config.sections.forEach((section) => {
      const evaluation =
        FormCalculatedFieldService.analyzeEquationsOfQuestionsInSection(
          section.questions,
          formSchema.config.questions,
          {
            parser: (eq) => CalculatedFieldService.getVariables(eq),
            skip: (q) => !q.equation,
          }
        );

      evaluationOrder.push(...evaluation.evaluationOrder);

      if (evaluation.evaluationOrder && evaluation.equationDependencies) {
        const dependentFields = Object.entries(evaluation.equationDependencies);

        // for every repeated section
        for (
          let sectionIndex = 0;
          sectionIndex < formValues.answers[section.id].length;
          sectionIndex++
        ) {
          dependentFields.forEach(([field, sourceFields]) => {
            const fieldNameToWatch = `answers.${section.id}.${sectionIndex}.${field}`;

            if (fieldsToWatch[fieldNameToWatch] === undefined) {
              fieldsToWatch[fieldNameToWatch] = {
                questionId: field,
                sectionId: section.id,
                sectionIdx: sectionIndex,
              };
            }

            fieldsToWatch[fieldNameToWatch].calculation = {
              sourceFields,
              sourceFieldValues: {},
            };
          });
        }
      }

      section.questions.forEach((questionId) => {
        const question = formSchema.config.questions[questionId];
        const defaultAnswer = question?.default_answer ?? "";

        if (isObject(defaultAnswer)) {
          if (
            "lookup_attribute" in defaultAnswer &&
            "lookup_question_id" in defaultAnswer
          ) {
            // for every repeated section
            for (
              let sectionIndex = 0;
              sectionIndex < formValues.answers[section.id].length;
              sectionIndex++
            ) {
              const fieldNameToWatch = getParentQuestionName({
                currentQuestionId: questionId,
                formValues,
                questionIdToFind: defaultAnswer.lookup_question_id,
              });

              const fieldToOverride = {
                lookupAttribute: defaultAnswer.lookup_attribute,
                fieldName: `answers.${section.id}.${sectionIndex}.${questionId}`,
              };

              if (fieldNameToWatch) {
                if (fieldsToWatch[fieldNameToWatch] === undefined) {
                  fieldsToWatch[fieldNameToWatch] = {
                    questionId,
                    sectionId: section.id,
                    sectionIdx: sectionIndex,
                  };
                }

                if (fieldsToWatch[fieldNameToWatch].lookup) {
                  fieldsToWatch[fieldNameToWatch].lookup.fieldsToOverride.push(
                    fieldToOverride
                  );
                } else {
                  const entityType = (
                    formSchema.config.questions[
                      defaultAnswer.lookup_question_id
                    ] as FormSchemaNormalQuestionLookupType
                  )?.lookup_entity_type;

                  fieldsToWatch[fieldNameToWatch].lookup = {
                    entityType,
                    fieldsToOverride: [fieldToOverride],
                  };
                }
              }
            }
          }
        }
      });
    });

    return { fieldsToWatch, evaluationOrder };
  }, [formSchema, JSON.stringify(formValues)]);

const resourceToDomainGetOneMap: Partial<
  Record<ResourceType, ResourceServiceType<any>["getOne"]>
> = {
  [Resources.FACILITY]: FacilityDomain.getOne,
  [Resources.EQUIPMENT]: EquipmentDomainV2.getOne,
  [Resources.DEVICE]: Device.getOne,
  [Resources.FLOW]: FlowDomain.getOne,
};

/**
 *
 * returns an array of objects in the form of:
 * { queryProps, meta }[] where queryProps are passed to useQueries,
 * and meta that contains information about what fields values should be overridden
 */
export const useGenerateLookupQueries = ({
  watchedFields,
  formValues,
  dirtyFields,
}: {
  formValues: { answers: FormSubmissionAnswersInFormType };
  watchedFields: WatchedFields;
  dirtyFields: object;
}) => {
  /*
  Only create a query for the fields that are touched.
  This helps with editing a form submission with lookup attribute values with a custom value.
  */
  const [wasTouched, setWasTouched] = useState<Record<string, boolean>>({});

  useEffect(() => {
    Object.keys(watchedFields).forEach((field) => {
      if (get(dirtyFields, getValuePathFromQuestionPath(field)))
        setWasTouched((prev) => ({ ...prev, [field]: true }));
    });
  }, [JSON.stringify(formValues)]);

  return useMemo(
    () =>
      Object.entries(watchedFields)
        .map(([fieldToWatch, fieldWatchConfiguration]) => {
          // Don't create a query for a field that wasn't even touched!
          if (!wasTouched?.[fieldToWatch]) return;

          const entityIdToFetch = get(
            formValues,
            getValuePathFromQuestionPath(fieldToWatch)
          );

          if (!entityIdToFetch) return;

          const lookupConfiguration = fieldWatchConfiguration.lookup;

          // if the entity type isn't set or the config doesn't exist, exit
          if (!lookupConfiguration?.entityType) return;

          const getOne =
            resourceToDomainGetOneMap?.[lookupConfiguration.entityType];

          // if the query doesn't exist, exit
          if (!getOne) return;

          return {
            queryProps: {
              queryKey: [
                lookupConfiguration.entityType,
                "getOne",
                { id: entityIdToFetch },
              ],
              queryFn: () =>
                getOne({
                  id: entityIdToFetch,
                }),
            },
            meta: {
              lookupConfiguration,
              fieldToWatch,
            },
          };
        })
        // Both of these filters are required for having absolutely zero type warnings and errors:
        .filter((q) => !!q)
        .filter((q) => !!q.queryProps),
    [
      Object.keys(wasTouched).length,
      JSON.stringify(watchedFields),
      ...Object.keys(watchedFields).map((field) =>
        get(formValues, getValuePathFromQuestionPath(field))
      ),
    ]
  );
};

export const useProcessAttributeLookupQueries = ({
  queries,
  queriesDetails,
  watchedFields,
  form,
  formValues,
  formSchema,
}: {
  queries: Array<UseQueryResult<GetOneResponseType<any> | undefined>>;
  queriesDetails: ReturnType<typeof useGenerateLookupQueries>;
  form: UseFormReturn<any>;
  formValues: { answers: FormSubmissionAnswersInFormType };
  formSchema?: FormSchemaType;
  watchedFields: WatchedFields;
}) => {
  const { resolved, fieldsNamesToDisable } = useMemo(() => {
    const fieldsNamesToDisable = new Set();
    const output: Record<string, Record<string, any>> = {};

    queries.forEach((query, index) => {
      if (!queriesDetails?.[index]) return;

      const queryDetails = queriesDetails[index].meta;

      if (!output[queryDetails.fieldToWatch])
        output[queryDetails.fieldToWatch] = {};

      queryDetails.lookupConfiguration.fieldsToOverride.forEach((field) => {
        const fieldName = getValuePathFromQuestionPath(field.fieldName);

        if (query.isFetching) {
          fieldsNamesToDisable.add(fieldName);
        } else {
          fieldsNamesToDisable.delete(fieldName);
        }
      });

      queryDetails.lookupConfiguration.fieldsToOverride.forEach(
        (fieldToOverride) => {
          const resolvedValue =
            query.data?.data?.custom_attributes?.[
              fieldToOverride.lookupAttribute
            ] ?? "";
          output[queryDetails.fieldToWatch][fieldToOverride.fieldName] =
            resolvedValue;
        }
      );
    });

    return { resolved: output, fieldsNamesToDisable };
  }, [queries, queriesDetails]);

  const resolvedSerialized = JSON.stringify(resolved);

  const previousResolvedSerialized = usePrevious(resolvedSerialized);

  useEffect(() => {
    if (!previousResolvedSerialized) return;
    const previousResolved = JSON.parse(previousResolvedSerialized ?? "{}");

    Object.keys(watchedFields).forEach((fieldKey) => {
      if (
        resolved[fieldKey] &&
        get(previousResolved, fieldKey) !== get(resolved, fieldKey)
      ) {
        Object.entries(resolved[fieldKey]).forEach(
          ([fieldToOverride, valueToOverride]) => {
            const questionId = fieldToOverride.split(/[. ]+/).pop() ?? "";

            // Only set the value for the question if it's rendered:
            if (
              areConditionsSatisfied({
                fieldName: getValuePathFromQuestionPath(fieldToOverride),
                values: formValues,
                conditions:
                  formSchema?.config.questions?.[questionId]?.conditions,
              })
            )
              form.setValue(
                getValuePathFromQuestionPath(fieldToOverride),
                valueToOverride
              );
          }
        );
      }
    });
  }, [
    resolvedSerialized,
    JSON.stringify(watchedFields),
    JSON.stringify(formSchema),
    form,
  ]);
  return { fieldsNamesToDisable };
};

export const useProcessCalculatedFields = ({
  evaluationOrder,
  watchedFields,
  form,
  formValues,
  formSchema,
}: {
  watchedFields: WatchedFields;
  evaluationOrder: string[];
  form: UseFormReturn<any>;
  formValues: { answers: FormSubmissionAnswersInFormType };
  formSchema?: FormSchemaType;
}): Record<
  string,
  {
    key: string;
    value: RecordValueType<WatchedFields>;
    areSourcesReady?: boolean;
    isCalculating?: boolean;
    isCalculatable?: boolean;
  }
> => {
  if (!formSchema?.config.questions) return {};

  const watchedFieldsMap = Object.entries(watchedFields).reduce<
    Record<
      string,
      {
        key: string;
        value: RecordValueType<WatchedFields>;
        areSourcesReady?: boolean;
        isCalculating?: boolean;
        isCalculatable?: boolean;
      }
    >
  >((map, [k, v]) => {
    map[v.questionId] = { key: k, value: v };
    return map;
  }, {});

  const globalInputs: Record<string, number> = {};

  for (const fieldNameToEvaluate of evaluationOrder) {
    if (!watchedFieldsMap[fieldNameToEvaluate]?.value?.calculation) continue;

    const equation = formSchema.config.questions[fieldNameToEvaluate].equation;

    if (!equation) {
      watchedFieldsMap[fieldNameToEvaluate].isCalculating = false;
      watchedFieldsMap[fieldNameToEvaluate].isCalculatable = false;
      continue;
    }

    const sourceFieldValues =
      watchedFieldsMap[
        fieldNameToEvaluate
      ].value.calculation?.sourceFields.reduce<
        Record<string, { value: any; unit?: string }>
      >((map, fieldName) => {
        const q = formSchema.config.questions[fieldName];

        map[fieldName] = {
          value: findAnswerForQuestion(
            formValues.answers,
            fieldName,
            watchedFieldsMap[fieldNameToEvaluate].value.sectionId,
            watchedFieldsMap[fieldNameToEvaluate].value.sectionIdx,
            {
              ignoreRepeatedSections: false,
            }
          )?.value,
          unit: FormCalculatedFieldService.getMeasurementUnitFromQuestion(
            q,
            false
          ),
        };

        return map;
      }, {}) ?? {};

    if (watchedFieldsMap[fieldNameToEvaluate].value.calculation) {
      watchedFieldsMap[
        fieldNameToEvaluate
      ].value.calculation.sourceFieldValues = sourceFieldValues;
    }

    // calculated field should be evaluated when any of the source fields are
    // dirty and have an evaluatable value
    const shouldEvaluate = Object.values(sourceFieldValues).every(
      (fieldValueAndUnit) =>
        FormCalculatedFieldService.canGetEvaluatuableValueFromAnswer(
          fieldValueAndUnit.value
        )
    );

    watchedFieldsMap[fieldNameToEvaluate].isCalculating = false;

    // don't evaluate this field if none of the source fields have a valid value
    if (!shouldEvaluate) {
      // reset calculated value if it exists, since all source fields have been reset too
      if (
        FormCalculatedFieldService.canGetEvaluatuableValueFromAnswer(
          (
            get(
              formValues,
              `${watchedFieldsMap[fieldNameToEvaluate].value.sectionId}.${watchedFieldsMap[fieldNameToEvaluate].value.sectionIdx}.${fieldNameToEvaluate}`
            ) as { value: FormSchemaQuestionAnswerType } | undefined
          )?.value
        )
      ) {
        form.setValue(
          `${watchedFieldsMap[fieldNameToEvaluate].key}.value`,
          "",
          {
            shouldDirty: false,
          }
        );
      }

      continue;
    }

    watchedFieldsMap[fieldNameToEvaluate].isCalculatable = true;
    watchedFieldsMap[fieldNameToEvaluate].isCalculating = true;

    Object.entries(sourceFieldValues).forEach(
      ([sourceFieldName, sourceFieldAnswerValueAndUnit]) => {
        const associatedQuestion =
          formSchema?.config.questions[sourceFieldName];
        let sourceFieldEvaluatableAnswerValue: number | null = null;

        switch (associatedQuestion?.type) {
          case "measurement": {
            // only consider calculated measurement questions
            if (!associatedQuestion.equation)
              sourceFieldEvaluatableAnswerValue = null;

            switch (associatedQuestion.measurement_type) {
              default:
                // measurements are always numeric
                sourceFieldEvaluatableAnswerValue =
                  FormCalculatedFieldService.getEvaluatableValueFromAnswer(
                    sourceFieldAnswerValueAndUnit.value,
                    "number"
                  );
            }
            break;
          }
          case "question": {
            sourceFieldEvaluatableAnswerValue =
              FormCalculatedFieldService.getEvaluatableValueFromAnswer(
                sourceFieldAnswerValueAndUnit.value,
                associatedQuestion.data_type
              );
            break;
          }
        }

        if (sourceFieldEvaluatableAnswerValue === null) {
          delete globalInputs[sourceFieldName];
        } else {
          globalInputs[sourceFieldName] = sourceFieldEvaluatableAnswerValue;
        }
      }
    );

    if (
      watchedFieldsMap[
        fieldNameToEvaluate
      ].value.calculation?.sourceFields.every((sourceFieldName) =>
        CalculatedFieldService.isEvaluatableValue(globalInputs[sourceFieldName])
      )
    ) {
      watchedFieldsMap[fieldNameToEvaluate].areSourcesReady = true;
    } else {
      watchedFieldsMap[fieldNameToEvaluate].areSourcesReady = false;
      watchedFieldsMap[fieldNameToEvaluate].isCalculating = false;
      continue;
    }

    const result = FormCalculatedFieldService.convertEvaluatedValueIfNecessary(
      CalculatedFieldService.evaluate(equation, globalInputs),
      formSchema.config.questions[fieldNameToEvaluate]
    );

    // when result is not a number, indicate error in evaluation
    if (isNaN(result)) {
      watchedFieldsMap[fieldNameToEvaluate].isCalculatable = false;
      watchedFieldsMap[fieldNameToEvaluate].isCalculating = false;

      // if the value of the field isn't already NaN
      if (
        !isNaN(
          get(formValues, watchedFieldsMap[fieldNameToEvaluate].key)?.value
        )
      ) {
        // set it to NaN
        form.setValue(
          `${watchedFieldsMap[fieldNameToEvaluate].key}.value`,
          NaN,
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      }
      continue;
    }

    // don't set the result, if it's the same as the previous calculation
    if (
      get(formValues, watchedFieldsMap[fieldNameToEvaluate].key)?.value ===
      result
    ) {
      watchedFieldsMap[fieldNameToEvaluate].isCalculating = false;
      continue;
    }

    form.setValue(
      `${watchedFieldsMap[fieldNameToEvaluate].key}.value`,
      result,
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
    watchedFieldsMap[fieldNameToEvaluate].isCalculating = false;
  }

  return watchedFieldsMap;
};
