import { Button, Dialog } from "@validereinc/common-components";
import React from "react";

export const EditFormTemplateDialog = ({
  isOpen,
  editedFormValues,
  onSubmitEditTemplate,
  onClose,
}: {
  isOpen: boolean;
  editedFormValues: any[];
  onSubmitEditTemplate: (values: any[]) => void;
  onClose: () => void;
}) => {
  const EDIT_QUESTION_DIALOG_TITLE = "Save new version of Form Template?";
  const EDIT_QUESTION_DIALOG_DESCRIPTION =
    "Saving the edits to the form template will generate a new template version. This may have effects on associated Default Record Value Configurations and Workflows. These changes cannot be undone.";

  return (
    <Dialog
      title={EDIT_QUESTION_DIALOG_TITLE}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      actionRow={[
        <Button
          key="edit-form-template-action"
          variant="primary"
          onClick={() => {
            onSubmitEditTemplate(editedFormValues);
            onClose();
          }}
        >
          Save
        </Button>,
      ]}
    >
      {EDIT_QUESTION_DIALOG_DESCRIPTION}
    </Dialog>
  );
};
