import { useFormContext } from "#src/contexts/FormSchemaContext";
import {
  useMemoizedFormState,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { moveItem } from "#src/utils/moveItem";
import {
  Accordion,
  Button,
  Form,
  Panel,
  useFieldArray,
  useForm,
} from "@validereinc/common-components";
import {
  ReorderDirection,
  type ReorderDirectionType,
} from "@validereinc/utilities";
import get from "lodash/get";
import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { CreateFormTemplateMetricTiles } from "./CreateFormTemplateMetricTiles";
import { CreateFormTemplateSection } from "./CreateFormTemplateSection";
import { DeleteSectionDialog } from "./DeleteSectionDialog";

const { AccordionPanel } = Accordion;

const SECTIONS_STEP_TITLE = "Sections & Questions";
const STEP_NUMBER = 2;

export const CreateFormTemplateSectionStep = () => {
  const {
    schema: { data: templateToEdit },
  } = useFormContext();

  const { currentStep, updateStep } = useMultiStepFormContext();

  const getNewSection = () => ({
    description: "",
    id: uuid(),
    name: "",
    is_repeatable: false,
    questions: [],
  });

  const defaultSectionValue = useMemo(
    () =>
      templateToEdit ? templateToEdit.config?.sections[0] : getNewSection(),
    [templateToEdit]
  );

  const form = useForm({
    defaultValues: {
      config: {
        questions: templateToEdit?.config?.questions ?? {},
        sections: templateToEdit?.config?.sections ?? [defaultSectionValue],
      },
    },
  });

  const [sectionIndexToDelete, setSectionIndexToDelete] = useState<number>(-1);

  const { fields, append, move, remove } = useFieldArray({
    control: form.control,
    name: "config.sections",
    // we need keyName to prevent RHF from overwriting the id we have already created
    keyName: "rhf_id",
  });

  const sectionArray = form.watch("config.sections");

  const onAddSection = () => {
    const newSection = getNewSection();
    append(newSection);
  };

  const onMoveSection = (
    indexToSwapFrom: number,
    direction: ReorderDirectionType
  ) => {
    const sectionIds = sectionArray.map((section) => section.id);
    moveItem(sectionIds, move, indexToSwapFrom, direction);
  };

  const onRemoveSection = (index: number) => {
    remove(index);
  };

  const getFormState = useMemoizedFormState(form);

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues(),
      getFormState,
    });
  }, [getFormState]);

  return (
    <>
      {currentStep === STEP_NUMBER ? (
        <Form {...form}>
          <Panel title={SECTIONS_STEP_TITLE}>
            <CreateFormTemplateMetricTiles />

            <Panel>
              {fields.map((field, index) => (
                <Accordion
                  key={field.id}
                  defaultActiveKeys={[field.id]}
                >
                  <AccordionPanel
                    key={field.id}
                    dataKey={field.id}
                    title={`Section ${index + 1}${
                      sectionArray[index]?.name
                        ? `: ${sectionArray[index]?.name}`
                        : ""
                    }`}
                    actionRow={[
                      ...(index !== 0
                        ? [
                            <Button
                              key={`move-section-up-${index}-button`}
                              icon="arrow-circle-up"
                              onClick={() =>
                                onMoveSection(index, ReorderDirection.UP)
                              }
                            />,
                          ]
                        : []),
                      ...(index !== fields.length - 1
                        ? [
                            <Button
                              key={`move-section-down-${index}-button`}
                              icon="arrow-circle-down"
                              onClick={() =>
                                onMoveSection(index, ReorderDirection.DOWN)
                              }
                            />,
                          ]
                        : []),
                      ...(fields?.length > 1
                        ? [
                            <Button
                              key={`remove-section-${index}-button`}
                              icon="trash"
                              variant="error-outline"
                              onClick={() => setSectionIndexToDelete(index)}
                            />,
                          ]
                        : []),
                    ]}
                    isError={get(
                      form.formState.errors,
                      `config.sections.${index}`
                    )}
                  >
                    <CreateFormTemplateSection
                      index={index}
                      sectionName={
                        sectionArray[index]?.name
                          ? `${sectionArray[index]?.name}`
                          : ""
                      }
                    />
                  </AccordionPanel>
                </Accordion>
              ))}
            </Panel>

            <Button
              onClick={onAddSection}
              icon="plus-circle"
            >
              Create Section
            </Button>
            <DeleteSectionDialog
              isOpen={sectionIndexToDelete > -1}
              onClose={() => {
                setSectionIndexToDelete(-1);
              }}
              handleDeleteSection={onRemoveSection}
              sectionIndexToDelete={sectionIndexToDelete}
            />
          </Panel>
        </Form>
      ) : null}
    </>
  );
};
