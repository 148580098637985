import { useStorageKey } from "#src/hooks/useStorageKey";
import React from "react";
import { ConfigurationTemplateRunsFilterPanel } from "./ConfigurationTemplateRunsFilterPanel";
import { ConfigurationTemplateRunsTablePanel } from "./ConfigurationTemplateRunsTablePanel";

export const ConfigurationTemplateRunsListTab = () => {
  const storageKeys = useStorageKey("configuration-template-submissions");

  return (
    <>
      <ConfigurationTemplateRunsFilterPanel
        viewConfigStorageKey={storageKeys.viewConfigStorageKey}
      />
      <ConfigurationTemplateRunsTablePanel {...storageKeys} />
    </>
  );
};
