import { CalculatorResultsSummaryPanel } from "#batteries-included-components/Panels/MetricTilePanels/CalculatorResultsSummaryPanel";
import { QUERY_STATUS } from "#constants";
import { useListCalculatorResults } from "#hooks/adapters/useCalculatorResults";
import { useSearchParams } from "#src/Routers/hooks";
import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { EquipmentPanel } from "#src/batteries-included-components/Panels/TablePanels/EmissionsEquipmentPanel/EmissionsEquipmentPanel";
import { EstimationMethodPanel } from "#src/batteries-included-components/Panels/TablePanels/EmissionsEstimationMethodPanel/EmissionsEstimationMethodPanel";
import { useQuery } from "@tanstack/react-query";
import { Form, useForm } from "@validereinc/common-components";
import type { GetListCalculatorResultsFiltersType } from "@validereinc/domain";
import { ReportingGroupDomain } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { PropsWithChildren, useEffect } from "react";
import styles from "./EmissionsRecordDetail.module.scss";

const cx = classNames.bind(styles);

export const FILTER_CONFIG = {
  reportingScenario: {
    name: "reporting_scenario",
  },
} as const;

const EmissionsRecordDetail = ({
  filters,
  timePeriod,
  children,
}: PropsWithChildren<EmissionsRecordDetailProps>) => {
  // state and hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const filterPanelFormProps = useForm({
    defaultValues: {
      [FILTER_CONFIG.reportingScenario.name]:
        searchParams[FILTER_CONFIG.reportingScenario.name] ?? "",
    },
  });
  const { watch } = filterPanelFormProps;
  const reportingGroup = watch(FILTER_CONFIG.reportingScenario.name);
  const { isLoading: reportingGroupsIsLoading, data: reportingGroups } =
    useQuery(["reportingGroups"], () => ReportingGroupDomain.getList(), {
      select: (response) => response?.data ?? [],
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });
  const { data: summaryData, status: aggregatedMeasurementResultsStatus } =
    useListCalculatorResults(
      {
        filters: {
          ...filters,
          ...(timePeriod ? { year_month: [timePeriod] } : {}),
          ...(reportingGroup
            ? { reporting_group: { $exact: reportingGroup } }
            : {}),
        },
        page: 1,
        pageSize: 1,
      },
      {
        enabled: !!timePeriod,
      }
    );

  const aggregatedMeasurementResults = summaryData?.data ?? [];

  // keep the reporting group filter in sync with search params
  useEffect(() => {
    // if the filter isn't set and the options to pick a default from aren't available, exit
    if (!reportingGroup && !reportingGroups?.length) {
      return;
    }

    setSearchParams({
      ...searchParams,
      // auto-select the first one, if no value available
      [FILTER_CONFIG.reportingScenario.name]: reportingGroup
        ? reportingGroup
        : reportingGroups?.[0].id,
    });
  }, [reportingGroup, reportingGroups]);

  return (
    <>
      <div
        role="region"
        aria-label="Filter Area"
        style={{ marginBottom: 16 }}
      >
        <div className={cx("filterPanel")}>
          <Form {...filterPanelFormProps}>
            <ReportingGroupFilterSwitcher
              name={FILTER_CONFIG.reportingScenario.name}
              options={reportingGroups}
              shouldSelectFirstOptionAsDefault
              isEditable={false}
            />
          </Form>
        </div>
      </div>
      <CalculatorResultsSummaryPanel
        data={aggregatedMeasurementResults?.[0] ?? null}
        isLoading={
          reportingGroupsIsLoading ||
          aggregatedMeasurementResultsStatus === QUERY_STATUS.LOADING
        }
        loadingGridSize={6}
        isError={aggregatedMeasurementResultsStatus === QUERY_STATUS.ERROR}
      />
      {children}
    </>
  );
};

type EmissionsRecordDetailProps = {
  filters: GetListCalculatorResultsFiltersType;
  timePeriod: string;
};

export default Object.assign(EmissionsRecordDetail, {
  EstimationMethodPanel,
  EquipmentPanel,
});
