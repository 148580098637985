import React, { createContext, useContext, useState } from "react";
import { ImportDataActionContextType, ImportDataActionStatus } from "./types";
import { ImportDataActionProps } from "../ImportDataAction";
import mime from "mime";

export const ImportDataActionContext =
  createContext<ImportDataActionContextType | null>(null);

export const useImportDataActionContext = () => {
  const ctx = useContext(ImportDataActionContext);

  if (ctx === null) {
    throw new Error("Must be called within a context provider.");
  }

  return ctx;
};

export const ImportDataActionProvider = ({
  children,
  modes: modesProp,
  ...props
}: {
  children: React.ReactNode;
} & ImportDataActionProps) => {
  const [status, setStatus] = useState<ImportDataActionStatus>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [datasetId, setDatasetId] = useState("");

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const acceptedMimeTypes = [
    mime.getType("csv"),
    mime.getType("xls"),
    mime.getType("xlsx"),
    mime.getType("json"),
  ].join(",");

  return (
    <ImportDataActionContext.Provider
      value={{
        status,
        setStatus,
        isDialogOpen,
        openDialog,
        closeDialog,
        datasetId,
        setDatasetId,
        acceptedMimeTypes,
        modes: modesProp ?? ["single-entity"],
        ...props,
      }}
    >
      {children}
    </ImportDataActionContext.Provider>
  );
};
