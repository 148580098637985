import {
  DeviceStatusDropdownInput,
  DeviceTypeDropdownInput,
  EquipmentDropdownInput,
  EquipmentTypeDropdownInput,
  FacilityDropdownInput,
  FacilityStatusDropdownInput,
  FlowTypeDropdownInput,
  ProductCategoryDropdownInput,
  ProductTypeDropdownInput,
} from "#batteries-included-components/Dropdowns";
import {
  DateSelectorInput,
  DropdownInput,
  GeoPointInput,
  RadioInput,
  TextInput,
} from "@validereinc/common-components";
import React from "react";

const LOOKUP_DROPDOWN_MAPPING = {
  deviceType: DeviceTypeDropdownInput,
  deviceStatus: DeviceStatusDropdownInput,
  facility: FacilityDropdownInput,
  facilityStatus: FacilityStatusDropdownInput,
  equipmentType: EquipmentTypeDropdownInput,
  flowType: FlowTypeDropdownInput,
  productCategory: ProductCategoryDropdownInput,
  productType: ProductTypeDropdownInput,
  equipment: EquipmentDropdownInput,
};

export const DataTypeInput = ({
  display_name,
  data_type,
  options = [],
  name,
  lookup_type,
}: {
  display_name: string;
  data_type: string;
  options?: Array<{ label: string; value: string }>;
  name: string;
  lookup_type?: string;
}) => {
  const commonProps = {
    name,
    label: display_name,
    isRequired: true,
    isFluid: true,
  };

  switch (data_type) {
    case "string":
      return <TextInput {...commonProps} />;

    case "lookup": {
      const DropdownComponent = LOOKUP_DROPDOWN_MAPPING[lookup_type];

      return (
        <DropdownComponent
          {...commonProps}
          isMulti={false}
        />
      );
    }
    case "pick-list":
    case "multi-pick-list":
      return (
        <DropdownInput
          {...commonProps}
          options={options}
          labelKey="label"
          valueKey="value"
          isMulti={data_type === "multi-pick-list"}
        />
      );
    case "integer":
    case "number":
      return (
        <TextInput
          {...commonProps}
          type="number"
        />
      );
    case "boolean":
      return (
        <RadioInput
          {...commonProps}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          labelKey="label"
          valueKey="value"
        />
      );
    case "date":
    case "date-time-range":
    case "date-time":
      return (
        <DateSelectorInput
          {...commonProps}
          variant={data_type === "date" ? "day" : "time"}
          isRange={data_type === "date-time-range"}
        />
      );
    case "geo_point":
      return <GeoPointInput {...commonProps} />;
    default:
      return null;
  }
};

export const getQueryObjectForQuestionType = ({
  value,
  comparator = "$eq",
}: {
  value: any;
  comparator: "$eq" | "$like" | "$gt" | "$gte" | "$lt" | "$lte" | "$in";
}) => {
  if (comparator === "$eq") {
    return value;
  }

  return { [comparator]: value };
};
