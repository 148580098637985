import { API_DOMAINS, DomainConfig, config } from "../config";
import type {
  DELETERequestType,
  GETRequestType,
  POSTRequestType,
  PUTRequestType,
  RequestCallbackType,
} from "../util";
import { DELETE, GET, POST, PUT } from "../util/requests";

const getEndpointString = ({
  url,
  endpoint,
  version,
}: {
  url: string;
  endpoint: string;
  version?: number;
}) => {
  switch (url) {
    case config.elixirURL:
      return `/api${endpoint}`;
    case config.nodeURL:
    case config.publicNodeURL:
      return `/v${version ?? 1}${endpoint}`;
    case config.alertingURL:
      return `/api/v${version ?? 1}${endpoint}`;
    case config.insightsURL:
    case config.dataPlatformSourceURL:
    case config.dataPlatformAdminURL:
    default:
      return endpoint;
  }
};

const constructRestAPIConfig = ({
  url,
  requestCallback,
}: {
  url: string;
  requestCallback?: RequestCallbackType;
}) => ({
  GET: async <T>({ endpoint, version, ...restArgs }: GETRequestType) =>
    await GET<T>({
      api: url,
      ...restArgs,
      endpoint: getEndpointString({ url, endpoint, version }),
      requestCallback,
    }),
  POST: async <T>({ endpoint, version, ...restArgs }: POSTRequestType) =>
    await POST<T>({
      api: url,
      ...restArgs,
      endpoint: getEndpointString({ url, endpoint, version }),
      requestCallback,
    }),
  PUT: async <T>({ endpoint, version, ...restArgs }: PUTRequestType) =>
    await PUT<T>({
      api: url,
      ...restArgs,
      endpoint: getEndpointString({ url, endpoint, version }),
      requestCallback,
    }),
  DELETE: async <T>({ endpoint, version, ...restArgs }: DELETERequestType) =>
    await DELETE<T>({
      api: url,
      ...restArgs,
      endpoint: getEndpointString({ url, endpoint, version }),
      requestCallback,
    }),
});

/**
 * Holds constructed instances of all the API REST configurations
 */
const restAPI: DomainRestAPI = {
  publicNodeAPI: constructRestAPIConfig({ url: "" }),
  nodeAPI: constructRestAPIConfig({ url: "" }),
  alertingAPI: constructRestAPIConfig({ url: "" }),
  dataEngAPI: constructRestAPIConfig({ url: "" }),
  elixirAPI: constructRestAPIConfig({ url: "" }),
  insightsAPI: constructRestAPIConfig({ url: "" }),
  dataPlatformSourceAPI: constructRestAPIConfig({ url: "" }),
  dataPlatformAdminAPI: constructRestAPIConfig({ url: "" }),
};

const setupRestAPI = ({ requestCallback, ...configData }: DomainConfig) => {
  restAPI.publicNodeAPI = constructRestAPIConfig({
    url: configData.publicNodeURL,
    requestCallback,
  });
  restAPI.nodeAPI = constructRestAPIConfig({
    url: configData.nodeURL,
    requestCallback,
  });
  restAPI.elixirAPI = constructRestAPIConfig({
    url: configData.elixirURL,
    requestCallback,
  });
  restAPI.dataEngAPI = constructRestAPIConfig({
    url: configData.dataEngURL,
    requestCallback,
  });
  restAPI.alertingAPI = constructRestAPIConfig({
    url: configData.alertingURL,
    requestCallback,
  });
  restAPI.insightsAPI = constructRestAPIConfig({
    url: configData.insightsURL,
    requestCallback,
  });
  restAPI.dataPlatformSourceAPI = constructRestAPIConfig({
    url: configData.dataPlatformSourceURL,
    requestCallback,
  });
  restAPI.dataPlatformAdminAPI = constructRestAPIConfig({
    url: configData.dataPlatformAdminURL,
    requestCallback,
  });
};

type RestRequestAdapterType = {
  GET: <T>(args: GETRequestType) => Promise<T>;
  POST: <T>(args: POSTRequestType) => Promise<T>;
  PUT: <T>(args: PUTRequestType) => Promise<T>;
  DELETE: <T>(args: DELETERequestType) => Promise<T>;
};

type APIDomainName = `${(typeof API_DOMAINS)[keyof typeof API_DOMAINS]}API`;
type DomainRestAPI = {
  [api in APIDomainName]: RestRequestAdapterType;
};

export { getEndpointString, restAPI, setupRestAPI };
export type { APIDomainName, DomainRestAPI, RestRequestAdapterType };
