import Map from "#components/Map/Map";
import Marker from "#components/Map/Marker/Marker";
import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { StorageKeys } from "@validereinc/common-components";
import {
  AssetType,
  Device,
  EquipmentDomain,
  FacilityDomain,
  FlowDomain,
} from "@validereinc/domain";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import styles from "./NetworkDetailsTab.module.scss";

const cx = classNames.bind(styles);

export const NetworkMapPanel = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const [filters] = useSessionStickyState<{ period: { from?: string } }>(
    {},
    viewConfigStorageKey
  );

  const { data: assetGroup } = useGetOneNetwork(networkId, {
    period: getYearMonthFromDateRange(filters.period),
  });

  const [assets, setAssets] = useState([]);
  const [initialViewState, setInitialViewState] = useState(null);

  const onFetchData = async () => {
    if (assetGroup?.assets?.length) {
      const flowIds = assetGroup.assets.reduce((total, { asset_type, id }) => {
        if (asset_type === AssetType.FLOW) {
          return [...total, id];
        }

        return total;
      }, []);

      const { data: flows = [] } = flowIds?.length
        ? await FlowDomain.getFlows({
            filters: { id: flowIds },
            pageSize: 1000,
          })
        : [];

      const flowFacilityIds = Array.from(
        new Set(
          flows?.reduce(
            (total, { downstream_facility_id, upstream_facility_id }) => {
              return [
                ...total,
                ...(downstream_facility_id ? [downstream_facility_id] : []),
                ...(upstream_facility_id ? [upstream_facility_id] : []),
              ];
            },
            []
          ) ?? []
        )
      );

      const facilityIds = [
        ...assetGroup.assets.reduce((total, { asset_type, id }) => {
          if (asset_type === AssetType.FACILITY) {
            return [...total, id];
          }

          return total;
        }, []),
        ...flowFacilityIds,
      ];

      const { data: facilities = [] } = facilityIds?.length
        ? await FacilityDomain.getList({
            filters: { id: facilityIds },
            pageSize: 1000,
          })
        : {};

      const flowEquipmentIds = Array.from(
        new Set(
          flows?.reduce(
            (total, { downstream_equipment_id, upstream_equipment_id }) => {
              return [
                ...total,
                ...(downstream_equipment_id ? [downstream_equipment_id] : []),
                ...(upstream_equipment_id ? [upstream_equipment_id] : []),
              ];
            },
            []
          ) ?? []
        )
      );

      const equipmentIds = [
        ...assetGroup.assets.reduce((total, { asset_type, id }) => {
          if (asset_type === AssetType.EQUIPMENT) {
            return [...total, id];
          }

          return total;
        }, []),
        ...flowEquipmentIds,
      ];

      const { data: equipment = [] } = equipmentIds?.length
        ? await EquipmentDomain.getEquipment({
            filters: { id: equipmentIds },
            pageSize: 1000,
          })
        : {};

      const deviceIds = assetGroup.assets.reduce(
        (total, { asset_type, id }) => {
          if (asset_type === AssetType.DEVICE) {
            return [...total, id];
          }

          return total;
        },
        []
      );

      const { data: devices = [] } = deviceIds?.length
        ? await Device.getList({
            filters: { id: deviceIds },
            pageSize: 1000,
          })
        : {};

      const markers = [
        ...facilities.map(({ name, id, latitude, longitude }) => ({
          id,
          name,
          iconType: "broadcast",
          latitude,
          longitude,
        })),
        ...equipment.map(({ name, id, latitude, longitude }) => ({
          id,
          name,
          iconType: "cylinder",
          latitude,
          longitude,
        })),
        ...devices.map(
          ({
            name,
            id,
            attributes: {
              location: [latitude, longitude] = [undefined, undefined],
            } = {},
          }) => ({
            id,
            name,
            iconType: "cylinder",
            latitude,
            longitude,
          })
        ),
      ].filter(({ latitude, longitude }) => latitude && longitude);

      setInitialViewState({
        latitude: markers.reduce(
          (max, { latitude }) => (latitude > max ? latitude : max),
          0
        ),
        longitude: markers.reduce(
          (max, { longitude }) => (longitude > max ? longitude : max),
          0
        ),
        zoom: 0,
      });

      setAssets(markers);
    }
  };

  useEffect(() => {
    onFetchData();
  }, [assetGroup]);

  return assets?.length ? (
    <div className={cx("flexedContainer")}>
      <Map initialViewState={initialViewState}>
        {assets?.map(({ id, name, latitude, longitude, iconType }) => (
          <Marker
            key={id}
            latitude={latitude}
            longitude={longitude}
            iconType={iconType}
            label={name}
            initialViewState={initialViewState}
          />
        ))}
      </Map>
    </div>
  ) : null;
};
