import { WorkflowsViewFilterArea } from "#src/batteries-included-components/FilterAreas/WorkflowsFilterAreas";
import {
  WorkflowsTablePanel,
  WorkflowsTablePanelProps,
} from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel";
import { type AssetTypeType } from "@validereinc/domain";
import React from "react";

type WorkflowsAllLayoutProps = {
  tablePanelProps: WorkflowsTablePanelProps;
};

export const WorkflowsListLayout = ({
  tablePanelProps,
  defaultAssetType,
}: WorkflowsAllLayoutProps & { defaultAssetType?: AssetTypeType }) => {
  return (
    <>
      <WorkflowsViewFilterArea
        viewConfigStorageKey={tablePanelProps.viewConfigStorageKey}
        defaultAssetType={defaultAssetType}
        showCategoryFilter={!tablePanelProps.isWithinWorkflowTemplateDetails}
        showTemplateFilter={!tablePanelProps.isWithinWorkflowTemplateDetails}
      />
      <WorkflowsTablePanel {...tablePanelProps} />
    </>
  );
};
