import { EmissionsRecordsTab } from "#batteries-included-components/Tabs/Emissions/EmissionsRecordsTab";
import { EstimationMethodsTab } from "#batteries-included-components/Tabs/EstimationMethod/ListTab";
import DeleteModal from "#common/DeleteModal/DeleteModal";
import FormTable from "#common/Forms/FormTable";
import { useFormCategories } from "#hooks/useForms";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToFacilities } from "#routers/links";
import { EquipmentRecordsTab } from "#routes/organization/equipment/[equipmentId]/EquipmentRecordsTab";
import FacilityService from "#services/FacilityService";
import EditEquipmentDialog from "#src/batteries-included-components/Dialogs/EditEquipmentDialog";
import { PageErrorContent } from "#src/batteries-included-components/Layouts/Errors/PageError";
import { EntityMeasurementsTab } from "#src/batteries-included-components/Tabs/EntityMeasurementsTab/EntityMeasurementsTab";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { linkToEstimationMethodDetail } from "#src/routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/detail";
import { linkToEstimationMethodCreate } from "#src/routes/organization/equipment/[equipmentId]/estimation-method/create";
import { useQuery } from "@tanstack/react-query";
import { Button, Page, Tab, useFilters } from "@validereinc/common-components";
import type { HTTPNotFoundErrorCtx } from "@validereinc/domain";
import { AssetType, EquipmentDomain } from "@validereinc/domain";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ChangeLogTab } from "./ChangeLogTab";
import EquipmentDetailOverviewTab from "./EquipmentDetailOverviewTab";
import { EquipmentViewFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useSessionStickyState } from "#src/hooks/useStickyState";

const DEFAULT_TAB_KEY = "overview";
const DELETE_EQUIPMENT_MODAL_KEY = "Delete Equipment";
const ENTITY_TYPE = "equipment";

const EquipmentDetail = ({ breadcrumbs }) => {
  const [isEstimationMethodsEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:estimation_methods",
    permissionQuery: "calculator_results:read",
  });
  const [isChangeLogAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:activity_logs",
    permissionQuery: "activities:read",
  });
  const assetsSharedStorageKeys = useStorageKey("assets-shared");
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const [formCategories] = useFormCategories({
    relatedEntityId: equipmentId,
    relatedEntityType: ENTITY_TYPE,
  });
  const [modalKey, setModalKey] = useState<string | boolean>(false);
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();
  const { localize } = useLocalization();
  const navigate = useNavigate();
  const [filters] = useSessionStickyState<{
    period: { from: string; to: string };
  }>({}, assetsSharedStorageKeys.viewConfigStorageKey);

  const activePeriod = getYearMonthFromDateRange(filters?.period) ?? "";

  const params = {
    equipmentId,
    period: activePeriod,
  };

  const { data: equipmentDetail, error } = useQuery({
    queryKey: ["equipment", params],
    queryFn: () => EquipmentDomain.getEquipmentById(params),
    meta: {
      hideErrorToasts: true,
      errorCtx: {
        resource: localize("Equipment"),
        resourceId: params.equipmentId,
      } satisfies HTTPNotFoundErrorCtx,
    },
  });

  const facilityId = equipmentDetail?.facility_id;
  const { isLoading: isMappingLoading } = useLocalization();

  const pageBreadcrumbs = useBreadcrumbs(
    breadcrumbs,
    {
      2: equipmentDetail?.facility?.name,
      3: equipmentDetail?.name,
    },
    { id: facilityId ?? "" }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const handleDeleteEquipmentClick = () => {
    setModalKey(DELETE_EQUIPMENT_MODAL_KEY);
  };

  const handleCloseModal = () => {
    setModalKey(false);
  };

  const onClickConfigureEquipment = () => setAssetIdToEdit(equipmentId);

  const handleActiveTabKeyChange = (tabKey: string) => {
    setSearchParams({ tab: tabKey });
  };

  const actionRow = (
    <>
      <Button
        variant="error-outline"
        onClick={handleDeleteEquipmentClick}
      >
        Delete
      </Button>

      <Button onClick={onClickConfigureEquipment}>Edit</Button>
    </>
  );
  return (
    <>
      <Page
        category={localize("Equipment")}
        isLoading={!equipmentDetail || isMappingLoading}
        title={equipmentDetail?.name}
        breadcrumbs={pageBreadcrumbs}
        actionRow={actionRow}
        onActiveTabKeyChange={handleActiveTabKeyChange}
        activeTabKey={
          !isChangeLogAvailable && searchParams?.tab === "change-log"
            ? DEFAULT_TAB_KEY
            : searchParams?.tab ?? DEFAULT_TAB_KEY
        }
        error={error}
        onErrorRender={({ error }) => <PageErrorContent error={error} />}
      >
        <Tab
          tabKey={DEFAULT_TAB_KEY}
          title="Overview"
        >
          <EquipmentDetailOverviewTab
            equipmentDetail={equipmentDetail}
            viewFilterAreaSlot={
              <EquipmentViewFilterArea
                viewConfigStorageKey={
                  assetsSharedStorageKeys.viewConfigStorageKey
                }
              />
            }
          />
        </Tab>

        {isEstimationMethodsEnabled ? (
          <Tab
            tabKey="emissions"
            title="Emissions"
          >
            <EmissionsRecordsTab
              assetType={AssetType.EQUIPMENT}
              assetId={equipmentId}
            />
          </Tab>
        ) : null}

        <Tab
          tabKey="records"
          title="Records"
        >
          <EquipmentRecordsTab />
        </Tab>

        <Tab
          tabKey="measurements"
          title="Measurements"
        >
          <EntityMeasurementsTab
            entityId={equipmentId}
            entityType={AssetType.EQUIPMENT}
          />
        </Tab>

        {isEstimationMethodsEnabled ? (
          <Tab
            tabKey="estimationMethods"
            title="Estimation Methods"
          >
            <EstimationMethodsTab
              isWithinEntityDetail
              entityId={equipmentId}
              entityType={AssetType.EQUIPMENT}
              onAdd={() =>
                navigate({
                  pathname: linkToEstimationMethodCreate(equipmentId),
                })
              }
              onRowClick={({ item: { id } }) =>
                navigate({
                  pathname: linkToEstimationMethodDetail(id, equipmentId),
                })
              }
            />
          </Tab>
        ) : null}

        <Tab
          tabKey="change-log"
          title="Change Log"
          isDisabled={!isChangeLogAvailable}
          iconVariant={!isChangeLogAvailable ? "lock" : ""}
        >
          {isChangeLogAvailable ? (
            <ChangeLogTab resourceId={equipmentId} />
          ) : null}
        </Tab>

        {formCategories.map((formCategory) => (
          <Tab
            key={formCategory?.id}
            tabKey={formCategory?.id}
            title={formCategory.name}
          >
            <FormTable
              formCategoryId={formCategory?.id}
              relatedEntityId={equipmentId}
              relatedEntityType={ENTITY_TYPE}
            />
          </Tab>
        ))}
      </Page>
      <DeleteModal
        open={!!modalKey}
        instanceName={equipmentDetail?.name ?? "this equipment"}
        entityName={localize("Equipment")}
        onClose={handleCloseModal}
        onDelete={() => {
          navigate({
            pathname: linkToFacilities(equipmentDetail?.facility_id),
          });
        }}
        doDelete={() => FacilityService.deleteEquipment(equipmentDetail?.id)}
      />
      <EditEquipmentDialog
        equipmentId={assetIdToEdit}
        onClose={() => setAssetIdToEdit(undefined)}
        defaultPeriod={activePeriod}
      />
    </>
  );
};

EquipmentDetail.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

export default EquipmentDetail;
