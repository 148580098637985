import { useListCalculators } from "#hooks/adapters/useCalculators";
import { useListEquipmentTypes } from "#hooks/adapters/useEquipment";
import {
  useExportEstimationMethods,
  useListEstimationMethods,
} from "#hooks/adapters/useEstimationMethods";
import { useListFlowTypes } from "#hooks/adapters/useFlows";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToFacilityDetail } from "#routes/organization/facilities/[facilityId]";
import { ImportDataAction } from "#src/batteries-included-components";
import { EstimationMethodsTableFilterArea } from "#src/batteries-included-components/FilterAreas/EstimationMethodsFilterAreas/EstimationMethodsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  linkToAssetDetailPage,
  linkToEstimationMethodDetailPage,
} from "#utils/links";
import {
  ButtonWithPopover,
  DataTablePanel,
  HeaderType,
  SortingType,
  StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  EstimationMethodType,
  ResourceDefinitions,
  SortDirection,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import React from "react";

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const EstimationMethodListTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
  assetType,
}: StorageKeys & {
  assetType: AssetTypeType;
}) => {
  const isFilteredByEntity = !!assetType;
  const { localize } = useLocalization();
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];
  const flowTypes = useListFlowTypes()?.data ?? [];
  const calculators = useListCalculators().data?.calculators ?? [];
  const [filters] = useSessionStickyState<any>({}, filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );
  const estimationMethodParams = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...toFlattenedObject(filters),
      ...(isFilteredByEntity
        ? { entity_type: { $exact: assetType } }
        : { entity_type: [AssetType.EQUIPMENT, AssetType.FLOW] }),
    },
  };
  const { data, isLoading } = useListEstimationMethods(estimationMethodParams);
  const { mutate: exportEstimationMethods, isLoading: isExporting } =
    useExportEstimationMethods(estimationMethodParams);

  const headers: Array<HeaderType<EstimationMethodType>> = [
    {
      label: "Estimation Method",
      key: "name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToEstimationMethodDetailPage(
            item.entity_type,
            item.entity_id,
            item.id
          )}
        >
          {item.name}
        </RoutingLink>
      ),
    },
    {
      label: "Reporting Scenario",
      key: "reporting_groups",
      renderComponent: ({ item: { reporting_groups: reportingGroups } }) =>
        reportingGroups?.map(({ name }) => name)?.join(", ") ?? "-",
    },
    {
      label: isFilteredByEntity ? "Asset" : toStartCaseString(assetType),
      key: "equipment.name",
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToAssetDetailPage(item.entity_type, item.entity_id)}
        >
          {item.equipment?.name ?? item.flow?.name ?? "-"}
        </RoutingLink>
      ),
    },
    ...(isFilteredByEntity
      ? [
          {
            label: "Asset Type",
            key: "entity_type",
            isSortable: true,
            renderComponent: ({ item }) => localize(item?.entity_type) ?? "-",
          },
        ]
      : []),
    {
      label: isFilteredByEntity
        ? "Asset Subtype"
        : `${toStartCaseString(assetType)} Type`,
      key: "equipment.type_id",
      renderComponent: ({ item }) => {
        switch (item.entity_type) {
          case AssetType.EQUIPMENT:
            return (
              equipmentTypes.find(({ id }) => id === item.equipment?.type_id)
                ?.name ?? "-"
            );
          case AssetType.FLOW:
            return (
              flowTypes.find(({ id }) => id === item.flow?.type)?.name ?? "-"
            );
          default:
            return toStartCaseString(
              item?.equipment?.type_id ?? item.flow?.type
            );
        }
      },
    },
    {
      label: "Facility",
      key: "equipment.facility.name",
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToFacilityDetail(
            item.flow?.associated_facility_id ?? item.equipment?.facility_id
          )}
        >
          {item.flow?.associated_facility?.name ??
            item.equipment?.facility?.name ??
            "-"}
        </RoutingLink>
      ),
    },
    {
      label: "Calculation",
      key: "analytics_calculator_id",
      isSortable: true,
      renderComponent: ({ item }) => {
        const calculator = calculators.find(
          ({ id }) => id === item.analytics_calculator_id
        );
        return (
          calculator?.versions.find(
            ({ version }) => version === calculator?.default_version
          )?.title ?? "-"
        );
      },
    },
  ];
  const actionRow = [
    <ButtonWithPopover
      icon="download"
      label="Export"
      key="export-estimation-methods"
      variant="outline"
      onClick={() => exportEstimationMethods()}
      isLoading={isExporting}
    />,
    isDataIngestionEnabled ? (
      <ImportDataAction
        key="import-estimation-methods"
        resource={ResourceDefinitions.estimation_method}
      />
    ) : null,
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        actionRow,
        title: `${
          isFilteredByEntity ? "All" : toStartCaseString(assetType)
        } ${ResourceDefinitions.estimation_method.label.plural}`,
      }}
      dataTableProps={{
        headers,
        items: data?.data ?? [],
        isLoading,
        isFluid: false,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        sorting,
        pagination: {
          page: tableState.page,
          total: data?.total_entries ?? 0,
          itemsPerPage: tableState.itemsPerPage,
        },
      }}
      filterComponent={
        <EstimationMethodsTableFilterArea
          filterConfigStorageKey={filterConfigStorageKey}
          assetType={assetType}
        />
      }
    />
  );
};
