import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { TemplatedReportSchema } from "./TemplatedReportSchemas";

export const ReportStatus = {
  EXPORTING: "exporting",
  SUCCESS: "success",
  FAILED: "failed",
};

export type ReportStatusType = (typeof ReportStatus)[keyof typeof ReportStatus];

export const ReportSchema = z
  .object({
    name: z.string(),
    company_id: z.string().uuid(),
    status: z.enum([
      ReportStatus.FAILED,
      ReportStatus.EXPORTING,
      ReportStatus.SUCCESS,
    ]),
    templated_report_name: z.string().nullable(),
    templated_report: TemplatedReportSchema.nullable(),
    input: z.record(z.union([z.string(), z.number(), z.boolean()])),
    s3_object_id: z.string().nullable(),
    report_version: z.string().nullable(),
    s3_download_link: z.string().url().nullable(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema);

export type ReportType = z.infer<typeof ReportSchema>;

export const ReportWithDownloadSchema = ReportSchema.merge(
  z.object({
    s3_download_link: z.string(),
  })
);

export type ReportWithDownloadType = z.infer<typeof ReportWithDownloadSchema>;
