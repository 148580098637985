import React from "react";
import { Page } from "@validereinc/common-components";
import { useNavigate, useParams } from "#routers/hooks";
import { linkToFacilities } from "#routers/links";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import useLocalization from "#src/hooks/useLocalization";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { EquipmentCreateRoute } from "#src/routes/organization/equipment/create";
import { sanitizeCustomAttributeFormInputs } from "#src/utils/customAttributes";
import { AssetType, EquipmentSchema, Resources } from "@validereinc/domain";
import { EquipmentListRoute } from "..";
import EquipmentOverviewFormStep from "#src/batteries-included-components/Panels/FormPanels/EquipmentFormPanel/EquipmentOverviewFormStep";
import EquipmentDetailsFormStep from "#src/batteries-included-components/Panels/FormPanels/EquipmentFormPanel/EquipmentDetailsFormStep";
import pick from "lodash/pick";
import { getTimeStringFromDate } from "#src/utils/timeFormatter";
import config from "#src/config";
import {
  parseAsLongitudeDegree,
  parseAsLatitudeDegree,
} from "@validereinc/utilities";
import {
  useCreateOneEquipment,
  useListEquipmentTypes,
} from "#src/components/hooks/adapters/useEquipment";
import { useCreateOneTemplatedConfigurationRun } from "../../../../components/hooks/adapters/useTemplatedConfigurations";
import {
  TemplatedConfigurationQueueType,
  templatedConfigurationRunQueueName,
} from "#src/batteries-included-components/Banners/TemplatedConfigurationRunStatus";
import { useQueueUnique } from "#src/hooks/useQueue";

const CreateEquipmentPageContent = () => {
  const [breadcrumbs] = useBreadcrumbsFromRoute(EquipmentCreateRoute);
  const { stepper, footer } = useMultiStepFormContext();
  const { localize } = useLocalization();

  return (
    <Page
      title={`Create ${localize("Equipment")}`}
      breadcrumbs={breadcrumbs}
      footer={footer}
      actionRow={stepper}
    >
      <EquipmentOverviewFormStep />
      <EquipmentDetailsFormStep />
    </Page>
  );
};

export const CreateEquipmentPage = () => {
  const { facilityId } = useParams<{ facilityId?: string }>();
  const { localize } = useLocalization();

  const navigate = useNavigate();

  const onCancel = () => {
    if (facilityId) {
      navigate({ pathname: linkToFacilities(facilityId), replace: true });
    } else {
      navigate(EquipmentListRoute.toLinkParts({ replace: true }));
    }
  };

  const redirectAfterSuccess = () => {
    if (facilityId) {
      navigate({
        pathname: linkToFacilities(facilityId),
        query: { section: "equipment" },
      });
    } else {
      navigate(EquipmentListRoute.toLinkParts());
    }
  };

  const customAttributes =
    useListCustomAttributes({ filters: { entity_type: AssetType.EQUIPMENT } })
      .data?.data ?? [];

  const createEquipmentMutation = useCreateOneEquipment({
    successMessage: (data) =>
      `Successfully added ${data.data.name} to ${data.data.facility.name || localize("Facility")}`,
    onSuccess: redirectAfterSuccess,
  });

  const [queue, setQueue] = useQueueUnique<TemplatedConfigurationQueueType>(
    [],
    templatedConfigurationRunQueueName(Resources.EQUIPMENT),
    (tx) => tx.job.id
  );

  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];

  const createTemplatedConfigurationRun = useCreateOneTemplatedConfigurationRun(
    {
      onSuccess: (data, variables) => {
        const response = data.data;

        const primaryResourceId =
          Object.keys(variables?.data?.custom_attribute_configuration)?.[0] ??
          "";

        const equipmentTypeUsedForTemplatedRunCreation = primaryResourceId
          ? response?.custom_attribute_configuration?.[primaryResourceId]
              .entity_subtype
          : "";

        const equipmentTypeDefinitionUsedForTemplatedRunCreation =
          equipmentTypes.find(
            (equipmentType) =>
              equipmentType.id === equipmentTypeUsedForTemplatedRunCreation
          );

        setQueue(
          queue.insert({
            job: {
              id: response.id,
              templateName: response.templated_configuration_name,
            },
            resourceDetails: {
              type: equipmentTypeDefinitionUsedForTemplatedRunCreation,
            },
          })
        );
        redirectAfterSuccess();
      },
    }
  );

  const onSubmit = (values: any[]) => {
    const allValues = values.reduce(
      (total, current) => ({ ...total, ...current }),
      {}
    );

    if (allValues.should_use_template) {
      createTemplatedConfigurationRun.mutate({
        data: {
          ...pick(allValues, "inputs", "custom_attribute_inputs"),
          custom_attribute_configuration: {
            [allValues.main_resource_id ?? ""]: {
              entity_subtype: allValues.type_id,
            },
          },
        },
        meta: {
          templated_configuration_name: allValues.template_name,
        },
      });
    } else {
      createEquipmentMutation.mutate({
        data: {
          ...pick(
            allValues,
            EquipmentSchema.keyof().Enum.effective_date,
            EquipmentSchema.keyof().Enum.facility_id,
            EquipmentSchema.keyof().Enum.name,
            EquipmentSchema.keyof().Enum.status,
            EquipmentSchema.keyof().Enum.type_id
          ),
          effective_date: getTimeStringFromDate(
            allValues.effective_date,
            config.DATE_FORMAT
          ),
          latitude: parseAsLatitudeDegree(
            String(allValues?.coordinations?.[0])
          ),
          longitude: parseAsLongitudeDegree(
            String(allValues?.coordinations?.[1])
          ),
          custom_attributes: sanitizeCustomAttributeFormInputs(
            allValues?.custom_attributes ?? {},
            customAttributes
          ),
        },
      });
    }
  };

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Overview",
        },
        {
          label: "Details",
        },
      ]}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitMutationState={{
        isSubmitting:
          createEquipmentMutation.isLoading ||
          createTemplatedConfigurationRun.isLoading,
      }}
      submissionActionText="Create"
    >
      <CreateEquipmentPageContent />
    </MultiStepFormProvider>
  );
};

export default CreateEquipmentPage;
