import { InputStack } from "@validereinc/common-components";
import { convertUnderscoresToSpaces } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React from "react";
import { permissionListToString } from "../Company/Roles/permissionUtils";
import "./PermissionList.css";
import { stripProductFromPermission } from "./Permissions";

const PermissionList = (props) => (
  <InputStack
    label={props.title}
    className="rolePermission"
  >
    <div className="rolePermissionList">
      {props.list
        ? Object.keys(props.list).map((row, index) => {
            const title = stripProductFromPermission(row);
            const permissionsListAsString = permissionListToString(
              props.list[row]
            );

            return (
              <div
                key={index}
                className="permissionListRow"
              >
                <div className="permissionListRow__title">
                  {convertUnderscoresToSpaces(title)}
                </div>
                <div className="permissionListRow__value pull-right">
                  {convertUnderscoresToSpaces(permissionsListAsString)}
                </div>
              </div>
            );
          })
        : null}
    </div>
  </InputStack>
);

PermissionList.propTypes = {
  title: PropTypes.string,
  titleCol: PropTypes.number,
  list: PropTypes.object,
  titleType: PropTypes.string,
};

export default PermissionList;
