import { RecordContext } from "#src/contexts/RecordContext";
import { PillProps, StatusPanel } from "@validereinc/common-components";
import React, { useContext } from "react";

import { useParams } from "#routers/hooks";
import { useGetOneUser } from "#src/components/hooks/adapters/useUsers";
import { RecordValueStatusType } from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";

export const RecordValueStatusPanel = () => {
  const { record, isLoading: isRecordLoading } =
    useContext(RecordContext) || {};
  const { measurementType } = useParams<{ measurementType: string }>();

  const isLoading = isRecordLoading;

  const recordValue = record?.values.find(
    ({ measurement_type }) => measurement_type === measurementType
  );

  const usersQuery = useGetOneUser({ id: recordValue?.updated_by });

  const recordStatusOptions: Record<
    RecordValueStatusType,
    { label: string; variant: PillProps["variant"] }
  > = {
    locked: { label: "Locked", variant: "default" },
    unlocked: { label: "Unlocked", variant: "info" },
  };

  const data = [
    {
      label: "Updated At",
      value: recordValue?.updated_at
        ? datetimeFormatter(new Date(recordValue.updated_at)).replace(",", "")
        : null,
    },
    {
      label: "Updated By",
      value: usersQuery.data?.name,
    },
  ];

  return (
    <StatusPanel
      isFluidY={false}
      data={data}
      statusText={
        recordValue?.status
          ? recordStatusOptions[recordValue?.status].label
          : ""
      }
      statusVariant={
        recordValue?.status
          ? recordStatusOptions[recordValue?.status].variant
          : "default"
      }
      isLoading={isLoading}
    />
  );
};
