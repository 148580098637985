import {
  Resources,
  TemplatedConfigurationAdapter,
  type TemplatedConfigurationInputType,
  TemplatedConfigurationRunAdapter,
  type TemplatedConfigurationRunCreatedType,
  type TemplatedConfigurationType,
} from "@validereinc/domain";
import { useCreateOne, useGetList, useGetOne } from "./adapterUtils";

// Templated Configuration hooks:

export const useGetOneTemplatedConfiguration =
  useGetOne<TemplatedConfigurationType>(
    TemplatedConfigurationAdapter.getOne,
    Resources.TEMPLATED_CONFIGURATION
  );

export const useListTemplatedConfigurations = useGetList(
  TemplatedConfigurationAdapter.getList,
  Resources.TEMPLATED_CONFIGURATION
);

export const useGetTemplatedConfigurationInputSchema =
  useGetOne<TemplatedConfigurationInputType>(
    TemplatedConfigurationAdapter.inputSchema.getOne,
    Resources.TEMPLATED_CONFIGURATION,
    "inputSchema"
  );

// Templated Configuration Run hooks:

export const useCreateOneTemplatedConfigurationRun =
  useCreateOne<TemplatedConfigurationRunCreatedType>(
    TemplatedConfigurationRunAdapter.createOne,
    Resources.TEMPLATED_CONFIGURATION_RUN
  );

export const useGetOneTemplatedConfigurationRun =
  useGetOne<TemplatedConfigurationRunCreatedType>(
    TemplatedConfigurationRunAdapter.getOne,
    Resources.TEMPLATED_CONFIGURATION_RUN
  );

export const useListTemplatedConfigurationsRun = useGetList(
  TemplatedConfigurationRunAdapter.getList,
  Resources.TEMPLATED_CONFIGURATION_RUN
);
