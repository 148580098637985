import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useHistory, useNavigate, useParams } from "#src/Routers/hooks";
import AlertMessageWithLink from "#src/components/Common/Alerts/AlertMessageWithLink";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import {
  FORM_CATEGORY_DETAILS_BREADCRUMB,
  linkToFormCategoryDetails,
} from "#src/routes/forms/categories/[categoryId]";
import { CREATE_FORM_CATEGORY_FORM_TEMPLATE_BREADCRUMB } from "#src/routes/forms/categories/[categoryId]/create-form-template";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Page, Pill, useAlert } from "@validereinc/common-components";
import { FormCategoryAdapter, FormSchemaAdapter } from "@validereinc/domain";
import React, { useState } from "react";
import { linkToFormTemplateDetail } from "../templates/[formTemplateId]";
import {
  CreateFormTemplateDetailsStep,
  CreateFormTemplateSectionStep,
} from "./steps";
import { NavigateWithoutSavingFormTemplateDialog } from "./NavigateWithoutSavingFormTemplateDialog";
import { EDIT_FORM_CATEGORY_FORM_TEMPLATE_BREADCRUMB } from "../edit-form-template/[formSchemaId]";
import { EditFormTemplateDialog } from "../edit-form-template/[formSchemaId]/EditFormTemplateDialog";
import {
  FormContextProvider,
  useFormContext,
} from "#src/contexts/FormSchemaContext";
import { useBlockNavigation } from "#src/hooks/useBlockNavigation";
import classNames from "classnames/bind";
import styles from "./CreateFormTemplate.module.scss";

const cx = classNames.bind(styles);

export const CreateFormTemplatePageContent = () => {
  const {
    schema: { data: templateToEdit },
  } = useFormContext();
  const { categoryId, schemaId } = useParams<{
    categoryId: string;
    schemaId: string;
  }>();

  const { stepper, footer } = useMultiStepFormContext();

  const categoryQuery = useQuery({
    queryKey: ["formCategories", categoryId],
    queryFn: ({ queryKey: [_, id] }) =>
      FormCategoryAdapter.getOne({
        id,
      }),
  });

  const breadcrumbs = useBreadcrumbs(
    [
      FORMS_BREADCRUMB,
      FORMS_CATEGORIES_BREADCRUMB,
      FORM_CATEGORY_DETAILS_BREADCRUMB,
      schemaId
        ? EDIT_FORM_CATEGORY_FORM_TEMPLATE_BREADCRUMB
        : CREATE_FORM_CATEGORY_FORM_TEMPLATE_BREADCRUMB,
    ],
    {
      2: categoryQuery?.data?.name,
    }
  );

  return (
    <Page
      breadcrumbs={breadcrumbs}
      category={schemaId ? "Edit Template" : categoryQuery?.data?.name}
      title={
        schemaId ? (
          <div className={cx("page-title")}>
            {templateToEdit?.name}
            {templateToEdit?.version && (
              <Pill
                variant="default"
                isCapitalized={false}
              >{`v${templateToEdit.version}`}</Pill>
            )}
          </div>
        ) : (
          "Create Template"
        )
      }
      actionRow={stepper}
      footer={footer}
    >
      <CreateFormTemplateDetailsStep />
      <CreateFormTemplateSectionStep />
    </Page>
  );
};

export const CreateFormTemplatePage = () => {
  const { addAlert } = useAlert();
  const { categoryId, schemaId } = useParams<{
    categoryId: string;
    schemaId: string;
  }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showNavigateAwayDialog, setShowNavigateAwayDialog] =
    useState<boolean>(false);
  const [confirmNavigateAway, setConfirmNavigateAway] =
    useState<boolean>(false);
  const [editedFormValues, setEditedFormValues] = useState<any[]>([]);

  const [, unblockNavigation] = useBlockNavigation(!confirmNavigateAway, () =>
    setShowNavigateAwayDialog(true)
  );

  const createMutation = useMutation({
    mutationFn: async (formValues) =>
      await FormSchemaAdapter.createOne({ data: formValues }),
    onSuccess: ({ id }) => {
      unblockNavigation({ redirect: false });

      addAlert?.({
        variant: "success",
        message: (
          <AlertMessageWithLink
            mainText="Successfully created form template."
            linkText="View Template Details"
            position="bottom"
            onLinkClick={() =>
              navigate({
                pathname: linkToFormTemplateDetail(categoryId, id),
              })
            }
          />
        ),
      });

      queryClient.invalidateQueries({
        queryKey: ["formCategories", categoryId],
      });

      queryClient.invalidateQueries({
        queryKey: ["formSchemas"],
      });

      navigate({
        pathname: linkToFormCategoryDetails(categoryId),
      });
    },
    onError: (error) => {
      addAlert?.({
        variant: "error",
        // message in format of "config -> ... -> field_that_caused_error_message"
        message: `Failed to create form template. There's an issue with the following field: ${error.responseBodyJson?.error?.split("->").at(-1)}`,
      });
    },
    enabled: !schemaId,
  });

  const updateMutation = useMutation({
    mutationFn: async (formValues) =>
      await FormSchemaAdapter.updateOne({ id: schemaId, data: formValues }),
    onSuccess: ({ id }) => {
      unblockNavigation({ redirect: false });

      addAlert?.({
        variant: "success",
        message: "Successfully saved a new version of the form template.",
      }),
        queryClient.invalidateQueries({
          queryKey: ["formSchemas"],
        });

      navigate({
        pathname: linkToFormTemplateDetail(categoryId, id),
      });
    },
    onError: (error) => {
      addAlert?.({
        variant: "error",
        // message in format of "config -> ... -> field_that_caused_error_message"
        message: `Failed to save a new version of the form template. There's an issue with the following field: ${error.responseBodyJson?.error?.split("->").at(-1)}`,
      });
    },
    enabled: !!schemaId,
  });

  const onSubmitCreateTemplate = (values: any[]) => {
    createMutation.mutate({
      ...values.reduce((total, current) => ({ ...total, ...current }), {}),
      form_category_id: categoryId,
    });
  };

  const onSubmitEditTemplate = (values: any[]) => {
    updateMutation.mutate({
      ...values.reduce((total, current) => ({ ...total, ...current }), {}),
      form_category_id: categoryId,
    });
  };

  const handleEditFormTemplate = (values: any[]) => {
    setEditedFormValues(values);
  };

  const onCloseEditFormTemplateDialog = () => {
    setEditedFormValues([]);
  };

  const onCancel = () => {
    navigate.goBack();
  };

  const handleConfirmNavigateAway = () => {
    setConfirmNavigateAway(true);
    onCloseConfirmNavigateAwayDialog();
    unblockNavigation({ redirect: true });
  };

  const onCloseConfirmNavigateAwayDialog = () => {
    setShowNavigateAwayDialog(false);
  };

  return (
    <FormContextProvider>
      <MultiStepFormProvider
        steps={[
          {
            label: "Template Details",
          },
          {
            label: "Sections & Questions",
          },
        ]}
        submissionActionText={schemaId ? "Save & Exit" : "Create"}
        onSubmit={schemaId ? handleEditFormTemplate : onSubmitCreateTemplate}
        onCancel={onCancel}
      >
        <CreateFormTemplatePageContent />
        <EditFormTemplateDialog
          isOpen={editedFormValues.length > 0}
          editedFormValues={editedFormValues}
          onSubmitEditTemplate={onSubmitEditTemplate}
          onClose={onCloseEditFormTemplateDialog}
        />
        <NavigateWithoutSavingFormTemplateDialog
          isOpen={showNavigateAwayDialog}
          handleConfirmNavigateAway={handleConfirmNavigateAway}
          onClose={onCloseConfirmNavigateAwayDialog}
          isCreating={!schemaId}
        />
      </MultiStepFormProvider>
    </FormContextProvider>
  );
};
