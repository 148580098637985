import { AssetTypeType, CustomAttributeRecordType } from "../schemas";
import type {
  GetListRequestType,
  GetListResponseType,
  GetOneRequestType,
} from "../util";
import { fetchAndCollate, getFilterObject } from "../util";
import { EstimationMethodType, ReportingGroupType } from "./";
import { restAPI } from "./api";

export type AssetGroupLookupType = {
  assetGroupId: string;
};

export type AssetGroupFilterType = Record<string, string>;

export const AssetGroupStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
} as const;

export type AssetGroupStatusType =
  (typeof AssetGroupStatus)[keyof typeof AssetGroupStatus];

type CreateNetworkRequestType = {
  name: string;
  status: AssetGroupStatusType;
  flow_ids: string[];
  custom_attributes?: CustomAttributeRecordType;
  reporting_group_id?: string;
};

export type AssetGroupAssetType = {
  id: string;
  asset_type: AssetTypeType;
  default_network_adjustable?: boolean;
};

export type AssetGroupType = {
  id: string;
  name: string;
  status: AssetGroupStatusType;
  default_network_reporting_group_id?: string;
  default_network_reporting_group?: ReportingGroupType;
  default_estimation_method_id?: string;
  default_estimation_method?: EstimationMethodType;
  custom_attributes: CustomAttributeRecordType;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
};

export type AssetGroupWithAssetsType = AssetGroupType & {
  assets: AssetGroupAssetType[];
  network_inlet_ids: string[];
};

export type NetworkConfigurationLookupType = {
  reportingGroupId: string;
  networkId: string;
  yearMonth: string;
};

export type NetworkAssetConfigurationType = {
  adjustable: boolean;
};

export type NetworkConfigurationInputType = Record<
  string,
  NetworkAssetConfigurationType
>;

export type NetworkConfigurationType = {
  adjustable: boolean;
  proratable: boolean;
  volume: number;
  adjusted_volume: number;
  volume_record_id?: string;
  volume_estimation_method_id?: string;
  volume_source_type: string;
  id: string;
  name: string;
  type: string;
  product_category: string;
  product_type: string;
};

export function isNetworkInputsLookupType(
  inputs: Partial<NetworkConfigurationLookupType>
): inputs is NetworkConfigurationLookupType {
  return !!inputs.networkId && !!inputs.reportingGroupId && !!inputs.yearMonth;
}

export const AssetGroupDomain = {
  getList: async ({
    page,
    pageSize,
    sortBy,
    sortDirection,
    filters,
    meta = {},
  }: GetListRequestType<AssetGroupFilterType, { period?: string }>) =>
    fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST<GetListResponseType<AssetGroupWithAssetsType>>({
          endpoint: "/asset_groups/search",
          query: meta.period ? { period: meta.period } : undefined,
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    ),

  getOne: async ({ id, meta }: GetOneRequestType<{ period: string }>) =>
    restAPI.nodeAPI.GET<AssetGroupWithAssetsType>({
      endpoint: `/asset_groups/${id}`,
      query: meta?.period ? { period: meta.period } : {},
    }),
  getNetworkConfiguration: async ({
    reportingGroupId,
    networkId,
    yearMonth,
  }: NetworkConfigurationLookupType) =>
    fetchAndCollate(({ page, pageSize }) =>
      restAPI.nodeAPI.PUT<GetListResponseType<NetworkConfigurationType>>({
        endpoint: `/networks/${networkId}/${yearMonth}`,
        body: {
          page,
          page_size: pageSize,
          reporting_group_id: reportingGroupId,
        },
      })
    ),
  setNetworkConfiguration: async ({
    reportingGroupId,
    networkId,
    yearMonth,
    input,
  }: NetworkConfigurationLookupType & {
    input: NetworkConfigurationInputType;
  }) =>
    fetchAndCollate(({ page, pageSize }) =>
      restAPI.nodeAPI.PUT<GetListResponseType<NetworkConfigurationType>>({
        endpoint: `/networks/${networkId}/${yearMonth}`,
        body: {
          reporting_group_id: reportingGroupId,
          page_size: pageSize,
          page,
          input,
        },
      })
    ),
  runNetworkCalculation: async ({
    networkId,
    yearMonth,
    promoteToRecord,
  }: NetworkConfigurationLookupType & { promoteToRecord: boolean }) =>
    restAPI.nodeAPI.POST<GetListResponseType<NetworkConfigurationType>>({
      endpoint: `/networks/${networkId}/${yearMonth}/run`,
      body: {
        promote_to_record: promoteToRecord,
      },
    }),

  /**
   * Create a single Asset Group (otherwise known as a Network)
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/asset_groups/create_asset_group
   */
  create: async ({
    flow_ids,
    name,
    status,
    custom_attributes,
    reporting_group_id,
  }: CreateNetworkRequestType) =>
    restAPI.nodeAPI.POST<AssetGroupWithAssetsType>({
      version: 2,
      endpoint: "/asset_groups",
      body: {
        asset_ids: [...flow_ids],
        asset_group_type_id: "network",
        name,
        status,
        custom_attributes,
        reporting_group_id,
      },
    }),

  /**
   * Update a single Asset Group (otherwise known as a Network)
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/asset_groups/put_asset_group
   * @returns the updated asset group
   */
  update: ({
    assetGroupId,
    reporting_group_id,
    name,
    status,
    custom_attributes,
    ...rest
  }: {
    assetGroupId: string;
    reporting_group_id?: string;
    name?: string;
    status?: AssetGroupStatusType;
    custom_attributes?: any;
  }) =>
    restAPI.nodeAPI.PUT<AssetGroupType>({
      endpoint: `/asset_groups/${assetGroupId}`,
      body: {
        ...rest,
        name,
        status,
        reporting_group_id,
        custom_attributes,
      },
    }),

  /**
   * Delete a single Asset Group (otherwise known as a Network)
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/asset_groups/put_asset_group
   * @returns the delele asset group
   */
  delete: async ({ assetGroupId }: { assetGroupId: string }) =>
    restAPI.nodeAPI.DELETE({ endpoint: `/asset_groups/${assetGroupId}` }),

  migrate: async ({ assetGroupId }: { assetGroupId: string }) =>
    restAPI.nodeAPI.POST({ endpoint: `/networks/${assetGroupId}/migrate` }),

  assets: {
    /**
     * Update the assets in a group, and it's corresponding network estimation method
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/asset_groups/update_asset_group_assets
     * @returns the new set of asset_ids
     */
    update: ({
      assetGroupId,
      assetIds,
    }: {
      assetGroupId: string;
      assetIds: string[];
    }) =>
      restAPI.nodeAPI.PUT<{ assets: string[] }>({
        endpoint: `/asset_groups/${assetGroupId}/asset`,
        body: {
          asset_ids: assetIds,
        },
      }),
  },
};
