import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  Form,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import { useUpdateMeasurementSeries } from "#src/components/hooks/adapters/useMeasurementSeries";
import {
  DeviceMeasurementSeriesSchema,
  MeasurementSeriesType,
} from "@validereinc/domain";

export const EditMeasurementSeriesDialog = ({
  itemToUpdate,
  isOpen,
  onClose,
}: {
  itemToUpdate: MeasurementSeriesType;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const form = useForm({
    defaultValues: { description: itemToUpdate?.description },
  });

  const onSuccess = () => {
    onClose?.();
  };
  const updateMeasurementSeries = useUpdateMeasurementSeries({ onSuccess });
  const handleSubmit = form.handleSubmit((values) => {
    const { description } = values;
    updateMeasurementSeries.mutate({
      id: itemToUpdate.id,
      data: {
        description,
        device_id: itemToUpdate.device_id,
        measurement_type: itemToUpdate.measurement_type,
        unit: itemToUpdate.unit,
      },
    });
  });

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  return (
    <Dialog
      title="Edit Measurement Series"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="edit-measurement-series"
          variant="primary"
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
    >
      <Form {...form}>
        <TextInput
          name={DeviceMeasurementSeriesSchema.keyof().Enum.description}
          label="Description"
          isRequired
          type="text"
        />
      </Form>
    </Dialog>
  );
};
