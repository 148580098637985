/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormSchemaType,
  type FormSchemaQuestionType,
  isNormalFormQuestionLookup,
} from "@validereinc/domain";
import isEmpty from "lodash/isEmpty";
import { ReactNode, useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormSubmissionsSectionRepeater } from "./FormSubmissionsSectionRepeater";
import { toFlattenedObject } from "@validereinc/utilities";
import get from "lodash/get";
import { usePrevious } from "../../../utilities";
import { getValuePathFromQuestionPath } from "../../../logic/forms";

export type SectionRendererPropType = {
  onAddSection: () => void;
  onRemoveSection: () => void;
  index: number;
  title: string;
  addButtonLabel: string;
  hasErrors?: boolean;
  canAddSection?: boolean;
  canRemoveSection?: boolean;
  children: ReactNode;
  dataKey: string;
  description?: string;
};

export type QuestionRendererPropType = {
  dataKey: string;
  name: string;
} & FormSchemaQuestionType;

export type SectionRenderFunctionType = (
  sectionRenderFunctionProps: SectionRendererPropType
) => ReactNode;

export type QuestionRenderFunctionType = (
  questionRenderFunctionProps: QuestionRendererPropType
) => ReactNode;

export type FormSubmissionFormControllerType = (props: {
  form: UseFormReturn<any>;
  formSchema?: FormSchemaType;
  sectionRenderFunction: SectionRenderFunctionType;
  questionRenderFunction: QuestionRenderFunctionType;
}) => JSX.Element | null;

const FormSubmissionFormController: FormSubmissionFormControllerType = ({
  form,
  formSchema,
  sectionRenderFunction,
  questionRenderFunction,
}) => {
  const formValues = form.watch();
  const formValuesSerialized = JSON.stringify(formValues);
  const previousFormValuesSerialized = usePrevious(formValuesSerialized);

  /**
   * This object has the form of: {parentQuestionId: ["dependentQuestionId1", "dependentQuestionId2", ... ], ...}
   * "dependent" here, is a lookup field that it's value gets wiped when its parent value is changed.
   */
  const questionDependencyMap = useMemo(() => {
    if (!formSchema) return {};

    const dependencies: Record<string, string[]> = {};

    Object.entries(formSchema.config.questions).map(
      ([questionId, questionDefinition]) => {
        if (
          questionDefinition.type === "question" &&
          isNormalFormQuestionLookup(questionDefinition)
        ) {
          if (questionDefinition?.filter) {
            Object.values(toFlattenedObject(questionDefinition.filter)).forEach(
              (filterValue) => {
                if (
                  typeof filterValue === "string" &&
                  filterValue?.startsWith("%") &&
                  filterValue?.endsWith("%")
                ) {
                  const parentQuestionId = filterValue.slice(1, -1);

                  if (dependencies?.[parentQuestionId]) {
                    dependencies[parentQuestionId].push(questionId);
                  } else {
                    dependencies[parentQuestionId] = [questionId];
                  }
                }
              }
            );
          } else if (questionDefinition?.parent_facility_question_id) {
            const parentQuestionId =
              questionDefinition?.parent_facility_question_id;
            if (dependencies?.[parentQuestionId]) {
              dependencies[parentQuestionId].push(questionId);
            } else {
              dependencies[parentQuestionId] = [questionId];
            }
          }
        }
      }
    );
    return dependencies;
  }, [formSchema]);

  /**
   * This object has the form of: {"answers.sectionId.sectionIndex.parentQuestionId": ["answers.sectionId.sectionIndex.dependentQuestionId1", ... ], ...}
   * So it's the same as questionDependencyMap, but with full paths that represent form answers.
   */
  const fieldsToWatch = useMemo(() => {
    const dependencies: Record<string, string[]> = {};

    formSchema?.config.sections.forEach((section) => {
      if (isEmpty(formValues)) return;
      for (
        let sectionIndex = 0;
        sectionIndex < (formValues.answers[section.id]?.length ?? 0);
        sectionIndex++
      ) {
        Object.entries(questionDependencyMap).forEach(
          ([parentQuestionId, dependentQuestionIds]) => {
            const listenTo = `answers.${section.id}.${sectionIndex}.${parentQuestionId}`;
            dependentQuestionIds.forEach((dependentQuestionId) => {
              const dependentFullPath = `answers.${section.id}.${sectionIndex}.${dependentQuestionId}`;
              if (dependencies?.[listenTo]) {
                dependencies[listenTo].push(dependentFullPath);
              } else {
                dependencies[listenTo] = [dependentFullPath];
              }
            });
          }
        );
      }
    });

    return dependencies;
  }, [questionDependencyMap, formValuesSerialized]);

  useEffect(() => {
    const previousFormValues = JSON.parse(previousFormValuesSerialized ?? "{}");

    Object.keys(fieldsToWatch).forEach((fieldKey) => {
      const currentValue = get(
        formValues,
        getValuePathFromQuestionPath(fieldKey)
      );
      const previousValue = get(
        previousFormValues,
        getValuePathFromQuestionPath(fieldKey)
      );
      if (currentValue && previousValue !== currentValue) {
        fieldsToWatch[fieldKey].forEach((dependentAnswerPath) => {
          form.setValue(getValuePathFromQuestionPath(dependentAnswerPath), "");
        });
      }
    });
  }, [JSON.stringify(fieldsToWatch), formValuesSerialized]);

  if (
    !formSchema ||
    !Array.isArray(formSchema?.config?.sections) ||
    isEmpty(formSchema?.config?.questions ?? {})
  )
    return null;

  return (
    <>
      {formSchema.config.sections.map((sectionProps) => (
        <FormSubmissionsSectionRepeater
          key={sectionProps.id}
          sectionRenderFunction={sectionRenderFunction}
          questionRenderFunction={questionRenderFunction}
          formQuestions={formSchema?.config?.questions}
          form={form}
          {...sectionProps}
        />
      ))}
    </>
  );
};

export default FormSubmissionFormController;
