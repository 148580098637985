import { InputSourceFlair } from "#batteries-included-components/Flairs/InputSource";
import {
  EstimationMethodDetailContextProvider,
  useEstimationMethodDetailContext,
} from "#batteries-included-components/Layouts/EstimationMethod/Detail";
import { SinglePropertyPillPanel } from "#batteries-included-components/Panels/PillPanels/SinglePropertyPillPanel";
import { RoutingLink } from "#batteries-included-components/RoutingLink";
import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#common/SummaryInformation";
import { QUERY_STATUS } from "#constants";
import {
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
} from "#hooks/adapters/useFlows";
import { useGetEstimationMethodRun } from "#hooks/useEstimationMethod";
import { useParams } from "#routers/hooks";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { FlowEstimationMethodResultDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/result/[yearMonth]/index";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { useQuery } from "@tanstack/react-query";
import {
  MathDataDisplayEquationsWithBreakdown,
  KeyValuePanel,
  MetricTileGrid,
  Page,
  Panel,
} from "@validereinc/common-components";
import {
  AssetType,
  CalculatorResultsDomain,
  type CalculationParameterSavedType,
  type FlowType,
} from "@validereinc/domain";
import {
  DateFormats,
  dateFormatter,
  getFormattedNumberWithUnit,
  monthFormatter,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import { parse } from "date-fns";
import { Tag } from "phosphor-react";
import React, { useMemo } from "react";
import styles from "./FlowEstimationMethodResultDetailPage.module.scss";
import useLocalization from "#src/hooks/useLocalization";

const cx = classNames.bind(styles);

const FlowEstimationMethodResultDetailContent = () => {
  const { localize } = useLocalization();
  const params = useParams<{
    flowId: string;
    yearMonth: string;
    estimationMethodId: string;
  }>();
  const {
    getUnitName,
    getTypeName,
    getPrecisionByType,
    getUnitByType,
    isLoading,
  } = useMeasurementTypes();
  const { yearMonth, estimationMethodId, flowId } = params;

  const { data: flow } = useFlowDetailFlow();
  const flowTypes = useListFlowTypes().data ?? [];
  const productCategories = useListFlowProductCategories().data ?? [];
  const productTypes = useListFlowProductTypes().data ?? [];

  const { method, calculator } = useEstimationMethodDetailContext<FlowType>();

  const resultPeriod = useMemo(
    () => parse(yearMonth, DateFormats.YEAR_MONTH, new Date()),
    [yearMonth]
  );

  const methodDetail = method?.data;
  const calculatorDetail = calculator?.data;

  const resultSummaryParams = {
    filters: {
      "estimation_method.id": estimationMethodId,
      year_month: yearMonth,
    },
  };

  const resultSummaryQuery = useQuery({
    queryKey: ["calculatorResults", resultSummaryParams],
    queryFn: () => CalculatorResultsDomain.getList(resultSummaryParams),
  });

  const { data: resultDetail, status: resultDetailFetchStatus } =
    useGetEstimationMethodRun(methodDetail?.id, resultPeriod, AssetType.FLOW);

  const [breadcrumbs] = useBreadcrumbsFromRoute(
    FlowEstimationMethodResultDetailRoute,
    {
      detail: { title: flow?.name },
      estimationMethodId: { title: methodDetail?.name },
      yearMonth: { title: monthFormatter(resultPeriod) },
    }
  );

  const sharedSummaryInfoProps = {
    type: "vertical",
    allowWrap: true,
    isStatusPanel: true,
  };

  const lastCalculatorVersion = calculatorDetail?.versions.find(
    ({ version }) => version === calculatorDetail?.default_version
  );

  const resultsAsMetricCardProps = useMemo(() => {
    const results = resultSummaryQuery.data?.data[0]?.measurement;
    return results &&
      resultDetail?.output.outputs &&
      Array.isArray(resultDetail?.output?.outputs)
      ? Object.entries(results).map(([key, value]) => ({
          title: getTypeName(key),
          description: resultDetail?.output.outputs.find(
            ({ measurement_type }) => measurement_type === key
          )?.description,
          value: getFormattedNumberWithUnit(
            {
              value: Number(value),
              unit: getUnitName(getUnitByType(key)),
            },
            getPrecisionByType(key),
            {
              showSmallNumberAsExponential: true,
              maxFractionDigits: 3,
            }
          ),
        }))
      : [];
  }, [resultDetail, resultSummaryQuery.data, isLoading]);

  const flowDetailsAsKeyValuePanelData = useMemo(() => {
    if (!flow) {
      return [];
    }

    return [
      {
        title: `${localize("Flow")}`,
        value: (
          <RoutingLink to={FlowDetailRoute.toLink({ pathParams: { flowId } })}>
            {flow.name ?? "-"}
          </RoutingLink>
        ),
      },
      {
        title: `${localize("Flow")} Type`,
        value: flow.type
          ? flowTypes?.find(({ id }) => id === flow.type)?.name
          : "-",
      },
      {
        title: "Product Type",
        value: flow.product_type
          ? productTypes?.find(({ id }) => id === flow.product_type)?.name
          : "-",
      },
      {
        title: "Product Category",
        value: flow.product_category
          ? productCategories?.find(({ id }) => id === flow.product_category)
              ?.name
          : "-",
      },
      {
        title: "Calculator Name",
        value: lastCalculatorVersion?.title,
      },
    ];
  }, [
    flow,
    methodDetail,
    flowTypes,
    productTypes,
    productCategories,
    lastCalculatorVersion,
  ]);

  const [userInputsAsKeyValuePanelData, defaultInputsAsKeyValuePanelData] =
    useMemo(() => {
      if (
        !resultDetail?.configuration_input ||
        !Array.isArray(resultDetail?.input?.calculation_parameters)
      ) {
        return [[], []];
      }

      const {
        configuration_input: configuredInputs,
        input: { calculation_parameters: calculationParameters },
      } = resultDetail;

      const getDisplayValue = (
        calculationInputAsOutput: CalculationParameterSavedType
      ) => {
        const {
          type,
          measurement_value: value,
          measurement_unit: unit,
        } = calculationInputAsOutput ?? {};

        switch (type) {
          case "number":
            return getFormattedNumberWithUnit(
              {
                value,
                unit: getUnitName(unit),
              },
              null,
              {
                showSmallNumberAsExponential: true,
                maxFractionDigits: 3,
              }
            );
          case "boolean":
          case "string":
          default:
            return String(value) ?? "-";
        }
      };

      return calculationParameters.reduce<
        [
          Array<{ title: string; value: string }>,
          Array<{ title: string; value: string }>,
        ]
      >(
        ([userData, defaultData], parameter) => {
          const { id } = parameter ?? {};
          // if the current parameter is found in the configured inputs, then this input was user-configured
          const isManuallyConfigured = Object.keys(configuredInputs).find(
            (configuredInputId) => configuredInputId === id
          );
          const item = {
            title: parameter.display_name,
            value: (
              <>
                <span>{getDisplayValue(parameter)}</span>
                <InputSourceFlair
                  inputParameter={parameter}
                  estimationMethodRun={resultDetail}
                />
              </>
            ),
          };

          if (isManuallyConfigured) {
            userData.push(item);
          } else {
            defaultData.push(item);
          }

          return [userData, defaultData];
        },
        [[], []]
      );
    }, [resultDetail]);

  return (
    <Page
      breadcrumbs={breadcrumbs}
      category="Calculation Result"
      title={monthFormatter(resultPeriod)}
    >
      {resultDetail ? (
        <div className={cx("container")}>
          <div>
            <Panel isFluidY={false}>
              <SummaryInformationContainer
                variant="vertical"
                status={"active"}
                statusVariant={"active"}
              >
                <SummaryInformation
                  {...sharedSummaryInfoProps}
                  title="Last Run"
                  value={dateFormatter(new Date(resultDetail.updated_at))}
                />

                <SummaryInformation
                  {...sharedSummaryInfoProps}
                  title="Last Run By"
                  value={resultDetail?.updatedBy?.name}
                />

                <SummaryInformation
                  {...sharedSummaryInfoProps}
                  title="Created At"
                  value={dateFormatter(new Date(resultDetail.created_at))}
                />

                <SummaryInformation
                  {...sharedSummaryInfoProps}
                  title="Created By"
                  value={resultDetail?.createdBy?.name}
                />
              </SummaryInformationContainer>
            </Panel>
            <div>
              <SinglePropertyPillPanel
                panelProps={{
                  title: "Reporting Scenarios",
                  loaded: resultDetailFetchStatus !== QUERY_STATUS.LOADING,
                }}
                values={
                  methodDetail?.reporting_groups?.map(({ name }) => name) ?? []
                }
                emptyStateProps={{
                  title: "No Reporting Scenarios",
                  suggestion:
                    "Edit the estimation method of this calculation result, to associate reporting scenarios",
                  icon: <Tag />,
                }}
              />
            </div>
          </div>
          <div className={cx("subContainer")}>
            <Panel
              title="Results"
              isFluidY={false}
            >
              <MetricTileGrid
                data={resultsAsMetricCardProps}
                isLoading={resultDetailFetchStatus === QUERY_STATUS.LOADING}
              />
            </Panel>

            <KeyValuePanel
              panelMaxColumnCount={2}
              panelProps={{
                title: "Flow Details",
                isFluidY: false,
              }}
              data={flowDetailsAsKeyValuePanelData}
            />

            {userInputsAsKeyValuePanelData.length ? (
              <KeyValuePanel
                panelMaxColumnCount={2}
                panelProps={{
                  title: "User Inputs",
                  isFluidY: false,
                }}
                data={userInputsAsKeyValuePanelData}
              />
            ) : null}

            {defaultInputsAsKeyValuePanelData.length ? (
              <KeyValuePanel
                panelMaxColumnCount={2}
                panelProps={{
                  title: "Default Inputs",
                  isFluidY: false,
                }}
                data={defaultInputsAsKeyValuePanelData}
              />
            ) : null}

            {typeof lastCalculatorVersion?.documentation === "object" &&
            Object.keys(lastCalculatorVersion?.documentation ?? {}).length ? (
              <Panel isFluidY={false}>
                <MathDataDisplayEquationsWithBreakdown
                  title={lastCalculatorVersion?.documentation.title}
                  sourceLink={lastCalculatorVersion?.documentation.link}
                  equations={lastCalculatorVersion?.documentation.calculations.map(
                    (calc) => ({
                      equation: calc.equation,
                      reference: calc.reference,
                      terms: calc.conditions.map((cond) => ({
                        math: cond.variable_name,
                        description: cond.variable_description,
                      })),
                    })
                  )}
                  isLoading={calculator?.status === QUERY_STATUS.LOADING}
                />
              </Panel>
            ) : null}
          </div>
        </div>
      ) : null}
    </Page>
  );
};

export const FlowEstimationMethodResultDetailPage = () => {
  const { estimationMethodId } = useParams<{
    estimationMethodId: string;
  }>();
  const { filterConfigStorageKey } = useStorageKey("flow-detail-filters");

  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });

  return (
    <EstimationMethodDetailContextProvider
      estimationMethodId={estimationMethodId}
      entityType={AssetType.FLOW}
      entityId={flow?.id}
      entityDetail={flow}
      entityFetchStatus={"loading"}
    >
      <FlowEstimationMethodResultDetailContent />
    </EstimationMethodDetailContextProvider>
  );
};
