import {
  ErrorCodes,
  ErrorCodesToExplanationGetters,
  HTTPForbiddenError,
  HTTPNotFoundError,
  HTTPResponseError,
} from "../errors";
import { HTTPUtils } from "../http";
import { RequestWrapperType, requestWrapper } from "./requestWrapper";
import { RequestCallbackType } from "./types";

export type PUTRequestType = {
  version?: number;
} & Pick<RequestWrapperType, "endpoint" | "body" | "headers" | "query">;

type PUTProps = {
  api: string;
  requestCallback?: RequestCallbackType;
} & PUTRequestType;

export const PUT = async <T = unknown>({
  api,
  endpoint,
  body,
  query,
  headers = {},
  requestCallback = (response) => response,
}: PUTProps): Promise<T> => {
  try {
    const request = requestWrapper({
      method: "PUT",
      endpoint: `${api}${endpoint}`,
      query,
      body,
      headers,
    });
    const response = await fetch(request).then(requestCallback);

    if (HTTPUtils.status.isSuccess(response.status)) {
      return (await response.json()) as T;
    } else {
      throw { response, request };
    }
  } catch (cause: unknown) {
    if (!HTTPUtils.objectHasResponseAndRequest(cause)) {
      throw new HTTPResponseError("PUT: unknown error", {
        code: ErrorCodes["http, unknown error"],
        getExplanation:
          ErrorCodesToExplanationGetters[ErrorCodes["http, unknown error"]],
        method: "PUT",
        endpoint: `${api}${endpoint}`,
        requestQuery: query,
        requestContentType: headers["Content-Type"],
        requestBodyJson: body,
        cause,
      });
    }

    if (
      cause.response.headers.get("Content-Type")?.includes("application/json")
    ) {
      if (HTTPUtils.status.isForbidden(cause.response.status)) {
        throw new HTTPForbiddenError("PUT: forbidden", {
          requestQuery: query,
          requestContentType: headers["Content-Type"],
          requestBodyJson: body,
          responseBodyJson: await cause.response.json(),
          status: cause.response.status,
          responseContentType: cause.response.headers.get("Content-Type"),
          endpoint: cause.request.url,
          method: "PUT",
        });
      } else if (HTTPUtils.status.isNotFound(cause.response.status)) {
        throw new HTTPNotFoundError("PUT: resource not found", {
          requestQuery: query,
          requestContentType: headers["Content-Type"],
          requestBodyJson: body,
          responseBodyJson: await cause.response.json(),
          status: cause.response.status,
          responseContentType: cause.response.headers.get("Content-Type"),
          endpoint: cause.request.url,
          method: "PUT",
        });
      } else {
        throw new HTTPResponseError("PUT: unknown error with info from API", {
          requestQuery: query,
          requestContentType: headers["Content-Type"],
          requestBodyJson: body,
          responseBodyJson: await cause.response.json(),
          status: cause.response.status,
          responseContentType: cause.response.headers.get("Content-Type"),
          endpoint: cause.request.url,
          method: "PUT",
        });
      }
    }

    throw new HTTPResponseError("PUT: unknown error", {
      code: ErrorCodes["http, unknown error"],
      getExplanation:
        ErrorCodesToExplanationGetters[ErrorCodes["http, unknown error"]],
      requestQuery: query,
      requestContentType: headers["Content-Type"],
      requestBodyJson: body,
      status: cause.response.status,
      responseContentType: cause.response.headers.get("Content-Type"),
      endpoint: cause.request.url,
      method: "PUT",
    });
  }
};
