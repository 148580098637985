import { useQuery } from "@tanstack/react-query";
import {
  DropdownInput,
  FilterSwitcherWithDialogEdit,
  FilterSwitcherWithMenuEdit,
} from "@validereinc/common-components";
import {
  ReportingGroupDomain,
  ReportingGroupType,
  Resources,
} from "@validereinc/domain";
import React, { useEffect, useMemo } from "react";

export const REPORTING_GROUP_VARIANTS = {
  MENU_EDIT: "withMenuEdit",
  DIALOG_EDIT: "withDialogEdit",
} as const;

export const ReportingGroupFilterSwitcher = ({
  name = "reportingScenario",
  options,
  shouldSelectFirstOptionAsDefault,
  shouldSortByPriority = true,
  shouldMergeOptions = false,
  variant = REPORTING_GROUP_VARIANTS.MENU_EDIT,
  ...restProps
}: ReportingGroupFilterSwitcherProps) => {
  const { data: reportingGroups, status } = useQuery(
    [Resources.REPORTING_GROUP],
    () => ReportingGroupDomain.getList({}),
    {
      select: (data) => data.data,
      enabled: !options || shouldMergeOptions,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleDisplayValueChange = (value: string) =>
    reportingGroups?.find((group) => group.id === value)?.name ?? value;

  const finalReportingGroupOptions = useMemo(() => {
    const newOptions =
      (shouldMergeOptions
        ? reportingGroups?.filter((group) =>
            options?.find((opt) => opt.id === group.id)
          )
        : reportingGroups?.slice()) ?? [];

    if (!shouldSortByPriority) {
      return newOptions;
    }

    return newOptions.sort((a, b) =>
      a.priority > b.priority ? -1 : a.priority < b.priority ? 1 : 0
    );
  }, [shouldMergeOptions, shouldSortByPriority, options, reportingGroups]);

  // if asked for, set the default value when options are available and trigger a change event with it
  useEffect(() => {
    if (
      !shouldSelectFirstOptionAsDefault ||
      !finalReportingGroupOptions?.length
    ) {
      return;
    }

    const newDefaultValue = finalReportingGroupOptions[0]?.id ?? null;

    if (!newDefaultValue) {
      return;
    }

    restProps.onChange?.(newDefaultValue);
  }, [shouldSelectFirstOptionAsDefault, finalReportingGroupOptions]);

  if (status !== "success") {
    return (
      <div
        className="skeleton"
        style={{ width: 250, height: 35 }}
        aria-busy="true"
        aria-live="polite"
        aria-label={`Reporting Scenario filter is loading...`}
        role="status"
      ></div>
    );
  }

  const getWithMenuEditVariant = () => (
    <FilterSwitcherWithMenuEdit
      {...restProps}
      label="Reporting Scenario"
      {...(shouldSelectFirstOptionAsDefault &&
      finalReportingGroupOptions?.length
        ? { defaultValue: finalReportingGroupOptions[0].id }
        : {})}
      name={name}
      isInline
      getDisplayValue={handleDisplayValueChange}
      options={finalReportingGroupOptions}
      labelKey="name"
      valueKey="id"
    />
  );

  const getWithDialogEditVariant = () => (
    <FilterSwitcherWithDialogEdit
      {...restProps}
      label="Reporting Scenario"
      {...(shouldSelectFirstOptionAsDefault &&
      finalReportingGroupOptions?.length
        ? { defaultValue: finalReportingGroupOptions[0].id }
        : {})}
      name={name}
      isInline
      editFormContent={
        <DropdownInput
          name={name}
          placeholder="Select a reporting scenario"
          options={finalReportingGroupOptions}
          isSortedAlphabetically={false}
          valueKey="id"
          labelKey="name"
          isSearchable
          isRequired
        />
      }
      getDisplayValue={handleDisplayValueChange}
    />
  );

  const render = (
    variant: (typeof REPORTING_GROUP_VARIANTS)[keyof typeof REPORTING_GROUP_VARIANTS]
  ) => {
    switch (variant) {
      case REPORTING_GROUP_VARIANTS.DIALOG_EDIT:
        return getWithDialogEditVariant();
      case REPORTING_GROUP_VARIANTS.MENU_EDIT:
      default:
        return getWithMenuEditVariant();
    }
  };

  return render(variant);
};

// IMPROVE: should be a union with the FilterSwitcherWithDialogEditProps once common-components types can be imported
export type ReportingGroupFilterSwitcherProps = {
  /** override the name of the input. Default is "reportingScenario" */
  name?: string;
  /** should the first reporting group option become the default value for the filter? */
  shouldSelectFirstOptionAsDefault?: boolean;
  /** should sort reporting groups by priority? Default is true */
  shouldSortByPriority?: boolean;
  /** override the reporting group options fetched by this component. Default is all reporting groups. */
  options?: Array<
    Pick<ReportingGroupType, "id" | "name" | "priority"> &
      Partial<ReportingGroupType>
  >;
  /** if override options are provided, should they merge with the full list of reporting groups or override them completely? */
  shouldMergeOptions?: boolean;
  /** allows the ability to render different variations which may include dialog or dropdown */
  variant?: (typeof REPORTING_GROUP_VARIANTS)[keyof typeof REPORTING_GROUP_VARIANTS];
};
