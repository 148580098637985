import CustomAttributeField from "#src/components/Common/CustomAttributeField";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import {
  useGetOneTemplatedConfiguration,
  useGetTemplatedConfigurationInputSchema,
} from "#src/components/hooks/adapters/useTemplatedConfigurations";
import { EQUIPMENT_STATUS_OPTIONS } from "#src/constants";
import useLocalization from "#src/hooks/useLocalization";
import {
  useMemoizedFormState,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import {
  Accordion,
  Form,
  Panel,
  useForm,
} from "@validereinc/common-components";
import { AssetType, EquipmentSchema, Resources } from "@validereinc/domain";
import { TemplatedConfigurations } from "@validereinc/domain-controllers";
import classNames from "classnames/bind";
import React, { useEffect, useMemo, useRef } from "react";
import { useCustomAttributeSectionsDefinition } from "./EquipmentFormPanel.helpers";
import styles from "./EquipmentFormPanel.module.scss";

const cx = classNames.bind(styles);

const EquipmentDetailsFormStep = () => {
  const today = useRef(new Date());
  const { localize } = useLocalization();

  // Form steps logic
  const { currentStep, updateStep, getStep } = useMultiStepFormContext();
  const STEP_NUMBER = 2;
  const isStepActive = currentStep === STEP_NUMBER;
  const prevStep = getStep(1);
  const prevStepValues = prevStep?.getValues();

  // Form logic
  const form = useForm();
  const getFormState = useMemoizedFormState(form);

  const isUsingTemplate = !!prevStepValues?.should_use_template as boolean;

  // Queries:
  const customAttributesQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.EQUIPMENT },
  });

  const customAttributes = customAttributesQuery.data?.data ?? [];

  const templatedConfigurationQuery = useGetOneTemplatedConfiguration(
    {
      id: (prevStepValues?.template_name as string) ?? "",
    },
    {
      enabled: !!prevStepValues?.template_name,
    }
  );

  const templatedConfiguration = templatedConfigurationQuery.data?.data;

  const mainResourceId = useMemo(
    () =>
      TemplatedConfigurations.logic.getResourceIdForResource(
        Resources.EQUIPMENT,
        templatedConfiguration
      ) ?? "",
    [templatedConfiguration?.display_name]
  );

  const isInputSchemaQueryEnabled =
    !!prevStepValues?.template_name && !!mainResourceId;

  const inputSchemaQuery = useGetTemplatedConfigurationInputSchema(
    {
      id: (prevStepValues?.template_name as string) ?? "",
      meta: {
        custom_attribute_configuration: {
          [mainResourceId]: {
            entity_subtype: (prevStepValues?.type_id as string) ?? "",
          },
        },
      },
    },
    {
      enabled: isInputSchemaQueryEnabled,
    }
  );

  const inputSchema = inputSchemaQuery.data?.data;

  const customAttributeSectionsDefinition =
    useCustomAttributeSectionsDefinition({
      templatedConfiguration,
      inputSchema,
      primaryResourceType: Resources.EQUIPMENT,
    });

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues(),
      getFormState,
    });
  }, [getFormState]);

  useEffect(() => {
    form.setValue(
      EquipmentSchema.keyof().Enum.effective_date,
      isUsingTemplate ? undefined : today.current
    );
  }, [isUsingTemplate]);

  useEffect(() => {
    if (!mainResourceId) return;
    form.setValue("main_resource_id", mainResourceId);
  }, [mainResourceId]);

  const isInputPanelLoaded =
    (isUsingTemplate &&
      isInputSchemaQueryEnabled &&
      !inputSchemaQuery.isLoading) ||
    (!isUsingTemplate && !customAttributesQuery.isLoading);

  const staticFieldsToDisplay = [
    {
      display_name: localize("Facility"),
      field_name: EquipmentSchema.keyof().Enum.facility_id,
      data_type: "lookup",
      lookup_entity_type: AssetType.FACILITY,
      is_required: true,
    },
    {
      display_name: `${localize("equipment")} name`,
      field_name: EquipmentSchema.keyof().Enum.name,
      data_type: "string",
      is_required: true,
    },
    {
      display_name: "Status",
      field_name: EquipmentSchema.keyof().Enum.status,
      data_type: "pick-list",
      pick_list_values: EQUIPMENT_STATUS_OPTIONS.map((option) => ({
        id: option.value,
        name: option.label,
      })),
      is_required: true,
    },
    {
      field_name: EquipmentSchema.keyof().Enum.effective_date,
      display_name: "Effective Date",
      is_required: true,
      data_type: "date",
    },
    {
      field_name: "coordinations",
      display_name: "Coordinations",
      is_required: false,
      data_type: "geo_point",
    },
    ...customAttributes
      .filter((custom_attribute) => {
        const should = custom_attribute.entity_subtype
          ? custom_attribute.entity_subtype ===
            (prevStepValues?.type_id as string)
          : true;
        return should;
      })
      .map((custom_attribute) => {
        return {
          ...custom_attribute,
          field_name: `custom_attributes.${custom_attribute.field_name}`,
        } as typeof custom_attribute;
      }),
  ];

  if (!isStepActive) return null;

  return (
    <Panel loaded={isInputPanelLoaded}>
      <Form {...form}>
        <h4 className={cx("section-title")}>Details</h4>
        <div className={cx("basic-info-container")}>
          {isUsingTemplate && (
            <>
              {Object.entries(inputSchema?.inputs ?? {}).map(
                ([attributeId, attributeBody]) => (
                  <CustomAttributeField
                    key={`inputs.${attributeId}`}
                    name={`inputs.${attributeId}`}
                    attribute={attributeBody}
                    subtype={(prevStepValues?.type_id as string) ?? null}
                  />
                )
              )}
              <Accordion>
                {customAttributeSectionsDefinition.map((section) => (
                  <Accordion.AccordionPanel
                    title={section.title}
                    dataKey={section.resourceId}
                    key={section.resourceId}
                  >
                    {Object.keys(section.fields).map((fieldKey) => (
                      <CustomAttributeField
                        key={`custom_attribute_inputs.${section.resourceId}.${fieldKey}`}
                        name={`custom_attribute_inputs.${section.resourceId}.${fieldKey}`}
                        attribute={section.fields[fieldKey]}
                        subtype={(prevStepValues?.type_id as string) ?? null}
                      />
                    ))}
                  </Accordion.AccordionPanel>
                ))}
              </Accordion>
            </>
          )}
          {!isUsingTemplate && (
            <>
              {staticFieldsToDisplay.map((field) => (
                <CustomAttributeField
                  key={field.field_name}
                  name={field.field_name}
                  attribute={field}
                  subtype={(prevStepValues?.type_id as string) ?? null}
                />
              ))}
            </>
          )}
        </div>
      </Form>
    </Panel>
  );
};

export default EquipmentDetailsFormStep;
