import { OrganizationRoute } from "#src/routes/organization";
import { RoutePath } from "#src/utils/route";

/** @deprecated use FacilitiesListRoute instead */
export const FACILITIES_LIST_BREADCRUMB = {
  title: "facility_plural",
  path: () => "/app/organization/facilities",
};

export const FacilitiesListRoute = OrganizationRoute.concat(
  new RoutePath({
    title: "facility_plural",
    path: "/facilities",
  })
);

export * from "./FacilityListPage";
