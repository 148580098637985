import { useFlowProductTypes, useFlowTypes } from "#hooks/useFlowConstants";
import { useParams } from "#routers/hooks";
import {
  FlowDetailParamsType,
  FlowDetailRoute,
} from "#routes/organization/flows/[flowId]/detail";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import useLocalization from "#src/hooks/useLocalization";
import { KeyValuePanel, StorageKeys } from "@validereinc/common-components";
import React from "react";

export const RecordFlowSummaryPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize } = useLocalization();
  const { flowId } = useParams<FlowDetailParamsType>();
  const { data: flow } = useFlowDetailFlow({
    filterConfigStorageKey: filterConfigStorageKey,
  });
  const { flowTypes } = useFlowTypes();
  const { flowProductTypes } = useFlowProductTypes();

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ variant: "shaded" }}
      panelProps={{ title: "Details", isFluidY: false }}
      data={[
        {
          title: `${localize("Flow")}`,
          value: (
            <RoutingLink
              to={FlowDetailRoute.toLink({
                pathParams: { flowId },
              })}
            >
              {flow?.name ?? "-"}
            </RoutingLink>
          ),
        },
        {
          title: "Type",
          value: flowTypes?.find(({ id }) => id === flow?.type)?.name,
        },
        {
          title: "Product Type",
          value: flowProductTypes?.find(({ id }) => id === flow?.product_type)
            ?.name,
        },
      ]}
    />
  );
};
