import { Button, Dialog } from "@validereinc/common-components";
import React from "react";

export const NavigateWithoutSavingFormTemplateDialog = ({
  isOpen,
  onClose,
  handleConfirmNavigateAway,
  isCreating,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmNavigateAway: () => void;
  isCreating: boolean;
}) => {
  const NAVIGATE_WITHOUT_SAVING_FORM_TEMPLATE_DIALOG_TITLE = `Leave Without ${isCreating ? "Creating" : "Saving"}?`;
  const NAVIGATE_WITHOUT_SAVING_FORM_TEMPLATE_DIALOG_DESCRIPTION = `Leaving an in-progress template will delete all of your configurations. This action cannot be undone. If you would like to ${isCreating ? "create the configuration in its current state" : "save the configuration"}, you may ${isCreating ? "Leave Without Creating" : "Leave Without Saving"} and ${isCreating ? "create" : "edit"} the template at a later date.`;

  return (
    <Dialog
      title={NAVIGATE_WITHOUT_SAVING_FORM_TEMPLATE_DIALOG_TITLE}
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="navigate-without-saving-form-template-action"
          variant="error"
          onClick={handleConfirmNavigateAway}
        >
          {isCreating ? "Leave Without Creating" : "Leave Without Saving"}
        </Button>,
      ]}
    >
      {NAVIGATE_WITHOUT_SAVING_FORM_TEMPLATE_DIALOG_DESCRIPTION}
    </Dialog>
  );
};
