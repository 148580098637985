import { useNavigate, useParams } from "#src/Routers/hooks";
import { FormSchemasTableFilterArea } from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { useQuery } from "@tanstack/react-query";
import {
  EmptyState,
  Flex,
  GridCard,
  Pagination,
  Panel,
  StorageKeys,
} from "@validereinc/common-components";
import { FormSchemaAdapter } from "@validereinc/domain";
import React from "react";

const { Container, Item } = Flex;

export const FormTemplatesPanel = ({
  withFormCategory,
  filterConfigStorageKey,
}: { withFormCategory?: boolean } & Pick<
  StorageKeys,
  "filterConfigStorageKey"
>) => {
  const navigate = useNavigate();
  const { categoryId } = useParams<{ categoryId: string }>();
  const [filters] = useSessionStickyState<{
    name: string;
  }>({}, filterConfigStorageKey);

  const [
    { header: _header, direction: _direction, ...pagination },
    setPagination,
  ] = useTableSortingAndPagination(
    {
      itemsPerPage: 30,
      isTotalKnown: false,
      entityPerPage: "Cards per page:",
    },
    filters
  );

  const { name, ...restFilters } = filters ?? {};
  const queryPayload: Parameters<typeof FormSchemaAdapter.getList>[0] = {
    page: pagination.page,
    pageSize: pagination.itemsPerPage,
    filters: {
      status: "active",
      "form_category.id": categoryId,
      ...restFilters,
      ...(name ? { name: { $like: name } } : {}),
    },
  };

  const query = useQuery({
    queryKey: ["formSchemas", queryPayload],
    queryFn: async () => {
      if (!categoryId) {
        return;
      }

      const { data: newFormCategories, total_entries } =
        await FormSchemaAdapter.getList(queryPayload);

      setPagination({ total: total_entries, isTotalKnown: true });

      return newFormCategories;
    },
    enabled: Boolean(categoryId),
  });

  const onCardClick = (formCategoryId: string, formSchemaId: string) => {
    navigate({
      pathname: linkToFormTemplateDetail(formCategoryId, formSchemaId),
    });
  };

  return (
    <Panel
      title="Templates"
      loaded={!query.isLoading}
      actionRow={[
        <FormSchemasTableFilterArea
          key="filters"
          filterConfigStorageKey={filterConfigStorageKey}
          filterDrawerContentSlot={null}
        />,
      ]}
    >
      {query?.data?.length === 0 ? (
        <EmptyState
          title="There are no form templates to display"
          suggestion="Please try adjusting your filters"
        />
      ) : (
        <>
          <Container variant="grid">
            {query.data?.map(
              ({
                id,
                name,
                updated_at,
                description,
                form_category_id,
                form_category: { name: formCategoryName },
              }) => (
                <Item key={`form-template-${id}`}>
                  <GridCard
                    title={name}
                    createdAt={new Date(updated_at)}
                    onClick={() => onCardClick(form_category_id, id)}
                    isLoading={query?.isLoading}
                    description={description}
                    pills={
                      withFormCategory
                        ? [{ variant: "default", children: formCategoryName }]
                        : undefined
                    }
                  />
                </Item>
              )
            )}
          </Container>

          <Pagination
            {...pagination}
            onChange={setPagination}
          />
        </>
      )}
    </Panel>
  );
};
