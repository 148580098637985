import type { FilterAreaRootProps } from "#src/components/FilterArea/FilterArea";
import { ExceptionUtils } from "#src/utils/exception";
import { useFormContext, useHookWatch } from "@validereinc/common-components";
import {
  BaseError,
  type NodeAPICoreSavedFilterType,
  type NodeAPISavedFilterType,
} from "@validereinc/domain";
import {
  datetimeFormatter,
  isDateRangeValue,
  toExpandedObject,
  toFlattenedObject,
} from "@validereinc/utilities";
import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import isNil from "lodash/isNil";
import isPlainObject from "lodash/isPlainObject";

export const FilterComparisonOperators = {
  EQUAL: "eq",
  LIKE: "like",
  GREATER_THAN: "gt",
  LESS_THAN: "lt",
  GREATER_THAN_OR_EQUAL: "gte",
  LESS_THAN_OR_EQUAL: "lte",
  SOME: "some",
  ALL: "all",
} as const;

export type FilterComparisonOperatorType =
  (typeof FilterComparisonOperators)[keyof typeof FilterComparisonOperators];

export type FilterComparisonOperatorConfig = Record<
  string,
  FilterComparisonOperatorType
>;

/**
 * Get the filter configuration given a filter value. Complement to {@link getFilterConfigFromFilterValue}
 * @param value the filter configuration value
 * @returns the raw filter value
 */
export const getFilterValueFromFilterConfig = (
  value: string | NodeAPICoreSavedFilterType
) => {
  if (typeof value === "string") {
    return value;
  }

  if (value?.$gt) {
    return value.$gt;
  }

  if (value?.$lt) {
    return value.$lt;
  }

  if (value?.$gte) {
    return value.$gte;
  }

  if (value?.$lte) {
    return value.$lte;
  }

  if (value?.$like) {
    return value.$like;
  }

  if (value?.$in) {
    return value?.$in;
  }

  return value;
};

/**
 * Get the filter configuration given a raw filter value. Complement to {@link getFilterValueFromFilterConfig}
 * @param value raw filter value
 * @param opts additional options
 * @returns the filter configuration value
 */
export const getFilterConfigFromFilterValue = (
  value: any,
  opts: {
    /** the comparison operator to use */
    comparisonOperator?: FilterComparisonOperatorType;
  } = {}
) => {
  if (opts.comparisonOperator) {
    switch (opts.comparisonOperator) {
      case "eq": {
        if (typeof value !== "string") break;

        return value;
      }
      case "like": {
        if (typeof value !== "string") break;

        return { $like: value };
      }
      case "gt": {
        if (typeof value !== "number" || typeof value !== "string") break;

        return { $gt: value };
      }
      case "lt": {
        if (typeof value !== "number" || typeof value !== "string") break;

        return { $lt: value };
      }
      case "lte": {
        if (typeof value !== "number" || typeof value !== "string") break;

        return { $lte: value };
      }
      case "gte": {
        if (typeof value !== "number" || typeof value !== "string") break;

        return { $gte: value };
      }
      case "some": {
        if (!Array.isArray(value)) break;

        return { $in: value };
      }
      case "all": {
        if (!Array.isArray(value)) break;

        return value;
      }
      default:
        break;
    }
  }

  if (Array.isArray(value)) {
    return { $in: value };
  }

  if (typeof value === "string" && !isValid(new Date(value))) {
    return { $like: value };
  }

  return value;
};

/**
 * Get the display value for a given filter value
 * @param v value of the filter
 * @param opts additional options
 * @returns a string representing the comparison applied on the filter value
 */
export const getFilterDisplayValue = (
  v: any,
  opts: {
    /** operator used against the value */
    operator?: FilterComparisonOperatorType;
  } = {
    operator: FilterComparisonOperators.EQUAL,
  }
): string => {
  if (isPlainObject(v)) {
    return Object.entries(v).reduce((explanation, [k, v]) => {
      if (
        ![...Object.values(FilterComparisonOperators), "in"]
          .map((op) => `$${op}`)
          .includes(k)
      ) {
        return explanation;
      }

      const actualOperator = k === "$in" ? FilterComparisonOperators.SOME : k;
      const displayValue = getFilterDisplayValue(v, {
        operator: actualOperator.replace(
          "$",
          ""
        ) as FilterComparisonOperatorType,
      });

      if (!displayValue) return explanation;
      if (!explanation) return displayValue;

      return `${explanation}, ${displayValue}`;
    }, "");
  }

  if (Array.isArray(v)) {
    if (opts?.operator === "some") {
      return `SOME OF [${v.join(", ")}]`;
    }

    return `ALL OF [${v.join(", ")}]`;
  }

  if (isNil(v)) {
    return "not set";
  }

  if (
    typeof v !== "string" &&
    typeof v !== "number" &&
    typeof v !== "boolean" &&
    !(v instanceof Date)
  ) {
    return "(invalid value)";
  }

  if (typeof v === "string" && opts?.operator === "like") {
    return `PATTERN MATCH "${v}"`;
  }

  if (typeof v === "string" && opts?.operator === "eq") {
    return `EXACTLY "${v}"`;
  }

  if (typeof v === "boolean") {
    return v ? "Yes" : "No";
  }

  if (typeof v === "number" && opts?.operator === "gt") {
    return `GREATER THAN "${v}"`;
  }

  if (typeof v === "number" && opts?.operator === "lt") {
    return `LESS THAN "${v}"`;
  }

  if (typeof v === "number" && opts?.operator === "gte") {
    return `GREATER THAN OR EQUAL TO "${v}"`;
  }

  if (typeof v === "number" && opts?.operator === "lte") {
    return `LESS THAN OR EQUAL TO "${v}"`;
  }

  if (typeof v === "number" && opts?.operator === "eq") {
    return `EXACTLY "${v}"`;
  }

  if (isValid(v) || v instanceof Date) {
    if (v instanceof Date) {
      return datetimeFormatter(v);
    } else if (typeof v === "string") {
      // string assumed to be ISO format
      const parsedDate = parseISO(v);

      return datetimeFormatter(parsedDate);
    } else {
      // unknown source value
      return "(unknown date format)";
    }
  }

  // IMPROVE: we should deal with numbers better

  return String(v);
};

/**
 * Convert saved filters back to stored filters format.
 * @param filters saved filter keys and values
 * @param isFormFieldRegistered predicate to check if a filter key from the
 * provided saved filters has an associated filter input
 * @returns stored filter keys and values that are actually applicable to the
 * rendered filter area
 */
export const convertSavedFiltersToStoredFilters = <
  TFilters extends Record<string, any> = Record<string, any>,
>(
  filters: NodeAPISavedFilterType,
  isFormFieldRegistered: (name: string) => boolean = (_) => true
) => {
  if (!filters || !isPlainObject(filters)) return {};

  return Object.entries(filters).reduce<TFilters>((dict, [key, value]) => {
    if (Array.isArray(value) && isPlainObject(value[0])) {
      // IMPROVE: current limitation takes only the first filter config
      const [[firstKey, firstValue]] = Object.entries(value[0]);

      if (value.length > 1) {
        console.warn(
          `FilterArea.helpers|convertSavedFiltersToStoredFilters: More than one filter configuration exists in ${key} block.`
        );
        ExceptionUtils.reportException(
          new BaseError(
            `More than one filter configuration exists in ${key} block.`
          ),
          "warning",
          {
            hint: "FilterArea.helpers > convertSavedFiltersToStoredFilters",
          }
        );
      }

      if (Object.keys(value[0]).length > 1) {
        console.warn(
          `FilterArea.helpers|convertSavedFiltersToStoredFilters: More than one filter configuration exists in first entry in ${key} block.`
        );
        ExceptionUtils.reportException(
          new BaseError(
            `More than one filter configuration exists in first entry in ${key} block.`
          ),
          "warning",
          {
            hint: "FilterArea.helpers > convertSavedFiltersToStoredFilters",
          }
        );
      }

      const actualValue = getFilterValueFromFilterConfig(
        firstValue as string | NodeAPICoreSavedFilterType
      );

      if (actualValue && isFormFieldRegistered(firstKey)) {
        dict[firstKey] = actualValue;
      }
    } else {
      // otherwise, extract the filter value. filter value could be an object or a primitive value.
      const actualValue = getFilterValueFromFilterConfig(value);

      if (actualValue && isFormFieldRegistered(key)) {
        dict[key] = actualValue;
      }
    }

    return dict;
  }, {} as TFilters);
};

/**
 * Convert stored filters to a format suitable to be saved (saved filters are
 * flattened)
 * @param filters stored filter names and values
 * @param operatorConfig map of specific operators to use for specific filter
 * keys. if not specified for a filter key, reasonable default is used.
 * @returns stored filter values ready to be saved
 */
export const convertStoredFiltersToSavedFilters = <
  TFilters extends Record<string, any> = Record<string, any>,
>(
  filters: TFilters,
  operatorConfig?: FilterComparisonOperatorConfig
): NodeAPISavedFilterType => {
  if (!filters || !isPlainObject(filters)) return {};

  return Object.fromEntries(
    Object.entries(toFlattenedObject(filters))
      .filter(([_, value]) => {
        if (Array.isArray(value)) return value.length > 0;
        if (isPlainObject(value))
          return (
            Object.hasOwn(value as object, "from") &&
            Object.hasOwn(value as object, "to")
          );
        if (typeof value === "string") return value !== "";

        return !isNil(value);
      })
      .map(([key, value]) => {
        if (operatorConfig?.[key]) {
          return [
            key,
            getFilterConfigFromFilterValue(value, {
              comparisonOperator: operatorConfig[key],
            }),
          ];
        }

        return [key, getFilterConfigFromFilterValue(value)];
      })
  );
};

/**
 * Reduce stored filters down to the filters that are actually applied. (i.e.
 * not reset or empty as represented by empty string "", null, or undefined)
 * @param filters stored filter names and their values
 * @returns applied stored filter names and their values
 */
export const reduceStoredFiltersToAppliedFilters = <
  TFilters extends Record<string, any> = Record<string, any>,
>(
  filters: TFilters
): NodeAPISavedFilterType => {
  if (!filters || !isPlainObject(filters)) return {};

  return Object.entries(filters).reduce<TFilters>((newObj, [key, value]) => {
    if (isNil(value)) return newObj;
    if (typeof value === "string" && value === "") return newObj;
    if (Array.isArray(value) && value.length === 0) return newObj;
    if (isPlainObject(value)) {
      if (isDateRangeValue(value)) {
        newObj[key] = value;
        return newObj;
      } else {
        const objValue = reduceStoredFiltersToAppliedFilters(value);

        if (!Object.keys(objValue).length) return newObj;

        newObj[key] = objValue;
        return newObj;
      }
    }

    newObj[key] = value;
    return newObj;
  }, {} as TFilters);
};

/**
 * Count the number of actually applied filters.
 * @param filters applied filter names and their values @see
 * {@link reduceStoredFiltersToAppliedFilters}
 * @returns a number that represents the # of applied filters
 */
export const countAppliedFilters = <
  TFilters extends Record<string, any> = Record<string, any>,
>(
  filters: TFilters
): number => {
  if (!filters || !isPlainObject(filters)) return 0;

  return Object.entries(filters).reduce<number>((total, [_, value]) => {
    if (isNil(value)) return total;
    if (typeof value === "string" && value === "") return total;
    if (Array.isArray(value) && value.length === 0) return total;
    if (isPlainObject(value)) {
      if (
        Object.keys(value).length === 2 &&
        Object.hasOwn(value as object, "from") &&
        Object.hasOwn(value as object, "to")
      ) {
        total += 1;
        return total;
      } else {
        const objTotal = countAppliedFilters(value);

        total += objTotal;
        return total;
      }
    }

    total += 1;
    return total;
  }, 0);
};

/**
 * Convert filter values to a format ready to be stored.
 * @param filters filter names and their values (the same object as returned by
 * react hook form's getValues())
 * @returns filter values, ready to be stored
 */
export const convertFilterValuesToStoredFilters = <
  TFilters extends Record<string, any>,
>(
  filters: TFilters
) => {
  if (!filters || !isPlainObject(filters)) return {};

  return Object.entries(filters).reduce((dict, [key, value]) => {
    if (isPlainObject(value)) {
      dict[key] = convertFilterValuesToStoredFilters(value);
      return dict;
    }

    if (
      (Array.isArray(value) && !value.length) ||
      isNil(value) ||
      value === ""
    ) {
      // the key:value pair is kept intentionally, so its fully representative
      // of the new state of stored filters
      dict[key] = "";
      return dict;
    }

    dict[key] = value;
    return dict;
  }, {} as TFilters);
};

/**
 * Reset all filter values. To do this accurately, every field must be set to an
 * empty string "" as per the Web Standard to reset the field.
 * @param filters filter names and their values (the same object as returned by react
 * hook form's getValues())
 * @returns filter values with their values reset
 */
export const resetFilterValues = <TFilters extends Record<string, any>>(
  filters: TFilters
) => {
  if (!filters || !isPlainObject(filters)) return;

  return Object.entries(filters).reduce((dict, [key, value]) => {
    if (isPlainObject(value)) {
      dict[key] = resetFilterValues(value);
      return dict;
    }

    dict[key] = "";
    return dict;
  }, {} as TFilters);
};

/**
 * Take provided default values, which can have flat keys (e.g.
 * "equipment.status": "active") and expand them to the full structural
 * representation so it's compatible with the way filters are stored and form
 * default values and values are set and read.
 * @param defaultValues default values as provided to the <FilterAreaRoot />
 * component. Can be an async function that returns a plain object or a plain
 * object.
 * @returns expanded default values
 */
export const expandDefaultValues = async <TFilters extends Record<string, any>>(
  defaultValues: FilterAreaRootProps<TFilters>["defaultValues"]
): Promise<TFilters> => {
  if (!isPlainObject(defaultValues)) {
    if (typeof defaultValues === "function") {
      const actualDefaultValues = await defaultValues();

      return toExpandedObject(actualDefaultValues) as TFilters;
    } else {
      return {} as TFilters;
    }
  }

  return toExpandedObject(defaultValues as TFilters) as TFilters;
};

/**
 * Get default values so they're loadable in the form and store-able later.
 * Default values are also merged with stored filters if stored filters exist.
 * Stored filters with the same keys will completely override default value
 * unless prioritizeStoredFilters is set to false.
 * @returns the resolved and complete default values to set in the form
 */
export const getDefaultValuesAndStoredFilters = async <
  TFilters extends Record<string, any>,
>({
  defaultValues,
  storedFilters,
  prioritizeStoredFilters = true,
}: {
  /** default values as provided to the <FilterAreaRoot /> component */
  defaultValues: FilterAreaRootProps<TFilters>["defaultValues"];
  /** stored filters are applied filters */
  storedFilters: TFilters;
  /** should the stored filters override the default values or the other way round? */
  prioritizeStoredFilters?: boolean;
}) => {
  if (isPlainObject(storedFilters) && Object.keys(storedFilters).length) {
    const expandedDefaultValues = await expandDefaultValues(defaultValues);

    return prioritizeStoredFilters
      ? { ...(expandedDefaultValues ?? {}), ...storedFilters }
      : { ...storedFilters, ...(expandedDefaultValues ?? {}) };
  }

  return expandDefaultValues(defaultValues);
};

/**
 * React hook form's useWatch() but always overriding watched values with most
 * current form values to account for race conditions on setting or registering
 * inputs.
 * @param watchParams the parameters you would set on useWatch()
 * @returns all the form values, updating as values change
 */
export const useWatchFormValues = (
  ...watchParams: Parameters<typeof useHookWatch>
) => {
  const { getValues } = useFormContext();

  return {
    ...useHookWatch(...watchParams), // subscribe to form value updates
    ...getValues(), // always merge with latest form values
  };
};
