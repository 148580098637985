import { usePrevious } from "@validereinc/common-components";
import { useEffect, useState } from "react";

/**
 * Get a value from local or session storage
 * @param defaultValue the first value to store. similar concept to useState.
 * @param key the key to store the value under in storage
 * @returns the value at the key in the storage location, run through the parser
 */
export const getFromStorage = <T>(
  defaultValue: T,
  key: string,
  {
    storageSystem = window.localStorage,
    parser = (value) => JSON.parse(value) as T,
  }: {
    /** the browser storage system to use e.g. window.localStorage or window.sessionStorage. default is local storage. */
    storageSystem?: Storage;
    /** an override to the parsing that needs to happen when the value is ready from storage. default is JSON.parse(). */
    parser?: (valueFromLocalStorage: string) => T;
  }
) => {
  const stickyValue = storageSystem.getItem(key);

  try {
    return stickyValue !== null ? parser(stickyValue) : defaultValue;
  } catch (err: unknown) {
    console.error(err);

    if (
      (err as DOMException)?.name === "InvalidCharacterError" ||
      (err as SyntaxError)?.name === "SyntaxError"
    ) {
      console.error("Sticky state was not JSON parse-able", {
        stickyValue,
        key,
      });
    }

    // return the default value, even if JSON parsing fails
    return defaultValue;
  }
};

/**
 * State that syncs with local storage.
 * Credits to Josh Comeau for the snippet.
 * @param defaultValue the first value to store. similar concept to useState.
 * @param key the key to store the value under in local storage.
 * @param parse optionally provide an override to the parsing that needs to happen when the value from storage is read. default is JSON.parse().
 * @returns a tuple: the value and the value setter. similar in concept to base useState.
 */
export function useStickyState<T>(
  defaultValue: T,
  key: string,
  parser: (valueFromLocalStorage: string) => T = (value) =>
    JSON.parse(value) as T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const oldKey = usePrevious(key);
  const boundGetFromStorage = () =>
    getFromStorage(defaultValue, key, {
      storageSystem: window.localStorage,
      parser,
    });
  const [value, setValue] = useState<T>(boundGetFromStorage());

  useEffect(
    function whenTheValueChangesUpdateTheStateAndNotify() {
      if (oldKey !== key) return;
      const oldValue = window.localStorage.getItem(key);

      // don't sync with storage if the value is already there
      if (oldValue === JSON.stringify(value)) {
        return;
      }

      window.localStorage.setItem(key, JSON.stringify(value));
      window.dispatchEvent(
        new StorageEvent("storage", {
          key,
          newValue: JSON.stringify(value),
          oldValue,
          storageArea: window.localStorage,
        })
      );
    },
    [key, oldKey, value]
  );

  useEffect(
    function subscribeToStateUpdateNotificationsAndUpdateTheState() {
      const handleStorageEvent = (ev: StorageEvent) => {
        if (
          ev.key !== key ||
          ev.oldValue === ev.newValue ||
          ev.newValue === JSON.stringify(value) ||
          ev.storageArea !== window.localStorage
        ) {
          return;
        }

        // re-sync value in storage with the value in state
        setValue(boundGetFromStorage());
      };

      window.addEventListener("storage", handleStorageEvent);

      return () => window.removeEventListener("storage", handleStorageEvent);
    },
    [key, value, boundGetFromStorage]
  );

  useEffect(
    function whenKeyChangesUpdateTheState() {
      // if they new key is the same as the old key
      if (oldKey === key) return;

      const oldValue = window.sessionStorage.getItem(key);

      // don't sync with storage if the value is already there
      if (oldValue === JSON.stringify(value)) {
        return;
      }

      setValue(boundGetFromStorage());
    },
    [oldKey, key, value, boundGetFromStorage]
  );

  return [value, setValue];
}

/**
 * State that syncs with session storage.
 * @param defaultValue the first value to store. similar concept to useState.
 * @param key the key to store the value under in session storage.
 * @param parse optionally provide an override to the parsing that needs to happen when the value from storage is read. default is JSON.parse().
 * @returns a tuple: the value and the value setter. similar in concept to base useState.
 */
export function useSessionStickyState<T>(
  defaultValue: T,
  key: string,
  parser: (valueFromSessionStorage: string) => T = (value) =>
    JSON.parse(value) as T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const oldKey = usePrevious(key);
  const boundGetFromStorage = () =>
    getFromStorage(defaultValue, key, {
      storageSystem: window.sessionStorage,
      parser,
    });
  const [value, setValue] = useState<T>(boundGetFromStorage());

  useEffect(
    function whenTheValueChangesUpdateTheStateAndNotify() {
      if (oldKey !== key) return;
      const oldValue = window.sessionStorage.getItem(key);

      // don't sync with storage if the value is already there
      if (oldValue === JSON.stringify(value)) {
        return;
      }

      window.sessionStorage.setItem(key, JSON.stringify(value));
      window.dispatchEvent(
        new StorageEvent("storage", {
          key,
          newValue: JSON.stringify(value),
          oldValue,
          storageArea: window.sessionStorage,
        })
      );
    },
    [value, key, oldKey]
  );

  useEffect(
    function subscribeToStateUpdateNotificationsAndUpdateTheState() {
      const handleStorageEvent = (ev: StorageEvent) => {
        if (
          ev.key !== key ||
          ev.oldValue === ev.newValue ||
          ev.newValue === JSON.stringify(value) ||
          ev.storageArea !== window.sessionStorage
        ) {
          return;
        }

        // re-sync value in storage with the value in state
        setValue(boundGetFromStorage());
      };

      window.addEventListener("storage", handleStorageEvent);

      return () => window.removeEventListener("storage", handleStorageEvent);
    },
    [key, value, boundGetFromStorage]
  );

  useEffect(
    function whenKeyChangesUpdateTheState() {
      // if they new key is the same as the old key
      if (oldKey === key) return;

      const oldValue = window.sessionStorage.getItem(key);

      // don't sync with storage if the value is already there
      if (oldValue === JSON.stringify(value)) {
        return;
      }

      setValue(boundGetFromStorage());
    },
    [oldKey, key, value, boundGetFromStorage]
  );

  return [value, setValue];
}
