import {
  FilterArea,
  type FilterAreaContentProps,
  type FilterAreaRootProps,
} from "#src/components/FilterArea";
import {
  FilterDrawer,
  type FilterDrawerContentProps,
  type FilterDrawerSavedFiltersContentProps,
  type FilterDrawerTriggerProps,
} from "#src/components/FilterDrawer";
import classNames from "classnames/bind";
import React from "react";
import styles from "./FilterPanel.module.css";

const cx = classNames.bind(styles);

export const FilterPanel = <
  TViewFilters extends Record<string, any>,
  TAppliedFilters extends Record<string, any>,
>({
  viewFilters,
  appliedFilters,
  ariaLabel,
}: {
  ariaLabel: string;
  viewFilters?: Pick<
    FilterAreaRootProps<TViewFilters>,
    "storageKey" | "defaultValues"
  > &
    Pick<FilterAreaContentProps, "children"> & {
      label: string;
    };
  appliedFilters?: Pick<
    FilterAreaRootProps<TAppliedFilters>,
    "storageKey" | "defaultValues"
  > &
    Pick<FilterDrawerContentProps, "children"> &
    Pick<FilterDrawerTriggerProps<TAppliedFilters>, "isLoading"> & {
      savedFilters: Pick<
        FilterDrawerSavedFiltersContentProps<TAppliedFilters>,
        "config"
      >;
      label: string;
    };
}) => {
  return (
    <div
      className={cx("container")}
      role="region"
      aria-label={ariaLabel}
    >
      {viewFilters ? (
        <FilterArea.Root
          storageKey={viewFilters.storageKey}
          defaultValues={viewFilters.defaultValues}
        >
          <FilterArea.Container
            aria-label={viewFilters.label}
            className={cx("view-filters-container")}
            formProps={{
              className: cx("view-filters-form"),
            }}
          >
            <FilterArea.Content>{viewFilters.children}</FilterArea.Content>
          </FilterArea.Container>
        </FilterArea.Root>
      ) : null}
      {appliedFilters ? (
        <FilterArea.Root
          storageKey={appliedFilters.storageKey}
          defaultValues={appliedFilters.defaultValues}
        >
          <FilterArea.Container aria-label={appliedFilters.label}>
            <FilterDrawer.Root>
              <FilterDrawer.Trigger
                label="Filters"
                isLoading={appliedFilters.isLoading}
              />
              <FilterDrawer.Content>
                <FilterDrawer.SavedFilters
                  config={appliedFilters.savedFilters.config}
                />
                <FilterDrawer.SavedFiltersAppliedIndicator />
                {appliedFilters.children}
              </FilterDrawer.Content>
            </FilterDrawer.Root>
          </FilterArea.Container>
        </FilterArea.Root>
      ) : null}
    </div>
  );
};
