import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Button, Page, Panel } from "@validereinc/common-components";
import { downloadCSV, localStorageToJSON } from "@validereinc/utilities";
import filter from "lodash/filter";
import intersection from "lodash/intersection";
import sortBy from "lodash/sortBy";
import union from "lodash/union";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import Papa from "papaparse";
import * as PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Checkbox, OverlayTrigger, Popover } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table/react-table.css";
import history from "../../Routers/history";
import {
  GetQueryParam,
  SetHistoryQueryParam,
} from "../../Routers/historyHelper";
import { linkToAnalyze, linkToLogin } from "../../Routers/links";
import { SAMPLE_TYPES } from "../../utils/enums";
import { getAnalyzeSpec } from "../Analyze/AnalyzeHelper";
import DateRangeSelector from "../Common/DateSelector/DateRangeSelector";
import { addToAnalyze } from "../Redux/actions/analyze";
import {
  defaultMeasurementNames,
  getMeasurementType,
  measurementNames,
} from "../Redux/reducers/measurements";
import { validereApi } from "../Services/ServiceHelper";
import TestDetailModal from "../Tests/TestDetailModal/TestDetailModal";
import FilterColumn from "./FilterColumn";
import "./LabResults.css";
import {
  getCSVExportHeaders,
  getMeasurementValueFromProps,
} from "./LabResultsHelper";
import LabResultsTest from "./LabResultsTest";

const _ = { uniq, intersection };

const HEIGHT_OFFSET = 80;

const MIN_HEIGHT = 650;

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const TODAY = moment();

const INITIAL_DATE_RANGE_IN_DAYS = 30;

const ANALYZE_DATE_RANGE_BUFFER_IN_DAYS = 15;

const getDownloadPopover = (files) => (
  <Popover id="labResult__popover">
    <div className="labResult__popover">
      {files.map((file, index) => (
        <a
          key={index}
          className="popover__link"
          href={file.url}
        >
          {file.file_name}
        </a>
      ))}
    </div>
  </Popover>
);

const mapDispatchToProps = {
  addToAnalyze,
};

function getSites(samples) {
  const sites = samples.map((sample) => ({
    value: sample.site.id,
    label: sample.site.name,
  }));

  return uniqBy(sites, "value");
}

function getStreams(samples) {
  const streams = samples.map((sample) => ({
    value: sample.stream.id,
    label: sample.stream.name,
  }));

  return uniqBy(streams, "value");
}

function getProductTypes(samples) {
  const types = samples.map((sample) => ({
    value: sample.stream.product_type,
    label: sample.stream.product_type,
  }));

  return uniqBy(types, "value");
}

function getSamplePointIds(samples) {
  const sample_point_ids = samples.map((sample) => ({
    value: sample.sample_point_id,
    label: sample.sample_point_id,
  }));

  return uniqBy(sample_point_ids, "value");
}

function getSourceFacilityCodes(samples) {
  const facilityCodes = samples.map((sample) => ({
    value: sample.stream.source_facility.facility_code,
    label: sample.stream.source_facility.facility_code,
  }));

  return uniqBy(facilityCodes, "value");
}

function getSourceLocationIndices(samples) {
  const locationIndices = samples.map((sample) => ({
    value: sample.stream.source_facility.location_index,
    label: sample.stream.source_facility.location_index,
  }));

  return uniqBy(locationIndices, "value");
}

function getMeasurementsWithValues(samples) {
  const uniqueMeasurements = new Set();

  samples.forEach((sample) => {
    sample.tests.forEach((test) => {
      Object.keys(test.measurements).forEach((measurement) => {
        uniqueMeasurements.add(measurement);
      });
    });
  });

  return Array.from(uniqueMeasurements);
}

function multiSelectFilter(filter, rows) {
  if (!filter?.value || filter.value.length === 0) {
    return rows;
  }

  const labels = filter.value.map((value) => value.label);

  return rows.filter((row) => {
    const rowValue = row[filter.id];

    if (Array.isArray(rowValue)) {
      return rowValue.some((value) => labels.includes(value));
    } else {
      return labels.includes(rowValue);
    }
  });
}

function streamFilter(filter, rows) {
  if (!filter?.value || filter.value.length === 0) {
    return rows;
  }

  const labels = filter.value.map((value) => value.label);
  return rows.filter((row) => {
    const rowContent = row[filter.id];

    if (React.isValidElement(rowContent)) {
      return labels.includes(rowContent.props.children[0]);
    } else {
      return labels.includes(rowContent);
    }
  });
}

function filterOffSpecSamples(samples) {
  return samples.filter((sample) => sample.has_offspec_tests === true);
}

const mapStateToProps = (state) => {
  return {
    supportedMeasurementTypes: measurementNames(state.measurements),
    measurementType: getMeasurementType(state.measurements),
    defaultMeasurementTypes: defaultMeasurementNames(state.measurements),
  };
};

class LabResults extends PureComponent {
  static getDerivedStateFromProps() {
    let startDate = moment(TODAY)
      .utc()
      .subtract(INITIAL_DATE_RANGE_IN_DAYS, "days");
    let endDate = moment(TODAY).utc();

    startDate = GetQueryParam("startDate", startDate);
    endDate = GetQueryParam("endDate", endDate);

    return {
      startDate: moment(startDate),
      endDate: moment(endDate),
    };
  }

  constructor(props) {
    super(props);

    const sampleTypes = Object.values(SAMPLE_TYPES).map((value) => ({
      value: value,
      label: value,
    }));

    this.state = {
      loading: true,
      samples: [],
      product_types: [],
      sample_types: sampleTypes,
      sample_point_ids: [],
      source_facility_codes: [],
      source_location_indices: [],
      sites: [],
      streams: [],
      selectedColumns: [],
      redirect: false,
      startDate: null,
      endDate: null,
      filterOffSpec: false,
      measurementColumnOptions: [],
      selectedTestId: null,
      selectedSampleDetail: null,
      selectedMeasurementType: null,
    };

    this.scrollTableToTop = this.scrollTableToTop.bind(this);
    this.redirectToAnalyze = this.redirectToAnalyze.bind(this);
    this.onTestClick = this.onTestClick.bind(this);
    this.onHideTestDetailModal = this.onHideTestDetailModal.bind(this);
    this.state.selectedColumns = this.getSelectedColumns();

    this.infoColumnOptions = [
      {
        id: "sample_point_id",
        Header: "Sample Point ID",
        accessor: "sample_point_id",
        width: 200,
        filterAll: true,
        filterMethod: multiSelectFilter,
        Filter: ({ filter, onChange }) => (
          <FilterColumn
            filter={filter}
            onChange={onChange}
            options={this.state.samplePointIds}
          />
        ),
        Cell: (row) => {
          return row.original?.sample_point_id;
        },
      },
      {
        id: "source_facility_code",
        Header: "Source Facility Code",
        accessor: "stream.source_facility.facility_code",
        width: 200,
        filterAll: true,
        filterMethod: multiSelectFilter,
        Filter: ({ filter, onChange }) => (
          <FilterColumn
            filter={filter}
            onChange={onChange}
            options={this.state.sourceFacilityCodes}
          />
        ),
      },
      {
        id: "source_location",
        Header: "Source Location",
        accessor: "stream.source_facility.location_index",
        width: 200,
        filterAll: true,
        filterMethod: multiSelectFilter,
        Filter: ({ filter, onChange }) => (
          <FilterColumn
            filter={filter}
            onChange={onChange}
            options={this.state.sourceLocationIndices}
          />
        ),
      },
      {
        id: "ticket_id",
        Header: "Ticket ID",
        accessor: "metadata.ticket_id",
        width: 200,
        filterable: true,
      },
      {
        id: "transport_company",
        Header: "Transport Company",
        accessor: "metadata.transport_company",
        width: 200,
        filterable: true,
      },
      {
        id: "container_id",
        Header: "Container ID",
        accessor: "metadata.container_id",
        width: 200,
        filterable: true,
      },
      {
        id: "batch_id",
        Header: "Batch ID",
        accessor: "metadata.batch_id",
        width: 200,
        filterable: true,
      },
      {
        id: "manual_entry",
        Header: "Manual Entry",
        accessor: "is_manual_entry",
        filterable: false,
        width: 170,
        Cell: (row) => {
          return row.original?.is_manual_entry ? "Yes" : "No";
        },
      },
    ].map((column) => {
      // Convert to select options
      return { value: column.id, label: column.Header, column: column };
    });

    this.fetchData = this.fetchData.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.setFilterOffSpec = this.setFilterOffSpec.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !moment(prevState.startDate).isSame(this.state.startDate) ||
      !moment(prevState.endDate).isSame(this.state.endDate)
    ) {
      this.fetchData();
    }

    if (
      prevProps.defaultMeasurementTypes !== this.props.defaultMeasurementTypes
    ) {
      this.setState({ selectedColumns: this.getSelectedColumns() });
    }
  }

  getLabResults(startDate, endDate) {
    const parameter = {
      start_date: moment(startDate).toISOString(),
      end_date: moment(endDate).toISOString(),
    };

    return validereApi.get("/api/lab_results", {
      params: parameter,
      timeout: 60000,
      headers: {
        Accept: `application/vnd.validere.v2019-03-21+json; text/csv;, charset=utf-8`,
      },
    });
  }

  fetchData() {
    this.setState({
      loading: true,
    });

    const { startDate, endDate } = this.state;

    this.getLabResults(startDate, endDate).then((response) => {
      const sites = getSites(response.data);
      const streams = getStreams(response.data);
      const product_types = getProductTypes(response.data);
      const samplePointIds = getSamplePointIds(response.data);
      const sourceFacilityCodes = getSourceFacilityCodes(response.data);
      const sourceLocationIndices = getSourceLocationIndices(response.data);
      const measurementsWithValues = getMeasurementsWithValues(response.data);

      // Measurement column options are those selected from before and any
      // measurement that has a value
      const measurementColumns = union(
        measurementsWithValues,
        this.state.selectedColumns.map((selectedColumn) => selectedColumn.value)
      );

      const measurementColumnOptions = intersection(
        this.props.supportedMeasurementTypes,
        measurementColumns
      ).map((measurementName) => {
        const measurementType = this.props.measurementType(measurementName);

        return {
          label: measurementType.display_name,
          value: measurementType.name,
        };
      });

      this.setState({
        loading: false,
        samples: response.data,
        samplePointIds,
        sourceFacilityCodes,
        sourceLocationIndices,
        product_types,
        sites,
        streams,
        measurementColumnOptions,
      });
    });
  }

  handleSelectedColumns = (selectedColumns) => {
    if (selectedColumns) {
      this.scrollTableToTop();
      this.setState({ selectedColumns });
      this.saveSelectedColumns(selectedColumns);
    }
  };

  saveSelectedColumns = (columns) => {
    const columnNames = columns.map(({ value }) => value);
    localStorage.setItem("lab_results--columns", JSON.stringify(columnNames));
    localStorage.setItem("lab_results--defaultMeasurementsSaved", true);
  };

  scrollTableToTop() {
    const reactTable = document.getElementsByClassName("rt-table");
    if (reactTable[0]) {
      reactTable[0].scrollTop = 0;
    }
  }

  /**
   * lab_results--columns stores all the optional columns to be shown
   * lab_results--defaultMeasurementsSaved is to ensure the addition of
   * defaultMeasurements to saved columns
   */
  getSelectedColumns = () => {
    const labels = localStorageToJSON("lab_results--columns");
    const defaultMeasurementTypesSaved = localStorage.getItem(
      "lab_results--defaultMeasurementsSaved"
    );

    const isDefaultMeasurementTypesLoaded =
      this.props.defaultMeasurementTypes.length !== 0;

    let storedColumns = [];
    if (labels) {
      storedColumns = labels.map((label) => ({
        value: label,
        label,
      }));
    }

    // If defaultMeasurements not saved in localStorage, add it to selected
    // columns and mark it as saved. Makes sure it only runs once and then
    // uses stored value for all other access
    if (!defaultMeasurementTypesSaved && isDefaultMeasurementTypesLoaded) {
      const defaultMeasurementLabels = this.props.defaultMeasurementTypes.map(
        (label) => ({
          value: label,
          label,
        })
      );

      const selectedColumns = uniqBy(
        [...defaultMeasurementLabels, ...storedColumns],
        "value"
      );

      this.saveSelectedColumns(selectedColumns);
      return selectedColumns;
    } else {
      return storedColumns;
    }
  };

  getColumns = (selectedColumns) => {
    const informationColumns = [
      {
        id: "stream",
        Header: "Stream",
        accessor: (sample) => {
          if (sample.files.length > 0) {
            return (
              <div>
                {sample.stream.name}
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  rootClose
                  overlay={getDownloadPopover(sample.files)}
                >
                  <Button className="labResults__pdfDownload">
                    <FontAwesome name="download" />
                  </Button>
                </OverlayTrigger>
              </div>
            );
          } else {
            return sample.stream.name;
          }
        },
        fixed: "left",
        width: 375,
        filterAll: true,
        filterMethod: streamFilter,
        Filter: ({ filter, onChange }) => (
          <FilterColumn
            filter={filter}
            onChange={onChange}
            options={this.state.streams}
            placeholder="Select streams..."
          />
        ),
      },
      {
        id: "product_type",
        Header: "Product",
        accessor: "stream.product_type",
        width: 125,
        filterAll: true,
        filterMethod: multiSelectFilter,
        Filter: ({ filter, onChange }) => (
          <FilterColumn
            filter={filter}
            onChange={onChange}
            options={this.state.product_types}
          />
        ),
      },
      {
        id: "source_type",
        Header: "Source",
        accessor: "sources",
        Cell: (props) => {
          if (props.value) {
            return <div className="capitalized">{props.value.join(", ")}</div>;
          } else {
            return "-";
          }
        },
        width: 120,
      },
      {
        Header: "Sample Type",
        accessor: "type",
        width: 140,
        getProps: (_state, _rowInfo, _column) => {
          return {
            style: {
              textTransform: "capitalize",
            },
          };
        },
        filterAll: true,
        filterMethod: multiSelectFilter,
        Filter: ({ filter, onChange }) => (
          <FilterColumn
            filter={filter}
            onChange={onChange}
            options={this.state.sample_types}
            placeholder="Select types..."
          />
        ),
      },
      {
        Header: "Sample Site",
        accessor: "site.name",
        width: 200,
        filterAll: true,
        filterMethod: multiSelectFilter,
        Filter: ({ filter, onChange }) => (
          <FilterColumn
            filter={filter}
            onChange={onChange}
            options={this.state.sites}
            placeholder="Select sites..."
          />
        ),
      },
      {
        Header: "Sample Start",
        accessor: "started_at",
        filterable: false,
        width: 200,
        Cell: (props) => {
          return getTimeStringFromDate(props.value, config.DATETIME_FORMAT);
        },
      },
      {
        Header: "Sample End",
        accessor: "ended_at",
        filterable: false,
        width: 200,
        Cell: (props) => {
          return getTimeStringFromDate(props.value, config.DATETIME_FORMAT);
        },
      },
    ];

    const findInfoColumn = (value) =>
      this.infoColumnOptions.find(({ value: val }) => value === val);

    const allSelectedMeasurementTypes = selectedColumns
      .filter((column) => !findInfoColumn(column.value))
      .map((column) => column.value);

    const validSelectedMeasurementTypes = _.intersection(
      allSelectedMeasurementTypes,
      this.state.measurementColumnOptions.map(({ value }) => value)
    );

    const selectedInfoColumns = selectedColumns.reduce((types, { value }) => {
      const infoColumn = findInfoColumn(value);

      return infoColumn ? [...types, infoColumn.column] : types;
    }, []);

    const measurementColumns = validSelectedMeasurementTypes.map(
      (measurementName) => {
        const measurementType = this.props.measurementType(measurementName);
        const unit = measurementType.unit;

        return {
          id: measurementName,
          Header: `${measurementType.display_name} (${unit})`,
          unit: unit,
          accessor: this.getMeasurement(measurementType),
          filterable: false,
          width: 200,
          sortMethod: (cell1, cell2) => {
            const value1 =
              cell1?.props?.children?.[0]?.props?.test?.measurements?.[
                measurementName
              ]?.value ?? null;

            const value2 =
              cell2?.props?.children?.[0]?.props?.test?.measurements?.[
                measurementName
              ]?.value ?? null;

            if (value1 === value2) {
              return 0;
            }

            if (!value1 && value2) {
              return -1;
            }

            if (value1 && !value2) {
              return 1;
            }

            return value1 > value2 ? 1 : -1;
          },
        };
      }
    );

    return [
      ...informationColumns,
      ...selectedInfoColumns,
      ...measurementColumns,
    ];
  };

  download = () => {
    const columns = this.getColumns(this.state.selectedColumns);
    const sortedData = this.table.getResolvedState().sortedData;

    const csvExportHeaders = getCSVExportHeaders(columns, sortedData);
    const timezone =
      this.props.profile.timezone_preference ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      "UTC";

    const fields = csvExportHeaders.map((column) => {
      if (column.accessor === "started_at" || column.accessor === "ended_at") {
        return `${column.Header} (${moment().tz(timezone).zoneAbbr()})`;
      }
      return column.Header;
    });

    const data = [
      ...sortedData.map((row) =>
        csvExportHeaders.map((column) => {
          const id = column.id || column.accessor;
          const value = row[id];
          const date = moment(value, moment.ISO_8601);

          if (date.isValid()) {
            return getTimeStringFromDate(
              date,
              config.DATETIME_NO_TIMEZONE_FORMAT
            );
          }

          // The measurement value is now a actionable link
          // so we need to grab the measurement value from
          // the component. For the stream column we are also
          // grabbing the value from the first element of the
          // array.
          if (React.isValidElement(value)) {
            if (column.id === "stream") {
              return value.props.children[0];
            } else {
              return getMeasurementValueFromProps(column, value.props.children);
            }
          }

          return value;
        })
      ),
    ];
    const csv = Papa.unparse({ fields, data });

    const now = moment();
    const date = getTimeStringFromDate(now, config.DATEMONTH_FORMAT);
    const filename = `Validere Lab Results - ${date}`;

    downloadCSV(filename, csv);
  };

  selectDate(startDate, endDate) {
    startDate = moment(startDate).utc().startOf("day");

    endDate = moment(endDate).utc().endOf("day");

    this.setState({
      startDate: startDate,
      endDate: endDate,
    });

    this.setURL(startDate, endDate);
  }

  setURL(startDate, endDate) {
    const startDateISO = getTimeStringFromDate(startDate, config.DATE_FORMAT);
    const endDateISO = getTimeStringFromDate(endDate, config.DATE_FORMAT);

    SetHistoryQueryParam({ startDate: startDateISO, endDate: endDateISO });
  }

  setFilterOffSpec() {
    const filterOffSpec = !this.state.filterOffSpec;
    this.setState({ filterOffSpec });
  }

  onTestClick(testId, sampleDetail, measurementType) {
    if (testId) {
      this.setState({
        selectedTestId: testId,
        selectedSampleDetail: sampleDetail,
        selectedMeasurementType: measurementType,
      });
    }
  }

  onHideTestDetailModal() {
    this.setState({
      selectedTestId: null,
      selectedSampleDetail: null,
      selectedMeasurementType: null,
    });
  }

  // Find the most recent value for the measurement type.
  getMeasurement(measurementType) {
    // eslint-disable-next-line
    return (row) => {
      const tests = filter(row.tests, (test) => {
        return test.measurements[measurementType.name] !== undefined;
      });

      if (tests.length > 0) {
        return (
          <div className="labResultsTestsContainer">
            {sortBy(tests, "type").map((test) => (
              <LabResultsTest
                key={test.id}
                sampleDetail={row}
                test={test}
                measurementType={measurementType}
                redirectToAnalyze={this.redirectToAnalyze}
                showType={tests.length > 1}
                onTestClick={this.onTestClick}
              />
            ))}
          </div>
        );
      }
    };
  }

  redirectToAnalyze() {
    const { selectedSampleDetail, selectedMeasurementType } = this.state;

    if (selectedSampleDetail && selectedMeasurementType) {
      const timeRangeStart = moment(selectedSampleDetail.started_at).subtract(
        ANALYZE_DATE_RANGE_BUFFER_IN_DAYS,
        "days"
      );

      const timeRangeEnd = moment(selectedSampleDetail.ended_at).add(
        ANALYZE_DATE_RANGE_BUFFER_IN_DAYS,
        "days"
      );

      const analyzeSpec = getAnalyzeSpec(
        selectedSampleDetail.stream,
        selectedSampleDetail.type,
        selectedMeasurementType,
        timeRangeStart,
        timeRangeEnd
      );

      this.props.addToAnalyze(analyzeSpec);
      history.push(linkToAnalyze());
    }
  }

  render() {
    const { startDate, endDate, filterOffSpec, loading, selectedColumns } =
      this.state;
    const { height } = this.props;

    const labResultTableHeight = Math.max(height - HEIGHT_OFFSET, MIN_HEIGHT);

    if (this.state.redirect) {
      return <Redirect to={{ pathname: linkToLogin() }} />;
    }

    const samples = filterOffSpec
      ? filterOffSpecSamples(this.state.samples)
      : this.state.samples;

    return (
      <Page
        title="Lab Results"
        breadcrumbs={this.props.breadcrumbs}
      >
        <Panel className="labResults">
          <div className="labResults__actionRow">
            <FilterColumn
              value={this.state.selectedColumns}
              onChange={this.handleSelectedColumns}
              options={[
                ...this.infoColumnOptions,
                ...this.state.measurementColumnOptions,
              ]}
              placeholder="Select columns..."
              className="columns-select"
              controlShouldRenderValue={false}
              isClearable={false}
            />

            <Button
              className="pull-right"
              onClick={this.download}
              disabled={loading}
            >
              .CSV
            </Button>

            <div className="labResults__dateRangeContainer pull-right">
              <DateRangeSelector
                dateRange={{
                  from: startDate,
                  to: endDate,
                }}
                onDateRangeChange={this.selectDate}
                icon
              />
            </div>

            <Checkbox
              className="labResults__checkBox pull-right"
              onChange={this.setFilterOffSpec}
            >
              Off-spec Results Only
            </Checkbox>
          </div>

          <div>
            <ReactTableFixedColumns
              innerRef={(r) => (this.table = r)}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
              data={samples}
              loading={loading}
              columns={this.getColumns(selectedColumns)}
              className="-highlight"
              style={{
                height: labResultTableHeight, // This will force the table body to overflow and scroll, since there is not enough room
              }}
              onFilteredChange={this.scrollTableToTop}
              getTdProps={() => {
                return {
                  style: {
                    padding: "10px 5px",
                  },
                };
              }}
              getTheadThProps={() => {
                return {
                  style: {
                    fontWeight: 700,
                  },
                };
              }}
              getTheadFilterThProps={() => {
                return {
                  style: {
                    overflow: "visible",
                  },
                };
              }}
              defaultPageSize={100}
              showPageSizeOptions={false}
            />
          </div>
        </Panel>

        {this.state.selectedTestId ? (
          <TestDetailModal
            showModal={true}
            testId={this.state.selectedTestId}
            onHide={this.onHideTestDetailModal}
            redirectToAnalyze={this.redirectToAnalyze}
          />
        ) : null}
      </Page>
    );
  }
}

LabResults.displayName = "LabResults";

LabResults.propTypes = {
  measurementType: PropTypes.func,
  defaultMeasurementTypes: PropTypes.array,
  addToAnalyze: PropTypes.func,
  height: PropTypes.number,
  supportedMeasurementTypes: PropTypes.array,
  profile: PropTypes.object,
  breadcrumbs: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabResults);
