import FacilityService from "#components/Services/FacilityService";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { linkToFacilities } from "#routers/links";
import useLocalization from "#src/hooks/useLocalization";
import {
  Button,
  Form,
  FormButton,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  parseAsLatitudeDegree,
  parseAsLongitudeDegree,
} from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import FacilityForm from "./FacilityForm";

const DEFAULT_INPUT = {
  status: "Active",
};

const CreateFacilities = ({ breadcrumbs }) => {
  const [formState, setFormState] = useState("loaded");

  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const { localize, isLoading: isMappingLoading } = useLocalization();

  async function handleOnSubmitClick({ name, status, ...restArgs }) {
    setFormState("loading");
    const geolocationInputs = restArgs?.attribute ?? null;
    try {
      const { data } = await FacilityService.addFacility({
        ...restArgs,
        name,
        status: status.toLowerCase(),
        latitude: parseAsLatitudeDegree(
          String(geolocationInputs?.coordinates?.[0])
        ),
        longitude: parseAsLongitudeDegree(
          String(geolocationInputs?.coordinates?.[1])
        ),
      });

      addAlert({
        variant: "success",
        message: `Successfully created ${localize("Facility")} ${name}`,
      });

      navigate({
        pathname: linkToFacilities(data.id),
      });
    } catch (_error) {
      setFormState("loaded");
    }
  }

  function handleOnCancelClick() {
    navigate({
      pathname: linkToFacilities(),
    });
  }

  const form = useForm({
    defaultValues: DEFAULT_INPUT,
  });

  const facilityBreadcrumbs = useBreadcrumbs(breadcrumbs);

  const footer = (
    <>
      <Button
        onClick={handleOnCancelClick}
        disabled={formState !== "loaded"}
      >
        Cancel
      </Button>

      <FormButton
        variant="primary"
        type="submit"
        isLoading={formState === "loading"}
        disabled={formState !== "loaded"}
      >
        Create {localize("Facility")}
      </FormButton>
    </>
  );

  return (
    <Form
      {...form}
      onSubmit={handleOnSubmitClick}
    >
      <Page
        title={`Create ${localize("Facility")}`}
        breadcrumbs={facilityBreadcrumbs}
        footer={footer}
        loading={isMappingLoading}
      >
        <FacilityForm
          formType="create"
          formState={formState}
          localize={localize}
        />
      </Page>
    </Form>
  );
};

CreateFacilities.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default CreateFacilities;
