import { useDeleteMeasurementSeries } from "#src/components/hooks/adapters/useMeasurementSeries";
import { Button, Dialog } from "@validereinc/common-components";
import { MeasurementSeriesType } from "@validereinc/domain";
import React from "react";

export const DeleteMeasurementSeriesDialog = ({
  isOpen,
  onClose,
  itemToDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  itemToDelete?: MeasurementSeriesType;
}) => {
  const onSuccess = () => {
    onClose?.();
  };
  const deleteMeasurementSeries = useDeleteMeasurementSeries({ onSuccess });
  const handleSubmit = () => {
    const id = itemToDelete?.id;
    deleteMeasurementSeries.mutate({
      id,
    });
  };

  return (
    <Dialog
      title="Delete Measurement Series"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="delete-measurement-series"
          variant="primary"
          onClick={handleSubmit}
        >
          Delete
        </Button>,
      ]}
    >
      This will delete the measurement series and all associated measurements.
      Are you sure?
    </Dialog>
  );
};
