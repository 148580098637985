import { Column, Row, StorageKeys } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { type ReactNode } from "react";
import styles from "./FlowDetailPage.module.scss";
import { FlowDetailsPanel } from "./FlowDetailsPanel";
import { FlowDevicesPanel } from "./FlowDevicesPanel";
import { FlowDownstreamPanel } from "./FlowDownstreamPanel";
import { FlowStatusPanel } from "./FlowStatusPanel";
import { FlowUpstreamPanel } from "./FlowUpstreamPanel";

const cx = classNames.bind(styles);

export const FlowOverviewTab = ({
  filterConfigStorageKey,
  viewFilterAreaSlot,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  viewFilterAreaSlot: ReactNode;
}) => {
  return (
    <>
      {viewFilterAreaSlot}
      <div className={cx("container")}>
        <div className={cx("contentStatus")}>
          <FlowStatusPanel filterConfigStorageKey={filterConfigStorageKey} />
        </div>

        <div className={cx("contentContainer")}>
          <FlowDetailsPanel filterConfigStorageKey={filterConfigStorageKey} />

          <Row>
            <Column variant={9}>
              <FlowUpstreamPanel
                filterConfigStorageKey={filterConfigStorageKey}
              />
            </Column>

            <Column variant={9}>
              <FlowDownstreamPanel
                filterConfigStorageKey={filterConfigStorageKey}
              />
            </Column>
          </Row>

          <FlowDevicesPanel filterConfigStorageKey={filterConfigStorageKey} />
        </div>
      </div>
    </>
  );
};
