import React, { Component } from "react";
import { Button, Panel } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import "./Popup.css";

/* eslint-disable react/prop-types */
class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: true,
    };

    this.popupRef = React.createRef();
    this.onClose = this.onClose.bind(this);
    this.clickEvent = this.clickEvent.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickEvent);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickEvent);
  }

  clickEvent(event) {
    // close popup if user click outside of the popup
    if (this.popupRef && !this.popupRef.current?.contains(event.target)) {
      this.props.closePopup();
    }
  }

  onClose() {
    this.props.closePopup();
  }

  render() {
    const { style, show } = this.props;

    return (
      <div
        ref={this.popupRef}
        hidden={!show}
      >
        <Panel
          className="monthlyView__popup"
          style={style}
        >
          <Panel.Body>
            <Button
              className="monthlyView__popupCloseButton"
              onClick={this.props.closePopup}
            >
              <FontAwesome name="times" />
            </Button>
            {this.props.children ? this.props.children : null}
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

export default Popup;
