import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { DropdownInput, useFormContext } from "@validereinc/common-components";
import { FacilityDomain } from "@validereinc/domain";
import { toFlattenedObject } from "@validereinc/utilities";
import debounce from "lodash/debounce";
import React, { useMemo, useState } from "react";

export const FacilityFormSubmissionDropdownInput = ({
  filter,
  name,
  ...restProps
}) => {
  const { watch } = useFormContext();

  const [searchTerm, setSearchTerm] = useState("");

  const value = watch(name);

  const isValidFilterObject = useMemo(() => {
    if (!filter) {
      return true;
    }

    const isValid = Object.values(toFlattenedObject(filter)).every(
      (value) => value
    );

    return isValid;
  }, [filter]);

  const valueOption = useQuery({
    queryKey: ["facilities", { filter, value }],
    queryFn: async ({ queryKey: [_entity, { filter, value }] }) => {
      if (!value) {
        return null;
      }

      const { data } = await FacilityDomain.getList({
        filters: {
          ...filter,
          id: value,
        },
      });

      return data?.[0] || null;
    },
  });

  const query = useInfiniteQuery({
    queryKey: ["facilities", filter, searchTerm],
    queryFn: async ({
      queryKey: [_entity, filters, searchTerm],
      pageParam,
    }) => {
      return await FacilityDomain.getList({
        page: pageParam ?? 1,
        sortBy: "name",
        sortDirection: "asc",
        pageSize: 25,
        filters: {
          ...filters,
          ...(searchTerm ? { name: { $like: searchTerm } } : {}),
          isAlreadyFormatted: true,
        },
      });
    },
    getNextPageParam: (lastPage) => lastPage?.page_number + 1,
    getPreviousPageParam: (prevPage) => prevPage?.page_number - 1,
    enabled: isValidFilterObject,
    staleTime: 1000 * 60 * 5,
  });

  const onSearchTermChange = debounce((newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  }, 500);

  const onScroll = ({ isBottom }) => {
    if (isBottom) {
      query.fetchNextPage();
    }
  };

  const options = useMemo(() => {
    const selected = valueOption.data;

    const options =
      query.data?.pages?.flatMap((page) =>
        page.data.filter(({ id }) => id !== selected?.id)
      ) ?? [];

    return [...(selected ? [selected] : []), ...options];
  }, [valueOption.data, query.data]);

  return (
    <DropdownInput
      {...restProps}
      name={name}
      options={options}
      valueKey="id"
      labelKey="name"
      searchTerm={searchTerm}
      onSearchTermChange={onSearchTermChange}
      onScroll={onScroll}
      isMulti={false}
      isDisabled={!isValidFilterObject}
      isLoading={query.isInitialLoading}
      isBusy={query.isLoading || query.isFetchingNextPage}
      isFluid
      defaultValue={value ?? ""}
    />
  );
};
