import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  Button,
  LegacyDataTable,
  useAlert,
} from "@validereinc/common-components";
import { downloadLink } from "@validereinc/utilities";
import get from "lodash/get";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";

const ThirdPartyLabImportsTable = ({
  thirdPartyLabImportFiles,
  filterRow,
  filterPillbox,
  noFilterListCount,
  openImportModal,
}) => {
  const dateRenderer = (rowData, columnKey) => {
    return getTimeStringFromDate(rowData[columnKey], config.DATETIME_FORMAT);
  };

  const sortBy = (list, columnKey) => {
    return [...list].sort((a, b) => {
      const aValue = get(a, columnKey, "-");
      const bValue = get(b, columnKey, "-");

      return aValue.localeCompare(bValue);
    });
  };
  const { addAlert } = useAlert();
  const onDownloadClick = (thirdPartyLabImportFile) => {
    downloadLink(thirdPartyLabImportFile.file_url);

    addAlert({
      variant: "success",
      message: "Your files will start downloading shortly.",
    });
  };

  const actionDropdown = [
    {
      title: "Download File",
      onClick: (rowData) => onDownloadClick(rowData),
    },
  ];

  const headers = [
    {
      label: "File Name",
      key: "file_name",
      width: 350,
      fixed: true,
      supress: true,
    },
    {
      label: "Site",
      key: "site.name",
      width: 200,
      sort: sortBy,
    },
    {
      label: "Issued By",
      key: "user.email",
      width: 250,
      sort: sortBy,
      supress: true,
    },
    {
      label: "Uploaded Date",
      key: "imported_at",
      width: 200,
      cellRenderer: dateRenderer,
    },
  ];

  const actionRow = (
    <div>
      <Button
        variant="primary"
        onClick={openImportModal}
      >
        Import Files
      </Button>
    </div>
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height}
          headers={headers}
          list={thirdPartyLabImportFiles}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          filterKey="file_name"
          defaultSortBy="imported_at"
          defaultSortDirection="desc"
          noFilterListCount={noFilterListCount}
          actionRow={actionRow}
          actionDropdown={actionDropdown}
        />
      )}
    </AutoSizer>
  );
};

ThirdPartyLabImportsTable.propTypes = {
  thirdPartyLabImportFiles: PropTypes.array.isRequired,
  filterRow: PropTypes.object.isRequired,
  filterPillbox: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  noFilterListCount: PropTypes.number,
  openImportModal: PropTypes.func,
};

export default ThirdPartyLabImportsTable;
