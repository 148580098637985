import { Tools } from "@validereinc/react-sketch";
import isNil from "lodash/isNil";
import { useEffect, useState } from "react";

const _ = { isNil };

export const STAGE_NAMES = {
  ZOOM_IN: "ZOOM_IN",
  DRAW_LINE: "DRAW_LINE",
  DRAW_BOX: "DRAW_BOX",
  ENTER_TICKS: "ENTER_TICKS",
  COMPLETE: "COMPLETE",
};

const TOOLS_FOR_STAGE_NAMES = {
  [STAGE_NAMES.ZOOM_IN]: Tools.ZoomBox,
  [STAGE_NAMES.DRAW_LINE]: Tools.Pencil,
  [STAGE_NAMES.DRAW_BOX]: Tools.Rectangle,
};

const TOOL_COLOURS_FOR_STAGE_NAMES = {
  [STAGE_NAMES.DRAW_LINE]: "yellow",
  [STAGE_NAMES.DRAW_BOX]: "red",
};

export const ANNOTATION_STEPS = [
  STAGE_NAMES.ZOOM_IN,
  STAGE_NAMES.DRAW_LINE,
  STAGE_NAMES.DRAW_BOX,
  STAGE_NAMES.ENTER_TICKS,
  STAGE_NAMES.COMPLETE,
];

export const getToolForStage = (stageName) => {
  const tool = TOOLS_FOR_STAGE_NAMES[stageName] || Tools.Nothing;
  const colour = TOOL_COLOURS_FOR_STAGE_NAMES[stageName] || "pink";

  return { tool, colour };
};

export const isStageActive = (currentStepIndex) => (stageName) => {
  const currentStageName = ANNOTATION_STEPS[currentStepIndex];
  return currentStageName === stageName;
};

export const isStageDisabled = (currentStepIndex) => (requestedStageName) => {
  const requestedStageIndex = ANNOTATION_STEPS.findIndex(
    (stepName) => stepName === requestedStageName
  );
  return requestedStageIndex < 0 || requestedStageIndex > currentStepIndex;
};

export const isStageComplete =
  (value, lowerTickValue, upperTickValue, isStageActive) => (stageName) => {
    const objects = value.objects || [];

    switch (stageName) {
      case STAGE_NAMES.DRAW_LINE:
        return objects.some((object) => object.type === "path");
      case STAGE_NAMES.DRAW_BOX:
        return objects.some((object) => object.type === "rect");
      case STAGE_NAMES.ENTER_TICKS: {
        const isLowerTickValid =
          !_.isNil(lowerTickValue) &&
          !isNaN(lowerTickValue) &&
          lowerTickValue !== "";
        const isUpperTickValid =
          !_.isNil(upperTickValue) &&
          !isNaN(upperTickValue) &&
          upperTickValue !== "";
        return isLowerTickValid && isUpperTickValid;
      }
      case STAGE_NAMES.COMPLETE:
        return isStageActive(STAGE_NAMES.COMPLETE);
      default:
        return true;
    }
  };

const useStages = (args) => {
  const {
    value,
    onToolSelect,
    onStageComplete,
    lowerTickValue,
    upperTickValue,
  } = args;

  [
    "value",
    "onToolSelect",
    "onStageComplete",
    "lowerTickValue",
    "upperTickValue",
  ].forEach((key) => {
    if (args[key] === undefined) {
      console.error(`useStages is missing required argument '${key}'`);
    }
  });

  const selectToolForStage = (stageName) => () => {
    const { tool, colour } = getToolForStage(stageName);

    onToolSelect(tool, colour);
  };

  const [stepIndex, setStepIndex] = useState(0);
  useEffect(() => {
    selectToolForStage(ANNOTATION_STEPS[stepIndex])();
  }, [stepIndex]);

  const goToNextStage = () => {
    const oldStageName = ANNOTATION_STEPS[stepIndex];
    const newStageIndex = stepIndex + 1;
    setStepIndex(newStageIndex);

    const newStageName = ANNOTATION_STEPS[newStageIndex];
    selectToolForStage(newStageName);

    onStageComplete(oldStageName);
  };

  return {
    currentStageName: ANNOTATION_STEPS[stepIndex],
    isStageActive: isStageActive(stepIndex),
    isStageDisabled: isStageDisabled(stepIndex),
    isStageComplete: isStageComplete(value, lowerTickValue, upperTickValue),
    goToNextStage,
    selectToolForStage,
  };
};

export default useStages;
