import { RoutePath } from "#src/utils/route";
import { DataSubmissionsRoutePath } from "..";

export { DataSetsListTab } from "./DataSetsListTab";

export const DataSetsRoutePath = DataSubmissionsRoutePath.concat(
  new RoutePath({
    path: "/datasets",
    title: "Dataset Submissions",
    isPresentational: true,
  })
);
