import {
  ControlledDropdownInputType,
  DropdownInput,
} from "@validereinc/common-components";
import {
  FilterObjectType,
  GetListRequestType,
  GetListResponseType,
  SortDirectionType,
  SortParamsType,
} from "@validereinc/domain";
import React from "react";

export type BaseEntityDropdownProps = {
  name?: string;
  isMulti?: boolean;
  isFluid?: boolean;
  isRequired?: boolean;
  isOptionalTextShown?: boolean;
  isInline?: boolean;
  placeholder?: string | null;
  fetchFilters?: Record<string, FilterObjectType<string | number | boolean>>;
  sort?: SortParamsType;
  description?: string;
} & ControlledDropdownInputType;

export type EntityDropdownProps<T> = {
  fetchEntityList: (
    props: GetListRequestType
  ) => Promise<GetListResponseType<T>>;
} & BaseEntityDropdownProps;

// TODO: remove all group related logic here - it is not maintainable and needs re-architecting
export const EntityDropdownInput = <T,>({
  name = "id",
  isMulti = true,
  isFluid = false,
  isRequired = false,
  isInline = false,
  placeholder = "",
  isOptionalTextShown = false,
  isLabelShown = true,
  fetchFilters = {},
  fetchEntityList,
  label,
  isDisabled,
  refetch,
  onChange, // required by FilterPanel so that it can provide a callback set state in session storage,
  sort = { sortBy: "created_at", sortDirection: "desc" },
  description,
  defaultValue,
  groups,
  onGroupChange,
  defaultGroup,
}: EntityDropdownProps<T>) => {
  const onFetchData = async ({
    value,
    pageSize,
    page,
    sortBy = sort.sortBy,
    sortDirection = sort.sortDirection,
    searchTerm,
  }: {
    value: string[];
    pageSize: number;
    page: number;
    sortBy?: string;
    sortDirection?: SortDirectionType;
    searchTerm?: string;
  }) => {
    const filters = {
      ...(searchTerm ? { name: searchTerm } : {}),
      ...(value?.length > 0 ? { id: value } : {}),
    };

    const { data } = await fetchEntityList({
      pageSize,
      page,
      sortBy,
      sortDirection,
      filters: { ...filters, ...fetchFilters },
    });

    return data;
  };

  return (
    <DropdownInput
      label={label}
      name={name}
      onFetchData={onFetchData}
      labelKey="name"
      valueKey="id"
      isMulti={isMulti}
      isFluid={isFluid}
      isInline={isInline}
      isRequired={isRequired}
      isOptionalTextShown={isOptionalTextShown}
      isLabelShown={isLabelShown}
      isDisabled={isDisabled}
      refetch={refetch}
      placeholder={placeholder}
      onChange={onChange}
      description={description}
      defaultValue={defaultValue}
      groups={groups}
      onGroupChange={onGroupChange}
      defaultGroup={defaultGroup}
    />
  );
};
