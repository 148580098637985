import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import {
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
} from "#hooks/adapters/useFlows";
import { FLOW_ATTRIBUTES } from "#hooks/tables/useFlowHeaders";
import {
  EquipmentDropdownInput,
  FacilityDropdownInput,
  FlowDropdownInput,
} from "#src/batteries-included-components";
import useLocalization from "#src/hooks/useLocalization";
import {
  DateSelectorInput,
  DropdownInput,
  TextInput,
} from "@validereinc/common-components";
import { AssetType, FlowStatus, FlowStatusOptions } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";

export const FLOW_FILTERS = {
  flowType: {
    name: "flow_type_id",
    label: "Type",
  },
  flowStatus: {
    name: "flow_status",
    label: "Status",
  },
  flowProductType: {
    name: "flow_product_type",
    label: "Product Type",
  },
  flowProductCategory: {
    name: "flow_product_category",
    label: "Product Category",
  },
  flowAssociatedFacility: {
    name: "flow_associated_facility",
    label: "Associated Facility",
  },
  flowAssociatedEquipment: {
    name: "flow_associated_equipment",
    label: "Associated Equipment",
  },
} as const;

/** Get the FilterPanel Filters for querying the Flows Search API */
export const useFlowFilters = ({
  isWithCustomAttributes = false,
  isWithPeriod = false,
}: {
  isWithCustomAttributes?: boolean;
  isWithPeriod?: boolean;
} = {}) => {
  const { localize } = useLocalization();
  const { customAttributeFilters } = useCustomAttributeFilters({
    assetType: AssetType.FLOW,
    enabled: isWithCustomAttributes,
  });

  const flowTypes = useListFlowTypes().data ?? [];
  const productTypes = useListFlowProductTypes().data ?? [];
  const productCategories = useListFlowProductCategories().data ?? [];

  const periodFilter = {
    component: (
      <DateSelectorInput
        key={"period"}
        name={"period"}
        variant="month"
        isInline
      />
    ),
  };

  return [
    {
      component: (
        <TextInput
          key={FLOW_ATTRIBUTES.NAME.key}
          name={FLOW_ATTRIBUTES.NAME.key}
          placeholder={`Search ${localize("flow_plural")}...`}
          type="search"
          isInline
        />
      ),
    },
    ...(isWithPeriod ? [periodFilter] : []),
    {
      section: "Filters",
      component: (
        <FlowDropdownInput
          key={FLOW_ATTRIBUTES.ID.key}
          name={`${localize(FLOW_ATTRIBUTES.NAME.key)}`}
          isMulti
          isFluid
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <DropdownInput
          key={FLOW_ATTRIBUTES.TYPE.key}
          name={FLOW_ATTRIBUTES.TYPE.key}
          label={FLOW_ATTRIBUTES.TYPE.label}
          options={flowTypes}
          labelKey="name"
          valueKey="id"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <DropdownInput
          key={FLOW_ATTRIBUTES.STATUS.key}
          name={FLOW_ATTRIBUTES.STATUS.key}
          label={FLOW_ATTRIBUTES.STATUS.label}
          options={FlowStatusOptions}
          labelKey="name"
          valueKey="id"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <DropdownInput
          key={FLOW_ATTRIBUTES.PRODUCT_CATEGORY.key}
          name={FLOW_ATTRIBUTES.PRODUCT_CATEGORY.key}
          label={FLOW_ATTRIBUTES.PRODUCT_CATEGORY.label}
          options={productCategories}
          labelKey="name"
          valueKey="id"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <DropdownInput
          key={FLOW_ATTRIBUTES.PRODUCT_TYPE.key}
          name={FLOW_ATTRIBUTES.PRODUCT_TYPE.key}
          label={FLOW_ATTRIBUTES.PRODUCT_TYPE.label}
          options={productTypes}
          labelKey="name"
          valueKey="id"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <FacilityDropdownInput
          key={FLOW_ATTRIBUTES.FACILITY.key}
          name={FLOW_ATTRIBUTES.FACILITY.key}
          isMulti
          isFluid
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <EquipmentDropdownInput
          key={FLOW_ATTRIBUTES.EQUIPMENT.key}
          name={FLOW_ATTRIBUTES.EQUIPMENT.key}
          isMulti
          isFluid
          isOptionalTextShown={false}
        />
      ),
    },
    ...(isWithCustomAttributes ? customAttributeFilters : []),
  ];
};

/** Flow filters for querying the Calculator Configuration Search API */
export const useFlowCalculationFilters = () => {
  const { localize } = useLocalization();

  const localizedFlowLabel = localize("Flow");
  const localizedFacilityLabel = localize("Facility");
  const localizedEquipmentLabel = localize("Equipment");

  const listFlowTypes = useListFlowTypes();
  const listProductType = useListFlowProductTypes();
  const listProductCategories = useListFlowProductCategories();

  const flowTypes = listFlowTypes.data ?? [];
  const flowProductTypes = listProductType.data ?? [];
  const flowProductCategories = listProductCategories.data ?? [];

  const { customAttributeFilters: flowCustomAttributeFilters } =
    useCustomAttributeFilters({
      assetType: AssetType.FLOW,
      section: "Flow",
      prefix: "flow.custom_attributes",
    });

  const { customAttributeFilters: facilityCustomAttributeFilters } =
    useCustomAttributeFilters({
      assetType: AssetType.FACILITY,
      section: localizedFacilityLabel,
      prefix: "flow.associated_facility.custom_attributes",
    });

  const { customAttributeFilters: equipmentCustomAttributeFilters } =
    useCustomAttributeFilters({
      assetType: AssetType.EQUIPMENT,
      section: localizedEquipmentLabel,
      prefix: "flow.associated_equipment.custom_attributes",
    });

  return {
    filters: [
      {
        section: localizedFlowLabel,
        component: (
          <DropdownInput
            key={FLOW_FILTERS.flowType.name}
            name={FLOW_FILTERS.flowType.name}
            label={FLOW_FILTERS.flowType.label}
            options={flowTypes || []}
            isLoading={!flowTypes}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
        ),
      },
      {
        section: localizedFlowLabel,
        component: (
          <FacilityDropdownInput
            key={FLOW_FILTERS.flowAssociatedFacility.name}
            name={FLOW_FILTERS.flowAssociatedFacility.name}
            label={FLOW_FILTERS.flowAssociatedFacility.label}
            isMulti
            isFluid
            isSearchable
          />
        ),
      },
      {
        section: localizedFlowLabel,
        component: (
          <EquipmentDropdownInput
            key={FLOW_FILTERS.flowAssociatedEquipment.name}
            name={FLOW_FILTERS.flowAssociatedEquipment.name}
            label={FLOW_FILTERS.flowAssociatedEquipment.label}
            isMulti
            isFluid
            isSearchable
          />
        ),
      },
      {
        section: localizedFlowLabel,
        component: (
          <DropdownInput
            key={FLOW_FILTERS.flowStatus.name}
            name={FLOW_FILTERS.flowStatus.name}
            label={FLOW_FILTERS.flowStatus.label}
            options={Object.values(FlowStatus).map((id) => ({
              id,
              name: toStartCaseString(id),
            }))}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
        ),
      },
      {
        section: localizedFlowLabel,
        component: (
          <DropdownInput
            key={FLOW_FILTERS.flowProductType.name}
            name={FLOW_FILTERS.flowProductType.name}
            label={FLOW_FILTERS.flowProductType.label}
            options={flowProductTypes || []}
            isLoading={!flowProductTypes}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
        ),
      },
      {
        section: localizedFlowLabel,
        component: (
          <DropdownInput
            key={FLOW_FILTERS.flowProductCategory.name}
            name={FLOW_FILTERS.flowProductCategory.name}
            label={FLOW_FILTERS.flowProductCategory.label}
            options={flowProductCategories || []}
            isLoading={!flowProductCategories}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
        ),
      },
      ...(facilityCustomAttributeFilters ?? []),
      ...(equipmentCustomAttributeFilters ?? []),
      ...(flowCustomAttributeFilters ?? []),
    ],
    filterKeys: [
      localizedFacilityLabel,
      localizedEquipmentLabel,
      localizedFlowLabel,
    ],
  };
};
