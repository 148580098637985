import React from "react";
import styles from "./TemplatedConfigurationResourcePills.module.scss";
import classNames from "classnames/bind";
import { TemplatedConfigurationResourcePillsPropTypes } from "./types";
import { Pill } from "@validereinc/common-components";
import { ResourceDefinitions } from "@validereinc/domain";
import { convertUnderscoresToSpaces } from "@validereinc/utilities";
const cx = classNames.bind(styles);

const TemplatedConfigurationResourcePills = (
  props: TemplatedConfigurationResourcePillsPropTypes
) => {
  const { templatedConfiguration } = props;

  const resourcesCreatedUsingSelectedTemplate = [
    {
      isPrimary: true,
      resourceType: templatedConfiguration?.primary_resource_type,
    },
    ...(templatedConfiguration?.other_resource_types ?? []).map(
      (resourceType) => ({
        isPrimary: false,
        resourceType,
      })
    ),
  ].filter((resource) => !!resource.resourceType);

  return (
    <div className={cx("pills-container")}>
      {resourcesCreatedUsingSelectedTemplate.map(
        (resourceDefinitionOnTemplate) => (
          <Pill
            key={resourceDefinitionOnTemplate.resourceType}
            variant="default"
            isBordered={resourceDefinitionOnTemplate.isPrimary}
          >
            {ResourceDefinitions[resourceDefinitionOnTemplate.resourceType!]
              ?.label.singular ??
              convertUnderscoresToSpaces(
                resourceDefinitionOnTemplate?.resourceType ?? ""
              ) ??
              ""}
          </Pill>
        )
      )}
    </div>
  );
};

export default TemplatedConfigurationResourcePills;
