import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns";
import { FilterArea } from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { SavedFilterTag } from "#src/components/hooks/FilterPanel/useSavedFilters";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  FacilityDomain,
  FacilitySchema,
  FacilityStatusOptions,
  Resources,
} from "@validereinc/domain";
import React from "react";

export const FacilitiesViewFilterArea = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { localize } = useLocalization();

  return (
    <FilterArea.Root<{
      period: { from: Date; to: Date };
    }>
      storageKey={viewConfigStorageKey}
      defaultValues={{
        period: DEFAULT_DATE_RANGES.currentWholeMonth,
      }}
    >
      <FilterArea.Container
        aria-label={`View Filters for ${localize(`${AssetType.FACILITY}_plural`)}`}
      >
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginBottom: 16,
              }}
            >
              <DateSelectorInput
                name={"period"}
                variant="month"
                label="Period"
                isInline
                isLabelShown={false}
                onChange={(val) => handleOnChange(val, "period")}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const FacilitiesTableFilterAreaContent = () => {
  const { localize } = useLocalization();
  const { customAttributeFilters: facilityCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.FACILITY,
    });

  const facilitySchemaKeys = FacilitySchema.keyof().Enum;

  return (
    <>
      <Accordion defaultActiveKeys={[AssetType.FACILITY]}>
        <Accordion.AccordionPanel
          dataKey={AssetType.FACILITY}
          title={localize(`${AssetType.FACILITY}_plural`)}
        >
          <FacilityDropdownInput
            name={facilitySchemaKeys.id}
            placeholder={`Select ${localize(`${AssetType.FACILITY}_plural`)}...`}
            isMulti
            isFluid
            isOptionalTextShown={false}
          />
          <DropdownInput
            name={facilitySchemaKeys.status}
            label="Status"
            placeholder="Select Status..."
            options={FacilityStatusOptions}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          {facilityCustomAttributeFilters}
        </Accordion.AccordionPanel>
      </Accordion>
    </>
  );
};

export const FacilitiesTableFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize } = useLocalization();
  const facilitySchemaKeys = FacilitySchema.keyof().Enum;

  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container
        aria-label={`Filters for ${localize(`${AssetType.FACILITY}_plural`)}`}
      >
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, marginBottom: 0 }}>
                <TextInput
                  name={facilitySchemaKeys.name}
                  label="Search"
                  isLabelShown={false}
                  placeholder={`Search ${localize("facility_plural")}...`}
                  type="search"
                  isInline
                  onChange={(val) =>
                    handleOnChange(val, facilitySchemaKeys.name)
                  }
                />
              </div>
            )}
          </FilterArea.Content>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <FilterDrawer.SavedFilters
              config={{
                resourceType: Resources.FACILITY,
                savedFilterResourceAdapter: FacilityDomain.savedFilters,
                tag: SavedFilterTag.LIST,
                filterBlacklist: ["period.from", "period.to"],
              }}
            />
            <FilterDrawer.SavedFiltersAppliedIndicator />
            <FacilitiesTableFilterAreaContent />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
