import { WorkflowTaskCompleteEvent } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskCompleteEvent";
import { getFormSchemaId } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskDetailDrawer.helpers";
import { WorkflowTaskFormSubmission } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskFormSubmission";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Drawer,
  Tooltip,
  useAlert,
} from "@validereinc/common-components";
import type { WorkflowTaskType } from "@validereinc/domain";
import {
  FormSchemaAdapter,
  WorkflowAdapter,
  WorkflowTaskAdapter,
  WorkflowTaskStatus,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useMemo } from "react";
import styles from "./WorkflowTaskDetailDrawer.module.scss";
import { WorkflowTaskDetailKeyValueList } from "./WorkflowTaskDetailKeyValueList";
import { WorkflowTaskDetailStatusKeyValueList } from "./WorkflowTaskDetailStatusKeyValueList";

const cx = classNames.bind(styles);

type WorkflowDetailsDrawerProps = {
  setSelectedTask: React.Dispatch<
    React.SetStateAction<WorkflowTaskType | null>
  >;
  selectedTask: WorkflowTaskType | null;
  onReassignTask?: (task: WorkflowTaskType) => void;
};

export const WorkflowTaskDetailDrawer = ({
  setSelectedTask,
  selectedTask,
  onReassignTask,
}: WorkflowDetailsDrawerProps) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlert();
  const workflowDetailsEnabled = Boolean(selectedTask?.workflow_id);
  const { data: workflowDetails, isLoading: workflowDetailsLoading } = useQuery(
    {
      queryKey: ["workflows", selectedTask?.workflow_id],
      queryFn: () => {
        if (!selectedTask?.workflow_id) {
          return;
        }

        return WorkflowAdapter.getOne({ id: selectedTask?.workflow_id });
      },
      select: (resp) => resp?.data,
      enabled: workflowDetailsEnabled,
    }
  );
  const { data: workflowTaskDetails } = useQuery({
    queryKey: ["workflows", "tasks", selectedTask?.id],
    queryFn: () => {
      if (!selectedTask?.id) {
        return;
      }

      return WorkflowTaskAdapter.getOne({ id: selectedTask?.id });
    },
    select: (resp) => resp?.data,
    enabled: Boolean(selectedTask?.id),
  });
  const { formSchemaId } = getFormSchemaId(selectedTask, workflowDetails);
  const { data: formSchemaDetails } = useQuery({
    queryKey: ["formSchemas", formSchemaId],
    queryFn: () => {
      if (!formSchemaId) {
        return;
      }

      return FormSchemaAdapter.getOne({ id: formSchemaId });
    },
    enabled: Boolean(formSchemaId),
    select: (resp) => resp?.data,
    staleTime: 2 * 60 * 1000,
  });

  const { isLoading: isLoadingComplete, mutate: completeTask } = useMutation({
    mutationFn: async () => {
      if (!selectedTask) {
        return;
      }

      await WorkflowTaskAdapter.updateOne({
        id: selectedTask.id,
        data: {
          status: WorkflowTaskStatus.COMPLETE,
        },
        previousData: selectedTask,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workflows"] });
      setTimeout(
        () =>
          queryClient.refetchQueries({
            queryKey: ["workflows"],
          }),
        4000
      );
      addAlert?.({
        variant: "success",
        message: "Successfully updated task status to complete",
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to update status. Please try again.",
      });
    },
  });

  const { isLoading: isLoadingDismissed, mutate: dismissTask } = useMutation({
    mutationFn: async () => {
      if (!selectedTask) {
        return;
      }

      await WorkflowTaskAdapter.updateOne({
        id: selectedTask.id,
        data: {
          status: WorkflowTaskStatus.DISMISSED,
        },
        previousData: selectedTask,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workflows"] });
      setTimeout(
        () =>
          queryClient.refetchQueries({
            queryKey: ["workflows"],
          }),
        4000
      );
      addAlert?.({
        variant: "success",
        message: "Successfully updated task status to dismissed",
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to dismiss task.",
      });
    },
  });
  const onClose = () => setSelectedTask(null);

  const isLoading = isLoadingComplete || isLoadingDismissed;

  const isTaskFormRequiredWithNoRelevantSubmissions = useMemo(() => {
    const workflowTask = workflowTaskDetails ?? selectedTask;
    const hasTaskAnySubmissionsOfSameSchema =
      !!workflowTask &&
      workflowTask?.type === "submit_form" &&
      Array.isArray(workflowTask?.form_submission) &&
      workflowTask?.form_submission.length > 0 &&
      workflowTask?.form_submission.some(
        (submission) => submission.form_schema.id === formSchemaId
      );

    const doesCurrentStepRequireFormSubmission =
      !!workflowDetails?.config.steps?.[selectedTask?.step_id ?? ""]?.task
        ?.form_required;

    return (
      doesCurrentStepRequireFormSubmission && !hasTaskAnySubmissionsOfSameSchema
    );
  }, [workflowTaskDetails, workflowDetails, selectedTask, formSchemaId]);

  const isCompleteButtonDisabled =
    (workflowDetailsEnabled && workflowDetailsLoading) ||
    selectedTask?.status !== "open" ||
    isTaskFormRequiredWithNoRelevantSubmissions;

  const completeButton = (
    <Button
      isLoading={isLoading}
      variant="primary"
      onClick={() => {
        completeTask();
      }}
      disabled={isCompleteButtonDisabled}
    >
      Complete Task
    </Button>
  );

  const completeButtonComponent =
    isTaskFormRequiredWithNoRelevantSubmissions ? (
      <Tooltip
        content={`At least one form submission ${formSchemaDetails?.name ? `of form template "${formSchemaDetails?.name}"` : ""} is required to complete the task.`}
      >
        {completeButton}
      </Tooltip>
    ) : (
      completeButton
    );

  return (
    <Drawer
      size="md"
      isOpen={!!selectedTask}
      onClose={onClose}
      title="Task Details"
      actionRow={
        <div className={cx("buttonContainer")}>
          {completeButtonComponent}
          {selectedTask ? (
            <Button
              isLoading={isLoading}
              onClick={() => {
                onReassignTask?.(selectedTask);
                setSelectedTask(null);
              }}
              disabled={selectedTask.status !== "open"}
            >
              Re-Assign Task
            </Button>
          ) : null}
          <Button
            isLoading={isLoading}
            variant="error-outline"
            onClick={dismissTask}
            disabled={selectedTask?.status !== "open"}
          >
            Dismiss Task
          </Button>
        </div>
      }
    >
      <div className={cx("contentContainer")}>
        <h6 className={cx("title")}>{selectedTask?.name}</h6>
        <WorkflowTaskDetailStatusKeyValueList selectedTask={selectedTask} />
        <WorkflowTaskDetailKeyValueList
          selectedTask={selectedTask}
          formSchema={formSchemaDetails}
        />
        {selectedTask?.type === "submit_form" ? (
          <WorkflowTaskFormSubmission
            workflowTask={workflowTaskDetails ?? selectedTask}
            formSchema={formSchemaDetails}
            workflow={workflowDetails}
          />
        ) : null}
        {selectedTask?.type === "complete_event" ? (
          <WorkflowTaskCompleteEvent
            workflowTask={workflowTaskDetails ?? selectedTask}
            workflow={workflowDetails}
          />
        ) : null}
      </div>
    </Drawer>
  );
};
