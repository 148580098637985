import { Modal, ReactLoader, Title } from "@validereinc/common-components";
import { downloadLink } from "@validereinc/utilities";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import moduleStyles from "./PreviewModal.module.scss";

const cx = classNames.bind(moduleStyles);

const PreviewModal = ({ onClose, smallImageUrl, largeImageUrl }) => {
  const [loadingState, setLoadingState] = useState("isLoading");

  const onImageLoaded = () => {
    setLoadingState("loaded");
  };

  return (
    <Modal
      className={cx("previewModal")}
      open={true}
      onClose={onClose}
    >
      <Modal.Header>
        <Title>Preview</Title>
      </Modal.Header>

      <Modal.Body>
        <div className={cx("imageContainer")}>
          <img
            className={cx(loadingState === "loaded" ? "show" : "hidden")}
            src={smallImageUrl}
            onLoad={onImageLoaded}
          />
          <ReactLoader
            loaded={loadingState === "loaded"}
            positions={50}
          />
        </div>
        <div>
          <a onClick={() => downloadLink(largeImageUrl)}>
            Download full size image
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

PreviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  smallImageUrl: PropTypes.string.isRequired,
  largeImageUrl: PropTypes.string.isRequired,
};

export default PreviewModal;
