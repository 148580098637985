import isPlainObject from "lodash/isPlainObject";
import { ReportWithDownloadType } from "../../schemas";
import { CustomAttributeServiceType } from "../../adapters";

// TODO: till we iron out the build issues, the keys here will have to be
// snake-cased. eventually they can all just be camel-cased and snake-cased only
// when commmunicating with the back-end

/**
 * Type for a unique identifier. In the domain right now, the unique id is
 * usually a UUID string.
 */
export type IdentifierType = string;

export interface DomainModelMetaType {
  /** when was this model created? (will be an ISO UTC date string) */
  created_at: string;
  /** when was this model updated? (will be an ISO UTC date string) */
  updated_at: string;
}

export interface DomainModelMetaExtendedType extends DomainModelMetaType {
  /** who created this model? (will be a UUID of a user model) */
  created_by: string;
  /** who updated this model? (will be a UUID of a user model) */
  updated_by: string;
}

/**
 * Generic type to type an instance of a data model. Every instance is assumed
 * to have a unique identifier.
 */
export interface DomainModelType {
  /** the unique identifier of this model/model in the database */
  readonly id: IdentifierType;
}

export interface ResponseStatusType {
  /** the HTTP status code of this network response */
  status: number;
  /** the error details from the API */
  error?: ResponseErrorType;
}

// IMPROVE: this type is reflective of the error type from the BE. We can improve this.
export interface ResponseErrorType {
  /** was the original request this response was for, successful? */
  success: boolean;
  /** a description of the error from the API */
  error: string;
}

export type RequestCallbackType = (response: Response) => Response;

export const isResponseErrorType = (data: any): data is ResponseErrorType => {
  if (
    isPlainObject(data) &&
    Object.hasOwn(data, "error") &&
    Object.hasOwn(data, "success")
  ) {
    return true;
  }

  return false;
};

export const SortDirection = {
  ASCENDING: "asc",
  DESCENDING: "desc",
} as const;

export type SortDirectionType =
  (typeof SortDirection)[keyof typeof SortDirection];

export interface PaginationParamsType {
  /** the page of data to retrieve */
  page: number;
  /** the number of entries per page to retrieve */
  pageSize: number;
}

export interface PaginationCursorParamsType {
  /* the deterministic property on the data being paginated is used as a "cursor" - a starting point to count from */
  cursorToken?: string;
  /** the number of entries per page to retrieve */
  pageSize: number;
}

export interface SortParamsType {
  /** the field name to sort on */
  sortBy: string;
  /** the direction to sort the field by */
  sortDirection: SortDirectionType;
}

export interface GroupParamsType {
  /** the field of a particular model to group by */
  groupBy: string[];
}

export interface PaginationAndSortParamsType
  extends PaginationParamsType,
    Partial<SortParamsType> {}

export interface PaginationCursorAndSortParams
  extends PaginationCursorParamsType,
    SortParamsType {}

export interface PaginationSortAndGroupParamsType
  extends Partial<PaginationParamsType>,
    Partial<SortParamsType>,
    Partial<GroupParamsType> {}

// IMPROVE: amalgamate this with FilterObjectType and fix the issues there
export interface FiltersType {
  [key: string]: string | string[] | FiltersType[];
}

export interface GetListRequestType<
  // TODO: needs an extends again
  TFilter = any,
  TMeta = any,
> extends PaginationSortAndGroupParamsType {
  /** the filters to apply to search for a list of entries */
  filters?: TFilter;
  /** additional metadata that was passed in. typing is generic - check the implementor for actual type.  */
  meta?: TMeta;
}

export interface GetListResponseType<TDomainModel> {
  /** list of entries that satisfy the provided filters */
  data: TDomainModel[];
  /** the page number of entries requested */
  page_number: number;
  /** the page size of entries being used to determine the # of pages */
  page_size: number;
  /** the total number of entries available */
  total_entries: number;
  /** the total number of pages based on the page_size available */
  total_pages: number;
}

export interface CursorListResponseType<TDomainModel> {
  total_entries: number;
  next_cursor_token?: string | null;
  data: TDomainModel[];
}

export interface GetOneRequestType<TMeta = any> {
  /** the ID of the model you want to get */
  id: IdentifierType;
  /** additional metadata that was passed in. typing is generic - check the implementor for actual type.  */
  meta?: TMeta;
}

export interface GetOneResponseType<TDomainModel> {
  /** the model requested */
  data: TDomainModel;
}

export interface GetManyRequestType<TMeta = any> {
  /** list of IDs corresponding to entries you want to get */
  ids: IdentifierType[];
  /** additional metadata that was passed in. typing is generic - check the implementor for actual type.  */
  meta?: TMeta;
}

export interface GetManyResponseType<TDomainModel> {
  /** list of entries requested */
  data: TDomainModel[];
}

export interface CreateOneRequestType<TData = any, TMeta = any> {
  /** create payload to send */
  data: TData;
  /** additional metadata that was passed in. typing is generic - check the implementor for actual type.  */
  meta?: TMeta;
}

export interface CreateOneResponseType<TDomainModel> {
  /** the created model */
  data: TDomainModel;
}

export interface CreateManyRequestType<TData = any, TMeta = any> {
  /** the entries to create */
  data: TData[];
  /** additional metadata that was passed in. typing is generic - check the implementor for actual type.  */
  meta?: TMeta;
}

export interface UpdateOneRequestType<TDomainModel, TMeta = any> {
  /** the ID of the model to update */
  id: IdentifierType;
  /** the data of the model to update */
  data: Partial<TDomainModel>;
  /** the previous state of the model before changes */
  previousData?: TDomainModel;
  /** additional metadata that was passed in. typing is generic - check the implementor for actual type.  */
  meta?: TMeta;
}

export interface UpdateOneResponseType<TDomainModel> {
  /** the updated model */
  data: TDomainModel;
}

export interface UpdateManyRequestType<TData = any, TMeta = any> {
  /** the IDs of entries to update */
  ids: IdentifierType[];
  /** the data to update for all entries */
  data: TData;
  /** additional metadata that was passed in. typing is generic - check the implementor for actual type.  */
  meta?: TMeta;
}

export interface DeleteOneRequestType<TDomainModel, TMeta = any> {
  /** the ID of the model to delete */
  id: IdentifierType;
  /** the previous state of the model */
  previousData?: TDomainModel;
  /** additional metadata that was passed in. typing is generic - check the implementor for actual type.  */
  meta?: TMeta;
}

export interface DeleteManyRequestType<TMeta = any> {
  /** the IDs of the entries to delete */
  ids: IdentifierType[];
  /** additional metadata that was passed in. typing is generic - check the implementor for actual type.  */
  meta?: TMeta;
}

/** Bulk actions from the NodeJS backend have a uniform interface */
export type BulkActionResultType<T> = {
  /** Whether the whole job succeeded or failed */
  status: BulkActionResultStatusType;
  /** A record with a key for each result in the bulk action */
  data: Record<
    string,
    {
      /** Whether the individual result succeeded or failed */
      status: BulkActionResultStatusType;
      /** If an error occurred, what that error was */
      error?: string;
      /** If a result has data, it is returned here */
      data?: T;
    }
  >;
};

export type BulkActionResultStatusType = "success" | "failed";

/**
 * Interface contract for every domain data service. Describes a uniform pattern
 * of dealing with a particular type of data (a "resource") from any external
 * API that is REST-API based. A "model" is a single instance of a "resource" from the API.
 */
export interface ResourceServiceType<TDomainModel> {
  /** get a list of entries by providing filters */
  getList: (
    params: GetListRequestType
  ) => Promise<GetListResponseType<TDomainModel>>;
  /** get a single model by providing its ID */
  getOne: (
    params: GetOneRequestType
  ) => Promise<GetOneResponseType<TDomainModel>>;
  /** get many entries by providing their IDs */
  getMany: (
    params: GetManyRequestType
  ) => Promise<GetManyResponseType<TDomainModel>>;
  /** update a single model by providing its ID, the data of that model you want to update, and the current data of the model without changes - both will be merged */
  updateOne: (
    params: UpdateOneRequestType<TDomainModel>
  ) => Promise<UpdateOneResponseType<TDomainModel>>;
  /** update many entries by providing their IDs and a single data payload to update all of them with */
  updateMany: (
    params: UpdateManyRequestType
  ) => Promise<BulkActionResultType<TDomainModel>>;
  /** create a single model by passing in arbitrary data */
  createOne: (
    params: CreateOneRequestType
  ) => Promise<CreateOneResponseType<TDomainModel>>;
  /** create many models by passing in an array of arbitrary data */
  createMany: (
    params: CreateManyRequestType
  ) => Promise<BulkActionResultType<TDomainModel>>;
  /** delete a single model by providing its ID */
  deleteOne: (
    params: DeleteOneRequestType<TDomainModel>
  ) => Promise<ResponseStatusType | ResponseErrorType>;
  /** delete many entries by providing their IDs */
  deleteMany: (
    params: DeleteManyRequestType
  ) => Promise<BulkActionResultType<TDomainModel>>;
  /** get an export containing a list entries by providing filters */
  exportList?: (params: GetListRequestType) => Promise<ReportWithDownloadType>;
  /** Custom Attributes of the entity, if any */
  customAttributes?: CustomAttributeServiceType;
}

// WIP: will remove these types gradually as we move towards new standardized contract above ^
/**
 * @deprecated
 */
export type PaginatedListRequestType<T> = {
  page: number;
  pageSize: number;
  sortBy?: string;
  sortDirection?: SortDirectionType;
  groupBy?: string[];
  filters?: T;
};

// Data Platform API Types

export const DataPlatformDataType = {
  TRANSACTION: "Transaction",
  TRANSACTION_DETAILS: "TransactionDetails",
  DATASET: "Dataset",
  DATASET_SCHEMA: "DatasetSchema",
  OPERATION: "Operation",
  FILE: "File",
  SOURCE: "Source",
  SINK_CONFIG: "SinkConfig",
  DATA_PRODUCT: "DataProduct",
  CLIENT: "Client",
  DEFAULT: "Default",
} as const;

export type DataPlatformDataTypeType =
  (typeof DataPlatformDataType)[keyof typeof DataPlatformDataType];

export type DataPlatformResponse<
  TData,
  TDataType extends DataPlatformDataTypeType,
> = {
  meta: {
    type: TDataType;
    continuation_token?: string | null;
    info?: string | null;
    total: number | null;
  };
  data: TData[];
};
