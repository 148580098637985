import {
  DEFAULT_INVALIDATE_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  UseMutationCallbackType,
  UseQueryOptionsType,
} from "#hooks/adapters/adapterUtils";
import { CALCULATOR_RESULTS_QUERY_KEY } from "#hooks/adapters/useCalculatorResults";
import { getSuccessAndFailureCounts } from "#hooks/adapters/utils/bulkActionUtils";
import { useStickyState } from "#src/hooks/useStickyState";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import {
  AssetTypeType,
  CreateEstimationMethodDataType,
  EstimationMethodApplyDefaultsFilterType,
  EstimationMethodConfigurationKeyType,
  EstimationMethodConfigurationType,
  EstimationMethodDomain,
  EstimationMethodInputType,
  EstimationMethodRunFilterType,
  PaginatedListRequestType,
} from "@validereinc/domain";
import { downloadLink } from "@validereinc/utilities";

export const ESTIMATION_METHODS_QUERY_KEY = "estimationMethods";

export const useListEstimationMethods = (
  apiParams: PaginatedListRequestType<any>
) => {
  return useQuery({
    queryKey: [ESTIMATION_METHODS_QUERY_KEY, "list", apiParams],
    queryFn: () => EstimationMethodDomain.getList(apiParams),
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useListEstimationMethodConfiguration = (
  apiParams: any,
  options: { enabled?: boolean; select?: (arg0: any) => any } = {}
) => {
  return useQuery({
    queryKey: [
      ESTIMATION_METHODS_QUERY_KEY,
      "configuration",
      "getList",
      apiParams,
    ],
    queryFn: () => EstimationMethodDomain.configuration.getList(apiParams),
    ...{
      ...options,
      enabled: options.enabled && !!apiParams?.filters?.year_month?.length,
    }, // API requires a year month
  });
};

export const useListEstimationMethodRuns = (
  params: Parameters<typeof EstimationMethodDomain.run.getList>[0] = {},
  options: UseQueryOptionsType<
    Awaited<ReturnType<typeof EstimationMethodDomain.run.getList>>
  > = {}
) =>
  useQuery({
    queryKey: [ESTIMATION_METHODS_QUERY_KEY, "run", "getList", params] as const,
    queryFn: ({ queryKey: [_, __, ___, params] }) =>
      EstimationMethodDomain.run.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useGetOneEstimationMethodConfiguration = (
  params: {
    id: string;
    assetType: AssetTypeType;
    yearMonth: string;
  },
  options: UseQueryOptionsType<
    Awaited<ReturnType<typeof EstimationMethodDomain.configuration.getOne>>
  > = {}
) =>
  useQuery({
    queryKey: [
      ESTIMATION_METHODS_QUERY_KEY,
      "configuration",
      "getOne",
      params.id,
      { yearMonth: params.yearMonth, entityType: params.assetType },
    ] as const,
    queryFn: ({ queryKey: [_, __, ___, id, meta] }) =>
      EstimationMethodDomain.configuration.getOne({ id, meta }),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useUpdateEstimationMethodConfiguration = ({
  onSuccess,
  onError,
}: UseMutationCallbackType = {}) => {
  const { addAlert } = useAlert();
  const { invalidate } = useClearCalculationCache();

  return useMutation({
    mutationFn: ({
      id,
      assetType,
      yearMonth,
      data,
    }: {
      id: string;
      assetType: AssetTypeType;
      yearMonth: string;
      data: Partial<
        Omit<EstimationMethodConfigurationType, "inputs" | "asset_inputs"> & {
          inputs: Record<string, EstimationMethodInputType | null>;
          asset_inputs: Record<
            string,
            Record<string, EstimationMethodInputType | null> | null
          >;
        }
      >;
    }) =>
      EstimationMethodDomain.configuration.update({
        id,
        data,
        meta: {
          entityType: assetType,
          yearMonth,
        },
      }),
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
      invalidate();
    },
    onError: () => {
      onError?.();
      addAlert?.({
        variant: "error",
        message: `Failed to update configuration`,
      });
    },
  });
};

export const useGetOneEstimationMethodRun = (
  params: {
    id: string;
    assetType: AssetTypeType;
    yearMonth: string;
  },
  options: UseQueryOptionsType<
    Awaited<ReturnType<typeof EstimationMethodDomain.run.getOne>>
  > = {}
) =>
  useQuery({
    queryKey: [
      ESTIMATION_METHODS_QUERY_KEY,
      "run",
      "getOne",
      params.id,
      { yearMonth: params.yearMonth, entityType: params.assetType },
    ] as const,
    queryFn: ({ queryKey: [_, __, ___, id, meta] }) =>
      EstimationMethodDomain.run.getOne({ id, meta }),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useRunEstimationMethod = () => {
  const { addAlert } = useAlert();
  const { invalidate } = useClearCalculationCache();

  return useMutation({
    mutationFn: ({
      id,
      assetType,
      yearMonth,
      isPreview,
      promoteToRecord,
    }: {
      id: string;
      assetType: AssetTypeType;
      yearMonth: string;
      isPreview?: boolean;
      promoteToRecord?: boolean;
    }) =>
      EstimationMethodDomain.run.create({
        meta: {
          methodId: id,
          entityType: assetType,
          yearMonth,
          isPreview,
          promoteToRecord,
        },
      }),
    onSuccess: () => {
      addAlert?.({
        variant: "success",
        message: `Successfully ran calculation`,
      });
      invalidate();
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: `Failed to run calculation`,
      });
    },
  });
};

export const useBulkRunEstimationMethodsAsync = (
  jobKey: string,
  promoteToRecord = true
) => {
  const { addAlert } = useAlert();
  const [_, setJobId] = useStickyState<string>("", jobKey);

  return useMutation({
    mutationFn: (filters: EstimationMethodRunFilterType) =>
      EstimationMethodDomain.run.bulkRunAsync({
        filters,
        isPreview: false,
        promoteToRecord,
      }),
    onSuccess: (result) => {
      setJobId(result.job.id);
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to run calculations",
      });
    },
  });
};

export const useApplyConfiguration = () => {
  const { addAlert } = useAlert();
  const { invalidate } = useClearCalculationCache();

  return useMutation({
    mutationFn: ({
      id,
      assetType,
      yearMonth,
    }: {
      id: string;
      assetType: AssetTypeType;
      yearMonth: string;
      isPreview?: boolean;
    }) =>
      EstimationMethodDomain.configuration.applyDefaults({
        id,
        meta: {
          entityType: assetType,
          yearMonth,
        },
      }),
    onSuccess: () => {
      addAlert?.({
        variant: "success",
        message: `Successfully applied configuration`,
      });
      invalidate();
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: `Failed to apply configuration`,
      });
    },
  });
};

export const useBulkApplyConfigurationAsync = (jobKey: string) => {
  const { addAlert } = useAlert();
  const [_, setJobId] = useStickyState<string>("", jobKey);

  return useMutation({
    mutationFn: (params: EstimationMethodApplyDefaultsFilterType) =>
      EstimationMethodDomain.configuration.bulkApplyDefaultsAsync(params),
    onSuccess: (result) => {
      setJobId(result.job.id);
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to apply configurations",
      });
    },
  });
};

export const useBulkApplyConfiguration = () => {
  const { addAlert } = useAlert();
  const { invalidate } = useClearCalculationCache();

  return useMutation({
    mutationFn: (ids: EstimationMethodConfigurationKeyType[]) =>
      EstimationMethodDomain.configuration.bulkApplyDefaults(ids),
    onSuccess: ({ data }) => {
      const { successCount, failureCount } = getSuccessAndFailureCounts(data);
      if (successCount) {
        addAlert?.({
          variant: "success",
          message: `Successfully applied configurations to ${successCount} row(s)`,
        });
      }
      if (failureCount) {
        addAlert?.({
          variant: "error",
          message: `Failed to apply configurations to ${failureCount} row(s)`,
        });
      }
      invalidate();
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to apply configurations",
      });
    },
  });
};

export const useBulkRunEstimationMethods = (promoteToRecord = true) => {
  const { addAlert } = useAlert();
  const { invalidate } = useClearCalculationCache();

  return useMutation({
    mutationFn: (filters: EstimationMethodRunFilterType) =>
      EstimationMethodDomain.run.bulkRun({
        filters,
        isPreview: false,
        promoteToRecord,
      }),
    onSuccess: ({ data }) => {
      const { successCount, failureCount } = getSuccessAndFailureCounts(data);
      if (successCount) {
        addAlert?.({
          variant: "success",
          message: `Successfully completed calculations on ${successCount} row(s)`,
        });
      }
      if (failureCount) {
        addAlert?.({
          variant: "error",
          message: `Failed to run calculations on ${failureCount} row(s)`,
        });
      }
      invalidate();
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to run calculations",
      });
    },
  });
};

export const useExportEstimationMethods = (
  apiParams: PaginatedListRequestType<any>
) => {
  return useMutation({
    mutationFn: async () => {
      const { name, s3_download_link } =
        await EstimationMethodDomain.exportList(apiParams);
      downloadLink(s3_download_link, name);
    },
  });
};

export const useUpdateEstimationMethod = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { invalidate } = useClearCalculationCache();

  return useMutation({
    mutationFn: async ({
      id,
      entityType,
      data,
    }: {
      id: string;
      entityType: string;
      data: Partial<CreateEstimationMethodDataType>;
    }) =>
      EstimationMethodDomain.update({
        id,
        data,
        meta: {
          entityType,
        },
      }),
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
      invalidate();
    },
  });
};

export const useClearCalculationCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [ESTIMATION_METHODS_QUERY_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
      queryClient.invalidateQueries({
        queryKey: [CALCULATOR_RESULTS_QUERY_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
  };
};
