import { FormSubmissionsFilterType } from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel";
import { HeaderType, StorageKeys } from "@validereinc/common-components";
import { FormSubmissionType } from "@validereinc/domain";
import React, { ReactNode, createContext, useContext, useState } from "react";

export type AddFormSubmissionContext = {
  tableHeaders: HeaderType[];
  setTableHeaders: React.Dispatch<React.SetStateAction<HeaderType[]>>;
  selectedFormSubmissions: FormSubmissionType[];
  setSelectedFormSubmissions: React.Dispatch<
    React.SetStateAction<FormSubmissionType[]>
  >;
  selectFormSubmission: (submission: FormSubmissionType) => void;
  deselectFormSubmission: (submissionId: FormSubmissionType["id"]) => void;
  filtersDefaultValues?: {
    [key in keyof FormSubmissionsFilterType]?: string;
  };
} & StorageKeys;

const AddFormSubmissionContext = createContext<AddFormSubmissionContext | null>(
  null
);

export const useAddFormSubmissionContext = () => {
  const ctx = useContext(AddFormSubmissionContext);

  if (ctx === null) {
    throw new Error("Must be used within a AddFormSubmissionContextProvider");
  }

  return ctx;
};

export const AddFormSubmissionContextProvider = ({
  children,
  filtersDefaultValues,
  filterConfigStorageKey,
  tableConfigStorageKey,
  viewConfigStorageKey,
}: {
  children: ReactNode;
} & Pick<
  AddFormSubmissionContext,
  | "filtersDefaultValues"
  | "filterConfigStorageKey"
  | "tableConfigStorageKey"
  | "viewConfigStorageKey"
>) => {
  const [tableHeaders, setTableHeaders] = useState<
    Array<HeaderType<FormSubmissionType>>
  >([
    {
      key: "name",
      label: "Submission Name",
    },
    {
      key: "form_schema.form_category.name",
      label: "Category",
    },
    { key: "form_schema.name", label: "Template" },
    {
      key: "status",
      label: `Status`,
      isSortable: true,
    },
    {
      key: "created_at",
      label: "Submission Date",
    },
  ]);
  const [selectedFormSubmissions, setSelectedFormSubmissions] = useState<
    FormSubmissionType[]
  >([]);

  const selectFormSubmission = (submission: FormSubmissionType) => {
    setSelectedFormSubmissions((oldFormSubmissions) => [
      ...oldFormSubmissions,
      submission,
    ]);
  };

  const deselectFormSubmission = (submissionId: FormSubmissionType["id"]) => {
    setSelectedFormSubmissions((oldFormSubmissions) =>
      oldFormSubmissions.filter(({ id }) => id !== submissionId)
    );
  };

  return (
    <AddFormSubmissionContext.Provider
      value={{
        selectedFormSubmissions,
        setSelectedFormSubmissions,
        selectFormSubmission,
        deselectFormSubmission,
        tableHeaders,
        setTableHeaders,
        filtersDefaultValues,
        filterConfigStorageKey,
        tableConfigStorageKey,
        viewConfigStorageKey,
      }}
    >
      {children}
    </AddFormSubmissionContext.Provider>
  );
};
