import type {
  ResourceType,
  TemplatedConfigurationType,
} from "@validereinc/domain";

export const getResourceIdForResource = (
  primaryResourceType: ResourceType,
  templatedConfiguration?: TemplatedConfigurationType
) => {
  if (
    !templatedConfiguration ||
    !Object.keys(
      templatedConfiguration.configuration?.custom_attribute_configuration ?? {}
    ).length
  )
    return;

  return Object.keys(
    templatedConfiguration.configuration.custom_attribute_configuration
  ).find(
    (resourceId) =>
      templatedConfiguration.configuration.custom_attribute_configuration[
        resourceId
      ].entity_type === primaryResourceType
  );
};
