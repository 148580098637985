import Map from "#components/Map/Map";
import Marker from "#components/Map/Marker/Marker";
import * as PropTypes from "prop-types";
import React from "react";
import OverviewPanel from "./OverviewPanel";

import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import { Panel } from "@validereinc/common-components";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import styles from "./FacilityDetailOverviewTab.module.scss";

const cx = classNames.bind(styles);

const FacilityDetailOverviewTab = ({ facilityDetail, isLoading }) => {
  const initialViewState = {
    latitude: facilityDetail?.latitude,
    longitude: facilityDetail?.longitude,
  };

  const sharedProps = {
    type: "vertical",
    isLoading,
    allowWrap: true,
    isStatusPanel: true,
  };

  const shouldDisplayMap =
    (facilityDetail?.latitude || facilityDetail?.latitude === 0) &&
    (facilityDetail?.longitude || facilityDetail?.longitude === 0);

  if (!facilityDetail) {
    return null;
  }

  return (
    <div className={cx("overviewAndMapSection")}>
      <Panel isFluidY={false}>
        <SummaryInformationContainer
          variant="vertical"
          status={facilityDetail?.status}
          statusVariant={facilityDetail?.status}
        >
          <SummaryInformation
            {...sharedProps}
            title="Created At"
            value={datetimeFormatter(new Date(facilityDetail?.created_at))}
          />
          <SummaryInformation
            {...sharedProps}
            title="Updated At"
            value={datetimeFormatter(new Date(facilityDetail?.updated_at))}
          />
        </SummaryInformationContainer>
      </Panel>
      <OverviewPanel
        facilityDetail={facilityDetail}
        isLoading={isLoading}
        isDisplayMap={shouldDisplayMap}
      />
      {shouldDisplayMap ? (
        <Map
          initialViewState={initialViewState}
          subtractFromHeightOnResize={16}
        >
          <Marker
            latitude={initialViewState.latitude}
            longitude={initialViewState.longitude}
            iconType="broadcast"
            size="large"
            label={facilityDetail.name}
          />
        </Map>
      ) : null}
    </div>
  );
};

FacilityDetailOverviewTab.propTypes = {
  facilityDetail: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
};

export default FacilityDetailOverviewTab;
