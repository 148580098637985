import { RecordValueDetailsPanel } from "#batteries-included-components/Panels/DetailsPanel/RecordValueDetailsPanel";
import { RecordValueSourcesTablePanel } from "#batteries-included-components/Panels/TablePanels/RecordValueSourcesTablePanel";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useParams } from "#routers/hooks";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import { EQUIPMENT_LIST_BREADCRUMB } from "#routes/organization/equipment";
import { EQUIPMENT_DETAIL_BREADCRUMB } from "#routes/organization/equipment/[equipmentId]";
import {
  EQUIPMENT_RECORD_DETAIL_BREADCRUMB,
  EQUIPMENT_RECORD_DETAIL_PAGE_TITLE,
} from "#routes/organization/equipment/[equipmentId]/record-details/[recordId]";
import BulkRecordValueStatusChangeButton from "#src/batteries-included-components/Buttons/BulkRecordValueStatusChangeButton/BulkRecordValueStatusChangeButton";
import ReportingGroupPillPanel from "#src/batteries-included-components/Panels/PillPanels/ReportingGroupPillPanel/ReportingGroupPillPanel";
import { RecordValueSummaryPanel } from "#src/batteries-included-components/Panels/SummaryPanels/RecordValueSummaryPanel/RecordValueSummaryPanel";
import { RecordValueActivityLogTablePanel } from "#src/batteries-included-components/Panels/TablePanels/RecordValueActivityLogTablePanel";
import {
  EquipmentContext,
  EquipmentProvider,
} from "#src/contexts/EquipmentContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useQuery } from "@tanstack/react-query";
import { Column, Page, Row } from "@validereinc/common-components";
import { AssetType, MeasurementsDomain, Resources } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import {
  EQUIPMENT_RECORD_VALUE_DETAIL_BREADCRUMB,
  EQUIPMENT_RECORD_VALUE_DETAIL_PAGE_TITLE,
  EquipmentRecordValuePageParamsType,
} from "./index";
import { RecordValueStatusPanel } from "#src/batteries-included-components/Panels/SummaryPanels/RecordValueStatusPanel";

const EquipmentRecordValueDetailPageContent = () => {
  const params = useParams<EquipmentRecordValuePageParamsType>();

  const { equipment } = useContext(EquipmentContext) || {};
  const { record, refetch } = useContext(RecordContext) || {};

  const { data: measurementTypes } = useQuery({
    queryKey: ["measurementTypes"],
    queryFn: MeasurementsDomain.listMeasurementTypes,
  });
  const measurementType = measurementTypes?.find(
    ({ id }) => id === params.measurementType
  );

  const recordPageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : EQUIPMENT_RECORD_DETAIL_PAGE_TITLE;

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} ${measurementType?.name}`
    : EQUIPMENT_RECORD_VALUE_DETAIL_PAGE_TITLE;

  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      EQUIPMENT_LIST_BREADCRUMB,
      EQUIPMENT_DETAIL_BREADCRUMB,
      EQUIPMENT_RECORD_DETAIL_BREADCRUMB,
      EQUIPMENT_RECORD_VALUE_DETAIL_BREADCRUMB,
    ],
    {
      2: equipment?.name,
      3: recordPageTitle,
      4: pageTitle,
    }
  );

  const recordValue = record?.values.find(
    ({ measurement_type }) => measurement_type === measurementType?.id
  );

  const tableKeys = useStorageKey(
    `activity-logs-${Resources.RECORD_VALUE}-${recordValue?.id}`
  );

  return (
    <Page
      category="Equipment Record"
      title={pageTitle}
      breadcrumbs={breadcrumbs}
      actionRow={
        <BulkRecordValueStatusChangeButton
          recordId={record?.id ?? ""}
          measurementTypes={[recordValue?.measurement_type ?? ""]}
          onSuccess={() => {
            refetch?.();
          }}
          variant="primary"
          label="Change Record Value Status"
        />
      }
    >
      <Row>
        <Column variant={6}>
          <RecordValueStatusPanel />
          <ReportingGroupPillPanel
            id={record?.reporting_group_id}
            isLoading={!record}
          />
        </Column>
        <Column variant={18}>
          <RecordValueSummaryPanel />
          <RecordValueDetailsPanel />
          <RecordValueSourcesTablePanel type={AssetType.EQUIPMENT} />
          <RecordValueActivityLogTablePanel
            recordId={record?.id ?? ""}
            measurement_type={recordValue?.measurement_type}
            {...tableKeys}
          />
        </Column>
      </Row>
    </Page>
  );
};

export const EquipmentRecordValueDetailPage = () => (
  <EquipmentProvider>
    <RecordProvider>
      <EquipmentRecordValueDetailPageContent />
    </RecordProvider>
  </EquipmentProvider>
);
