import {
  useGetOneEquipment,
  useListEquipmentTypes,
  useUpdateEquipment,
} from "#src/components/hooks/adapters/useEquipment";
import { EQUIPMENT_STATUS_OPTIONS } from "#src/constants";
import useLocalization from "#src/hooks/useLocalization";
import { AssetType } from "@validereinc/domain";
import React from "react";
import AssetEditDialog from "./AssetEditDialog/AssetEditDialog";
import { AssetEditDialogPropTypes } from "./AssetEditDialog/types";

const EditEquipmentDialog = (props: {
  equipmentId?: string;
  defaultPeriod: string;
  onClose: () => void;
  onEdit?: () => void;
}) => {
  const { localize } = useLocalization();

  const equipmentTypesQuery = useListEquipmentTypes({});

  const { data: assetDetails } = useGetOneEquipment(props.equipmentId, {
    period: props.defaultPeriod,
  });
  const updateEquipmentMutation = useUpdateEquipment({
    onSuccess: () => {
      props.onEdit?.();
      props.onClose();
    },
    successMessage: `Successfully updated ${localize("equipment")} attribute(s)`,
  });

  const isUpdating = updateEquipmentMutation.isLoading;
  const isLoading = equipmentTypesQuery.isLoading || isUpdating;

  const primaryFields: AssetEditDialogPropTypes["primaryFields"] = [
    {
      display_name: localize("facility"),
      field_name: "facility_id",
      data_type: "lookup",
      lookup_entity_type: AssetType.FACILITY,
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: `${localize("equipment")} name`,
      field_name: "name",
      data_type: "string",
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: "Status",
      field_name: "status",
      data_type: "pick-list",
      pick_list_values: EQUIPMENT_STATUS_OPTIONS.map((option) => ({
        id: option.value,
        name: option.label,
      })),
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: `${localize("equipment")} type`,
      field_name: "type",
      data_type: "pick-list",
      pick_list_values: equipmentTypesQuery.data?.data ?? [],
      is_required: true,
      is_state_managed: false,
    },
  ];

  return (
    <AssetEditDialog
      primaryFields={primaryFields}
      assetId={props.equipmentId}
      assetType={AssetType.EQUIPMENT}
      dialogProps={{ title: "Edit Equipment Attributes" }}
      onClose={() => {
        if (isUpdating) return;
        props.onClose();
      }}
      isLoading={isLoading}
      onUpdate={(newValues) =>
        updateEquipmentMutation.mutate({
          id: props.equipmentId ?? "",
          data: newValues,
        })
      }
      assetDetails={assetDetails}
      hasGeoPointField
    />
  );
};

export default EditEquipmentDialog;
