import { Button, Dialog, useAlert } from "@validereinc/common-components";
import React from "react";

export const DeleteSectionDialog = ({
  isOpen,
  onClose,
  handleDeleteSection,
  sectionIndexToDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleDeleteSection: (sectionIndex: number) => void;
  sectionIndexToDelete: number;
}) => {
  const DELETE_SECTION_DIALOG_TITLE = "Delete Section?";
  const DELETE_SECTION_DIALOG_DESCRIPTION =
    "This will delete all associated questions and any filter and conditional logic associated to questions in this section.";

  const { addAlert } = useAlert();

  return (
    <Dialog
      title={DELETE_SECTION_DIALOG_TITLE}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      actionRow={[
        <Button
          key="delete-section-action"
          variant="error"
          onClick={() => {
            handleDeleteSection(sectionIndexToDelete);
            onClose();
            addAlert?.({
              variant: "success",
              message: "Successfully deleted section.",
            });
          }}
        >
          Delete
        </Button>,
      ]}
    >
      {DELETE_SECTION_DIALOG_DESCRIPTION}
    </Dialog>
  );
};
