import {
  EntityDropdownInput,
  BaseEntityDropdownProps,
} from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import { FormSchemaAdapter } from "@validereinc/domain";
import React from "react";

export const FormSchemaDropdownInput = ({
  name,
  label,
  ...props
}: BaseEntityDropdownProps) => {
  return (
    <EntityDropdownInput
      fetchEntityList={FormSchemaAdapter.getList}
      label={label ?? "Form Template"}
      name={name}
      sort={{
        sortBy: "name",
        sortDirection: "asc",
      }}
      {...props}
    />
  );
};
