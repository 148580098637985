import {
  useCreateOne,
  useDeleteOne,
  useGetList,
  useUpdateOne,
} from "#hooks/adapters/adapterUtils";
import {
  MeasurementSeriesAdapter,
  MeasurementSeriesType,
  Resources,
} from "@validereinc/domain";

export const CUSTOM_ATTRIBUTE_KEY = Resources.MEASUREMENT_SERIES;

export const useListMeasurementSeries = useGetList(
  MeasurementSeriesAdapter.getList,
  CUSTOM_ATTRIBUTE_KEY
);
export const useCreateMeasurementSeries = useCreateOne<MeasurementSeriesType>(
  MeasurementSeriesAdapter.createOne,
  CUSTOM_ATTRIBUTE_KEY
);
export const useUpdateMeasurementSeries = useUpdateOne<MeasurementSeriesType>(
  MeasurementSeriesAdapter.updateOne,
  CUSTOM_ATTRIBUTE_KEY
);
export const useDeleteMeasurementSeries = useDeleteOne<MeasurementSeriesType>(
  MeasurementSeriesAdapter.deleteOne,
  CUSTOM_ATTRIBUTE_KEY
);
