import { CalculationsRoutePath } from "#src/routes/calculations";
import { RoutePath } from "#src/utils/route";

export * from "./EstimationMethodListPage";

export const CalculationsEstimationMethodsRoutePath =
  CalculationsRoutePath.concat(
    new RoutePath({
      path: "/estimation-methods",
      title: "Estimation Methods",
    })
  );
