import { AssetType } from "@validereinc/domain";

const flowGroupBy = [
  "id",
  "reporting_group_id",
  "year_month",
  "flow.id",
  "flow.name",
  "flow.type",
];

const flowExportGroupBy = [
  "reporting_group_name",
  "year_month",
  "flow.name",
  "flow.type",
  "flow.associated_facility.name",
  "record_value.note",
];
const equipmentGroupBy = [
  "id",
  "reporting_group_id",
  "year_month",
  "equipment.id",
  "equipment.name",
  "equipment.status",
  "equipment.type.name",
];

const equipmentExportGroupBy = [
  "reporting_group_name",
  "year_month",
  "equipment.facility.name",
  "equipment.name",
  "equipment.status",
  "equipment.type.name",
  "record_value.note",
];

const facilityGroupBy = [
  "id",
  "reporting_group_id",
  "year_month",
  "facility.id",
  "facility.name",
];

const facilityExportGroupBy = [
  "reporting_group_name",
  "year_month",
  "facility.name",
  "record_value.note",
];

export const getGroupBy = (type: string) => {
  switch (type) {
    case AssetType.FACILITY:
      return facilityGroupBy;
    case AssetType.EQUIPMENT:
      return equipmentGroupBy;
    case AssetType.FLOW:
    default:
      return flowGroupBy;
  }
};

export const getExportGroupBy = (type: string) => {
  switch (type) {
    case AssetType.FACILITY:
      return facilityExportGroupBy;
    case AssetType.EQUIPMENT:
      return equipmentExportGroupBy;
    case AssetType.FLOW:
    default:
      return flowExportGroupBy;
  }
};

export const formatStatusFilter = (status: string | string[]) =>
  Array.isArray(status) ? status : { $exact: status };
