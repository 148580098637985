import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { ActivityLogsDrawer } from "#src/batteries-included-components/Drawers/ActivityLogsDrawer";
import { DEFAULT_QUERY_OPTIONS } from "#src/components/hooks/adapters/adapterUtils";
import {
  useEnhanceRecordResources,
  useGetOneRecord,
} from "#src/components/hooks/adapters/useRecords";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { getPropertyAsMap } from "#src/utils/objectFormatter";
import {
  renderRecordValueAttachment,
  renderRecordValueConfigurationType,
  renderRecordValueNote,
  renderRecordValueSource,
  renderRecordValueSourceType,
  renderRecordValueStatus,
  useRenderRecordValue,
} from "#src/utils/recordUtils";
import { UseQueryOptions, useQueries, useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  ActivitiesDomain,
  ActivityResource,
  ActivitySchema,
  ActivityType,
  RecordType,
  RecordValueType,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { ClockClockwise } from "phosphor-react";
import React, { useMemo, useState } from "react";

export const RecordValueActivityLogTablePanel = ({
  recordId,
  measurement_type = "",
  tableConfigStorageKey,
  filterConfigStorageKey,
}: {
  measurement_type?: string;
  recordId: string;
} & StorageKeys) => {
  const [filters] = useFilters<
    Parameters<typeof ActivitiesDomain.getList>[0]["filters"]
  >(filterConfigStorageKey);

  const recordQuery = useGetOneRecord({ recordId });
  const isRecordLoading = recordQuery.isLoading;
  const record = recordQuery.data;

  const recordValue = record?.values?.find(
    (rv) => rv.measurement_type === measurement_type
  );

  const resourceId = recordValue?.id;

  const [selectedLog, setSelectedLog] = useState<ActivityType | null>(null);
  const initialSorting: SortingType = {
    sortBy: "timestamp",
    sortDirection: "desc",
  };
  const [tableState, setTableState] = useTableSortingAndPagination(
    initialSorting,
    filters
  );

  const { getTypeName } = useMeasurementTypes();

  const activitiesQueryPayload = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...filters,
      ...(filters?.resource_id || resourceId
        ? { resource_id: filters?.resource_id ?? resourceId }
        : {}),
    },
    meta: { resourceType: ActivityResource.RECORD_VALUE },
  } satisfies Parameters<typeof ActivitiesDomain.getList>[0];

  const isActivitiesQueryEnabled = !!resourceId;

  const activitiesQuery = useQuery(
    ["activities", activitiesQueryPayload],
    () => ActivitiesDomain.getList(activitiesQueryPayload),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: isActivitiesQueryEnabled,
    }
  );
  const associatedUsersQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      activitiesQuery.data?.data.map((activity) => ({
        queryKey: ["users", activity.author_id],
        queryFn: () => {
          if (!activity.author_id) {
            return;
          }

          return UsersAdapter.getOne({ id: activity.author_id });
        },
        enabled: Boolean(activity.author_id),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp?.data,
      })) ?? [],
  });

  const authorIdsToUserDetailsMap = useMemo(() => {
    return getPropertyAsMap(
      associatedUsersQueries,
      "data",
      "data.id"
    ) as Record<string, UserType>;
  }, [associatedUsersQueries]);

  const activityModelKeys = ActivitySchema.keyof().Enum;

  const renderRecordValue = useRenderRecordValue();

  const headers: Array<HeaderType<ActivityType<RecordValueType>>> = [
    {
      label: "Record Value",
      key: "value",
      renderComponent: ({ item }) => renderRecordValue(item.after),
    },
    {
      label: "Updated At",
      key: activityModelKeys.timestamp,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.timestamp}
          withTime={true}
        />
      ),
    },
    {
      label: "Updated By",
      key: activityModelKeys.author_id,
      renderComponent: ({ item }) =>
        authorIdsToUserDetailsMap[item[activityModelKeys.author_id]]?.name ??
        "-",
    },
    {
      label: "Source Type",
      key: "source_type",
      renderComponent: ({ item }) => renderRecordValueSourceType(item.after),
    },
    {
      label: "Status",
      key: "status",
      renderComponent: ({ item }) => renderRecordValueStatus(item.after),
    },
    {
      label: "Source",
      key: "source",
      renderComponent: ({ item }) => renderRecordValueSource(item.after),
    },
    {
      label: "Configuration Type",
      key: "config_type",
      renderComponent: ({ item }) =>
        renderRecordValueConfigurationType(item.after),
    },
    {
      label: "Note",
      key: "note",
      renderComponent: ({ item }) => renderRecordValueNote(item.after),
    },
    {
      label: "Attachment",
      key: "attachment",
      renderComponent: ({ item }) => renderRecordValueAttachment(item.after),
    },
  ];

  const rawItems = activitiesQuery.data?.data ?? [];

  // All entries of the activity logs should be "enhanced" to have calculation estimation_method_entity_id.
  // To do that, a dummy record is created and all the activity logs are attached to it as its values,
  // Then it gets sent to be enhanced:

  const dummyRecord = {
    ...record,
    values: rawItems.map((i) => i.after),
  } as RecordType;

  const {
    recordOrRecords: enhancedDummyRecord,
    isLoading: isEnhancingActivityLogs,
  } = useEnhanceRecordResources(dummyRecord, { enabled: rawItems.length > 0 });

  const items = rawItems.map((item, index) => ({
    ...item,
    after: enhancedDummyRecord?.values[index],
  }));

  const isLoading =
    (isActivitiesQueryEnabled && activitiesQuery.isLoading) ||
    (isRecordLoading && !!recordId) ||
    (isEnhancingActivityLogs && rawItems.length > 0);

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        dataTableProps={{
          headers: items.length ? headers : [],
          items,
          isLoading,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: activitiesQuery.data?.total_entries ?? 0,
            entityPerPage: "Logs per page:",
          },
          onSortChange: setTableState,
          onPaginationChange: setTableState,
          emptyStateProps: {
            title: measurement_type
              ? `No activities recorded for ${getTypeName(measurement_type)}`
              : "",
            suggestion: "Changes will be listed here",
            icon: <ClockClockwise />,
          },
        }}
        panelProps={{
          title: "Change Log",
          isFluidY: false,
        }}
      />
      <ActivityLogsDrawer
        logDetails={selectedLog}
        onClose={() => setSelectedLog(null)}
      />
    </>
  );
};
