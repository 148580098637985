import {
  useCreateOne,
  useDeleteOne,
  useGetList,
  useGetOne,
  useUpdateOne,
} from "#src/components/hooks/adapters/adapterUtils";
import { useMutation } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import {
  FormSubmissionAdapter,
  FormSubmissionFilterType,
  FormSubmissionType,
  GetListRequestType,
  Resources,
} from "@validereinc/domain";
import { downloadLink } from "@validereinc/utilities";
import { useState } from "react";
import pLimit from "p-limit";

export const useGetOneFormSubmission = useGetOne<FormSubmissionType>(
  FormSubmissionAdapter.getOne,
  Resources.FORM_SUBMISSION
);

export const useListFormSubmissions = useGetList<FormSubmissionType>(
  FormSubmissionAdapter.getList,
  Resources.FORM_SUBMISSION
);

export const useDeleteOneFormSubmission = useDeleteOne<FormSubmissionType>(
  FormSubmissionAdapter.deleteOne,
  Resources.FORM_SUBMISSION
);

export const useCreateOneFormSubmission = useCreateOne<FormSubmissionType>(
  FormSubmissionAdapter.createOne,
  Resources.FORM_SUBMISSION
);

export const useUpdateOneFormSubmission = useUpdateOne<FormSubmissionType>(
  FormSubmissionAdapter.updateOne,
  Resources.FORM_SUBMISSION
);

// Exports all form submissions for a template. This is different from useForms useExportFormSubmission
export const useExportFormSubmissions = (
  apiParams: GetListRequestType<FormSubmissionFilterType> = {}
) => {
  const { addAlert } = useAlert();

  return useMutation({
    mutationFn: async () => {
      const report = await FormSubmissionAdapter.exportList?.(apiParams);
      if (report) downloadLink(report.s3_download_link);
      else throw new Error();
    },
    onError: (error) => {
      console.error(error);
      addAlert?.({
        variant: "error",
        message: "Unable to export form submissions",
      });
    },
  });
};

export const useBulkDeleteFormSubmissions = () => {
  const { addAlert } = useAlert();

  const [selectedFormSubmissions, setSelectedFormSubmissions] = useState<
    Record<string, FormSubmissionType>
  >({});
  const [isDeleting, setIsDeleting] = useState(false);

  const formSubmissionsToDeleteCount = Object.keys(
    selectedFormSubmissions
  ).length;

  const deleteMutation = useDeleteOneFormSubmission({
    noAlerts: true,
  });

  const handleBulkDelete = async () => {
    setIsDeleting(true);

    const limit = pLimit(8);

    const promises = Object.keys(selectedFormSubmissions).map(
      async (selectedFormSubmissionId) =>
        limit(async () => {
          const result = await deleteMutation.mutateAsync({
            id: selectedFormSubmissionId,
          });
          return { ...result, selectedFormSubmissionId };
        })
    );

    const results = await Promise.allSettled(promises);

    const allDeletionsSuccessful = results.every(
      (result) => result.status === "fulfilled"
    );
    if (allDeletionsSuccessful) {
      addAlert?.({
        variant: "success",
        message: `Successfully deleted ${formSubmissionsToDeleteCount} submissions`,
      });
    } else {
      addAlert?.({
        variant: "error",
        message: `Failed to delete some submission(s)`,
      });
    }

    setSelectedFormSubmissions((prevSelectedFormSubmissions) => {
      const newSelectedFormSubmissions = {
        ...prevSelectedFormSubmissions,
      };

      Object.keys(selectedFormSubmissions).forEach(
        (selectedFormSubmissionId) => {
          if (
            results.find(
              (result) =>
                result.status === "fulfilled" &&
                result.value.selectedFormSubmissionId ===
                  selectedFormSubmissionId
            )
          ) {
            delete newSelectedFormSubmissions[selectedFormSubmissionId];
          }
        }
      );

      return newSelectedFormSubmissions;
    });

    setIsDeleting(false);
  };

  return {
    selectedFormSubmissions,
    setSelectedFormSubmissions,
    isDeleting,
    handleBulkDelete,
  };
};
