import { RecordFlowSummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/RecordFlowSummaryPanel";
import { RecordSummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/RecordSummaryPanel";
import { RecordValueTablePanel } from "#batteries-included-components/Panels/TablePanels/RecordValueTablePanel";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import ReportingGroupPillPanel from "#src/batteries-included-components/Panels/PillPanels/ReportingGroupPillPanel/ReportingGroupPillPanel";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Column, Page, Row } from "@validereinc/common-components";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import { FlowRecordDetailRoute } from "./index";
import useLocalization from "#src/hooks/useLocalization";

const FlowRecordDetailPageContent = () => {
  const { localize } = useLocalization();
  const { filterConfigStorageKey } = useStorageKey("flow-detail-filters");
  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });
  const { record } = useContext(RecordContext) ?? {};

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FlowRecordDetailRoute.title;

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowRecordDetailRoute, {
    detail: { title: flow?.name },
    recordId: { title: pageTitle },
  });

  return (
    <Page
      category={`${localize("Flow")} Record`}
      title={pageTitle}
      breadcrumbs={breadcrumbs}
    >
      <Row>
        <Column variant={6}>
          <RecordSummaryPanel />
          <ReportingGroupPillPanel
            id={record?.reporting_group_id}
            isLoading={!record}
          />
        </Column>
        <Column variant={18}>
          <RecordFlowSummaryPanel
            filterConfigStorageKey={filterConfigStorageKey}
          />
          <RecordValueTablePanel />
        </Column>
      </Row>
    </Page>
  );
};

export const FlowRecordDetailPage = () => (
  <RecordProvider>
    <FlowRecordDetailPageContent />
  </RecordProvider>
);
