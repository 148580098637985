import { EstimationMethodsTab } from "#batteries-included-components/Tabs/EstimationMethod/ListTab";
import { useNavigate, useParams } from "#routers/hooks";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { FlowCreateEstimationMethodRoute } from "#routes/organization/flows/[flowId]/detail/create-estimation-method";
import { FlowEstimationMethodDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]";
import { FlowDetailParamsType } from "#routes/organization/flows/[flowId]/detail/index";
import { StorageKeys, Tab } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React from "react";

export const FlowEstimationMethodsTab = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const navigate = useNavigate();
  const { flowId } = useParams<FlowDetailParamsType>();
  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });

  return (
    <Tab
      title="Estimation Methods"
      tabKey="estimation_methods"
    >
      {flow ? (
        <EstimationMethodsTab
          isWithinEntityDetail
          entityId={flow?.id}
          entityType={AssetType.FLOW}
          onAdd={() =>
            navigate(
              FlowCreateEstimationMethodRoute.toLinkParts({
                pathParams: { flowId },
              })
            )
          }
          onRowClick={({ item: { id } }) =>
            navigate(
              FlowEstimationMethodDetailRoute.toLinkParts({
                pathParams: { flowId, estimationMethodId: id },
              })
            )
          }
        />
      ) : null}
    </Tab>
  );
};
