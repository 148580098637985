import {
  Accordion,
  Button,
  DropdownInput,
  Panel,
  RadioInput,
  useFormContext,
} from "@validereinc/common-components";
import {
  FormSchemaQuestionType,
  type FormSchemaNormalQuestionType,
  type FormSchemaQuestionTypeType,
} from "@validereinc/domain";
import React, { useEffect } from "react";
import { QuestionFilterDropdown } from "./QuestionFilterDropdown";
import { QuestionFilterStaticInput } from "./QuestionFilterStaticInput";

const { AccordionPanel } = Accordion;

export const LookupQuestionFilter = ({
  defaultField,
  name,
  questions,
  id,
  index,
  onRemove,
  lookupEntityType,
}: {
  defaultField: {
    attribute: string;
    filterType: string;
    referenceQuestion?: string;
  };
  name: string;
  questions: Array<
    Record<
      string,
      {
        type: FormSchemaQuestionTypeType;
        data_type?: FormSchemaNormalQuestionType["data_type"];
      }
    >
  >;
  id: string;
  index: number;
  onRemove: (index: number) => void;
  lookupEntityType: "facility" | "device" | "flow" | "equipment";
}) => {
  const { watch, setValue } = useFormContext();

  const filterType = watch(`${name}.filterType`);
  const attribute = watch(`${name}.attribute`);
  const referenceQuestion = watch(`${name}.referenceQuestion`);

  useEffect(() => {
    setValue(`${name}.filterType`, defaultField.filterType);
    setValue(`${name}.attribute`, defaultField.attribute);
    setValue(`${name}.referenceQuestion`, defaultField.referenceQuestion);
  }, [defaultField]);

  const referencedQuestion = questions.find((question) => {
    return question.question_id === referenceQuestion;
  });

  return (
    <Panel>
      <Accordion defaultActiveKeys={[id]}>
        <AccordionPanel
          dataKey={id}
          title={`Filter ${index + 1}`}
          actionRow={[
            <Button
              key={`remove-filter-${id}`}
              icon="trash"
              onClick={() => {
                onRemove(index);
              }}
              variant="error-outline"
            />,
          ]}
        >
          <>
            <QuestionFilterDropdown
              variant={lookupEntityType}
              name={`${name}.attribute`}
            />

            {questions?.length ? (
              <>
                <RadioInput
                  label="Filterable Value Source"
                  name={`${name}.filterType`}
                  isOptionalTextShown={false}
                  labelKey="label"
                  valueKey="value"
                  options={[
                    { label: "Define manually", value: "static" },
                    {
                      label: "Reference previous question",
                      value: "dynamic",
                    },
                  ]}
                />

                {filterType === "dynamic" ? (
                  <>
                    <DropdownInput
                      name={`${name}.referenceQuestion`}
                      options={questions}
                      isOptionalTextShown={false}
                      label={"Question from which to derive source values"}
                      labelKey="label"
                      valueKey="question_id"
                    />
                    {referencedQuestion?.type ===
                      FormSchemaQuestionType.MEASUREMENT ||
                    [
                      "string",
                      "number",
                      "integer",
                      "date",
                      "date-time",
                      "multi-pick-list",
                    ].includes(referencedQuestion?.data_type) ? (
                      <DropdownInput
                        label="Comparator"
                        name={`${name}.operation`}
                        options={[
                          { label: "=", value: "$eq" },
                          ...(referencedQuestion?.data_type === "string"
                            ? [{ label: "⊂", value: "$like" }]
                            : []),
                          ...(referencedQuestion?.type ===
                            FormSchemaQuestionType.MEASUREMENT ||
                          ["number", "integer", "date", "date-time"].includes(
                            referencedQuestion?.data_type
                          )
                            ? [
                                { label: ">", value: "$gt" },
                                { label: ">=", value: "$gte" },
                                { label: "<", value: "$lt" },
                                { label: "<=", value: "$lte" },
                              ]
                            : []),
                          ...(referencedQuestion?.data_type ===
                          "multi-pick-list"
                            ? [{ label: "∈", value: "$in" }]
                            : []),
                        ]}
                        labelKey="label"
                        valueKey="value"
                        isRequired
                        isFluid
                        defaultValue={"$eq"}
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}

            {filterType === "static" ? (
              <QuestionFilterStaticInput
                name={`${name}`}
                variant={lookupEntityType}
                attribute={attribute}
              />
            ) : null}
          </>
        </AccordionPanel>
      </Accordion>
    </Panel>
  );
};
