import { RoutePath } from "#src/utils/route";
import { DataSubmissionsRoutePath } from "..";

export { ConfigurationTemplateRunsListTab } from "./ConfigurationTemplateRunsListTab";

export const ConfigurationTemplateSubmissionsRoutePath =
  DataSubmissionsRoutePath.concat(
    new RoutePath({
      path: "/configuration-templates",
      title: "Configuration Template Submissions",
      isPresentational: true,
    })
  );
