import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import {
  ResourcesSchema,
  type FilterObjectType,
  type ResourceType,
} from "../util";
import {
  FormSchemaMeasurementQuestionSchema,
  FormSchemaQuestionAnswerSchema,
  FormSchemaSchema,
} from "./FormSchemaSchemas";
import { FormSubmissionStatus } from "./FormSubmissionSchemas.shared";

export const FormSubmissionNormalQuestionAnswerSchema = z.object({
  value: FormSchemaQuestionAnswerSchema,
});
export type FormSubmissionNormalQuestionAnswerType = z.infer<
  typeof FormSubmissionNormalQuestionAnswerSchema
>;

export const FormSubmissionMeasurementQuestionAnswerSchema = z
  .object({
    source_id: z.string().optional(),
    subject_id: z.string().optional(),
    subject_type: ResourcesSchema.optional(),
    time: z.string(),
    value: FormSchemaQuestionAnswerSchema,
  })
  .merge(
    FormSchemaMeasurementQuestionSchema.pick({
      measurement_type: true,
      measurement_unit: true,
    }).required()
  );
export type FormSubmissionMeasurementQuestionAnswerType = z.infer<
  typeof FormSubmissionMeasurementQuestionAnswerSchema
>;

export const FormSubmissionAnswerSchema = z.union([
  FormSubmissionNormalQuestionAnswerSchema,
  FormSubmissionMeasurementQuestionAnswerSchema,
]);
export type FormSubmissionAnswerType = z.infer<
  typeof FormSubmissionAnswerSchema
>;

export const FormSubmissionAnswersByQuestionMapSchema = z.record(
  FormSubmissionAnswerSchema
);
export type FormSubmissionAnswersByQuestionMapType = z.infer<
  typeof FormSubmissionAnswersByQuestionMapSchema
>;

export const FormSubmissionAnswersBySectionMapSchema = z.record(
  z.array(FormSubmissionAnswersByQuestionMapSchema)
);
export type FormSubmissionAnswersBySectionMapType = z.infer<
  typeof FormSubmissionAnswersBySectionMapSchema
>;

export const FormSubmissionSchema = z
  .object({
    company_id: z.string().uuid(),
    form_schema_id: z.string().uuid(),
    form_schema_version: z.number().int(),
    form_schema: FormSchemaSchema.optional(),
    status: z.nativeEnum(FormSubmissionStatus),
    answers: FormSubmissionAnswersBySectionMapSchema,
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a Form Submission in the Node API.");
export type FormSubmissionType = z.infer<typeof FormSubmissionSchema>;

export type FormSubmissionFilterType = FilterObjectType<{
  created_at?: string;
  status?: FormSubmissionType["status"];
  "form_category.id"?: string;
  "form_category.name"?: string;
  "form_schema.id"?: string;
  related_entity_id?: string;
  related_entity_type?: ResourceType;
}>;

export const FormSubmissionWithAnswersSchema = z
  .object({
    company_id: z.string().uuid(),
    form_schema_id: z.string().uuid(),
    form_schema_version: z.number().int(),
    status: z.nativeEnum(FormSubmissionStatus),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema.pick({ created_by: true }))
  .describe(
    "Represents a Form Submission with answers for a particular Form Schema version in the Node API."
  );
export type FormSubmissionWithAnswersType = z.infer<
  typeof FormSubmissionWithAnswersSchema
> &
  Record<`${string}:${string}`, string | number | boolean>;

export const FormSubmissionAnswerInFormSchema = z.object({
  value: FormSchemaQuestionAnswerSchema,
});
export type FormSubmissionAnswerInFormType = z.infer<
  typeof FormSubmissionAnswerInFormSchema
>;

export const FormSubmissionAnswersInFormByQuestionMapSchema = z.record(
  FormSubmissionAnswerInFormSchema
);
export type FormSubmissionAnswersInFormByQuestionMapType = z.infer<
  typeof FormSubmissionAnswersInFormByQuestionMapSchema
>;

export const FormSubmissionAnswersInFormBySectionMapSchema = z.record(
  z.array(FormSubmissionAnswersInFormByQuestionMapSchema)
);
export type FormSubmissionAnswersInFormBySectionMapType = z.infer<
  typeof FormSubmissionAnswersInFormBySectionMapSchema
>;

export const FormSubmissionAnswersInFormSchema =
  FormSubmissionAnswersInFormBySectionMapSchema;
export type FormSubmissionAnswersInFormType = z.infer<
  typeof FormSubmissionAnswersInFormSchema
>;
