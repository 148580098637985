import classNames from "classnames/bind";
import React from "react";
import { UserDetailStatusPanel } from "../../Panels/DetailsPanel/UserDetailStatusPanel";
import { UserDetailsPanel } from "../../Panels/DetailsPanel/UserDetailsPanel";
import styles from "./UserSummaryTab.module.scss";
import { UserGroupMembershipTablePanel } from "#src/batteries-included-components/Panels/TablePanels/UserGroupMembershipTablePanel";

const cx = classNames.bind(styles);

export const UserSummaryTab = () => {
  return (
    <div className={cx("container")}>
      <div className={cx("contentStatus")}>
        <UserDetailStatusPanel />
      </div>

      <div className={cx("contentContainer")}>
        <UserDetailsPanel />
        <UserGroupMembershipTablePanel />
      </div>
    </div>
  );
};
