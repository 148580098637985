import { useEffect, useMemo } from "react";
import moment from "moment";
import { useSearchParams } from "#routers/hooks";

export const DEFAULT_DATE_RANGES = {
  currentWholeMonth: {
    from: moment().startOf("month").toDate(),
    to: moment().endOf("month").toDate(),
  },
  lastWholeMonth: {
    from: moment().subtract(1, "months").startOf("month").toDate(),
    to: moment().subtract(1, "months").endOf("month").toDate(),
  },
  lastThreeMonths: {
    from: moment().subtract(3, "months").startOf("month").toDate(),
    to: moment().endOf("month").toDate(),
  },
  currentWholeYear: {
    from: moment().startOf("month").subtract(11, "months").toDate(),
    to: moment().endOf("month").toDate(),
  },
  lastTwelveMonths: {
    from: moment().startOf("month").subtract(1, "year").toDate(),
    to: moment().endOf("month").toDate(),
  },
  last30Days: {
    from: moment().subtract(30, "days").toDate(),
    to: moment().endOf("day").toDate(),
  },
  // add more common default ranges
};

/**
 * Date range get value and set func, hooked up to search params
 * @example
 * // use predefined default date range
 * const [dateRange, setDateRange] = useDateRange(DEFAULT_DATE_RANGES.currentWholeMonth)
 * @example
 * // use custom default date range
 * const defaultDateRange = { from: moment().startOf("week").toDate(), to: moment().endOf("week").toDate() }
 * const [dateRange, setDateRange] = useDateRange(defaultDateRange)
 * @returns [dateRange: {from, to}, onDateRangeChange: function].
 */

export default function useDateRange(defaultDateRange) {
  const [searchParams, setSearchParams] = useSearchParams();

  const onDateRangeChange = ({ from, to }) => {
    setSearchParams({
      ...searchParams,
      from,
      to,
    });
  };

  useEffect(() => {
    if (!searchParams?.from || !searchParams?.to) {
      setSearchParams({
        ...searchParams,
        from: searchParams.from || defaultDateRange.from,
        to: searchParams.to || defaultDateRange.to,
      });
    }
  }, [searchParams]);

  const dateRange = useMemo(() => {
    if (searchParams?.from && searchParams?.to) {
      return {
        from: new Date(searchParams.from),
        to: new Date(searchParams.to),
      };
    }

    return defaultDateRange;
  }, [searchParams]);

  return [dateRange, onDateRangeChange];
}
