import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToCreateFacility } from "#routers/links";
import {
  FacilityTablePanel,
  RecordsListTablePanel,
} from "#src/batteries-included-components";
import { ApplyAutomationProgressBanner } from "#src/batteries-included-components/Banners/ApplyAutomationProgressBanner";
import {
  FacilityRecordsTableFilterArea,
  FacilityRecordsViewFilterArea,
} from "#src/batteries-included-components/FilterAreas/RecordsFilterAreas";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Button, Page, Tab } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React from "react";
import { FacilitiesListRoute } from "./";

const DEFAULT_TAB_KEY = "list";

export const FacilityListPage = () => {
  const { localize, isLoading } = useLocalization();
  const [breadcrumbs] = useBreadcrumbsFromRoute(FacilitiesListRoute);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const assetsSharedStorageKeys = useStorageKey("assets-shared");
  const calcsAndRecordsSharedStorageKeys = useStorageKey(
    "calculations-records-shared"
  );
  const storageKeys = useStorageKey("facilities");
  const recordStorageKeys = useStorageKey("facilities-records");
  const recordsApplyAutomationStorageKey = useStorageKey(
    "facilities-records-apply-automation"
  ).tableConfigStorageKey;

  const handleOnCreateFacilityClick = () => {
    navigate({
      pathname: linkToCreateFacility(),
    });
  };

  const actionRow = (
    <Button
      variant="primary"
      onClick={handleOnCreateFacilityClick}
    >
      Create {localize("facility")}
    </Button>
  );

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={`${localize(FacilitiesListRoute.title)}`}
      actionRow={actionRow}
      isLoading={isLoading}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title="List"
        tabKey="list"
      >
        <FacilityTablePanel
          {...storageKeys}
          viewConfigStorageKey={assetsSharedStorageKeys.viewConfigStorageKey}
        />
      </Tab>
      <Tab
        title="Records"
        tabKey="records"
      >
        <ApplyAutomationProgressBanner
          jobStorageKey={recordsApplyAutomationStorageKey}
        />
        <FacilityRecordsViewFilterArea
          viewConfigStorageKey={
            calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
          }
        />
        <RecordsListTablePanel
          type={AssetType.FACILITY}
          {...recordStorageKeys}
          viewConfigStorageKey={
            calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
          }
          applyAutomationStorageKey={recordsApplyAutomationStorageKey}
          filterComponentSlot={({ recordValueTypes }) => (
            <FacilityRecordsTableFilterArea
              filterConfigStorageKey={recordStorageKeys.filterConfigStorageKey}
              selectableProperties={recordValueTypes}
            />
          )}
        />
      </Tab>
    </Page>
  );
};
