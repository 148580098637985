import SidePanelSection from "#common/SidePanel/SidePanelSection";
import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import { fetchInstrumentList } from "#redux/actions/index";
import RecordOfQualityService from "#services/RecordOfQualityService";
import SampleService from "#services/SampleService";
import config from "#src/config";
import { getPropertyAsMap } from "#utils/objectFormatter";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Button, Modal, Panel, useAlert } from "@validereinc/common-components";
import { downloadLink } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import FieldManualEntryModal from "../ManualEntry/ManualEntryForms/Field/FieldManualEntryModal";
import useManualEntryModal from "../ManualEntry/ManualEntryForms/useManualEntryModal";
import TestDetailModal from "../Tests/TestDetailModal/TestDetailModal";
import "./SampleAnalysis.scss";
import SampleComparisonTable from "./SampleComparisonTable";
import { getAlerts } from "./SampleComparisonTableHelper";
import { sampleStateRenderer } from "./SampleHelper";
import SubmitAnalysisModal from "./SubmitAnalysisModal";
import TestsList from "./TestsList";

const SAMPLE_DOWNLOAD_FILES = [
  { title: "Crude Analysis", id: "crude_analysis" },
  { title: "Full Compositional Analysis", id: "compositional_analysis" },
  { title: "Violation Letter", id: "violation_letter" },
];

const mapStateToProps = ({ instruments }) => {
  return {
    instruments: instruments.data?.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  fetchInstrumentList,
};

const useManageTestDetailModal = () => {
  const [selectedTestId, setSelectedTestId] = useState(null);

  const onTestClick = (selectedTest) => {
    setSelectedTestId(selectedTest?.id);
  };

  const onHideTestDetailModal = () => {
    setSelectedTestId(null);
  };

  return [selectedTestId, onTestClick, onHideTestDetailModal];
};

const SampleAnalysis = ({
  selectedSample,
  isSelectedSampleLoading,
  updatedSelectedSample,
  haveWritePermission,
  fetchInstrumentList,
  instruments,
  refreshSample,
  onClose,
}) => {
  const [alerts, setAlerts] = useState([]);
  const [showSubmitAnalysisModal, setShowSubmitAnalysisModal] = useState(false);
  const { addAlert } = useAlert();

  const [selectedTestId, onTestClick, onHideTestDetailModal] =
    useManageTestDetailModal();

  const [show, showModal, onHide] = useManualEntryModal();

  const sortedTestsWithSiteDetail = useMemo(() => {
    if (selectedSample?.tests && instruments.length) {
      const instrumentNameMap = getPropertyAsMap(instruments, "name");

      return selectedSample.tests
        .map((test) => ({
          ...test,
          instrumentName: instrumentNameMap[test.instrument_id],
        }))
        .sort((a, b) => a.instrumentName?.localeCompare(b.instrumentName));
    } else {
      return [];
    }
  }, [selectedSample?.tests, instruments]);

  const createSuccessfulDownloadAlertMessage = () => {
    addAlert({
      variant: "success",
      message: "Your files will start downloading shortly.",
    });
  };

  const onDownloadLinkClick = (sampleId, type) => {
    RecordOfQualityService.getDownloadSampleCompositionalLink(
      sampleId,
      type
    ).then(({ data }) => {
      downloadLink(data.url);
      createSuccessfulDownloadAlertMessage();
    });
  };

  const onLabResultDownloadLinkClick = (labReportId) => {
    if (labReportId) {
      SampleService.getLabReportFile(labReportId).then(({ data }) => {
        downloadLink(data.url);
        createSuccessfulDownloadAlertMessage();
      });
    }
  };

  const onHideAnalysisModal = () => {
    setShowSubmitAnalysisModal(false);
  };

  useEffect(() => {
    fetchInstrumentList();
  }, []);

  useEffect(() => {
    if (selectedSample?.id) {
      setAlerts(getAlerts(selectedSample));
    } else {
      setAlerts([]);
    }
  }, [selectedSample]);

  const numTests = sortedTestsWithSiteDetail.length ?? 0;

  return (
    <>
      <ReactLoader
        position={15}
        loaded={!isSelectedSampleLoading}
      >
        <Modal.Body className="sampleAnalysis__summary">
          <div className="sampleAnalysis">
            <SidePanelSection subHeader="Sample Summary">
              <Panel className="sampleAnalysis__overview">
                <div>
                  <SummaryInformation
                    title="Source"
                    value={selectedSample?.sample_type ?? "-"}
                    capitalized
                  />

                  <SummaryInformation
                    title="Product Type"
                    value={selectedSample.stream?.product_type ?? "-"}
                    capitalized
                  />
                </div>

                <div>
                  <SummaryInformation
                    title="Sample Date"
                    value={getTimeStringFromDate(
                      selectedSample.ended_at,
                      config.DATETIME_FORMAT
                    )}
                  />

                  <SummaryInformation
                    title="State"
                    value={sampleStateRenderer(selectedSample)}
                    capitalized
                  />
                </div>

                <div>
                  <SummaryInformation
                    title="Validated By"
                    value={selectedSample?.validated_by?.email ?? "-"}
                    suppress
                  />

                  <SummaryInformation
                    title="Validated Date"
                    value={
                      selectedSample.validated_at
                        ? getTimeStringFromDate(
                            selectedSample.validated_at,
                            config.DATETIME_FORMAT
                          )
                        : "-"
                    }
                  />
                </div>
              </Panel>
            </SidePanelSection>

            <SidePanelSection
              subHeader={`Tests ${numTests ? `(${numTests})` : ""}`}
            >
              {haveWritePermission && (
                <div
                  className="sampleAnalysis__addTest link"
                  onClick={() => showModal()}
                >
                  <FontAwesome
                    name="plus"
                    className="icon"
                  />
                  Add Field Test
                </div>
              )}

              <TestsList
                tests={sortedTestsWithSiteDetail}
                onClick={onTestClick}
              />
            </SidePanelSection>

            <SidePanelSection subHeader="Downloadable Reports">
              <div className="sampleAnalysis__downloadableReports">
                {selectedSample?.id &&
                  SAMPLE_DOWNLOAD_FILES.map((file) => {
                    return file.id !== "violation_letter" || alerts.length ? (
                      <div key={file.id}>
                        <a
                          className="link"
                          onClick={() =>
                            onDownloadLinkClick(selectedSample.id, file.id)
                          }
                        >
                          <FontAwesome
                            name="download"
                            className="icon fa-fw"
                          />

                          {file.title}
                        </a>
                      </div>
                    ) : null;
                  })}

                {sortedTestsWithSiteDetail?.map((test) => {
                  if (test.lab_report_id) {
                    return (
                      <div key={test.id}>
                        <a
                          className="link"
                          onClick={() =>
                            onLabResultDownloadLinkClick(test?.lab_report_id)
                          }
                        >
                          <FontAwesome
                            name="download"
                            className="icon fa-fw"
                          />

                          {test.type === "lab"
                            ? "Lab Report"
                            : `${test?.instrumentName ?? ""} Report`}
                        </a>
                      </div>
                    );
                  }
                })}
              </div>
            </SidePanelSection>

            <SidePanelSection subHeader="Sample Analysis">
              <Panel
                className="sampleAnalysis__analysis"
                loaded={!isSelectedSampleLoading}
              >
                <span>(*) - Measurement contains notes</span>

                <SampleComparisonTable
                  selectedSample={selectedSample}
                  onTestClick={onTestClick}
                />
              </Panel>
            </SidePanelSection>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="pull-left"
            onClick={onClose}
          >
            Close
          </Button>

          {haveWritePermission && (
            <Button
              variant="primary"
              onClick={() => setShowSubmitAnalysisModal(true)}
            >
              Validate Sample
            </Button>
          )}
        </Modal.Footer>

        {showSubmitAnalysisModal && (
          <SubmitAnalysisModal
            show={showSubmitAnalysisModal}
            selectedSample={selectedSample}
            updatedSelectedSample={updatedSelectedSample}
            onHide={onHideAnalysisModal}
          />
        )}

        {selectedTestId && (
          <TestDetailModal
            showModal={true}
            testId={selectedTestId}
            updateSample={updatedSelectedSample}
            onHide={onHideTestDetailModal}
          />
        )}

        {show && (
          <FieldManualEntryModal
            show={true}
            onHide={onHide}
            sampleInfo={selectedSample}
            refetchData={refreshSample}
          />
        )}
      </ReactLoader>
    </>
  );
};

SampleAnalysis.propTypes = {
  selectedSample: PropTypes.object.isRequired,
  isSelectedSampleLoading: PropTypes.bool,
  haveWritePermission: PropTypes.bool.isRequired,
  updatedSelectedSample: PropTypes.func.isRequired,
  instruments: PropTypes.array.isRequired,
  fetchInstrumentList: PropTypes.func.isRequired,
  refreshSample: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleAnalysis);
