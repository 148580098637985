import {
  useAddMemberToUserGroup,
  useListUserGroups,
} from "#src/components/hooks/adapters/useUserGroups";
import {
  Button,
  Dialog,
  DropdownInput,
  Form,
  useForm,
} from "@validereinc/common-components";
import { UserType } from "@validereinc/domain";
import React, { useEffect } from "react";

export const AddUserGroupMembershipDialog = ({
  isOpen,
  onClose,
  user,
}: {
  isOpen: boolean;
  onClose: () => void;
  user: UserType;
}) => {
  const form = useForm({});
  const onSuccess = () => {
    onClose?.();
  };

  const addMemberToUserGroup = useAddMemberToUserGroup({ onSuccess });
  const handleSubmit = form.handleSubmit((values) => {
    const groupId = values.user_group;
    addMemberToUserGroup.mutate({ user, groupId });
  });

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  const userGroups = useListUserGroups({}).data?.data ?? [];

  return (
    <Dialog
      title="Add User to User Group"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="add-user-group-membership"
          variant="primary"
          onClick={handleSubmit}
        >
          Add
        </Button>,
      ]}
    >
      <Form {...form}>
        <DropdownInput
          label="User Group"
          name="user_group"
          options={userGroups}
          labelKey="name"
          valueKey="id"
          isFluid
          isSearchable
          isRequired
        />
      </Form>
    </Dialog>
  );
};
