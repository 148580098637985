import {
  type FormSchemaQuestionBaseType,
  type FormSchemaType,
} from "@validereinc/domain";
import { useMemo, type ReactNode } from "react";
import { FormCalculatedFieldService } from "../../../logic/forms";

export type CalculatedFieldEquationControllerProps = {
  children: (props: {
    displayEquation: string;
    tokens: Array<{
      startGroup?: boolean;
      endGroup?: boolean;
      isVariable?: boolean;
      displayValue: string;
      value?: number;
      unit?: string;
    }>;
  }) => ReactNode;
  equation: NonNullable<FormSchemaQuestionBaseType["equation"]>;
  sourceFieldValuesMap: Record<string, { value: number | null; unit?: string }>;
  questionsMap: FormSchemaType["config"]["questions"];
  getTokens?: boolean;
};

export const CalculatedFieldEquationController = ({
  equation,
  sourceFieldValuesMap,
  questionsMap,
  children,
  getTokens = false,
}: CalculatedFieldEquationControllerProps) => {
  const config = useMemo(() => {
    return FormCalculatedFieldService.getEquationDisplayConfig(
      equation,
      questionsMap,
      sourceFieldValuesMap,
      getTokens
    );
  }, [equation, questionsMap, sourceFieldValuesMap, getTokens]);

  const getRenderableTokens = (): Parameters<
    CalculatedFieldEquationControllerProps["children"]
  >[0]["tokens"] => {
    if (!config?.lexicalTokens) return [];

    return config.lexicalTokens.map((token) => {
      if (token.value === "(")
        return {
          startGroup: true,
          displayValue: token.show,
          value: token.value,
        };
      if (token.value === ")")
        return {
          endGroup: true,
          displayValue: token.show,
          value: token.value,
        };
      if (token.show.startsWith("$"))
        return {
          isVariable: true,
          displayValue: config.variablesToLabelsMap[token.show.substring(1)],
          value: token.value,
          unit: sourceFieldValuesMap[token.show.substring(1)].unit,
        };
      // tokenTypes enum is not consumable from the math library we use, yet
      // eslint-disable-next-line
      if (token.type === 1) {
        return { displayValue: token.value, value: token.value };
      }

      return { displayValue: token.show, value: token.value };
    });
  };

  const renderableTokens = useMemo(() => getRenderableTokens(), [config]);

  return (
    <>
      {children({
        displayEquation: config?.displayEquation ?? equation,
        tokens: renderableTokens,
      })}
    </>
  );
};
