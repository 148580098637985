import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  FileInput,
  Form,
  TextInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  RecordDomain,
  RecordValueType,
  RecordValueUpdateType,
} from "@validereinc/domain";
import { useFile } from "#src/hooks/useFile";

export const AddRecordNoteDialog = ({
  isOpen,
  onClose,
  recordId,
  recordValue,
}: {
  isOpen: boolean;
  onClose: () => void;
  recordId: string;
  recordValue?: RecordValueType;
}) => {
  const noteForm = useForm({});
  const { status, upload } = useFile();
  const { addAlert } = useAlert();

  const handleSubmit = async () => {
    if (!recordId || !recordValue?.measurement_type) {
      return;
    }

    const noteFormValues = noteForm.getValues();
    const note = noteFormValues?.note?.trim();

    const body = {
      measurement_unit: recordValue?.measurement_unit,
      configuration: recordValue?.configuration,
      ...(note ? { note } : {}),
    } as RecordValueType;

    const file = noteFormValues?.attachment?.item(0);

    if (file) {
      try {
        const { name, ref } = await upload({
          file: noteFormValues.attachment.item(0),
          description:
            "A user-uploaded file through record value notes (and attachments) - IN DEVELOPMENT",
        });
        body.attachment_filename = name;
        body.attachment_ref = ref;
      } catch (e) {
        addAlert({
          variant: "error",
          message: "Unable to upload attachment",
        });
        return;
      }
    }

    try {
      await RecordDomain.recordValue.update(
        {
          recordId,
          measurementType: recordValue?.measurement_type,
        },
        body as RecordValueUpdateType
      );
      addAlert({
        variant: "success",
        message: "Success!",
      });
      onClose?.();
    } catch (err) {
      console.error(err);
      addAlert({
        variant: "error",
        message: "Unable to update record configuration",
      });
    }
  };

  useEffect(() => {
    noteForm.reset();
  }, [isOpen]);

  return (
    <Dialog
      title="Include Note"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          onClick={noteForm?.handleSubmit(handleSubmit)}
          variant="primary"
          disabled={noteForm?.formState.isSubmitting}
          key="done-button"
        >
          Done
        </Button>,
      ]}
    >
      <Form {...noteForm}>
        <TextInput
          name="note"
          label="Note"
          isRequired={false}
        />

        <FileInput
          name="attachment"
          label="Attachment"
          placeholder="You can drag a file here to use as an attachment (Max. 20MB)"
          showIcon={true}
          isRequired={false}
          uploadStatus={status}
        />
      </Form>
    </Dialog>
  );
};
