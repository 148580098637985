import {
  DeviceDropdownInput,
  EquipmentDropdownInput,
  FacilityDropdownInput,
  FlowDropdownInput,
} from "#src/batteries-included-components/Dropdowns";
import {
  DateSelectorInput,
  DropdownInput,
  RadioInput,
  TextInput,
} from "@validereinc/common-components";
import { FormSchemaQuestionType } from "@validereinc/domain";

import React from "react";

export const ConditionValueField = ({ name, question, condition }) => {
  const inputProps = {
    name,
    label: "Value",
    isRequired: true,
    isFluid: true,
  };

  return (
    <>
      {question?.data_type === "string" ? <TextInput {...inputProps} /> : null}

      {question?.data_type === "number" ||
      question.type === FormSchemaQuestionType.MEASUREMENT ? (
        <TextInput
          {...inputProps}
          type="number"
          formatType="number"
        />
      ) : null}

      {question?.data_type === "integer" ? (
        <TextInput
          {...inputProps}
          type="number"
          formatType="integer"
        />
      ) : null}

      {question?.data_type === "boolean" ? (
        <RadioInput
          {...inputProps}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          labelKey="label"
          valueKey="value"
        />
      ) : null}

      {question?.data_type === "date" ? (
        <DateSelectorInput {...inputProps} />
      ) : null}

      {question?.data_type === "date-time" ? (
        <DateSelectorInput
          {...inputProps}
          variant="time"
        />
      ) : null}

      {question?.data_type === "pick-list" ? (
        <DropdownInput
          {...inputProps}
          options={question.pick_list_values}
          valueKey="value"
          labelKey="value"
          isMulti={condition?.operation === "$in"}
        />
      ) : null}

      {question?.data_type === "multi-pick-list" ? (
        <DropdownInput
          {...inputProps}
          options={question.pick_list_values}
          valueKey="value"
          labelKey="value"
          isMulti
        />
      ) : null}

      {question?.data_type === "lookup" ? (
        <>
          {question?.lookup_entity_type === "facility" ? (
            <FacilityDropdownInput {...inputProps} />
          ) : null}

          {question?.lookup_entity_type === "equipment" ? (
            <EquipmentDropdownInput {...inputProps} />
          ) : null}

          {question?.lookup_entity_type === "device" ? (
            <DeviceDropdownInput {...inputProps} />
          ) : null}

          {question?.lookup_entity_type === "flow" ? (
            <FlowDropdownInput {...inputProps} />
          ) : null}
        </>
      ) : null}
    </>
  );
};
