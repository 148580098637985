import {
  getDownstreamName,
  getUpstreamName,
} from "#src/routes/change-log/ActivityLogsPage.helpers";
import { KeyValueList } from "@validereinc/common-components";
import { AssetType, type ActivityType } from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React from "react";
import styles from "./ActivityLogsCreateDetailsKeyValueList.module.scss";
import useLocalization from "#src/hooks/useLocalization";

const cx = classNames.bind(styles);

export const ActivityLogsCreateDetailsKeyValueList = ({
  selectedLog,
}: {
  selectedLog: ActivityType | null;
}) => {
  const { localize } = useLocalization();
  if (!selectedLog) {
    return null;
  }

  const { resource_type, after } = selectedLog;

  if (!resource_type || !after) {
    return null;
  }

  let data: Array<{ title: string; value: string }> = [];

  if (resource_type === AssetType.EQUIPMENT) {
    data = [
      {
        title: "Name",
        value: after.name || "",
      },
      {
        title: "Status",
        value: after.status || "",
      },
      { title: "Type", value: after.type?.name || "" },
      {
        title: localize(AssetType.FACILITY),
        value: after.facility?.name || "",
      },
      {
        title: "Effective Date",
        value: after.effective_date
          ? datetimeFormatter(new Date(after.effective_date))
          : "",
      },
    ];
  }

  if (resource_type === AssetType.FACILITY) {
    data = [
      { title: "Name", value: after.name || "" },
      {
        title: "Status",
        value: after.status || "",
      },
    ];
  }

  if (resource_type === AssetType.DEVICE) {
    data = [
      { title: "Name", value: after.name || "" },
      {
        title: "Status",
        value: after.status || "",
      },
      {
        title: "Type",
        value: after.type?.name || "",
      },
      {
        title: localize(AssetType.FACILITY),
        value: after.facility?.name || "",
      },
      {
        title: "Valid Measurement Types",
        value: after.measurement_types?.length || "",
      },
      { title: "Provider", value: after.attributes?.provider || "" },
    ];
  }

  if (resource_type === AssetType.FLOW) {
    data = [
      { title: "Name", value: after.name || "" },
      {
        title: "Type",
        value: after.type || "",
      },
      { title: "Product Category", value: after.product_category || "" },
      { title: "Product Type", value: after.product_type || "" },
      { title: "Origin", value: getUpstreamName(after) },
      { title: "Destination", value: getDownstreamName(after) },
    ];
  }

  const resourceType =
    resource_type?.charAt(0)?.toUpperCase() + resource_type?.slice(1);

  return (
    <>
      <h1 className={cx("title")}>{localize(resourceType)} Attributes</h1>
      <KeyValueList
        data={data}
        className={cx("keyValueListContainer")}
      />
    </>
  );
};
