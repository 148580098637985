import { EMISSIONS_HOME_FILTERS } from "#batteries-included-components/Layouts/HomeEmissions/EmissionsHomeFilterPanel";
import { MapCluster } from "#components/Map/MapCluster";
import { MapClusterTooltip } from "#components/Map/MapCluster/MapClusterTooltip";
import type {
  MapClusterMassDataType,
  MapClusterPointType,
  MapClusterPropertiesType,
  MapClusterTooltipStyleType,
  MapFacilityNodeDataCollectionType,
  MapFacilityNodeDataType,
} from "#components/Map/MapCluster/types";
import { useNavigate } from "#routers/hooks";
import { useListFacilities } from "#src/components/hooks/adapters/useFacilities";
import {
  HOUSTON_COORDINATES,
  INIT_MASS_POLLUTANT,
  INIT_REPORTING_GROUP,
} from "#src/constants";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { getIntervalInMonthsFormatted, hydrateDateRange } from "#utils/date";
import { animated, useSpring } from "@react-spring/web";
import { StorageKeys, useFilters } from "@validereinc/common-components";
import { CalculatorResultsDomain } from "@validereinc/domain";
import { getFormattedNumberWithUnit } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./EmissionsHomeMapPanel.module.scss";
import { getCachedDataUrlParams, setUrlCacheAfterLoad } from "./helpers";

const cx = classNames.bind(styles);

export const EmissionsHomeMapPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const navigate = useNavigate();
  const { getUnitByType, getPrecisionByType, getUnitName } =
    useMeasurementTypes();

  const facilities = useListFacilities().data?.data ?? [];
  const [animateStyle, animate] = useSpring(() => ({ opacity: 0 }));
  const [isMapContainerReady, setMapContainerReady] = useState(false);
  const mapContainerRef = useRef(null);
  const [
    {
      [EMISSIONS_HOME_FILTERS.dateRange.name]: dateRange,
      [EMISSIONS_HOME_FILTERS.reportingScenario.name]: reportingGroup,
    },
  ] = useFilters(filterConfigStorageKey);

  /** https://validere.atlassian.net/browse/FE-2299 - Existing bug that is beyond the scope of this refactor */
  const { mass, label } = INIT_MASS_POLLUTANT;
  const precision = getPrecisionByType(mass);
  const unit = getUnitName(getUnitByType(mass));

  const [isUnclusteredNodesVisible, setUnclusteredNodesVisible] =
    useState<boolean>(true);

  const initialViewState = {
    ...HOUSTON_COORDINATES,
    zoom: 2,
  };

  const [tooltipStyle, setTooltipStyle] =
    useState<MapClusterTooltipStyleType>(null);
  const [tooltipData, setTooltipData] = useState<MapClusterMassDataType>(null);

  // reduce the facilities so that all latitude and longitude pairs are unique
  // remove duplicates which is a problem on staging with poor data but not on prod with clean data
  const getUniqueFacilities = (): MapFacilityNodeDataCollectionType => {
    const uniqueFacilities: MapFacilityNodeDataCollectionType =
      facilities && facilities.length > 0
        ? facilities.reduce(
            (
              acc: MapFacilityNodeDataCollectionType,
              facility: MapFacilityNodeDataType
            ) => {
              const facilityExists = acc.find(
                (accFacility) =>
                  accFacility.latitude === facility.latitude &&
                  accFacility.longitude === facility.longitude
              );
              if (
                !facilityExists &&
                facility.latitude !== null &&
                facility.longitude !== null
              ) {
                acc.push(facility);
              }
              return acc;
            },
            []
          )
        : [];

    return uniqueFacilities;
  };

  const filteredFacilities = useMemo(() => getUniqueFacilities(), [facilities]);

  /**
   * getFacilityEmissionsData will fetch the emissions data for a single facility on hover of a map node
   */
  const getFacilityEmissionsData = async (
    id: string,
    reportingGroup: string,
    mass: string
  ): Promise<number> => {
    const request = {
      page: 1,
      pageSize: 1,
      filters: {
        "equipment.facility.id": id,
        year_month: {
          $in: getIntervalInMonthsFormatted(hydrateDateRange(dateRange)),
        },
        reporting_group: reportingGroup ?? INIT_REPORTING_GROUP,
        isAlreadyFormatted: true,
      },

      group_by: ["equipment.facility.id", "equipment.facility.name"],
    };

    const response = await CalculatorResultsDomain.getList(request);
    const item =
      response.data && response.data.length > 0 ? response.data[0] : null;

    const measurement = Number(item?.measurement[mass]) || 0;

    setUrlCacheAfterLoad(id, measurement);

    return measurement;
  };

  // give the map time to draw to ensure that the map container is ready and then fade in the map
  useEffect(() => {
    setTimeout(() => {
      setMapContainerReady(true);
      animate({ opacity: 1, delay: 500 });
    }, 1000);
    // }
  }, []);

  const mouseEnterCallback = async (
    properties: MapClusterPropertiesType,
    point: MapClusterPointType
  ) => {
    setUnclusteredNodesVisible(false);
    setTooltipStyle({ top: point.y - 1, left: point.x - 8, opacity: 1 });
    const dataWithoutMass = {
      ...properties,
      label,
    };

    const { cachedFacilityId, cachedFacilityMeasurement } =
      getCachedDataUrlParams();

    if (cachedFacilityId === properties.id) {
      const cachedDataWithMass = {
        ...dataWithoutMass,
        mass: getFormattedNumberWithUnit(
          { value: parseFloat(cachedFacilityMeasurement ?? ""), unit },
          precision
        ),
      };
      return setTooltipData(cachedDataWithMass);
    }

    const dataWithMass = await getPointData(dataWithoutMass);
    // once we have the mass, update the tooltip data to include it removing the skeleton loader
    setTooltipData(dataWithMass);
  };

  const handleClosePopup = () => {
    setTooltipStyle({ ...tooltipStyle, opacity: 0 });
    setTimeout(() => {
      // reset the point
      setUnclusteredNodesVisible(true);
      setTooltipData(null);
    }, 500);
  };

  const handleSetPopupData = (data: MapClusterMassDataType) => {
    setTooltipStyle(data);
  };

  const getPointData = async (properties: MapClusterPropertiesType) => {
    const measurement = await getFacilityEmissionsData(
      properties?.id,
      reportingGroup,
      mass
    );

    const facilityData = {
      id: properties?.id,
      name: properties?.name ?? "No facility nearby",
      description: properties?.industry_segment?.name ?? "",
      url: `/app/organization/facilities/${properties?.id}/detail`,
      label,
      mass: getFormattedNumberWithUnit({ value: measurement, unit }, precision),
    };
    return facilityData;
  };

  if (!filteredFacilities || filteredFacilities.length === 0) {
    return (
      <div
        style={{ height: "100%", width: "100%", position: "relative" }}
        ref={mapContainerRef}
      >
        <div className={cx("mapContainer")} />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          minHeight: 480,
          height: "100%",
          width: "100%",
          position: "relative",
        }}
        ref={mapContainerRef}
      >
        <MapClusterTooltip
          data={tooltipData}
          handleClosePopup={handleClosePopup}
          tooltipStyle={tooltipStyle}
          navigate={navigate}
        />

        <div className={cx("mapContainer")}>
          {isMapContainerReady && filteredFacilities.length > 0 ? (
            <animated.div
              style={{
                ...animateStyle,
                height: "100%",
                width: "100%",
                padding: 0,
                margin: 0,
                overflow: "hidden",
              }}
            >
              <MapCluster
                initialViewState={initialViewState}
                data={filteredFacilities}
                onMapItemClick={handleSetPopupData}
                mouseEnterCallback={mouseEnterCallback}
                isUnclusteredNodesVisible={isUnclusteredNodesVisible}
              />
            </animated.div>
          ) : null}
        </div>
      </div>
    </>
  );
};
