import { useNavigate, useHistory } from "#src/Routers/hooks";
import { useEffect, useRef, useState } from "react";

/**
 * Block routing navigation from happening based on a condition and allow unblocking manually as the consumer chooses.
 *
 * Note, navigation will be unblocked on component unmount.
 * @param shouldBlock when this condition evaluates to true, all navigation will be blocked
 * @param onBlock when a navigation is attempted, this will be run
 * @returns a boolean indicating whether navigation is currently blocked and an unblock callback. run the function to unblock navigation.
 */
export const useBlockNavigation = (
  shouldBlock: boolean,
  onBlock: (() => boolean) | (() => void)
): [boolean, (options: { redirect: boolean }) => void] => {
  const history = useHistory();
  const navigate = useNavigate();
  const unblockNavigation = useRef<() => void>(() => {
    return;
  });
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [pathnameToNavigateTo, setPathnameToNavigateTo] = useState<string>("");

  useEffect(() => {
    if (!shouldBlock) {
      unblockNavigation.current();
      setIsBlocked(false);
      return;
    }

    setIsBlocked(true);
    // @ts-expect-error the block callback actually takes in a boolean but the current version is mistyped
    unblockNavigation.current = history.block((location) => {
      setPathnameToNavigateTo(location.pathname);
      const res = onBlock();

      // if blocking handler returns true, unblock immediately
      // otherwise, continue blocking, till unblock is manually called by consumer
      return !!res;
    });

    return () => {
      unblockNavigation.current();
      setIsBlocked(false);
    };
  }, [history, shouldBlock]);

  return [
    isBlocked,
    (options) => {
      unblockNavigation.current();
      setIsBlocked(false);

      if (options.redirect) {
        navigate({ pathname: pathnameToNavigateTo });
      }
    },
  ];
};
