import { UsersDropdownInput } from "#src/batteries-included-components/Dropdowns/UsersDropdownInput";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Button,
  ContentSwitcher,
  Dialog,
  DropdownInput,
  Form,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  UserGroupSchema,
  UserGroupsAdapter,
  WorkflowTaskAdapter,
  WorkflowTaskBaseSchema,
  WorkflowTaskType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import styles from "./WorkflowTaskUpdateAssigneeDialog.module.css";

export type WorkflowTaskUpdateAssigneeDialogProps = {
  task: WorkflowTaskType | null;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (formValues: FormValues) => void;
};

type FormValues = {
  assignee_user: string;
  assignee_group: string;
};

const ContentSwitcherTabs = {
  user: "user",
  user_groups: "user_groups",
} as const;

type ContentSwitcherTabsType =
  (typeof ContentSwitcherTabs)[keyof typeof ContentSwitcherTabs];

const cx = classNames.bind(styles);

export const WorkflowTaskUpdateAssigneeDialog = ({
  task,
  isOpen,
  onClose,
  onSubmit,
}: WorkflowTaskUpdateAssigneeDialogProps) => {
  const { addAlert } = useAlert();
  const form = useForm({
    defaultValues: {
      [WorkflowTaskBaseSchema.keyof().Enum.assignee_user]:
        task?.assignee_user ?? "",
      [WorkflowTaskBaseSchema.keyof().Enum.assignee_group]:
        task?.assignee_group ?? "",
    },
  });
  const queryClient = useQueryClient();
  const [assigneeUserType, setAssigneeUserType] =
    useState<ContentSwitcherTabsType>(() =>
      task?.assignee_group ? "user_groups" : "user"
    );
  const [userGroupsSearchTerm, setUserGroupsSearchTerm] = useState("");

  const { mutateAsync } = useMutation({
    mutationFn: (
      updatePayload: Partial<
        Pick<WorkflowTaskType, "status" | "assignee_user" | "assignee_group">
      >
    ) => {
      if (!task || !updatePayload) {
        return;
      }

      return WorkflowTaskAdapter.updateOne({
        id: task?.id,
        data: updatePayload,
        previousData: task,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows"],
      });
    },
  });

  const userGroupsQuery = useInfiniteQuery({
    queryKey: ["users", "groups"],
    queryFn: ({ pageParam = 1 }) => {
      return UserGroupsAdapter.getList({
        page: pageParam,
        pageSize: 25,
        sortBy: UserGroupSchema.keyof().Enum.name,
        sortDirection: "asc",
        filters: {
          ...(userGroupsSearchTerm ? { name: userGroupsSearchTerm } : {}),
        },
      });
    },
    getNextPageParam: (resp) =>
      resp.page_number < resp.total_pages ? resp.page_number + 1 : undefined,
    getPreviousPageParam: (resp) =>
      resp.page_number > 0 ? resp.page_number - 1 : undefined,
  });

  const handleClose = () => {
    form.reset();
    setUserGroupsSearchTerm("");
    onClose?.();
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      onSubmit?.(values);
      await mutateAsync({
        ...(assigneeUserType === ContentSwitcherTabs.user
          ? { assignee_user: values.assignee_user, assignee_group: null }
          : { assignee_user: null }),
        ...(assigneeUserType === ContentSwitcherTabs.user_groups
          ? { assignee_group: values.assignee_group, assignee_user: null }
          : { assignee_group: null }),
      });
    } catch (err) {
      console.error("Couldn't update assignee for workflow task", err);
      addAlert({
        variant: "error",
        message: "Couldn't update assignee. Try again?",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      title={`Re-Assign Task: ${task?.name ? ` ${task.name}` : ""}`}
      isOpen={isOpen}
      onClose={handleClose}
      actionRow={[
        <Button
          key="save"
          variant="primary"
          onClick={form.handleSubmit(handleSubmit)}
        >
          Save
        </Button>,
      ]}
    >
      <Form {...form}>
        <p>Select a single user or a user group to assign this task to</p>
        <ContentSwitcher
          className={cx("assignee-type-content-switcher")}
          items={[
            {
              label: "Individual Users",
              dataKey: ContentSwitcherTabs.user,
              description:
                "Tasks assigned to an individual user will be assigned to that specific user.",
            },
            {
              label: "User Groups",
              dataKey: ContentSwitcherTabs.user_groups,
              description:
                "Tasks assigned to a user group will be available to all users in that group.",
            },
          ]}
          activeKey={assigneeUserType}
          onChange={(key) => {
            setAssigneeUserType(key as ContentSwitcherTabsType);
            setUserGroupsSearchTerm("");
          }}
        />
        <div
          className={cx(
            assigneeUserType !== ContentSwitcherTabs.user && "hidden"
          )}
        >
          <UsersDropdownInput
            key={WorkflowTaskBaseSchema.keyof().Enum.assignee_user}
            inputId={ContentSwitcherTabs.user}
            name={WorkflowTaskBaseSchema.keyof().Enum.assignee_user}
            placeholder="Select a user"
            isFluid
          />
        </div>
        <div
          className={cx(
            assigneeUserType !== ContentSwitcherTabs.user_groups && "hidden"
          )}
        >
          <DropdownInput
            key={WorkflowTaskBaseSchema.keyof().Enum.assignee_group}
            inputId={ContentSwitcherTabs.user_groups}
            name={WorkflowTaskBaseSchema.keyof().Enum.assignee_group}
            options={userGroupsQuery.data?.pages.flatMap((p) => p.data)}
            onScroll={({ isBottom }) => {
              if (!isBottom) return;

              userGroupsQuery.fetchNextPage();
            }}
            searchTerm={userGroupsSearchTerm}
            onSearchTermChange={setUserGroupsSearchTerm}
            isBusy={userGroupsQuery.isFetching}
            isLoading={userGroupsQuery.isLoading && !userGroupsQuery.isFetched}
            labelKey="name"
            valueKey="id"
            placeholder="Select a user group"
            isFluid
          />
        </div>
      </Form>
    </Dialog>
  );
};
