import { useGetOneFlow } from "#hooks/adapters/useFlows";
import { useParams } from "#routers/hooks";
import { FlowDetailParamsType } from "#routes/organization/flows/[flowId]/detail/index";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { hydrateDateRange } from "#utils/date";
import { getYearMonthFromDateRange } from "@validereinc/utilities";

export const useFlowDetailFlow = ({
  filterConfigStorageKey,
  enabled,
}: { filterConfigStorageKey?: string; enabled?: boolean } = {}) => {
  const { flowId } = useParams<FlowDetailParamsType>();
  const [flowDetailFilter] = useSessionStickyState<{
    period: { from: string; to: string };
  }>({}, filterConfigStorageKey);
  const period = getYearMonthFromDateRange(
    flowDetailFilter?.period
      ? hydrateDateRange(flowDetailFilter.period)
      : DEFAULT_DATE_RANGES.currentWholeMonth
  );

  return useGetOneFlow(
    {
      flowId,
      period,
    },
    { enabled: enabled ?? true }
  );
};
