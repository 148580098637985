import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { useAlert } from "@validereinc/common-components";
import moment from "moment";
import PropTypes from "prop-types";
import TimePicker from "rc-time-picker";
import React, { useEffect, useRef, useState } from "react";
import { Checkbox } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
import { linkToUserSetting } from "../../../../Routers/links";
import NotificationService from "../../../Services/NotificationService";
import "./WorkflowDigestEmailSettings.css";

const InputIcon = <FontAwesome name="caret-down" />;

export const WorkflowDigestEmailSettings = (props) => {
  const isMount = useRef(true);
  const { addAlert } = useAlert();

  const [digestTime, setDigestTime] = useState(
    moment(props.profile.digest_settings?.time, config.TIME_MINUTE_FORMAT)
  );

  const [pickerTime, setPickerTime] = useState(
    moment(props.profile.digest_settings?.time, config.TIME_MINUTE_FORMAT)
  );

  const [isDigestEnabled, setIsDigestEnabled] = useState(
    props.profile.digest_settings?.is_enabled
  );

  useEffect(() => {
    if (isMount.current) {
      isMount.current = false;
    } else {
      setDigestPreferences();
    }
  }, [digestTime, isDigestEnabled]);

  const onTimePickerChange = (time) => {
    setPickerTime(time);
  };

  const onTimePickerClose = () => {
    setDigestTime(pickerTime);
  };

  const onCheckboxChange = () => {
    setIsDigestEnabled(!isDigestEnabled);
  };

  const setDigestPreferences = () => {
    const newWorkflowDigestTimePreferences = {
      time: getTimeStringFromDate(digestTime, config.TIME_FORMAT),
      is_enabled: isDigestEnabled,
    };

    NotificationService.updateUserWorkflowDigestEmailTimePreferences(
      newWorkflowDigestTimePreferences
    ).then(() => {
      addAlert({
        variant: "success",
        message: "Workflow digest preferences have been updated.",
      });
      props.fetchUserProfile();
    });
  };

  const timePickerClass = (isEnabled) => {
    return isEnabled
      ? "workflowDigestEmailSettings__timePicker"
      : "workflowDigestEmailSettings__timePicker workflowDigestEmailSettings__disabledControl";
  };

  return (
    <div className="workflowDigestEmailSettings">
      <Checkbox
        className="workflowDigestEmailSettings__checkbox"
        checked={isDigestEnabled}
        onChange={onCheckboxChange}
      >
        Receive daily workflow digest emails at:
      </Checkbox>
      <TimePicker
        value={pickerTime}
        className={timePickerClass(isDigestEnabled)}
        format={config.TIME_MINUTE_FORMAT}
        onChange={onTimePickerChange}
        onClose={onTimePickerClose}
        use12Hours={true}
        disabled={!isDigestEnabled}
        allowEmpty={false}
        showSecond={false}
        showMinute={false}
        inputIcon={InputIcon}
      />
      {props.profile.timezone_preference}
      <Link to={linkToUserSetting("profile")}>&nbsp;(change timezone)</Link>
    </div>
  );
};

WorkflowDigestEmailSettings.propTypes = {
  profile: PropTypes.object.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
};
