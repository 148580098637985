import {
  AssetTypeOptions,
  AssetTypeSelection,
  useAssetDropdownInput,
  useAssetSearchInput,
  type AssetTypeSelectionType,
} from "#src/batteries-included-components/FilterAreas/assetFilters.helpers";
import {
  FilterArea,
  useFilterAreaContentContext,
  useFilterAreaContext,
} from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  FilterPills,
  FilterPillVariants,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  WorkflowCategoriesAdapter,
  WorkflowSchema,
  WorkflowStatus,
  WorkflowTemplatesAdapter,
} from "@validereinc/domain";
import endOfMonth from "date-fns/endOfMonth";
import subMonths from "date-fns/subMonths";
import type { rest } from "lodash";
import startCase from "lodash/startCase";
import React from "react";

export const FilterConfig = {
  status: {
    name: WorkflowSchema.keyof().Enum.status,
  },
  dateRange: {
    name: "date_range",
  },
  category: {
    name: "categoryId",
  },
  template: {
    name: "templateId",
  },
  assetName: {
    name: "assetName",
  },
  facility: {
    name: "facilityId",
  },
  equipment: {
    name: "equipmentId",
  },
  device: {
    name: "deviceId",
  },
  flow: {
    name: "flowId",
  },
  asset_group: {
    name: "assetGroupId",
  },
  assetType: {
    name: "assetType",
  },
} as const;

export type WorkflowsTableFiltersType = {
  [FilterConfig.status.name]: string;
  [FilterConfig.dateRange.name]: { from: string; to: string };
  [FilterConfig.facility.name]: string[];
  [FilterConfig.equipment.name]: string[];
  [FilterConfig.device.name]: string[];
  [FilterConfig.flow.name]: string[];
  [FilterConfig.asset_group.name]: string[];
  workflow: Record<string, any>;
};

export type WorkflowsViewFiltersType = {
  [FilterConfig.assetType.name]: AssetTypeSelectionType;
  [FilterConfig.assetName.name]?: string;
  [FilterConfig.category.name]?: string;
  [FilterConfig.template.name]?: string;
};

export const WorkflowsViewFilterAreaContent = ({
  viewConfigStorageKey,
  defaultAssetType,
  showCategoryFilter,
  showTemplateFilter,
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  defaultAssetType?: AssetTypeSelectionType;
  showCategoryFilter?: boolean;
  showTemplateFilter?: boolean;
}) => {
  const { handleOnChange } = useFilterAreaContentContext();
  const [filters] = useSessionStickyState<WorkflowsViewFiltersType>(
    {} as WorkflowsViewFiltersType,
    viewConfigStorageKey
  );
  const assetType = filters.assetType ?? defaultAssetType;

  const assetSearchInput = useAssetSearchInput(
    assetType,
    FilterConfig.assetName.name,
    {
      isLabelShown: false,
      isFluid: false,
      isInline: true,
      onChange: (val) => handleOnChange(val, FilterConfig.assetName.name),
    }
  );

  return (
    <>
      <DropdownInput
        name={FilterConfig.assetType.name}
        isClearable={false}
        isInline
        labelKey="label"
        valueKey="value"
        placeholder={"Select Asset Type..."}
        options={AssetTypeOptions}
        isSortedAlphabetically={false}
        description="Filter by Asset Type"
        onChange={(val) => handleOnChange(val, FilterConfig.assetType.name)}
      />
      {assetSearchInput}
      {showCategoryFilter ? (
        <DropdownInput
          name={FilterConfig.category.name}
          onFetchData={async (payload) => {
            let { data } = await WorkflowCategoriesAdapter.getList({
              ...payload,
              filters: {
                ...(payload.searchTerm
                  ? {
                      name: payload.searchTerm,
                    }
                  : {}),
              },
            });

            // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
            if (Array.isArray(payload.value)) {
              data = data.filter((d) => payload.value.includes(d.id));
            }

            return data;
          }}
          labelKey="name"
          valueKey="id"
          placeholder="Select Category..."
          label="Workflow Category"
          description="Filter by Workflow Category"
          isOptionalTextShown={false}
          isLabelShown={false}
          isInline
          onChange={(val) => handleOnChange(val, FilterConfig.category.name)}
        />
      ) : null}
      {showTemplateFilter ? (
        <DropdownInput
          name={FilterConfig.template.name}
          onFetchData={async (payload) => {
            let { data } = await WorkflowTemplatesAdapter.getList({
              ...payload,
              filters: {
                ...(payload.searchTerm
                  ? {
                      name: payload.searchTerm,
                    }
                  : {}),
              },
            });

            // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
            if (Array.isArray(payload.value)) {
              data = data.filter((d) => payload.value.includes(d.id));
            }

            return data;
          }}
          labelKey="name"
          valueKey="id"
          placeholder="Select Template..."
          label="Workflow Template"
          description="Filter by Workflow Template"
          isOptionalTextShown={false}
          isLabelShown={false}
          isMulti
          isInline
          onChange={(val) => handleOnChange(val, FilterConfig.template.name)}
        />
      ) : null}
    </>
  );
};

export const WorkflowsViewFilterArea = ({
  viewConfigStorageKey,
  defaultAssetType = AssetTypeSelection.FACILITY,
  ...rest
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  defaultAssetType?: AssetTypeSelectionType;
  showCategoryFilter?: boolean;
  showTemplateFilter?: boolean;
}) => {
  return (
    <FilterArea.Root
      storageKey={viewConfigStorageKey}
      defaultValues={{
        [FilterConfig.assetType.name]: defaultAssetType,
      }}
      applyDefaultValues
    >
      <FilterArea.Container style={{ marginBottom: 16 }}>
        <FilterArea.Content>
          {() => (
            <div
              style={{
                display: "flex",
                gap: 8,
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <WorkflowsViewFilterAreaContent
                viewConfigStorageKey={viewConfigStorageKey}
                defaultAssetType={defaultAssetType}
                {...rest}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

const WorkflowsTableFilterAreaDrawerContent = ({
  viewConfigStorageKey,
  defaultAssetType,
}: Pick<StorageKeys, "filterConfigStorageKey" | "viewConfigStorageKey"> & {
  defaultAssetType: AssetTypeSelectionType;
}) => {
  const { localize } = useLocalization();
  const [filters] = useSessionStickyState<WorkflowsViewFiltersType>(
    {} as WorkflowsViewFiltersType,
    viewConfigStorageKey
  );
  const assetType = filters.assetType ?? defaultAssetType;

  const assetSelectionInput = useAssetDropdownInput(
    assetType,
    FilterConfig[assetType as keyof typeof FilterConfig]?.name
  );
  const attributeFilters = useCustomAttributeFiltersV2({
    assetType:
      assetType !== AssetTypeSelection.ALL_ASSETS
        ? assetType
        : AssetTypeSelection.FACILITY,
    prefix: `workflow.${assetType}.custom_attributes`,
  });

  return (
    <>
      <DateSelectorInput
        key={FilterConfig.dateRange.name}
        name={FilterConfig.dateRange.name}
        variant="month"
        isRange
        isFluid
        label="Created At"
        description="Filter by Created At"
        isOptionalTextShown={false}
      />
      {assetType !== "all_assets" ? (
        <Accordion defaultActiveKeys={[assetType]}>
          <Accordion.AccordionPanel
            dataKey={assetType}
            title={localize(`${assetType}_plural`)}
          >
            {assetSelectionInput}
            {...attributeFilters.customAttributeFilters}
          </Accordion.AccordionPanel>
        </Accordion>
      ) : null}
    </>
  );
};

const WorkflowsStatusFilterPills = ({
  onChange,
}: {
  onChange: (val: string) => void;
}) => {
  const { storedFilters } = useFilterAreaContext<{ status: string }>();
  const filterPills = [
    {
      name: "All",
      label: "All",
      value: null,
      isSelected: !storedFilters.status,
    },
    {
      name: WorkflowStatus.COMPLETE,
      label: startCase(WorkflowStatus.COMPLETE),
      value: WorkflowStatus.COMPLETE,
      variant: FilterPillVariants.GOOD,
      isSelected: storedFilters.status === WorkflowStatus.COMPLETE,
    },
    {
      name: WorkflowStatus.IN_PROGRESS,
      label: startCase(WorkflowStatus.IN_PROGRESS),
      value: WorkflowStatus.IN_PROGRESS,
      variant: FilterPillVariants.PENDING,
      isSelected: storedFilters.status === WorkflowStatus.IN_PROGRESS,
    },
    {
      name: WorkflowStatus.DISMISSED,
      label: startCase(WorkflowStatus.DISMISSED),
      value: WorkflowStatus.DISMISSED,
      variant: FilterPillVariants.NEUTRAL,
      isSelected: storedFilters.status === WorkflowStatus.DISMISSED,
    },
    {
      name: WorkflowStatus.OVERDUE,
      label: startCase(WorkflowStatus.OVERDUE),
      value: WorkflowStatus.OVERDUE,
      variant: FilterPillVariants.ATTENTION,
      isSelected: storedFilters.status === WorkflowStatus.OVERDUE,
    },
    {
      name: WorkflowStatus.MISSED,
      label: startCase(WorkflowStatus.MISSED),
      value: WorkflowStatus.MISSED,
      variant: FilterPillVariants.FAILURE,
      isSelected: storedFilters.status === WorkflowStatus.MISSED,
    },
  ];

  return (
    <FilterPills
      name="status"
      pills={filterPills}
      onChange={(val) => onChange(val)}
    />
  );
};

export const WorkflowsTableTitleDecorationFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container aria-label="Filters for Workflows">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div style={{ marginRight: 8, display: "flex", gap: 8 }}>
              <WorkflowsStatusFilterPills
                onChange={(val) =>
                  handleOnChange(val, FilterConfig.status.name)
                }
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const WorkflowsTableFilterArea = ({
  filterConfigStorageKey,
  viewConfigStorageKey,
  defaultAssetType = AssetTypeSelection.FACILITY,
}: Pick<StorageKeys, "filterConfigStorageKey" | "viewConfigStorageKey"> & {
  defaultAssetType?: AssetTypeSelectionType;
}) => {
  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{
        [FilterConfig.dateRange.name]: {
          from: subMonths(new Date(), 6),
          to: endOfMonth(new Date()),
        },
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="Filters for Workflows">
        <FilterDrawer.Root>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <WorkflowsTableFilterAreaDrawerContent
              filterConfigStorageKey={filterConfigStorageKey}
              viewConfigStorageKey={viewConfigStorageKey}
              defaultAssetType={defaultAssetType}
            />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
