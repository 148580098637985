import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import FileDownloadLink from "#src/components/Common/FileDownloadLink/FileDownloadLink";
import { linkToAssetDetailPage } from "#src/utils/links";
import {
  CalculatedFieldDataDisplay,
  DataTable,
  MathDataDisplay,
  NumberDataDisplay,
} from "@validereinc/common-components";
import {
  FormSchemaType,
  isMeasurementFormQuestion,
  type FormSubmissionAnswersBySectionMapType,
  type FormSubmissionAnswerType,
  type MeasurementUnitType,
} from "@validereinc/domain";
import { FormCalculatedFieldService } from "@validereinc/domain-controllers/logic/forms";
import { CalculatedFieldEquationController } from "@validereinc/domain-controllers/view/forms";
import {
  booleanFormatter,
  datetimeFormatter,
  formatCoordinateDegrees,
  getFormattedNumber,
  isFiniteNumber,
} from "@validereinc/utilities";
import isValid from "date-fns/isValid";
import isBoolean from "lodash/isBoolean";
import React from "react";

export const renderFormSubmissionAnswer = (
  answer: FormSubmissionAnswerType,
  questionId: string,
  {
    measurementUnits,
    schema,
    sectionId,
    sectionIdx,
    allAnswers,
    allQuestions,
  }: {
    measurementUnits: MeasurementUnitType[];
    schema: FormSchemaType;
    sectionId: string;
    sectionIdx: number;
    allAnswers: FormSubmissionAnswersBySectionMapType;
    allQuestions: FormSchemaType["config"]["questions"];
  }
) => {
  const question = schema.config.questions[questionId];

  if (question.equation) {
    const equationVariablesAndValues =
      FormCalculatedFieldService.getEquationVariablesAndValuesFromSubmission(
        question.equation,
        sectionId,
        sectionIdx,
        allAnswers,
        allQuestions
      );
    const displayMeasurementUnitId =
      FormCalculatedFieldService.getMeasurementUnitFromQuestion(
        question,
        false
      );
    const displayMeasurementUnit = measurementUnits.find(
      ({ id }) => displayMeasurementUnitId === id
    );

    return (
      <CalculatedFieldDataDisplay
        value={answer.value}
        displaySlot={(props) => (
          <NumberDataDisplay
            {...props}
            unit={
              displayMeasurementUnit?.name?.symbol ?? displayMeasurementUnitId
            }
          />
        )}
        alignment="right"
        showHint
        isValid
        hintSlot={
          <>
            This was calculated using the equation:{" "}
            <CalculatedFieldEquationController
              equation={question.equation}
              questionsMap={schema.config.questions}
              sourceFieldValuesMap={equationVariablesAndValues}
              getTokens
            >
              {({ tokens }) => (
                <MathDataDisplay.Root>
                  <MathDataDisplay
                    colorScheme="light"
                    style={{ whiteSpace: "nowrap", fontSize: 14 }}
                  >
                    {tokens.map((t, idx) => {
                      if (t.startGroup || t.endGroup) {
                        return <span key={idx}>{t.displayValue}</span>;
                      } else if (t.isVariable) {
                        const displayMeasurementUnit = measurementUnits.find(
                          ({ id }) => t.unit === id
                        );
                        const unit =
                          displayMeasurementUnit?.name?.symbol ?? t.unit;

                        return (
                          <MathDataDisplay.InteractiveGroup
                            key={idx}
                            value={t.value}
                            displayValue={
                              t.value !== undefined &&
                              t.value !== null &&
                              String(t.value) !== ""
                                ? t.value.toString()
                                : null
                            }
                            description={`This is the answer from the question of the same name.${unit ? ` In units: "${unit}"` : ""}`}
                          >
                            &quot;{t.displayValue}&quot;
                          </MathDataDisplay.InteractiveGroup>
                        );
                      } else {
                        return (
                          <span
                            key={idx}
                            dangerouslySetInnerHTML={{
                              __html: t.displayValue,
                            }}
                          ></span>
                        );
                      }
                    })}
                  </MathDataDisplay>
                </MathDataDisplay.Root>
              )}
            </CalculatedFieldEquationController>
          </>
        }
      />
    );
  }

  if (isMeasurementFormQuestion(question)) {
    if (!answer?.subject_type || !answer?.subject_id) return answer.value;

    const displayMeasurementUnit = measurementUnits.find(
      ({ id }) => schema.config.questions[questionId].measurement_unit === id
    );

    const displayValue =
      Number(answer.value) > 10 ** 6
        ? Number(answer.value).toPrecision(6)
        : getFormattedNumber(answer.value, null, {
            errorFallback: "n/a",
            maxFractionDigits: 6,
            showSmallNumberAsExponential: true,
            smallNumberThreshold: 0.01,
          });

    return (
      <RoutingLink
        to={{
          pathname: linkToAssetDetailPage(
            answer.subject_type,
            answer.subject_id
          ),
          query: {
            tab: "measurements",
          },
        }}
      >
        {displayValue}
        {displayMeasurementUnit?.name?.symbol ??
          schema.config.questions[questionId].measurement_unit}
      </RoutingLink>
    );
  }

  // IMPROVE: need better renderers for complex data types
  switch (question.data_type) {
    case "geo_point":
      return formatCoordinateDegrees(answer.value);
    case "file": {
      if (!answer.value?.name || !answer.value?.ref) {
        return "-";
      }

      return (
        <FileDownloadLink
          fileName={answer.value.name}
          fileUrl={answer.value.ref}
          prefetch={false}
        />
      );
    }
    case "lookup":
      if (!answer?.entity_type || !answer?.value) {
        return answer?.name ?? "-";
      }

      return (
        <RoutingLink
          to={linkToAssetDetailPage(answer?.entity_type, answer?.value)}
        >
          {answer?.name}
        </RoutingLink>
      );
    case "multi-pick-list": {
      if (!Array.isArray(answer.value)) return "-";

      const valuesToShow = answer.value.slice(0, 3);
      return valuesToShow.length < answer.value.length
        ? `${valuesToShow.join(", ")}, +${answer.value.length - valuesToShow.length} more`
        : valuesToShow.join(", ");
    }
    case "pick-list": {
      if (typeof answer.value !== "string" && !Array.isArray(answer.value))
        return "-";

      return answer.value;
    }
    case "date": {
      if (typeof answer.value !== "string" && !isValid(answer.value))
        return "-";

      return (
        <DataTable.DataRow.DateCell
          value={answer.value}
          withTime={false}
        />
      );
    }
    case "date-time": {
      if (typeof answer.value !== "string" && !isValid(answer.value))
        return "-";

      return (
        <DataTable.DataRow.DateCell
          value={answer.value}
          withTime
        />
      );
    }
    case "date-time-range":
      return `${datetimeFormatter(
        new Date(answer.value?.[0])
      )} - ${datetimeFormatter(new Date(answer.value?.[1]))}`;
    case "boolean": {
      if (!isBoolean(answer.value)) return "-";

      return booleanFormatter(Boolean(answer.value));
    }
    case "number": {
      if (Number(answer.value) > 10 ** 6) {
        return Number(answer.value).toPrecision(6);
      }

      return getFormattedNumber(answer.value, null, {
        errorFallback: "n/a",
        maxFractionDigits: 6,
        showSmallNumberAsExponential: true,
        smallNumberThreshold: 0.01,
      });
    }
    case "integer": {
      if (!isFiniteNumber(answer.value)) return "-";

      if (Number(answer.value) > 10 ** 6) {
        return Number(answer.value).toPrecision(6);
      }

      return answer.value;
    }
    case "string": {
      if (typeof answer.value !== "string") return "-";

      return answer.value;
    }
    default:
      return "n/a";
  }
};

export const renderFormSectionName = (
  sectionId: string,
  section_index: number,
  schema: FormSchemaType
) => {
  const sectionName =
    schema?.config?.sections?.find((section) => section.id === sectionId)
      ?.name || "";

  const sectionIndexAsNumber = Number(section_index);
  return `${sectionName} ${!isNaN(sectionIndexAsNumber) ? sectionIndexAsNumber + 1 : ""}`;
};
