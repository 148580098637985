import { linkToDeviceDetail } from "#src/Routers/links";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { linkToEstimationMethodDetailPage } from "#src/utils/links";
import { useQuery } from "@tanstack/react-query";
import {
  DefaultRecordValueConfigurationType,
  DefaultRecordValueConfigurationTypesType,
  Device,
  DefaultRecordValueConfigurationTypeTypes as DRVCTypeTypes,
  EstimationMethodDomain,
  FormSchemaAdapter,
} from "@validereinc/domain";

export const useDRVCTargetResolve = ({
  drvc,
}: {
  drvc?: DefaultRecordValueConfigurationType;
}) => {
  const DRVCFormSchemasFilter = {
    filters: {
      id: drvc?.configuration?.filter["form_schema.id"],
    },
  };

  const isDRVCFormSchemasQueryEnabled =
    drvc?.configuration?.configuration_type ===
      (DRVCTypeTypes.MEASUREMENT as DefaultRecordValueConfigurationTypesType) &&
    Array.isArray(DRVCFormSchemasFilter.filters.id?.$in) &&
    DRVCFormSchemasFilter.filters.id.$in.length > 0;

  const formSchemasQuery = useQuery({
    queryKey: ["DRVC-formSchemas", DRVCFormSchemasFilter],
    queryFn: () => FormSchemaAdapter.getList(DRVCFormSchemasFilter),
    enabled: isDRVCFormSchemasQueryEnabled,
  });

  const sortedFormSchemasByID = Object.fromEntries(
    (formSchemasQuery?.data?.data ?? []).map((formSchema) => [
      formSchema.id,
      {
        name: formSchema.name,
        url: linkToFormTemplateDetail(
          formSchema.form_category_id,
          formSchema.id
        ),
      },
    ])
  );

  let device_ids;
  const deviceId = drvc?.configuration?.filter?.device_id;
  if (Array.isArray(deviceId?.$in)) {
    device_ids = deviceId?.$in;
  } else if (deviceId) {
    device_ids = [deviceId];
  }

  const DRVCDevicesFilter = {
    filters: {
      id: device_ids,
    },
  };

  const isDRVCDevicesQueryEnabled =
    drvc?.configuration?.configuration_type ===
      (DRVCTypeTypes.MEASUREMENT_SERIES as DefaultRecordValueConfigurationTypesType) &&
    Array.isArray(DRVCDevicesFilter.filters.id) &&
    DRVCDevicesFilter.filters.id.length > 0;

  const devicesQuery = useQuery({
    queryKey: ["DRVC-devices", DRVCDevicesFilter],
    queryFn: () => Device.getList(DRVCDevicesFilter),
    enabled: isDRVCDevicesQueryEnabled,
  });

  const sortedDevicesByID = Object.fromEntries(
    (devicesQuery?.data?.data ?? []).map((device) => [
      device.id,
      { name: device.name, url: linkToDeviceDetail(device.id) },
    ])
  );

  let estimation_method_ids;
  const estimationMethodId =
    drvc?.configuration?.filter?.["estimation_method.id"];
  if (Array.isArray(estimationMethodId?.$in)) {
    estimation_method_ids = estimationMethodId?.$in;
  } else if (estimationMethodId) {
    estimation_method_ids = [estimationMethodId];
  }

  const DRVCEstimationMethodFilter = {
    filters: {
      id: estimation_method_ids,
    },
  };

  const isDRVCEstimationMethodQueryEnabled =
    drvc?.configuration?.configuration_type ===
      (DRVCTypeTypes.CALCULATION as DefaultRecordValueConfigurationTypesType) &&
    Array.isArray(DRVCEstimationMethodFilter.filters.id) &&
    DRVCEstimationMethodFilter.filters.id.length > 0;

  const estimationMethodQuery = useQuery({
    queryKey: ["DRVC-estimation-methods", DRVCEstimationMethodFilter],
    queryFn: () => EstimationMethodDomain.getList(DRVCEstimationMethodFilter),
    enabled: isDRVCEstimationMethodQueryEnabled,
  });

  const sortedEstimationMethodsByID = Object.fromEntries(
    (estimationMethodQuery?.data?.data ?? []).map((estimationMethod) => [
      estimationMethod.id,
      {
        name: estimationMethod.name,
        url: linkToEstimationMethodDetailPage(
          estimationMethod.entity_type,
          estimationMethod.asset_id ?? "",
          estimationMethod.id
        ),
      },
    ])
  );

  let resolvedValues = [];
  let dateRange = "Undefined Date Range";
  let window = "";

  if (
    drvc?.configuration?.configuration_type ===
    (DRVCTypeTypes.MEASUREMENT as DefaultRecordValueConfigurationTypesType)
  ) {
    const formSchemaIdConfig = drvc?.configuration?.filter["form_schema.id"];
    if (Array.isArray(formSchemaIdConfig?.$in)) {
      resolvedValues = formSchemaIdConfig?.$in.map((formSchemaId) => ({
        name:
          sortedFormSchemasByID[formSchemaId]?.name || "Unknown Form Schema",
        url: sortedFormSchemasByID[formSchemaId]?.url,
      }));
    } else if (drvc?.configuration?.filter?.["form_category.name"]) {
      resolvedValues = [
        { name: drvc?.configuration?.filter?.["form_category.name"] },
      ];
    }
  }

  if (
    drvc?.configuration?.configuration_type ===
    (DRVCTypeTypes.MEASUREMENT_SERIES as DefaultRecordValueConfigurationTypesType)
  ) {
    const deviceIdConfig = drvc?.configuration?.filter?.device_id;

    if (
      drvc?.configuration?.filter?.start &&
      drvc?.configuration?.filter?.end
    ) {
      dateRange = `${drvc?.configuration?.filter?.start} - ${drvc?.configuration?.filter?.end}`;
    }

    if (Array.isArray(deviceIdConfig?.$in)) {
      resolvedValues = deviceIdConfig?.$in.map((deviceId: string) => ({
        name: sortedDevicesByID[deviceId]?.name || "Unknown Device",
        url: sortedDevicesByID[deviceId]?.url,
      }));
    } else if (deviceIdConfig) {
      resolvedValues = [
        {
          name: sortedDevicesByID[deviceIdConfig]?.name || "Unknown Device",
          url: sortedDevicesByID[deviceIdConfig]?.url,
        },
      ];
    }
  }

  if (
    drvc?.configuration?.configuration_type ===
    (DRVCTypeTypes.CALCULATION as DefaultRecordValueConfigurationTypesType)
  ) {
    const estimationMethodIdConfig =
      drvc?.configuration?.filter["estimation_method.id"];

    if (Array.isArray(estimationMethodIdConfig?.$in)) {
      resolvedValues = estimationMethodIdConfig?.$in.map(
        (estimationMethodId: string) => ({
          name:
            sortedEstimationMethodsByID[estimationMethodId]?.name ||
            "Unknown Estimation Method",
          url: sortedEstimationMethodsByID[estimationMethodId]?.url,
        })
      );
    } else if (estimationMethodIdConfig) {
      resolvedValues = [
        {
          name:
            sortedEstimationMethodsByID[estimationMethodIdConfig]?.name ||
            "Unknown Estimation Method",
          url: sortedEstimationMethodsByID[estimationMethodIdConfig]?.url,
        },
      ];
    }
  }

  const isLoading =
    (isDRVCFormSchemasQueryEnabled && formSchemasQuery.isLoading) ||
    (isDRVCDevicesQueryEnabled && devicesQuery.isLoading) ||
    (isDRVCEstimationMethodQueryEnabled && estimationMethodQuery.isLoading);

  if (drvc?.configuration?.window) {
    const windowValue = drvc.configuration.window;
    let unit = "";
    if (windowValue.endsWith("year")) unit = "year";
    if (windowValue.endsWith("month")) unit = "month";
    const value = Number(windowValue.replace(unit, ""));
    if (!isNaN(value)) {
      window = `${value} ${`${unit}${value > 1 ? "s" : ""}`}`;
    }
  }

  return { resolvedValues, isLoading, dateRange, window };
};
