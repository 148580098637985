import { useNavigate, useParams } from "#routers/hooks";
import { linkToDeviceDetail } from "#routers/links";
import RecordValueConfigureSourcesButton from "#src/batteries-included-components/Buttons/RecordValueConfigureSourcesButton/RecordValueConfigureSourcesButton";
import { AddRecordNoteDialog } from "#src/batteries-included-components/Dialogs/AddRecordNoteDialog";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { RecordProvider } from "#src/contexts/RecordContext";
import {
  RecordValueContext,
  RecordValueProvider,
} from "#src/contexts/RecordValueContext";
import { linkToRecordValueSourceDetail } from "#utils/links";
import { getSourceName, getSourcePath } from "#utils/recordUtils";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  RecordValueConfigurationSourceType,
  RecordValueConfigurationTypeType,
  RecordValueConfigurationTypeTypeType,
  isRecordValueLocked as isRecordValueLockedFunction,
} from "@validereinc/domain";
import React, { useContext, useMemo, useState } from "react";

export const RecordValueSourcesTablePanelContent = ({
  type,
}: {
  type: AssetTypeType;
}) => {
  const {
    measurementType: measurementTypeId,
    recordId,
    facilityId,
    equipmentId,
    flowId,
  } = useParams<{
    measurementType: string;
    recordId: string;
    flowId: string;
    equipmentId: string;
    facilityId: string;
  }>();
  const navigate = useNavigate();
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const { getUnitName } = useMeasurementTypes();
  const { recordValue, measurementType, isLoading } =
    useContext(RecordValueContext) ?? {};

  const isRecordValueLocked =
    recordValue && isRecordValueLockedFunction(recordValue);

  const sources = useMemo(() => {
    // For manual configuration, sources table need to be manually populated:

    if (
      recordValue?.configuration.configuration_type ===
      RecordValueConfigurationTypeType.MANUAL
    ) {
      return [
        {
          name: "Manual Entry",
          value: recordValue.value,
          unit: recordValue.measurement_unit,
        },
      ];
    }

    // Other configuration types:
    return recordValue?.configuration?.sources ?? [];
  }, [recordValue]);
  const configurationType =
    recordValue?.configuration.configuration_type ??
    RecordValueConfigurationTypeType.MANUAL;

  const navigateToAddSource = () => {
    navigate({
      pathname: linkToRecordValueSourceDetail(
        type,
        facilityId || equipmentId || flowId,
        recordId,
        measurementTypeId
      ),
    });
  };

  const name = measurementType?.name ?? "-";

  const headersByConfiguration: Record<
    RecordValueConfigurationTypeTypeType,
    Array<HeaderType<RecordValueConfigurationSourceType>>
  > = {
    [RecordValueConfigurationTypeType.MEASUREMENT_SERIES]: [
      {
        key: "device_name",
        label: "Name",
        renderComponent: ({ item }) => (
          <RoutingLink to={linkToDeviceDetail(item.device_id)}>
            {item.device_name}
          </RoutingLink>
        ),
      },
      {
        key: "start",
        label: "Start",
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell
            value={item?.configuration?.start}
            withTime
          />
        ),
      },
      {
        key: "end",
        label: "End",
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell
            value={item?.configuration?.end}
            withTime
          />
        ),
      },
    ],
    [RecordValueConfigurationTypeType.MEASUREMENT]: [
      {
        key: "form_schema_name",
        label: "Name",
        renderComponent: ({ item }) => (
          <RoutingLink to={getSourcePath(configurationType, item)}>
            {getSourceName(configurationType, item)}
          </RoutingLink>
        ),
      },
      {
        key: "value",
        label: measurementType?.name,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.NumberCell
            value={item.value}
            unit={getUnitName(item.unit)}
          />
        ),
      },
    ],
    [RecordValueConfigurationTypeType.CALCULATION]: [
      {
        key: "estimation_method_name",
        label: "Name",
        renderComponent: ({ item }) => (
          <RoutingLink
            to={{
              pathname: getSourcePath(
                RecordValueConfigurationTypeType.CALCULATION,
                item
              ),
              search:
                sources[0]?.estimation_method_entity_type ===
                AssetType.ASSET_GROUP
                  ? `?${new URLSearchParams({
                      tab: "calculations",
                      period: sources[0].year_month ?? "",
                    }).toString()}`
                  : "",
            }}
          >
            {getSourceName(configurationType, item)}
          </RoutingLink>
        ),
      },
      {
        key: "value",
        label: measurementType?.name,
        renderComponent: ({
          item,
        }: {
          item: RecordValueConfigurationSourceType;
        }) => (
          <DataTable.DataRow.NumberCell
            value={item.value}
            unit={getUnitName(item.unit)}
          />
        ),
      },
    ],
    [RecordValueConfigurationTypeType.MANUAL]: [
      {
        key: "name",
        label: "Name",
      },
      {
        key: "value",
        label: measurementType?.name,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.NumberCell
            value={item.value}
            unit={getUnitName(item.unit)}
          />
        ),
      },
    ],
  };

  return (
    <>
      <DataTablePanel
        panelProps={{ title: "Sources", isFluidY: false }}
        dataTableProps={{
          headers: sources.length
            ? headersByConfiguration[configurationType] ?? []
            : [],
          items: sources,
          getItemActions: () => [
            {
              label: "Add Note",
              buttonProps: {
                onClick: () => {
                  setIsNoteModalOpen(true);
                },
                icon: "note-pencil",
              },
            },
            {
              label: "Edit",
              buttonProps: {
                onClick: () => {
                  navigateToAddSource();
                },
                icon: "pencil-simple",
              },
            },
          ],
          emptyStateProps: {
            title: `There are no sources to show`,
            suggestion: `Click "Add Source" to define how the ${name.toLowerCase()} record is created.`,
            action: (
              <Button
                variant="primary"
                onClick={navigateToAddSource}
              >
                Add Source
              </Button>
            ),
          },
          isLoading,
        }}
        actionRowWhenNoRowsSelected={
          sources.length
            ? [
                <Button
                  icon="note-pencil"
                  key="add note"
                  onClick={() => {
                    setIsNoteModalOpen(true);
                  }}
                >
                  Add Note
                </Button>,
                <RecordValueConfigureSourcesButton
                  key="manage-sources"
                  assetId={facilityId || equipmentId || flowId}
                  assetType={type}
                  measurementType={measurementTypeId}
                  recordId={recordId}
                  isLocked={isRecordValueLocked}
                />,
              ]
            : []
        }
      />
      <AddRecordNoteDialog
        isOpen={isNoteModalOpen}
        recordId={recordId}
        recordValue={recordValue}
        onClose={() => {
          setIsNoteModalOpen(false);
        }}
      ></AddRecordNoteDialog>
    </>
  );
};

export const RecordValueSourcesTablePanel = ({
  type,
}: {
  type: AssetTypeType;
}) => (
  <RecordProvider>
    <RecordValueProvider>
      <RecordValueSourcesTablePanelContent type={type} />
    </RecordValueProvider>
  </RecordProvider>
);
