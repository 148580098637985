import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { FormSchemasTablePanel } from "#src/batteries-included-components/Panels/TablePanels/FormSchemasTablePanel";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { BreadcrumbType, Page } from "@validereinc/common-components";
import React from "react";

const FormSchemaListPage = ({ breadcrumbs }: FormSchemaListPageProps) => {
  const storageKeys = useStorageKey("forms-templates");

  return (
    <Page
      title="Templates"
      activeTabKey="submissions"
      breadcrumbs={useBreadcrumbs(breadcrumbs)}
    >
      <FormSchemasTablePanel {...storageKeys} />
    </Page>
  );
};

type FormSchemaListPageProps = {
  breadcrumbs: BreadcrumbType[];
};

export default FormSchemaListPage;
