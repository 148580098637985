import {
  RecordValueConfigurationContext,
  RecordValueConfigurationContextProvider,
} from "#batteries-included-components/Layouts/RecordConfiguration/RecordConfigurationContext";
import { useFile } from "#src/hooks/useFile";
import {
  Banner,
  Button,
  Dialog,
  FileInput,
  Form,
  Icon,
  Page,
  TextInput,
  Tooltip,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  RecordDomain,
  RecordValueConfigurationType,
  RecordValueUpdateType,
  isRecordValueLocked as isRecordValueLockedFunction,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useContext, useState } from "react";
import { RecordConfigurationPanel } from "../RecordConfigurationPanel";

import styles from "./RecordValueSourceConfigurationPage.module.scss";
const cx = classNames.bind(styles);

export type RecordConfigurationFormType = RecordValueConfigurationType &
  Pick<RecordValueUpdateType, "measurement_unit">;

export type RecordValueSourceConfigurationPagePropsType = {
  pageProps: any; // TODO: Get this type from common-components
  onClose: () => void;
};

const SaveButton = ({
  isLocked,
  onClick,
  isDisabled,
}: {
  isLocked: boolean;
  onClick: () => void;
  isDisabled: boolean;
}) => {
  const button = (
    <Button
      variant="primary"
      onClick={onClick}
      disabled={isDisabled || isLocked}
      type="button"
      icon={isLocked ? "lock" : ""}
    >
      Save
    </Button>
  );

  if (isLocked) {
    return (
      <Tooltip
        content="You cannot edit because the record is locked."
        title="Locked Record Value"
      >
        {button}
      </Tooltip>
    );
  }
  return button;
};

export const RecordValueSourceConfigurationPageContent = ({
  pageProps,
  onClose,
}: RecordValueSourceConfigurationPagePropsType) => {
  const { addAlert } = useAlert();

  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const noteForm = useForm({});

  const { record, recordValue, isLoading, form } =
    useContext(RecordValueConfigurationContext) || {};

  const { status, upload } = useFile();

  const handleSubmit = async ({
    measurement_unit,
    ...configuration
  }: RecordConfigurationFormType) => {
    if (!record?.id || !recordValue?.measurement_type) {
      return;
    }

    let body = {
      measurement_unit,
      configuration,
    } as object;

    if (configuration.value) {
      configuration.value = Number(configuration.value);
    }

    const noteFormValues = noteForm.getValues();

    if (noteFormValues?.attachment?.item(0)) {
      try {
        const { name: attachment_filename, ref: attachment_ref } = await upload(
          {
            file: noteFormValues.attachment.item(0),
            description:
              "A user-uploaded file through record value notes (and attachments) - IN DEVELOPMENT",
          }
        );
        body = { ...body, attachment_filename, attachment_ref };
      } catch (e) {
        addAlert({
          variant: "error",
          message: "Unable to upload attachment",
        });
        return;
      }
    }

    if (noteFormValues?.note?.trim()) {
      body = { ...body, note: noteFormValues.note.trim() };
    }

    try {
      await RecordDomain.recordValue.update(
        {
          recordId: record?.id,
          measurementType: recordValue?.measurement_type,
        },
        body as RecordValueUpdateType
      );
      onClose?.();
    } catch (err) {
      console.error(err);
      addAlert({
        variant: "error",
        message: "Unable to update record configuration",
      });
    }
  };

  const isRecordValueLocked =
    !!recordValue && isRecordValueLockedFunction(recordValue);

  const footer = (
    <>
      <Button
        onClick={onClose}
        disabled={form?.formState.isSubmitting}
      >
        Cancel
      </Button>
      <SaveButton
        isLocked={isRecordValueLocked}
        onClick={() => setIsNoteModalOpen(true)}
        isDisabled={isLoading || form?.formState.isSubmitting}
      />
    </>
  );

  const renderNotesModal = () => (
    <Dialog
      title="Include Note"
      isOpen={isNoteModalOpen}
      onClose={() => {
        if (form?.formState.isSubmitting) return;
        setIsNoteModalOpen(false);
      }}
      actionRow={[
        <Button
          onClick={form?.handleSubmit(handleSubmit)}
          variant="primary"
          disabled={form?.formState.isSubmitting}
          key="done-button"
        >
          Done
        </Button>,
      ]}
    >
      <Form {...noteForm}>
        <TextInput
          name="note"
          label="Note"
          isRequired={false}
        />

        <FileInput
          name="attachment"
          label="Attachment"
          placeholder="You can drag a file here to use as an attachment (Max. 20MB)"
          showIcon={true}
          isRequired={false}
          uploadStatus={status}
        />
      </Form>
    </Dialog>
  );

  return (
    <Form
      {...form}
      onSubmit={form?.handleSubmit(handleSubmit)}
    >
      <Page
        {...pageProps}
        isLoading={isLoading}
        footer={footer}
      >
        {isRecordValueLocked && (
          <Banner
            titleText="This record value is locked and cannot be edited."
            variant="generic"
            icon={<Icon variant="lock-simple" />}
            className={cx("lockedBanner")}
          />
        )}
        <RecordConfigurationPanel />
        {renderNotesModal()}
      </Page>
    </Form>
  );
};

export const RecordValueSourceConfigurationPage = (
  props: RecordValueSourceConfigurationPagePropsType
) => {
  return (
    <RecordValueConfigurationContextProvider>
      <RecordValueSourceConfigurationPageContent {...props} />
    </RecordValueConfigurationContextProvider>
  );
};
