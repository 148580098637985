import { restAPI } from ".";
import {
  AssetTypeType,
  DeviceType,
  EquipmentType,
  FacilityType,
  type FormSubmissionType,
} from "../schemas";
import type { FilterObjectType, GetListRequestType } from "../util";
import {
  GetListResponseType,
  ResponseStatusType,
  UpdateOneResponseType,
  getFilterObject,
} from "../util";

export type EventGetListFilterType = FilterObjectType<
  Pick<EventDetailsType, "name" | "status" | "associated_facility">
>;

// IMPROVE: create this type from a schema instead
export type EventDetailsType = {
  created_at: string;
  created_by: string;
  due_date: string;
  id: string;
  company_id: string;
  name: string;
  description: string;
  status: EventStatusType;
  classification: string;
  start: string;
  end: string;
  updated_at: string;
  longitude: string;
  latitude: string;
  duration: string;
  associated_facility: FacilityType;
  associated_facility_id: string;
};

// TODO: undo this - this was a temporary type made to fix the issues that came
// from enriching data returned from the methods in this adapter
export type EnrichedEventDetailsType = EventDetailsType & {
  form_submissions: {
    data: FormSubmissionType[];
    total: number;
  };
  equipment: {
    data: EquipmentType[];
    total: number;
  };
  devices: {
    data: DeviceType[];
    total: number;
  };
  alerts: {
    data: EventAlertType[];
    total: number;
  };
  facility: FacilityType;
};

// TODO: use utility type here
// TODO: confirm sort_by and sort_direction in the referenced EventsAllRowType are actually returned
// https://github.com/ValidereInc/validere-frontend/pull/531#discussion_r1221871661
type EventsAllRowType = {
  name: string;
  description: string;
  status: string;
  classification: string;
  start_window: string;
  end_window: string;
  sort_by: string;
  sort_direction: string;
};

// TODO: use utility type here
type EventsAllRowListType = EventsAllRowType[];

export type SavedEventFilterType = {
  id: string;
  company_id: string;
  entity_type: AssetTypeType;
  name: string;
  filter: any;
};

export const EventStatus = {
  open: "open",
  overdue: "overdue",
  dismissed: "dismissed",
  resolved: "resolved",
} as const;

export type EventStatusType = (typeof EventStatus)[keyof typeof EventStatus];

// TODO: remove unnecessary duplication of base type
export type GetEventResponseType = {
  created_at: string;
  created_by: string;
  id: string;
  company_id: string;
  name: string;
  description: string;
  status: EventStatusType;
  classification: string;
  start: string;
  end: string;
  updated_at: string;
};

// TODO: use utility type here
export type GetAllEventsResponseType = {
  page_size: number;
  page_number: number;
  total_entries: number;
  total_pages: number;
  data: EventsAllRowListType;
};

type EventAlertType = {
  id?: string;
  alert_id?: string;
  eventId?: string;
  monitor?: { name: string };
  condition_descriptions?: string[];
  resolved?: string;
  data: any;
};

const eventEndpoint = "/events";

export const EventsDomain = {
  /**
   * Get a list of alert related events
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/list_event_alerts
   * @see https://staging-alerting-api.validere.xyz/swaggerui#/Alert%20Management/AlertingWeb.API.V1.AlertController.index
   * @returns the standard wrapped API response of this endpoint
   */
  getListEventsAndAlerts: async ({ eventId }: EventAlertType) => {
    // 1. get all alerts for event
    const events = await restAPI.nodeAPI.GET<EventAlertType[]>({
      endpoint: `/events/${eventId}/alerts`,
    });

    // get all the alert ids and use them in the next query as one array of ids
    const alertIds = events.map(({ alert_id }: EventAlertType) => alert_id);

    // 2. get status of each alert
    const alertResponses = await restAPI.alertingAPI.GET<EventAlertType>({
      endpoint: `/alerts/`,
      query: {
        id: alertIds as string[],
      },
    });

    // 3. return array of alerts with status included
    return alertResponses.data.map((alertDetails: EventAlertType) => {
      const originalEvent = events.find(
        (event: EventAlertType) => event.alert_id === alertDetails.id
      );

      return {
        ...originalEvent,
        total_entries: events.length, // this endpoint doesn't have proper pagination so total_entries is not available but we can use length since it returns everything
        device: alertDetails?.monitor?.name ?? "",
        alert_condition_satisfied:
          alertDetails.condition_descriptions?.toString() ?? "",
        status: alertDetails.resolved ? "resolved" : "active",
      };
    });
  },

  /**
   * Get a single event
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/get_event
   * @returns the standard wrapped API response of this endpoint
   */
  getEvent: async ({ eventId }: { eventId: string }) =>
    await restAPI.nodeAPI.GET<EventDetailsType>({
      endpoint: `/events/${eventId}`,
    }),

  /**
   * Geta a list of events
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/list_events
   * @returns
   */
  getList: async ({
    filters,
    page,
    pageSize,
    sortBy,
    sortDirection,
  }: GetListRequestType<EventGetListFilterType>): Promise<
    GetListResponseType<EventDetailsType>
  > =>
    await restAPI.nodeAPI.POST({
      endpoint: `${eventEndpoint}/search?event_schema=true`,
      body: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    }),

  /**
   * Get a single event
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/get_event
   * @returns the standard wrapped API response of this endpoint
   */
  getOne: async ({ eventId }: { eventId: string }) => {
    const event = await restAPI.nodeAPI.GET<EventDetailsType>({
      endpoint: `/events/${eventId}`,
    });

    const { data: facilitiesData } = await restAPI.nodeAPI.POST<
      GetListResponseType<FacilityType>
    >({
      endpoint: `/facilities/search`,
      body: {
        page: 1,
        page_size: 1000,
        filter: { "event.id": eventId },
      },
    });

    let alerts: { data: EventAlertType[]; total: number } = {
      data: [],
      total: 0,
    };

    const eventAlerts = await restAPI.nodeAPI.GET<
      Array<{ event_id: string; alert_id: string }>
    >({ endpoint: `/events/${eventId}/alerts` });

    if (eventAlerts.length) {
      const { data: alertData, total_entries } = await restAPI.alertingAPI.GET<
        GetListResponseType<EventAlertType>
      >({
        endpoint: `/alerts`,
        query: {
          id: eventAlerts.map(({ alert_id }) => alert_id),
        },
      });

      alerts = { data: alertData, total: total_entries };
    }

    const { data: formSubmissionData, total_entries: totalFormSubmissions } =
      await restAPI.nodeAPI.POST<GetListResponseType<FormSubmissionType>>({
        endpoint: `/form_submissions/search?answers=true`,
        body: {
          page: 1,
          page_size: 1000,
          filter: { "event.id": eventId },
        },
      });

    const { data: equipmentData, total_entries: totalEquipment } =
      await restAPI.nodeAPI.POST<GetListResponseType<EquipmentType>>({
        endpoint: `/equipment/search`,
        body: {
          page: 1,
          page_size: 1000,
          filter: { "event.id": eventId },
        },
      });

    const { data: devicesData, total_entries: totalDevices } =
      await restAPI.nodeAPI.POST<GetListResponseType<DeviceType>>({
        endpoint: `/devices/search`,
        body: {
          page: 1,
          page_size: 1000,
          filter: { "event.id": eventId },
        },
      });

    return {
      ...event,
      facility: facilitiesData[0],
      alerts,
      // TODO: undo this. don't mutate the original shape ... makes the return type unreliable
      form_submissions: {
        data: formSubmissionData,
        total: totalFormSubmissions,
      },
      // TODO: undo this. don't mutate the original shape ... makes the return type unreliable
      equipment: { data: equipmentData, total: totalEquipment },
      // TODO: undo this. don't mutate the original shape ... makes the return type unreliable
      devices: { data: devicesData, total: totalDevices },
    };
  },

  /**
   * Get a list of events
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/list_events
   * @returns the standard wrapped API response of this endpoint
   */
  getListEvents: async ({
    name,
    description,
    classification,
    start_window,
    end_window,
    sort_by,
    sort_direction,
  }: EventsAllRowType) =>
    await restAPI.nodeAPI.GET<GetAllEventsResponseType>({
      endpoint: `/events`,
      query: {
        page_size: 1000,
        name,
        description,
        classification,
        start_window,
        end_window,
        sort_by,
        sort_direction,
      },
    }),

  /**
   * Create a new event
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/create_event
   * @returns the newly created event
   */
  create: async (body: {
    name: string;
    description?: string;
    classification: string;
    status: string;
    start: string;
    end: string;
    event_schema_id: string;
    completed_at?: string;
    due_date?: string;
    associated_facility_id: string;
  }) =>
    await restAPI.nodeAPI.POST<GetEventResponseType>({
      endpoint: `/events`,
      body,
    }),

  /**
   * Edit an event
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/update_event
   * @returns the edited event
   */
  edit: async ({
    eventId,
    ...body
  }: {
    eventId: string;
    name: string;
    description?: string;
    classification: string;
    status: string;
    start: string;
    end: string;
    event_schema_id: string;
    completed_at?: string;
    due_date?: string;
  }) => {
    return await restAPI.nodeAPI.PUT<GetEventResponseType>({
      endpoint: `/events/${eventId}`,
      body,
    });
  },

  /**
   * Get a list of saved event filters
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/get_events_filter
   * @returns the standard wrapped API response of this endpoint
   */
  getAllSavedEventsFilters: async () =>
    await restAPI.nodeAPI.GET<GetListResponseType<EventAlertType>>({
      endpoint: `/events/filters`,
    }),

  /**
   * Post an event filter to save it
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/post_events_filter
   * @returns the standard wrapped API response of this endpoint
   */
  saveEventsFilter: async (name: string, filter: Record<string, any>) =>
    await restAPI.nodeAPI.POST<{
      data: { id: string; filter: any };
    }>({
      endpoint: `/events/filters`,
      body: { name, filter },
    }),

  /**
   * Put an event filter to update it
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/put_events_filter
   * @returns the standard wrapped API response of this endpoint
   */
  editEventFilter: async (id: string, filter: Record<string, any>) =>
    // REVIEW: update typing
    await restAPI.nodeAPI.PUT<UpdateOneResponseType<any>>({
      endpoint: `/events/filters/${id}`,
      body: { filter },
    }),

  /**
   * Delete an event filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/events/delete_events_filter
   * @returns the standard wrapped API response of this endpoint
   */
  deleteEventsFilter: async (id: string) =>
    // REVIEW: verify this response
    await restAPI.nodeAPI.DELETE<ResponseStatusType>({
      endpoint: `/events/filters/${id}`,
    }),

  eventStatuses: {
    getList: async () =>
      await restAPI.nodeAPI.GET<Array<{ id: string; name: string }>>({
        endpoint: "/event_statuses",
      }),
  },

  facility: {
    addToEvent: async ({
      eventId,
      facilityId,
    }: {
      eventId: string;
      facilityId: string;
    }) =>
      await restAPI.nodeAPI.PUT({
        endpoint: `/events/${eventId}/asset/${facilityId}`,
      }),
  },

  devices: {
    getList: async ({ eventId }: { eventId: string }) =>
      await restAPI.nodeAPI.POST<Array<{ event_id: string; alert_id: string }>>(
        {
          endpoint: `/devices/search`,
          body: {
            page: 1,
            page_size: 1000,
            filter: { "event.id": eventId },
          },
        }
      ),

    addToEvent: async ({
      eventId,
      deviceId,
    }: {
      eventId: string;
      deviceId: string;
    }) =>
      await restAPI.nodeAPI.PUT({
        endpoint: `/events/${eventId}/asset/${deviceId}`,
      }),

    removeOne: async ({
      eventId,
      deviceId,
    }: {
      eventId: string;
      deviceId: string;
    }) =>
      await restAPI.nodeAPI.DELETE({
        endpoint: `/events/${eventId}/asset/${deviceId}`,
      }),
  },

  formSubmissions: {
    addToEvent: async ({
      eventId,
      formSubmissionId,
    }: {
      eventId: string;
      formSubmissionId: string;
    }) =>
      await restAPI.nodeAPI.PUT({
        endpoint: `/events/${eventId}/form_submissions/${formSubmissionId}`,
      }),

    removeOne: async ({
      eventId,
      formSubmissionId,
    }: {
      eventId: string;
      formSubmissionId: string;
    }) =>
      await restAPI.nodeAPI.DELETE({
        endpoint: `/events/${eventId}/form_submissions/${formSubmissionId}`,
      }),
  },

  equipment: {
    addToEvent: async ({
      eventId,
      equipmentId,
    }: {
      eventId: string;
      equipmentId: string;
    }) =>
      await restAPI.nodeAPI.PUT({
        endpoint: `/events/${eventId}/asset/${equipmentId}`,
      }),
    removeOne: async ({
      eventId,
      equipmentId,
    }: {
      eventId: string;
      equipmentId: string;
    }) =>
      await restAPI.nodeAPI.DELETE({
        endpoint: `/events/${eventId}/asset/${equipmentId}`,
      }),
  },

  alerts: {
    removeOne: async ({
      eventId,
      alertId,
    }: {
      eventId: string;
      alertId: string;
    }) =>
      await restAPI.nodeAPI.DELETE({
        endpoint: `/events/${eventId}/alerts/${alertId}`,
      }),
  },
};
