import {
  EntityDropdownInput,
  EntityDropdownProps,
} from "#src/batteries-included-components/Dropdowns/EntityDropdownInput";
import type { WorkflowTasksViewFiltersType } from "#src/batteries-included-components/FilterAreas/WorkflowTasksFilterAreas";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { DropdownInput, StorageKeys } from "@validereinc/common-components";
import {
  UserGroupType,
  UserGroupsAdapter,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import React, { useState } from "react";

const defaultSelectedGroup = "users";

// TODO: this component needs to be re-wired when the "groups" pattern within
// the Dropdown component itself is nixed / re-architected
const WorkflowTaskUserGroupDropdown = (
  props: Omit<
    EntityDropdownProps<UserType | UserGroupType>,
    "fetchEntityList"
  > &
    Pick<StorageKeys, "viewConfigStorageKey">
) => {
  const { viewConfigStorageKey, name, isInline, ...restProps } = props;
  const [activeGroup, setActiveGroup] = useState(defaultSelectedGroup);
  const [filters, setFilters] =
    useSessionStickyState<WorkflowTasksViewFiltersType>(
      {},
      viewConfigStorageKey
    );

  return (
    <>
      <DropdownInput
        name="assignee_type"
        label="Assignee"
        placeholder="Select Assignee Type..."
        options={[
          { id: "users", name: "Users" },
          { id: "groups", name: "User Groups" },
        ]}
        labelKey="name"
        valueKey="id"
        defaultValue={defaultSelectedGroup}
        onChange={(val) => {
          setActiveGroup(val);
          setFilters((prev) => ({ ...prev, assignee_type: val }));
        }}
        isInline={isInline}
        isLabelShown={!isInline}
        description={
          isInline
            ? "To filter by Assignee, select the assignee type first."
            : ""
        }
      />
      {activeGroup === "users" && filters.assignee_type ? (
        <EntityDropdownInput<UserType | UserGroupType>
          name={name}
          isMulti={false}
          fetchEntityList={UsersAdapter.getList}
          sort={{
            sortBy: "name",
            sortDirection: "asc",
          }}
          isLabelShown={false}
          isInline={isInline}
          {...restProps}
        />
      ) : null}
      {activeGroup === "groups" && filters.assignee_type ? (
        <EntityDropdownInput<UserType | UserGroupType>
          name={name}
          isMulti={false}
          fetchEntityList={UserGroupsAdapter.getList}
          sort={{
            sortBy: "name",
            sortDirection: "asc",
          }}
          isLabelShown={false}
          isOptionalTextShown={false}
          isInline={isInline}
          {...restProps}
        />
      ) : null}
    </>
  );
};

export default WorkflowTaskUserGroupDropdown;
