import {
  EquipmentDropdownInput,
  FacilityDropdownInput,
  FlowDropdownInput,
} from "#src/batteries-included-components/Dropdowns";
import { FilterArea } from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import {
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
} from "#src/components/hooks/adapters/useFlows";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { SavedFilterTag } from "#src/components/hooks/FilterPanel/useSavedFilters";
import { FLOW_ATTRIBUTES } from "#src/components/hooks/tables/useFlowHeaders";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  FlowDomain,
  FlowStatusOptions,
  Resources,
} from "@validereinc/domain";
import React from "react";

export const FlowsViewFilterArea = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { localize } = useLocalization();

  return (
    <FilterArea.Root<{
      period: { from: Date; to: Date };
    }>
      storageKey={viewConfigStorageKey}
      defaultValues={{
        period: DEFAULT_DATE_RANGES.currentWholeMonth,
      }}
    >
      <FilterArea.Container
        aria-label={`View Filters for ${localize(`${AssetType.FLOW}_plural`)}`}
      >
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginBottom: 16,
              }}
            >
              <DateSelectorInput
                name={"period"}
                variant="month"
                label="Period"
                isInline
                isLabelShown={false}
                onChange={(val) => handleOnChange(val, "period")}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const FlowsTableFilterAreaContent = ({
  hasCustomAttributeFilters,
  hasSubAssetFilters,
}: {
  hasCustomAttributeFilters?: boolean;
  hasSubAssetFilters?: boolean;
}) => {
  const { localize } = useLocalization();
  const { customAttributeFilters: flowCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.FLOW,
      enabled: hasCustomAttributeFilters,
    });
  const flowTypes = useListFlowTypes().data ?? [];
  const productTypes = useListFlowProductTypes().data ?? [];
  const productCategories = useListFlowProductCategories().data ?? [];

  return (
    <>
      <Accordion defaultActiveKeys={[AssetType.FLOW]}>
        <Accordion.AccordionPanel
          dataKey={AssetType.FLOW}
          title={localize(`${AssetType.FLOW}_plural`)}
        >
          <FlowDropdownInput
            name={FLOW_ATTRIBUTES.NAME.key}
            placeholder={`Search ${localize(`${AssetType.FLOW}_plural`)}...`}
            isMulti
            isFluid
            isOptionalTextShown={false}
          />
          <DropdownInput
            name={FLOW_ATTRIBUTES.TYPE.key}
            label={FLOW_ATTRIBUTES.TYPE.label}
            options={flowTypes}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          <DropdownInput
            name={FLOW_ATTRIBUTES.STATUS.key}
            label={FLOW_ATTRIBUTES.STATUS.label}
            options={FlowStatusOptions}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          <DropdownInput
            name={FLOW_ATTRIBUTES.PRODUCT_CATEGORY.key}
            label={FLOW_ATTRIBUTES.PRODUCT_CATEGORY.label}
            options={productCategories}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          <DropdownInput
            name={FLOW_ATTRIBUTES.PRODUCT_TYPE.key}
            label={FLOW_ATTRIBUTES.PRODUCT_TYPE.label}
            options={productTypes}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          {hasCustomAttributeFilters ? flowCustomAttributeFilters : null}
        </Accordion.AccordionPanel>
        {hasSubAssetFilters ? (
          <>
            <Accordion.AccordionPanel
              dataKey={AssetType.FACILITY}
              title={localize(`${AssetType.FACILITY}_plural`)}
            >
              <FacilityDropdownInput
                name={FLOW_ATTRIBUTES.FACILITY.key}
                placeholder={`Search ${localize(`${AssetType.FACILITY}_plural`)}...`}
                isMulti
                isFluid
                isOptionalTextShown={false}
              />
            </Accordion.AccordionPanel>
            <Accordion.AccordionPanel
              dataKey={AssetType.EQUIPMENT}
              title={localize(`${AssetType.EQUIPMENT}_plural`)}
            >
              <EquipmentDropdownInput
                name={FLOW_ATTRIBUTES.EQUIPMENT.key}
                placeholder={`Search ${localize(`${AssetType.EQUIPMENT}_plural`)}...`}
                isMulti
                isFluid
                isOptionalTextShown={false}
              />
            </Accordion.AccordionPanel>
          </>
        ) : null}
      </Accordion>
    </>
  );
};

export const FlowsTableFilterArea = ({
  filterConfigStorageKey,
  hasCustomAttributeFilters = true,
  hasSubAssetFilters = true,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  hasCustomAttributeFilters?: boolean;
  hasSubAssetFilters?: boolean;
}) => {
  const { localize } = useLocalization();

  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container
        aria-label={`Filters for ${localize(`${AssetType.FLOW}_plural`)}`}
      >
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, marginBottom: 0 }}>
                <TextInput
                  name={FLOW_ATTRIBUTES.NAME.key}
                  label="Search"
                  isLabelShown={false}
                  placeholder={`Search ${localize(`${AssetType.FLOW}_plural`)}...`}
                  type="search"
                  isInline
                  onChange={(val) =>
                    handleOnChange(val, FLOW_ATTRIBUTES.NAME.key)
                  }
                />
              </div>
            )}
          </FilterArea.Content>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <FilterDrawer.SavedFilters
              config={{
                resourceType: Resources.FLOW,
                savedFilterResourceAdapter: FlowDomain.savedFilters,
                tag: SavedFilterTag.LIST,
                filterBlacklist: ["period.from", "period.to"],
              }}
            />
            <FilterDrawer.SavedFiltersAppliedIndicator />
            <FlowsTableFilterAreaContent
              hasCustomAttributeFilters={hasCustomAttributeFilters}
              hasSubAssetFilters={hasSubAssetFilters}
            />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
