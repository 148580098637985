import {
  TemplatedConfigurationInputCustomAttributeConfigurationType,
  TemplatedConfigurationInputType,
  TemplatedConfigurationType,
  type TemplatedConfigurationFilterType,
} from "../schemas/TemplatedConfigurationSchemas";
import {
  fetchAndCollate,
  getFilterObject,
  GetListResponseType,
  GetOneRequestType,
  GetOneResponseType,
  ResourceServiceType,
  type GetListRequestType,
} from "../util";
import { restAPI } from "./api";

interface TemplatedConfigurationServiceType
  extends Pick<
    ResourceServiceType<TemplatedConfigurationType>,
    "getList" | "getOne"
  > {
  getOne: (
    params: GetOneRequestType
  ) => Promise<GetOneResponseType<TemplatedConfigurationType>>;
  getList: (
    params: GetListRequestType<TemplatedConfigurationFilterType>
  ) => Promise<GetListResponseType<TemplatedConfigurationType>>;
  inputSchema: {
    getOne: (
      params: GetOneRequestType<{
        custom_attribute_configuration?: TemplatedConfigurationInputCustomAttributeConfigurationType;
      }>
    ) => Promise<GetOneResponseType<TemplatedConfigurationInputType>>;
  };
}

export const TemplatedConfigurationAdapter: TemplatedConfigurationServiceType =
  {
    getOne: ({ id }) =>
      restAPI.nodeAPI
        .GET<TemplatedConfigurationType>({
          endpoint: `/templated_configurations/${id}`,
        })
        .then((resp) => ({
          data: resp,
        })),

    getList: ({ filters, page, pageSize, sortBy, sortDirection }) =>
      fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST({
            endpoint: "/templated_configurations/search_company",
            body: {
              page,
              page_size: pageSize,
              sort_by: sortBy,
              sort_direction: sortDirection,
              filter: getFilterObject(filters),
            },
          }),
        page,
        pageSize
      ),

    inputSchema: {
      getOne: ({ id, meta }) =>
        restAPI.nodeAPI
          .POST<TemplatedConfigurationInputType>({
            endpoint: `/templated_configurations/${id}/input_schema`,
            body: meta,
          })
          .then((resp) => ({ data: resp })),
    },
  };
