import { validate as uuidValidate } from "uuid";

type DateRange = {
  from?: string | Date;
  to?: string | Date;
};

const isDateRange = (value: any): value is DateRange => {
  return value && typeof value === "object" && "from" in value && "to" in value;
};

/**
 * Formats a value to support the mongo-like syntax the backend expects.
 *
 * @param value The atomic value we want to send to the backend
 * @returns A filter value the backend can consume for filter endpoints
 */
export const getFilterValue = <T>(value: FilterKeyType<T>) => {
  const isSingleStringFilterValue = typeof value === "string" && value !== "";

  const isBooleanFilterValue = typeof value === "boolean";

  const isMultiStringFilterValue =
    Array.isArray(value) &&
    value?.length &&
    value.every((v) => typeof v === "string" && v !== "");

  const isExactFilterValue =
    value &&
    typeof value === "object" &&
    "$exact" in value &&
    value.$exact !== "";

  if (
    !isSingleStringFilterValue &&
    !isBooleanFilterValue &&
    !isMultiStringFilterValue &&
    !isExactFilterValue
  ) {
    return;
  }

  if (isMultiStringFilterValue) {
    return { $in: value };
  }

  if (isSingleStringFilterValue) {
    // An exception is made for a string that is a valid uuid since the $like syntax won't work
    if (uuidValidate(value)) {
      return value;
    }

    // REVIEW: this is problematic as we don't want to force fuzzy searching
    // always for string values
    return { $like: value };
  }

  if (isExactFilterValue) {
    return value.$exact;
  }

  return value;
};

/**
 * Formats an object's values to support the mongo-like syntax the backend expects.
 *
 * @param filters An object containing key/value pairs we want to filter on
 * @returns A filter object the backend can consume for filter endpoints
 */
export const getFilterObject = <T>(filters?: FilterObjectType<T>) => {
  if (!filters) {
    return {};
  }
  const { isAlreadyFormatted, ...filterValues } = filters;
  if (isAlreadyFormatted) {
    return filterValues;
  }

  return Object.entries(filterValues).reduce(
    (total, [key, value]) => {
      if (key === "$and") {
        if (Object.hasOwn(total, "$and")) {
          return { ...total, $and: [...total.$and, ...value] };
        }

        return { ...total, [key]: value };
      }

      if (key === "$or") {
        return { ...total, [key]: value };
      }

      if (isDateRange(value)) {
        const { from, to } = value;

        if (Object.hasOwn(total, "$and")) {
          return {
            ...total,
            $and: [
              ...total.$and,
              { [key]: { $gte: from } },
              { [key]: { $lte: to } },
            ],
          };
        }

        return {
          ...total,
          $and: [{ [key]: { $gte: from } }, { [key]: { $lte: to } }],
        };
      }

      return { ...total, [key]: getFilterValue(value) };
    },
    {} as Record<string, any>
  );
};

export type FilterObjectType<FilterType> = {
  [key in keyof FilterType]?: FilterKeyType<FilterType[key]>;
} & {
  $or?: Array<FilterObjectType<FilterType>>;
  $and?: Array<FilterObjectType<FilterType>>;
  isAlreadyFormatted?: boolean;
};

export type FilterKeyType<T> =
  | T
  | T[]
  | {
      $exact: T;
    };
