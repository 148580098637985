import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToFormSubmissionDetail } from "#routers/links";
import {
  FormSubmissionsTableFilterArea,
  FormSubmissionsTableFilterAreaDrawerContent,
} from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { useAddFormSubmissionContext } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import { FormSubmissionsFilterType } from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel/FormSubmissionsFilterPanel";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListFormSubmissions } from "#src/components/hooks/adapters/useFormSubmissions";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
  useFilters,
} from "@validereinc/common-components";
import {
  FormSubmissionAdapter,
  FormSubmissionStatus,
  FormSubmissionType,
  SortDirection,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import React, { useMemo } from "react";

const sorting: SortingType = {
  sortBy: "created_at",
  sortDirection: SortDirection.DESCENDING,
};

export const AddFormSubmissionsTablePanel = ({
  hideDrafts = true,
}: {
  // submissions of status "draft" are hidden by default
  hideDrafts?: boolean;
}) => {
  const {
    selectedFormSubmissions,
    selectFormSubmission,
    deselectFormSubmission,
    tableHeaders,
    filterConfigStorageKey,
    tableConfigStorageKey,
    viewConfigStorageKey,
    filtersDefaultValues,
  } = useAddFormSubmissionContext();
  const [viewFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    viewConfigStorageKey
  );
  const [tableFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    filterConfigStorageKey
  );
  const filters = useMemo(
    () => ({ ...tableFilters, ...viewFilters }),
    [tableFilters, viewFilters]
  );
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { created_at, ...restFilters } = filters;
  const params: Parameters<typeof FormSubmissionAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      created_at,
      ...toFlattenedObject({
        ...restFilters,
        "form_schema.status": "active",
      }),
      ...(hideDrafts && !restFilters.status?.length
        ? {
            status: Object.values(FormSubmissionStatus).filter(
              (s) => s !== FormSubmissionStatus.DRAFT
            ),
          }
        : {}),
    },
    meta: { answers: true },
  };

  const { data, isLoading } = useListFormSubmissions(params);

  const FORM_SUBMISSIONS_COLUMN_PROPS_MAPPING: Record<
    string,
    Partial<HeaderType<FormSubmissionType>>
  > = {
    name: {
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToFormSubmissionDetail(item?.id)}
        >{`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}</RoutingLink>
      ),
    },
    "form_schema.form_category.name": {
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToFormCategoryDetails(item.form_schema?.form_category_id)}
        >
          {item?.form_schema?.form_category?.name ?? "-"}
        </RoutingLink>
      ),
    },
    "form_schema.name": {
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToFormTemplateDetail(
            item.form_category_id,
            item.form_schema_id
          )}
        >
          {item.form_schema?.name}
        </RoutingLink>
      ),
    },
    created_at: {
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
    status: {
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={
            {
              [FormSubmissionStatus.VALIDATED]: "success",
              [FormSubmissionStatus.INVALIDATED]: "error",
              [FormSubmissionStatus.PENDING]: "warning",
              [FormSubmissionStatus.SUBMITTED]: "info",
              [FormSubmissionStatus.DRAFT]: "default",
            }[item.status]
          }
          value={toStartCaseString(item.status)}
        />
      ),
    },
  };
  const headers = tableHeaders.map((header) => {
    if (FORM_SUBMISSIONS_COLUMN_PROPS_MAPPING[header.key]) {
      return {
        ...header,
        ...FORM_SUBMISSIONS_COLUMN_PROPS_MAPPING[header.key],
        sortable: true,
      };
    }

    return header;
  });
  const items = data?.data ?? [];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Form Submissions",
      }}
      filterComponent={
        <FormSubmissionsTableFilterArea
          defaultValues={filtersDefaultValues}
          filterConfigStorageKey={filterConfigStorageKey}
          filterDrawerContentSlot={
            <FormSubmissionsTableFilterAreaDrawerContent
              hasStatusFilter={false}
              hasCategoryFilter
              hasTemplateFilter
            />
          }
        />
      }
      dataTableProps={{
        items,
        headers,
        isLoading,
        sorting,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: data?.total_entries ?? 0,
        },
        onPaginationChange: updateTableState,
        onSortChange: updateTableState,
        getItemActions: ({ item }: { item: FormSubmissionType }) =>
          selectedFormSubmissions?.find((selected) => selected.id === item.id)
            ? [
                {
                  label: "Remove",
                  buttonProps: {
                    onClick: () => deselectFormSubmission(item?.id),
                    icon: "minus-circle",
                  },
                },
              ]
            : [
                {
                  label: "Add",
                  buttonProps: {
                    onClick: () => selectFormSubmission(item),
                    icon: "plus-circle",
                  },
                },
              ],
      }}
    />
  );
};
