import {
  useBulkDeleteFormSubmissions,
  useExportFormSubmissions,
  useListFormSubmissions,
} from "#hooks/adapters/useFormSubmissions";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { ImportDataAction } from "#src/batteries-included-components";
import { BulkDeleteFormSubmissionDialog } from "#src/batteries-included-components/Dialogs/BulkDeleteFormSubmissionsDialog";
import {
  FormSubmissionsTableFilterArea,
  FormSubmissionsTableFilterAreaDrawerContent,
} from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { FormSubmissionsFilterType } from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel";
import { renderFormSubmissionAnswer } from "#src/batteries-included-components/Panels/TablePanels/FormSubmissionsTablePanel.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { getStatusType } from "#src/components/Common/Table/rendererHelper";
import { useExportFormSubmissionAsPDF } from "#src/components/Forms/exportFormSubmission";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import {
  useHasPermission,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToUpdateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/update-form-submission";
import { UseQueryOptions, useQueries, useQuery } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  DropdownMenu,
  HeaderType,
  SortingType,
  StorageKeys,
} from "@validereinc/common-components";
import {
  FormSchemaAdapter,
  FormSchemaType,
  FormSubmissionAdapter,
  FormSubmissionStatus,
  FormSubmissionType,
  ResourceDefinitions,
  SortDirection,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import React, { useEffect, useMemo, useState } from "react";

const sorting: SortingType = {
  sortBy: "created_at",
  sortDirection: SortDirection.DESCENDING,
};

export const FormSubmissionsTablePanel = ({
  isDraft = false,
  formSchemaId,
  filterConfigStorageKey,
  viewConfigStorageKey,
  tableConfigStorageKey,
}: {
  isDraft?: boolean;
  formSchemaId?: string;
} & StorageKeys) => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const [canDeleteFormSubmissions] = useHasPermission(
    "form_submissions:delete"
  );
  const [viewFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    viewConfigStorageKey
  );
  const [tableFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    filterConfigStorageKey
  );
  const filters = useMemo(
    () => ({ ...tableFilters, ...viewFilters }),
    [tableFilters, viewFilters]
  );
  const { created_at, status, ...restFilters } = filters;
  const formSchemaIdFilter = formSchemaId ?? restFilters["form_schema.id"];
  const [{ sortBy, sortDirection, ...pagination }, setTableState] =
    useTableSortingAndPagination(sorting);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const {
    selectedFormSubmissions,
    setSelectedFormSubmissions,
    isDeleting,
    handleBulkDelete,
  } = useBulkDeleteFormSubmissions();

  const queryPayload: Parameters<typeof FormSubmissionAdapter.getList>[0] = {
    page: pagination.page,
    pageSize: pagination.itemsPerPage,
    sortBy,
    sortDirection,
    filters: {
      created_at,
      ...toFlattenedObject({
        status: isDraft
          ? FormSubmissionStatus.DRAFT
          : status
            ? status
            : Object.values(FormSubmissionStatus).filter(
                (s) => s !== FormSubmissionStatus.DRAFT
              ),
        "form_schema.status": "active",
        ...restFilters,
      }),
      ...(formSchemaId ? { "form_schema.id": formSchemaId } : {}),
    },
    meta: { answers: true },
  };

  const query = useListFormSubmissions(queryPayload);

  const schemaQuery = useQuery({
    queryKey: ["formSchemas", formSchemaId],
    queryFn: () => {
      if (!formSchemaId) return;

      return FormSchemaAdapter.getOne({
        id: formSchemaId,
      });
    },
    enabled: Boolean(formSchemaId),
    select: (resp) => resp?.data,
    staleTime: 2 * 60 * 1000,
  });

  // WIP: will be completely leveraged in next PR
  // const answersQueryPayload: Parameters<
  //   typeof FormSubmissionAdapter.answers.getList
  // >[0] = {
  //   page: pagination.page,
  //   pageSize: pagination.itemsPerPage,
  //   sortBy,
  //   sortDirection,
  //   meta: {
  //     ...(formSchemaId ? { form_schema_id: formSchemaId } : {}),
  //     version: 1,
  //   },
  // };
  // const answersQuery = useQuery({
  //   queryKey: ["forms", "submissions", "answers", answersQueryPayload],
  //   queryFn: () => {
  //     if (!formSchemaId) return;

  //     return FormSubmissionAdapter.answers.getList(answersQueryPayload);
  //   },
  //   enabled: Boolean(formSchemaId),
  //   staleTime: 2 * 60 * 1000,
  // });

  const formSubmissionsCreatedByUsers = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      query?.data?.data.map((submission) => ({
        queryKey: ["users", submission.created_by],
        queryFn: () =>
          UsersAdapter.getOne({
            id: submission.created_by,
          }),
        enabled: Boolean(submission.created_by),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp?.data,
      })) ?? [],
  });

  const formSubmissionsCreatedByUsersMap = useMemo(() => {
    return formSubmissionsCreatedByUsers.reduce<Record<string, UserType>>(
      (map, q) => {
        if (!q.data?.id || map[q.data.id]) {
          return map;
        }

        map[q.data.id] = q.data;
        return map;
      },
      {}
    );
  }, [formSubmissionsCreatedByUsers]);

  const bulkExportXLSXMutation = useExportFormSubmissions(queryPayload);
  const exportPDFMutation = useExportFormSubmissionAsPDF({
    includeEmptyAnswers: isDraft,
    showUpdatedAt: isDraft,
    metaUserDataMap: formSubmissionsCreatedByUsersMap,
  });
  const { measurementUnits } = useMeasurementTypes();

  const actionRowWhenRowsSelected = [
    ...(canDeleteFormSubmissions
      ? [
          <Button
            key="bulk-delete"
            variant="error-outline"
            onClick={() => setShowDeleteDialog(true)}
            disabled={!Object.keys(selectedFormSubmissions).length}
            isLoading={isDeleting}
          >
            Delete
          </Button>,
        ]
      : []),
    <DropdownMenu
      key="export"
      options={[
        {
          label: "As XLSX",
          isDisabled: bulkExportXLSXMutation.isLoading,
          onClick: () => bulkExportXLSXMutation.mutate(),
        },
      ]}
    >
      <Button
        key="export-equipment"
        variant="outline"
        isLoading={bulkExportXLSXMutation.isLoading}
        icon="caret-down"
        iconPosition="right"
      >
        Export{" "}
        {query.data?.total_entries ? <>({query.data.total_entries})</> : ""}
      </Button>
    </DropdownMenu>,
    isDraft || !isDataIngestionEnabled ? null : (
      <ImportDataAction
        key="import-form-template-submission"
        resource={{
          ...ResourceDefinitions.form_schema,
          label: {
            singular: "Form Template Submission",
            plural: "Form Template Submissions",
          },
        }}
        resourceId={formSchemaIdFilter}
      />
    ),
  ];

  // remove Delete action when no rows are selected
  const actionRowWhenNoRowsSelected = [
    <DropdownMenu
      key="export"
      options={[
        {
          label: "As XLSX",
          isDisabled: bulkExportXLSXMutation.isLoading,
          onClick: () => bulkExportXLSXMutation.mutate(),
        },
      ]}
    >
      <Button
        key="export-equipment"
        variant="outline"
        isLoading={bulkExportXLSXMutation.isLoading}
        icon="caret-down"
        iconPosition="right"
      >
        Export{" "}
        {query.data?.total_entries ? <>({query.data.total_entries})</> : ""}
      </Button>
    </DropdownMenu>,
    isDraft || !isDataIngestionEnabled ? null : (
      <ImportDataAction
        key="import-form-template-submission"
        resource={{
          ...ResourceDefinitions.form_schema,
          label: {
            singular: "Form Template Submission",
            plural: "Form Template Submissions",
          },
        }}
        resourceId={formSchemaIdFilter}
      />
    ),
  ];

  const getHeadersFromSchema = (
    schema?: FormSchemaType
  ): Array<HeaderType<FormSubmissionType>> => {
    if (!schema) return [];

    return schema.config.sections.map<HeaderType<FormSubmissionType>>((s) => {
      return {
        key: s.id,
        label: `${s.name}${s.is_repeatable ? " (Repeatable)" : ""}`,
        headers: s.questions.map((qid) => ({
          label: schema.config.questions[qid].prompt,
          key: `${s.id}.${qid}`,
          tooltip: schema.config.questions[qid].description,
          minWidth: 180,
          renderComponent: ({ item }) => {
            if (!item.answers[s.id]?.[0]?.[qid]) return "-";

            const answer = item.answers[s.id][0][qid];
            const multipleAnswersCount =
              item.answers[s.id].length > 1
                ? item.answers[s.id].reduce((count, sec) => {
                    if (!sec?.[qid]) return count;
                    return count + 1;
                  }, 0)
                : 1;

            // IMPROVE: better UI to show multiple answers
            return multipleAnswersCount > 1 ? (
              <>(multiple answers)</>
            ) : (
              renderFormSubmissionAnswer(answer, qid, {
                schema,
                measurementUnits,
                sectionId: s.id,
                sectionIdx: 0,
                allAnswers: item.answers,
                allQuestions: schema.config.questions,
              })
            );
          },
        })),
      };
    });
  };

  const dynamicSubmissionsHeaders = useMemo(
    () => getHeadersFromSchema(schemaQuery.data),
    [schemaQuery.data]
  );

  const submissionsHeaders: Array<HeaderType<FormSubmissionType>> = [
    {
      label: "Name",
      key: "id",
      renderComponent: ({ item }) => (
        <RoutingLink
          to={
            isDraft
              ? linkToUpdateFormSubmission(
                  item?.form_schema?.form_category_id,
                  item?.form_schema?.id,
                  item?.id
                )
              : linkToFormSubmissionDetail(item.id)
          }
        >
          {`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}
        </RoutingLink>
      ),
    },
    ...(!isDraft
      ? [
          {
            label: "Status",
            key: "status",
            isSortable: true,
            renderComponent: ({ item }: { item: FormSubmissionType }) => (
              <DataTable.DataRow.PillCell
                variant={getStatusType(item.status, "form").type}
                value={toStartCaseString(item.status)}
              />
            ),
          },
        ]
      : []),
    {
      label: "Created At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
    {
      label: "Last Saved At",
      key: "updated_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.updated_at}
          withTime
        />
      ),
    },
    {
      label: isDraft ? "Saved By" : "Submitted By",
      key: "created_by",
      isSortable: true,
      renderComponent: ({ item }) =>
        formSubmissionsCreatedByUsersMap[item.created_by]?.name ?? "-",
    },
    ...dynamicSubmissionsHeaders,
  ];

  useEffect(() => {
    setTableState({ page: 1, itemsPerPage: 25 });
  }, [isDraft]);

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        filterComponent={
          <FormSubmissionsTableFilterArea
            filterConfigStorageKey={filterConfigStorageKey}
            filterDrawerContentSlot={
              <FormSubmissionsTableFilterAreaDrawerContent hasStatusFilter />
            }
          />
        }
        panelProps={{
          title: isDraft ? "Draft Form Submissions" : "Form Submissions",
        }}
        actionRowWhenRowsSelected={actionRowWhenRowsSelected}
        actionRowWhenNoRowsSelected={actionRowWhenNoRowsSelected}
        dataTableProps={{
          headers: submissionsHeaders,
          isLoading: query?.isLoading,
          items: query?.data?.data ?? [],
          pagination: {
            ...pagination,
            total: query.data?.total_entries ?? 0,
            isTotalKnown: !!query.data?.total_entries,
          },
          sorting,
          selected: selectedFormSubmissions,
          onSortChange: setTableState,
          onPaginationChange: setTableState,
          getItemId: canDeleteFormSubmissions ? (item) => item.id : undefined,
          onSelectionChange: setSelectedFormSubmissions,
          getItemActions: ({ item }: { item: FormSubmissionType }) => [
            {
              label: "Export as PDF",
              buttonProps: {
                icon: "share",
                variant: "text",
                isLoading: exportPDFMutation.isLoading,
                onClick: () => {
                  exportPDFMutation.mutate(item);
                },
              },
            },
          ],
        }}
      />
      <BulkDeleteFormSubmissionDialog
        isOpen={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        handleBulkDelete={handleBulkDelete}
        formSubmissionsCount={Object.keys(selectedFormSubmissions).length}
      />
    </>
  );
};
