import type { ReportType } from "../schemas";
import {
  type TemplatedConfigurationRunCreatedType,
  type TemplatedConfigurationRunCreateType,
  type TemplatedConfigurationRunType,
} from "../schemas/TemplatedConfigurationRunSchemas";
import {
  fetchAndCollate,
  getFilterObject,
  HTTPResponseError,
  type CreateOneRequestType,
  type CreateOneResponseType,
  type FilterObjectType,
  type GetListRequestType,
  type GetListResponseType,
  type GetOneRequestType,
  type GetOneResponseType,
  type ResourceServiceType,
  type SortParamsType,
} from "../util";
import { restAPI } from "./api";

interface TemplatedConfigurationRunServiceType
  extends Pick<
    ResourceServiceType<TemplatedConfigurationRunType>,
    "getOne" | "createOne" | "getList"
  > {
  getOne: (
    params: GetOneRequestType<
      Pick<TemplatedConfigurationRunType, "templated_configuration_name">
    >
  ) => Promise<GetOneResponseType<TemplatedConfigurationRunCreatedType>>;
  createOne: (
    params: CreateOneRequestType<
      TemplatedConfigurationRunCreateType,
      Pick<TemplatedConfigurationRunType, "templated_configuration_name">
    >
  ) => Promise<CreateOneResponseType<TemplatedConfigurationRunCreatedType>>;
  getList: (
    params: GetListRequestType<
      Pick<
        TemplatedConfigurationRunType,
        | "templated_configuration_name"
        | "status"
        | "created_at"
        | "updated_at"
        | "created_by"
        | "updated_by"
      > & {
        "resources.template_resource_id": string;
        "resources.status": string;
        "resources.name": string;
        "resources.type": string;
        "resources.id": string;
        "resources.is_primary": boolean;
      }
    >
  ) => Promise<GetListResponseType<TemplatedConfigurationRunCreatedType>>;
  exportList: (
    params: SortParamsType & {
      filters: FilterObjectType<
        Pick<
          TemplatedConfigurationRunType,
          "status" | "created_at" | "updated_at" | "created_by" | "updated_by"
        >
      >;
    } & {
      meta: Pick<TemplatedConfigurationRunType, "templated_configuration_name">;
    }
  ) => Promise<ReportType>;
}

export const TemplatedConfigurationRunAdapter: TemplatedConfigurationRunServiceType =
  {
    /**
     * Create a single templated configuration run
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/create_templated_configuration_run
     */
    createOne: ({ data, meta }) => {
      if (!meta?.templated_configuration_name)
        throw new HTTPResponseError(
          "'templated_configuration_name' must be specified to create a run.",
          {
            cause: { data, meta },
          }
        );

      return restAPI.nodeAPI
        .POST<TemplatedConfigurationRunCreatedType>({
          endpoint: `/templated_configurations/${meta?.templated_configuration_name}/runs`,
          body: data,
        })
        .then((resp) => ({ data: resp }));
    },

    /**
     * Get a single templated configuration run
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/get_templated_configuration_run
     */
    getOne: ({ id, meta }) => {
      if (!meta?.templated_configuration_name)
        throw new HTTPResponseError(
          "'templated_configuration_name' is required to fetch a run.",
          {
            cause: { id, meta },
          }
        );

      return restAPI.nodeAPI
        .GET<TemplatedConfigurationRunCreatedType>({
          endpoint: `/templated_configurations/${meta?.templated_configuration_name}/runs/${id}`,
        })
        .then((resp) => ({ data: resp }));
    },
    /**
     * Get the list of templated configuration runs by searching
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/templated_configurations/search_templated_configuration_runs
     */
    getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
      fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST({
            endpoint: "/templated_configurations/runs/search",
            body: {
              sort_by: sortBy,
              sort_direction: sortDirection,
              page,
              page_size: pageSize,
              filter: getFilterObject(filters),
            },
          }),
        page,
        pageSize
      ),
    /**
     * Export templated configuration runs.
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/templated_configurations/export_templated_configuration_runs}
     */
    exportList: ({ sortBy, sortDirection, filters, meta }) => {
      return restAPI.nodeAPI.POST({
        endpoint: `/templated_configurations/${meta.templated_configuration_name}/runs/export`,
        body: {
          sort_by: sortBy,
          sort_direction: sortDirection,
          filter: getFilterObject(filters),
        },
      });
    },
  };
