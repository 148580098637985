import {
  FormSchemaQuestionType,
  type FormSchemaType,
} from "@validereinc/domain";
import type { WatchedFields } from "@validereinc/domain-controllers/logic/forms";
import type { RecordValueType } from "@validereinc/utilities/lib/types/type";
import React from "react";
import { MeasurementField } from "./MeasurementField";
import { QuestionField } from "./QuestionField";

export const FormSubmissionField = (
  props: {
    dataKey: string;
    name: string;
    isReady?: boolean;
    isValid?: boolean;
    isBusy?: boolean;
  } & FormSchemaQuestionType &
    RecordValueType<WatchedFields>["calculation"] & {
      questionsMap: FormSchemaType["config"]["questions"];
    }
) => {
  const { type, dataKey, ...questionProps } = props;

  return (
    <>
      {type === FormSchemaQuestionType.QUESTION ? (
        <div style={{ width: 400 }}>
          <QuestionField
            key={dataKey}
            {...questionProps}
          />
        </div>
      ) : null}
      {type === FormSchemaQuestionType.MEASUREMENT ? (
        <MeasurementField
          key={dataKey}
          {...questionProps}
        />
      ) : null}
    </>
  );
};
