import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
  FormCategoryType,
  FormSchemaAdapter,
  FormSchemaType,
} from "@validereinc/domain";
import React, { ReactNode, createContext, useContext } from "react";
import { useParams } from "react-router";

export type FormContextType = {
  schema: UseQueryResult<FormSchemaType, unknown>;
  category?: FormCategoryType;
};

export const FormContext = createContext<FormContextType | null>(null);

export const useFormContext = () => {
  const context = useContext(FormContext);

  if (context === null) {
    throw new Error("Must be called within FormContextProvider.");
  }

  return context;
};

export const FormContextProvider = ({ children }: { children?: ReactNode }) => {
  const params = useParams<{
    categoryId: string;
    schemaId: string;
  }>();
  const { schemaId } = params;

  const formSchemaQueryResult = useQuery(
    ["formSchemas", schemaId],
    () => FormSchemaAdapter.getOne({ id: schemaId }),
    {
      enabled: Boolean(schemaId),
      select: (resp) => resp?.data,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return (
    <FormContext.Provider
      value={{
        schema: formSchemaQueryResult,
        category: formSchemaQueryResult?.data?.form_category,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
