import { AttributeLookupInput } from "#batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField/QuestionField/LookupField/AttributeLookupInput";
import { FormSchemaDropdownInput } from "#batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField/QuestionField/LookupField/FormSchemaDropdownInput";
import { CustomAttributeKeyValueList } from "#src/batteries-included-components/KeyValueList/CustomAttributeKeyValueList";
import { useFormContext } from "@validereinc/common-components";
import classNames from "classnames/bind";
import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";
import React, { useMemo } from "react";
import {
  getParentQuestionName,
  getValuePathFromQuestionPath,
} from "@validereinc/domain-controllers/logic/forms";
import { DeviceFormSubmissionDropdownInput } from "./DeviceFormSubmissionDropdownInput";
import { EquipmentFormSubmissionDropdownInput } from "./EquipmentFormSubmissionDropdownInput";
import { FacilityFormSubmissionDropdownInput } from "./FacilityFormSubmissionDropdownInput";
import { FlowFormSubmissionDropdownInput } from "./FlowFormSubmissionDropdownInput";
import styles from "./LookupField.module.scss";
const cx = classNames.bind(styles);

export const LookupField = ({
  lookup_entity_type,
  lookup_entity_attribute,
  parent_facility_question_id,
  name,
  filter,
  lookup_entity_display_attributes = [],
  questionId,
  ...rest
}: {
  lookup_entity_display_attributes?: string[];
}) => {
  const { watch, getValues } = useFormContext();

  const parentFacilityQuestionName = useMemo(() => {
    if (parent_facility_question_id) {
      return getParentQuestionName({
        currentQuestionId: name,
        formValues: getValues(),
        questionIdToFind: parent_facility_question_id,
      });
    }
    return "";
  }, [parent_facility_question_id]);

  const parentFacilityId = parentFacilityQuestionName
    ? watch(getValuePathFromQuestionPath(parentFacilityQuestionName))
    : null;

  const values = watch();

  const filterObject = useMemo(() => {
    const values = getValues();

    if (!filter && !parentFacilityQuestionName) {
      return null;
    }

    const formattedFilter = Object.entries(filter ?? {}).reduce(
      (total, [key, value]) => {
        if (!isPlainObject(value)) {
          if (
            typeof value === "string" &&
            value.startsWith("%") &&
            value.endsWith("%")
          ) {
            const questionName = getParentQuestionName({
              currentQuestionId: name,
              formValues: values,
              questionIdToFind: value.slice(1, -1),
            });

            const questionValue = get(
              values,
              getValuePathFromQuestionPath(questionName)
            );

            return { ...total, [key]: questionValue };
          }

          return { ...total, [key]: value };
        }

        return {
          ...total,
          [key]: Object.entries(value).reduce(
            (totalObject, [operand, value]) => {
              if (
                typeof value !== "string" ||
                !value?.startsWith("%") ||
                !value?.endsWith("%")
              ) {
                return { ...totalObject, [operand]: value };
              }

              const questionName = getParentQuestionName({
                currentQuestionId: name,
                formValues: values,
                questionIdToFind: value.slice(1, -1),
              });

              const questionValue = get(
                values,
                getValuePathFromQuestionPath(questionName)
              );

              return { ...totalObject, [operand]: questionValue };
            },
            {}
          ),
        };
      },
      {
        ...(parentFacilityQuestionName
          ? { "facility.id": parentFacilityId }
          : {}),
      }
    );

    return Object.keys(formattedFilter) ? formattedFilter : undefined;
  }, [filter, values, parentFacilityId, parentFacilityQuestionName]);

  const props = {
    ...rest,
    name,
    filter: filterObject,
    isMulti: false,
  };

  if (lookup_entity_attribute) {
    return (
      <AttributeLookupInput
        lookup_entity_attribute={lookup_entity_attribute}
        lookup_entity_type={lookup_entity_type}
        {...props}
      />
    );
  }
  if (lookup_entity_type === "form_schema") {
    return <FormSchemaDropdownInput {...props} />;
  }
  return (
    <>
      {lookup_entity_type === "facility" ? (
        <FacilityFormSubmissionDropdownInput {...props} />
      ) : null}

      {lookup_entity_type === "equipment" ? (
        <EquipmentFormSubmissionDropdownInput {...props} />
      ) : null}

      {lookup_entity_type === "flow" ? (
        <FlowFormSubmissionDropdownInput {...props} />
      ) : null}

      {lookup_entity_type === "device" ? (
        <DeviceFormSubmissionDropdownInput {...props} />
      ) : null}

      {lookup_entity_type === "form_schema" ? (
        <FormSchemaDropdownInput {...props} />
      ) : null}

      <div className={cx("attributeContainer")}>
        <CustomAttributeKeyValueList
          assetType={lookup_entity_type}
          assetId={getValues(name)}
          displayAttributes={lookup_entity_display_attributes}
        />
      </div>
    </>
  );
};
