import { FormSubmissionsViewFilterArea } from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { useAddFormSubmissionContext } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import {
  SelectedFormSubmissionsPanel,
  SelectedFormSubmissionsPanelProps,
} from "#src/batteries-included-components/Panels/SelectedEntitiesPanels/SelectedFormSubmissionsPanel";
import { AddFormSubmissionsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/AddFormSubmissionsTablePanel";
import classNames from "classnames/bind";
import React from "react";
import styles from "./AddFormSubmission.module.scss";

const cx = classNames.bind(styles);

export type AddFormSubmissionProps = {
  selectedFormSubmissionsPanelProps: SelectedFormSubmissionsPanelProps;
};

export const AddFormSubmission = ({
  selectedFormSubmissionsPanelProps,
}: AddFormSubmissionProps) => {
  const { viewConfigStorageKey } = useAddFormSubmissionContext();

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <FormSubmissionsViewFilterArea
          viewConfigStorageKey={viewConfigStorageKey}
        />
      </div>
      <div className={cx("container")}>
        <AddFormSubmissionsTablePanel />
        <SelectedFormSubmissionsPanel {...selectedFormSubmissionsPanelProps} />
      </div>
    </>
  );
};
