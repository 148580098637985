import {
  EquipmentDropdownInput,
  FacilityDropdownInput,
  FlowDropdownInput,
} from "#src/batteries-included-components/Dropdowns";
import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { FilterArea } from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { SavedFilterTag } from "#src/components/hooks/FilterPanel/useSavedFilters";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useQuery } from "@tanstack/react-query";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  FlowDomain,
  FlowStatus,
  RecordDomain,
  ReportingGroupDomain,
  Resources,
} from "@validereinc/domain";
import React, { useMemo } from "react";

export const FlowRecordsViewFilterArea = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { data: reportingGroups } = useQuery(
    [Resources.REPORTING_GROUP],
    () => ReportingGroupDomain.getList({}),
    {
      select: ({ data }) => data ?? [],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const sortedReportingGroups = useMemo(
    () =>
      reportingGroups
        ?.slice()
        .sort((a, b) =>
          a.priority > b.priority ? -1 : a.priority < b.priority ? 1 : 0
        ),
    [reportingGroups]
  );

  return (
    <FilterArea.Root<{
      reporting_group_id: string;
      date_range: { from: Date; to: Date };
    }>
      storageKey={viewConfigStorageKey}
      defaultValues={{
        reporting_group_id: sortedReportingGroups?.[0]?.id,
        date_range: DEFAULT_DATE_RANGES.lastWholeMonth,
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="View Filters for Flow Records">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginBottom: 16,
              }}
            >
              <ReportingGroupFilterSwitcher
                name="reporting_group_id"
                options={reportingGroups}
                onChange={(val) => handleOnChange(val, "reporting_group_id")}
              />
              <DateSelectorInput
                name="date_range"
                variant="month"
                isRange
                isInline
                onChange={(val) => handleOnChange(val, "date_range")}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const FlowRecordsTableFilterAreaContent = ({
  selectableProperties,
}: {
  selectableProperties?: string[];
}) => {
  const { localize } = useLocalization();
  const { getTypeName } = useMeasurementTypes();
  const propertySelectorValues = selectableProperties?.map((key) => ({
    key,
    name: getTypeName(key),
  }));
  const { customAttributeFilters: facilityCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.FACILITY,
      prefix: "flow.associated_facility.custom_attributes",
    });
  const { customAttributeFilters: equipmentCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.EQUIPMENT,
      prefix: "flow.associated_equipment.custom_attributes",
    });
  const { customAttributeFilters: flowCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.FLOW,
      prefix: "flow.custom_attributes",
    });
  const { data: flowTypes } = useQuery({
    queryKey: ["flowTypes"],
    queryFn: () => FlowDomain.getFlowTypes(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const { data: flowProductTypes } = useQuery({
    queryKey: ["flowProductTypes"],
    queryFn: () => FlowDomain.getFlowProductTypes(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const { data: flowProductCategories } = useQuery({
    queryKey: ["flowProductCategories"],
    queryFn: () => FlowDomain.getFlowProductCategories(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <>
      <DropdownInput
        key="measurement_type"
        name="measurement_type"
        label="Measurement Type"
        options={propertySelectorValues}
        placeholder="Select Property..."
        labelKey="name"
        valueKey="key"
        isSearchable
        isOptionalTextShown={false}
      />
      <Accordion defaultActiveKeys={[AssetType.FLOW]}>
        <Accordion.AccordionPanel
          dataKey={AssetType.FLOW}
          title={localize(`${AssetType.FLOW}_plural`)}
        >
          <FlowDropdownInput
            name="flow.id"
            placeholder={`Select ${localize(`${AssetType.FLOW}_plural`)}...`}
            isMulti
            isFluid
            isOptionalTextShown={false}
          />
          <DropdownInput
            name="flow.type"
            label={`${localize(AssetType.FLOW)} Type`}
            options={flowTypes ?? []}
            placeholder="Select Type..."
            labelKey="name"
            valueKey="id"
            isMulti
            isSearchable
            isOptionalTextShown={false}
          />
          <DropdownInput
            name="flow.status"
            label={`${localize(AssetType.FLOW)} Status`}
            placeholder="Select Status..."
            options={[
              { name: "Active", id: "active" },
              { name: "Inactive", id: "inactive" },
            ]}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          <DropdownInput
            name="flow.product_category"
            label={`${localize(AssetType.FLOW)} Product Category`}
            placeholder="Select Category..."
            options={flowProductCategories ?? []}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          <DropdownInput
            name="flow.product_type"
            label={`${localize(AssetType.FLOW)} Product Type`}
            placeholder="Select Type..."
            options={flowProductTypes ?? []}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          {flowCustomAttributeFilters}
        </Accordion.AccordionPanel>
        <Accordion.AccordionPanel
          dataKey={AssetType.FACILITY}
          title={localize(`${AssetType.FACILITY}_plural`)}
        >
          <FacilityDropdownInput
            name="flow.associated_facility_id"
            placeholder={`Select ${localize(`${AssetType.FACILITY}_plural`)}...`}
            isMulti
            isFluid
            isOptionalTextShown={false}
          />
          {facilityCustomAttributeFilters}
        </Accordion.AccordionPanel>
        <Accordion.AccordionPanel
          dataKey={AssetType.EQUIPMENT}
          title={localize(`${AssetType.EQUIPMENT}_plural`)}
        >
          <EquipmentDropdownInput
            name="flow.associated_equipment_id"
            placeholder={`Select ${localize(`${AssetType.EQUIPMENT}_plural`)}...`}
            isMulti
            isFluid
            isOptionalTextShown={false}
          />
          {equipmentCustomAttributeFilters}
        </Accordion.AccordionPanel>
      </Accordion>
    </>
  );
};

export const FlowRecordsTableFilterArea = ({
  filterConfigStorageKey,
  selectableProperties,
}: {
  selectableProperties?: string[];
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize } = useLocalization();

  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{
        "flow.status": [FlowStatus.ACTIVE],
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="Filters for Flow Records">
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, marginBottom: 0 }}>
                <TextInput
                  name="flow.name"
                  label="Search"
                  isLabelShown={false}
                  placeholder={`Search ${localize(`${AssetType.FLOW}_plural`)}...`}
                  type="search"
                  isInline
                  onChange={(val) => handleOnChange(val, "flow.name")}
                />
              </div>
            )}
          </FilterArea.Content>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <FilterDrawer.SavedFilters
              config={{
                resourceType: Resources.RECORD,
                savedFilterResourceAdapter: RecordDomain.savedFilters,
                tag: SavedFilterTag.FLOW_RECORDS,
                filterBlacklist: ["date_range.from", "date_range.to"],
              }}
            />
            <FilterDrawer.SavedFiltersAppliedIndicator />
            <FlowRecordsTableFilterAreaContent
              selectableProperties={selectableProperties}
            />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
