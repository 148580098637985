import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useParams } from "#routers/hooks";
import { linkToEquipmentDetail, linkToFacilities } from "#routers/links";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { KeyValuePanel, StorageKeys } from "@validereinc/common-components";
import { AssetType, AttributeDataType } from "@validereinc/domain";
import {
  dateFormatter,
  datetimeFormatter,
  getYearMonthFromDateRange,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import isNull from "lodash/isNull";
import React from "react";
import styles from "./NetworkDetailsTab.module.scss";

const cx = classNames.bind(styles);

export const NetworkDetailsPanel = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { localize } = useLocalization();
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const [filters] = useSessionStickyState<{ period: { from?: string } }>(
    {},
    viewConfigStorageKey
  );

  const { data: assetGroup } = useGetOneNetwork(networkId, {
    period: getYearMonthFromDateRange(filters.period),
  });
  const customAttributesQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.ASSET_GROUP },
  });

  const networkCustomAttributes = customAttributesQuery.data?.data ?? [];

  const data = [
    {
      title: localize("facility_plural"),
      value: assetGroup?.assets?.filter(
        ({ asset_type }) => asset_type === AssetType.FACILITY
      )?.length,
    },
    {
      title: localize("equipment_plural"),
      value: assetGroup?.assets?.filter(
        ({ asset_type }) => asset_type === AssetType.EQUIPMENT
      )?.length,
    },
    {
      title: "Devices",
      value: assetGroup?.assets?.filter(
        ({ asset_type }) => asset_type === AssetType.DEVICE
      )?.length,
    },
    {
      title: localize("flow_plural"),
      value: assetGroup?.assets?.filter(
        ({ asset_type }) => asset_type === AssetType.FLOW
      )?.length,
    },
    ...networkCustomAttributes.map(
      ({ display_name, field_name, data_type }) => {
        const value = assetGroup?.custom_attributes?.[field_name];

        if (typeof value === "undefined" || isNull(value)) {
          return {
            title: display_name,
            value: "-",
          };
        }

        // IMPROVE: need to abstract display logic away for attributes in one
        // centralized place. This is currently repeated and fragmented in
        // several places, most notably, forms.
        switch (data_type) {
          case AttributeDataType.DATE_TIME:
            return {
              title: display_name,
              value: datetimeFormatter(new Date(value)),
            };
          case AttributeDataType.DATE:
            return {
              title: display_name,
              value: dateFormatter(new Date(value)),
            };
          case AttributeDataType.LOOKUP:
            switch (value?.entity_type) {
              case AssetType.FACILITY:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink to={linkToFacilities(value?.id)}>
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.EQUIPMENT:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink to={linkToEquipmentDetail(value?.id)}>
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.FLOW:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink
                      to={FlowDetailRoute.toLink({
                        pathParams: { flowId: value?.id },
                      })}
                    >
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              default:
                return null;
            }
          case AttributeDataType.STRING:
          default: {
            return {
              title: display_name,
              value,
            };
          }
        }
      }
    ),
  ];

  return (
    <div className={cx("flexedContainer")}>
      <KeyValuePanel
        panelMaxColumnCount={2}
        panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
        data={data}
      />
    </div>
  );
};
