import { useCreateMeasurementSeries } from "#src/components/hooks/adapters/useMeasurementSeries";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  Button,
  Dialog,
  DropdownInput,
  Form,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import { DeviceMeasurementSeriesSchema } from "@validereinc/domain";
import React, { useEffect, useMemo } from "react";

export const CreateMeasurementSeriesDialog = ({
  id,
  isOpen,
  onClose,
}: {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const form = useForm({});
  const { measurementTypes, getUnitsByQuantity } = useMeasurementTypes();
  const measurementTypeId = form.watch("measurement_type");
  const measurementType = useMemo(
    () => measurementTypes.find(({ id }) => id === measurementTypeId),
    [measurementTypes, measurementTypeId]
  );

  const onSuccess = () => {
    onClose?.();
  };
  const createMeasurementSeries = useCreateMeasurementSeries({ onSuccess });
  const handleSubmit = form.handleSubmit((values) => {
    const { description, measurement_type, unit } = values;
    createMeasurementSeries.mutate({
      data: {
        description,
        device_id: id,
        measurement_type,
        unit,
      },
    });
  });
  useEffect(() => {
    form.reset();
  }, [isOpen]);

  const units = getUnitsByQuantity(measurementType?.quantity);

  return (
    <Dialog
      title="Add Measurement Series"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="add-measurement-series"
          variant="primary"
          onClick={handleSubmit}
        >
          Add
        </Button>,
      ]}
    >
      <Form {...form}>
        <TextInput
          name={DeviceMeasurementSeriesSchema.keyof().Enum.description}
          label="Description"
          isRequired
          type="text"
        />
        <DropdownInput
          name={DeviceMeasurementSeriesSchema.keyof().Enum.measurement_type}
          label="Measurement Type"
          options={measurementTypes ?? []}
          valueKey="id"
          labelKey="name"
          isRequired
        />
        <DropdownInput
          name={DeviceMeasurementSeriesSchema.keyof().Enum.unit}
          label="Measurement Unit"
          options={units}
          valueKey="id"
          labelKey="name.symbol"
          isRequired
        />
      </Form>
    </Dialog>
  );
};
