import {
  Button,
  TextInput,
  useFieldArray,
  useFormContext,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "../../../CreateFormTemplate.module.scss";
import { getValuePathFromQuestionPath } from "@validereinc/domain-controllers/logic/forms";

const cx = classNames.bind(styles);

export const PickListFields = () => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "pick_list_values",
  });

  return (
    <div>
      <Button
        className={cx("addPicklistItemButton")}
        onClick={() => {
          append("");
        }}
        icon="plus-circle"
      >
        Add Picklist Item
      </Button>

      {fields.map((field, index: number) => (
        <div
          key={field.id}
          className={cx("pickListOptionsContainer")}
        >
          <div style={{ flex: 1 }}>
            <TextInput
              name={`pick_list_values.${index}`}
              isRequired
              placeholder={`Option ${index + 1}`}
              isFluid
              isInline
            />
          </div>

          {fields?.length > 1 ? (
            <Button
              icon="trash"
              onClick={() => remove(index)}
              variant="error-outline"
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};
