import { z } from "zod";
import { ResourcesSchema } from "../util";
import { DomainModelSchema } from "./DomainModelSchemas";

export const AssetType = {
  FACILITY: "facility",
  EQUIPMENT: "equipment",
  DEVICE: "device",
  FLOW: "flow",
  ASSET_GROUP: "asset_group",
} as const;

export type AssetTypeType = (typeof AssetType)[keyof typeof AssetType];

export const AssetTypeSchema = ResourcesSchema.extract([
  "facility",
  "equipment",
  "device",
  "flow",
  "asset_group",
]);

export const AssetSchema = z
  .object({
    name: z.string(),
    asset_type: AssetTypeSchema,
  })
  .merge(DomainModelSchema)
  .describe("Schema for an asset in the Node API");

export type AssetSchemaType = z.infer<typeof AssetSchema>;
