import { useSearchParams } from "#routers/hooks";
import { EstimationMethodListTablePanel } from "#routes/calculations/estimation-methods/EstimationMethodList/EstimationMethodListTablePanel";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page, Tab } from "@validereinc/common-components";
import { AssetType, ResourceDefinitions } from "@validereinc/domain";
import React from "react";
import { CalculationsEstimationMethodsRoutePath } from ".";

const DEFAULT_TAB_KEY = "equipment";

export const EstimationMethodListPage = () => {
  const { localize } = useLocalization();
  const equipmentStorageKeys = useStorageKey("equipment-estimation-methods");
  const flowStorageKeys = useStorageKey("flow-estimation-methods");
  const [{ tab }, setSearchParams] = useSearchParams();
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const [breadcrumbs] = useBreadcrumbsFromRoute(
    CalculationsEstimationMethodsRoutePath
  );

  return (
    <Page
      title={CalculationsEstimationMethodsRoutePath.title}
      category={CalculationsEstimationMethodsRoutePath.previous?.title}
      breadcrumbs={breadcrumbs}
      activeTabKey={tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tab) => setSearchParams({ tab })}
    >
      <Tab
        title="Equipment"
        tabKey="equipment"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            resource={ResourceDefinitions.estimation_method}
          />
        ) : null}
        <EstimationMethodListTablePanel
          {...equipmentStorageKeys}
          assetType={AssetType.EQUIPMENT}
        />
      </Tab>
      <Tab
        title={`${localize("Flow")}`}
        tabKey="flow"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            resource={ResourceDefinitions.estimation_method}
          />
        ) : null}
        <EstimationMethodListTablePanel
          {...flowStorageKeys}
          assetType={AssetType.FLOW}
        />
      </Tab>
    </Page>
  );
};
