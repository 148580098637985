import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToCreateEquipment } from "#routers/links";
import { EquipmentList } from "#routes/organization/equipment/EquipmentList";
import { RecordsListTablePanel } from "#src/batteries-included-components";
import { ApplyAutomationProgressBanner } from "#src/batteries-included-components/Banners/ApplyAutomationProgressBanner";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import {
  EquipmentRecordsTableFilterArea,
  EquipmentRecordsViewFilterArea,
} from "#src/batteries-included-components/FilterAreas/RecordsFilterAreas";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { EquipmentListRoute } from "#src/routes/organization/equipment";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Button, Page, Tab } from "@validereinc/common-components";
import { AssetType, ResourceDefinitions } from "@validereinc/domain";
import React from "react";

const DEFAULT_TAB_KEY = "list";

export const EquipmentListPage = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const calcsAndRecordsSharedStorageKeys = useStorageKey(
    "calculations-records-shared"
  );
  const recordsStorageKeys = useStorageKey("equipment-records");
  const recordsApplyAutomationStorageKey = useStorageKey(
    "equipment-records-apply-automation"
  ).tableConfigStorageKey;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { localize, isLoading } = useLocalization();
  const [breadcrumbs] = useBreadcrumbsFromRoute(EquipmentListRoute);

  function handleOnCreateEquipmentClick() {
    navigate({
      pathname: linkToCreateEquipment(),
    });
  }

  const actionRow = [
    <IsFeatureAvailable
      key="create-equipment"
      featureFlagQuery="core:equipment"
      permissionQuery="equipment:write"
      fallbackChildren={null}
    >
      <Button
        variant="primary"
        onClick={handleOnCreateEquipmentClick}
      >
        Create {localize("Equipment")}
      </Button>
    </IsFeatureAvailable>,
  ];

  return (
    <Page
      title={localize("equipment_plural")}
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      actionRow={actionRow}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title="List"
        tabKey="list"
      >
        <EquipmentList />
      </Tab>
      <Tab
        title="Records"
        tabKey="records"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            resource={{
              ...ResourceDefinitions.record,
              label: {
                singular: `${localize(ResourceDefinitions.equipment.label.singular)} ${ResourceDefinitions.record.label.singular}`,
                plural: `${localize(ResourceDefinitions.equipment.label.singular)} ${ResourceDefinitions.record.label.plural}`,
              },
            }}
          />
        ) : null}
        <ApplyAutomationProgressBanner
          jobStorageKey={recordsApplyAutomationStorageKey}
        />
        <EquipmentRecordsViewFilterArea
          viewConfigStorageKey={
            calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
          }
        />
        <RecordsListTablePanel
          type={AssetType.EQUIPMENT}
          {...recordsStorageKeys}
          viewConfigStorageKey={
            calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
          }
          applyAutomationStorageKey={recordsApplyAutomationStorageKey}
          filterComponentSlot={({ recordValueTypes }) => (
            <EquipmentRecordsTableFilterArea
              filterConfigStorageKey={recordsStorageKeys.filterConfigStorageKey}
              selectableProperties={recordValueTypes}
            />
          )}
        />
      </Tab>
    </Page>
  );
};
