import { WorkflowTasksViewFilterArea } from "#src/batteries-included-components/FilterAreas/WorkflowTasksFilterAreas";
import {
  WorkflowTasksTablePanel,
  WorkflowTasksTablePanelProps,
} from "#src/batteries-included-components/Panels/TablePanels/WorkflowTasksTablePanel";
import { AssetType } from "@validereinc/domain";
import React from "react";

type WorkflowTasksListLayoutProps = {
  tablePanelProps: WorkflowTasksTablePanelProps;
  isDisplayingMyTasks?: boolean;
};

export const WorkflowTasksListLayout = ({
  tablePanelProps,
  isDisplayingMyTasks,
}: WorkflowTasksListLayoutProps) => {
  return (
    <>
      <WorkflowTasksViewFilterArea
        viewConfigStorageKey={tablePanelProps?.viewConfigStorageKey}
        showAssigneeFilter={!isDisplayingMyTasks}
        defaultAssetType={AssetType.FACILITY}
      />
      <WorkflowTasksTablePanel
        {...tablePanelProps}
        isDisplayingMyTasks={!!isDisplayingMyTasks}
        defaultAssetType={AssetType.FACILITY}
      />
    </>
  );
};
