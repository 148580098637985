import { Button, Dialog } from "@validereinc/common-components";
import React from "react";

export const BulkDeleteFormSubmissionDialog = ({
  isOpen,
  onClose,
  handleBulkDelete,
  formSubmissionsCount,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleBulkDelete: () => void;
  formSubmissionsCount: number;
}) => {
  const BULK_DELETE_FORM_SUBMISSION_DIALOG_TITLE = "Delete Form Submission(s)?";
  const BULK_DELETE_FORM_SUBMISSION_DIALOG_DESCRIPTION = `Are you sure you want to delete ${formSubmissionsCount} form submission(s)? This action cannot be undone.`;

  return (
    <Dialog
      title={BULK_DELETE_FORM_SUBMISSION_DIALOG_TITLE}
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="delete-question-action"
          variant="error"
          onClick={() => {
            handleBulkDelete();
            onClose();
          }}
        >
          Delete
        </Button>,
      ]}
    >
      {BULK_DELETE_FORM_SUBMISSION_DIALOG_DESCRIPTION}
    </Dialog>
  );
};
