import FileService from "#src/components/Services/FileService";
import {
  useAuthenticatedContext,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useQueueUnique } from "#src/hooks/useQueue";
import {
  Button,
  ButtonWithPopover,
  Dialog,
  Form,
  RadioInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import type {
  ResourceDefinitionType,
  TransactionType,
} from "@validereinc/domain";
import {
  ResourceDefinitions,
  Resources,
  TransactionAdapter,
} from "@validereinc/domain";
import kebabCase from "lodash/kebabCase";
import React, { ReactNode, useEffect } from "react";
import {
  ImportDataActionProvider,
  useImportDataActionContext,
} from "./ImportDataActionContext/ImportDataActionContext";
import SingleEntityImportAction from "./SingleEntityImportAction/SingleEntityImportAction";
import EquipmentTemplatedConfigurationImportForm from "./TemplatedConfigurationsImportAction/EquipmentTemplatedConfigurationImportForm";

export const ImportDataActionContent = () => {
  const { claims } = useAuthenticatedContext();
  const { addAlert } = useAlert();

  const {
    status,
    setStatus,
    openDialog,
    isDialogOpen,
    closeDialog,
    resource,
    resourceId,
    getTrigger,
    modes,
    datasetId,
  } = useImportDataActionContext();

  const [isTemplatedConfigurationsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configuration_runs:write",
  });

  const [queue, setQueue] = useQueueUnique<TransactionType>(
    [],
    `queue-import-data-action-${resource.id}-${kebabCase(resource.label.singular)}${
      resourceId ? `-${resourceId}` : ""
    }`,
    (tx) => tx.transaction_id
  );

  // Form to render the radio buttons for choosing import type (classic / with templated config)
  const templatedConfigurationForm = useForm<{
    importMode?: "single-entity" | "templated-configuration";
  }>({
    defaultValues: {
      importMode:
        modes?.length &&
        modes[0] === "templated-configuration" &&
        isTemplatedConfigurationsAvailable
          ? "templated-configuration"
          : modes?.[0],
    },
  });

  const form = useForm<{
    file?: File;
  }>({
    defaultValues: {
      file: undefined,
    },
  });

  const {
    getValues,
    formState: { isSubmitting },
  } = form;

  const selectedFile = form.watch("file");
  const importMode = templatedConfigurationForm.watch("importMode");

  useEffect(() => {
    form.setValue("file", undefined);
  }, [importMode]);

  useEffect(() => {
    if (isDialogOpen) {
      form.reset();
      templatedConfigurationForm.reset();
    }
  }, [isDialogOpen]);

  const handleFileUpload = async (file: File) => {
    if (!datasetId || !claims) {
      return {
        file,
        uploadedFile: null,
        transactionId: null,
      };
    }

    // extract key file details
    const { name, type: contentType, lastModified } = file;

    // create a file in the file upload service
    setStatus({ percentComplete: 1, label: "Uploading..." });
    const {
      status: createFileStatus,
      data: {
        data: [uploadedFileData],
        meta: { transaction_id: transactionId },
      },
    } = await FileService.createFile({
      name,
      contentType,
      description:
        "A user-uploaded file through the import data action component",
      tags: {
        lastModified: new Date(lastModified).toISOString(),
      },
      datasetId,
      companyId: claims.company?.id,
    })!;

    if (createFileStatus !== 200 && createFileStatus !== 201) {
      throw new Error("Could not create an entry in the file upload service");
    }

    // extract the ID and upload URL of the file entry in the file upload service
    const { file_id: fileId, uri: uploadUrl } = uploadedFileData;

    // upload the file to the pre-signed upload URL
    const { status: uploadFileStatus } = await FileService.uploadFile({
      url: uploadUrl,
      fileBlob: file,
    })!;

    if (uploadFileStatus !== 200 && uploadFileStatus !== 201) {
      throw new Error(
        "Could not upload file to the created entry in file upload service"
      );
    }

    // poll and check to see if the file scanning in the file upload service has completed
    setStatus({ percentComplete: 33, label: "Scanning..." });
    let scanStatus = "unknown";
    let pollLimit = 10;

    while (pollLimit > 0 && !["safe", "unsafe"].includes(scanStatus)) {
      const {
        data: {
          data: [{ file_scan_status }],
        },
      } = await FileService.getFileDownloadUrl(fileId)!;

      scanStatus = file_scan_status;

      // scan status re-check polling interval
      await new Promise((res) => setTimeout(res, 1000));
      pollLimit -= 1;
    }

    // verify based on the scan result
    setStatus({ percentComplete: 66, label: "Verifying..." });

    if (scanStatus !== "safe") {
      throw new Error(
        "The uploaded file has been deemed as unsafe or failed to complete verification"
      );
    }

    setStatus({ percentComplete: 100, label: "Uploading Complete" });
    return {
      file,
      uploadedFile: {
        ref: fileId,
        name,
      },
      transactionId,
    };
  };

  const handleSubmit = async () => {
    const formValues = getValues();

    try {
      const { transactionId } = await handleFileUpload(formValues.file!);

      if (!transactionId) {
        throw new Error(
          "Transaction ID not returned for initiated data import"
        );
      }

      const transaction = await TransactionAdapter.getOne({
        id: transactionId,
        meta: { history: false },
      });

      if (!transaction?.data.length) {
        throw new Error("No transactions found for initiated transaction");
      }

      setQueue(queue.insert(transaction.data[0]));
      closeDialog();
      addAlert({
        variant: "normal",
        message: "Successfully started data import",
      });
    } catch (err) {
      console.error(err);
      addAlert({
        variant: "error",
        message: "Failed to start data import. Try again?",
      });
    } finally {
      setStatus({ percentComplete: 0, label: "Ready" });
    }
  };

  const isValid = datasetId && selectedFile;

  const dialogActionRow = [
    <Button
      key="submit-action"
      variant="primary"
      type="submit"
      onClick={form.handleSubmit(handleSubmit)}
      isLoading={isSubmitting}
      disabled={!isValid || !!status?.percentComplete}
    >
      {status?.percentComplete ? status.label : "Import Data"}
    </Button>,
  ];
  const trigger = getTrigger ? (
    getTrigger({ handleClick: openDialog, isDialogOpen })
  ) : (
    <ButtonWithPopover
      buttonProps={{
        icon: "upload-simple",
        onClick: openDialog,
        isLoading: isDialogOpen,
      }}
      label="Import"
    />
  );

  const dialogTitle = `Import Data${
    resource.label.singular ? `: ${resource.label.singular}` : ""
  }${importMode === "templated-configuration" ? ` using ${ResourceDefinitions[Resources.TEMPLATED_CONFIGURATION].label.singular}` : ""}`;

  return (
    <>
      {trigger}
      <Dialog
        title={dialogTitle}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        actionRow={dialogActionRow}
      >
        {modes && modes.length > 1 ? (
          <Form {...templatedConfigurationForm}>
            <RadioInput
              name="importMode"
              inputId="radio"
              labelKey="label"
              valueKey="value"
              label="Import Type"
              isOptionalTextShown={false}
              options={[
                ...(modes?.includes("single-entity")
                  ? [
                      {
                        label: `${resource.label.singular} Data`,
                        value: "single-entity",
                      },
                    ]
                  : []),
                ...(isTemplatedConfigurationsAvailable &&
                modes?.includes("templated-configuration")
                  ? [
                      {
                        label: "Configuration Data",
                        value: "templated-configuration",
                      },
                    ]
                  : []),
              ]}
            />
          </Form>
        ) : null}
        <Form {...form}>
          {importMode === "single-entity" && <SingleEntityImportAction />}
          {importMode === "templated-configuration" && (
            <>
              {resource.id === Resources.EQUIPMENT && (
                <EquipmentTemplatedConfigurationImportForm />
              )}
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};

export const ImportDataAction = (props: ImportDataActionProps) => (
  <ImportDataActionProvider {...props}>
    <ImportDataActionContent />
  </ImportDataActionProvider>
);

export type ImportDataActionProps = {
  modes?: Array<"single-entity" | "templated-configuration">;
  resource: ResourceDefinitionType;
  resourceId?: string;
  preselectedTemplatedConfigurationName?: string;
  getTrigger?: ({
    handleClick,
    isDialogOpen,
  }: {
    handleClick: () => void;
    isDialogOpen: boolean;
  }) => ReactNode;
};
