import { useLocation, useSearchParams } from "#src/Routers/hooks";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { ConfigurationTemplateRunsListTab } from "#src/routes/data-tools/submissions/configuration-templates/ConfigurationTemplateRunsListTab";
import { DataSetsListTab } from "#src/routes/data-tools/submissions/datasets/DataSetsListTab";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";
import { DataSubmissionsRoutePath } from ".";
import { ConfigurationTemplateSubmissionsRoutePath } from "./configuration-templates";
import { DataSetsRoutePath } from "./datasets";

export const DataSubmissionsListPageTabs = {
  DataSetsTab: "datasets",
  ConfigurationsTab: "configuration-templates",
} as const;

export const DataSubmissionsListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [breadcrumbs] = useBreadcrumbsFromRoute(DataSubmissionsRoutePath);
  const [isConfigurationTemplatesAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configuration_runs:read",
  });

  const DEFAULT_TAB_KEY =
    ConfigurationTemplateSubmissionsRoutePath.path === location.pathname
      ? DataSubmissionsListPageTabs.ConfigurationsTab
      : DataSubmissionsListPageTabs.DataSetsTab;

  return (
    <Page
      title={DataSubmissionsRoutePath.title}
      breadcrumbs={breadcrumbs}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title={DataSetsRoutePath.title}
        tabKey={DataSubmissionsListPageTabs.DataSetsTab}
      >
        <DataSetsListTab />
      </Tab>
      {isConfigurationTemplatesAvailable ? (
        <Tab
          title={ConfigurationTemplateSubmissionsRoutePath.title}
          tabKey={DataSubmissionsListPageTabs.ConfigurationsTab}
        >
          <ConfigurationTemplateRunsListTab />
        </Tab>
      ) : null}
    </Page>
  );
};
