import { DropdownInput } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "../../../../CreateFormTemplate.module.scss";
import { ConditionValueField } from "./ConditionValueField";

const cx = classNames.bind(styles);

export const ComparativeCondition = ({ name, question }) => {
  const comparatorOptions = [
    { label: ">", value: "$gt" },
    { label: ">=", value: "$gte" },
    { label: "<", value: "$lt" },
    { label: "<=", value: "$lte" },
  ];

  return (
    <div className={cx("comparativeConditionContainer")}>
      <DropdownInput
        label="Comparator"
        name={`${name}.operation`}
        className={cx("comparatorDropdown")}
        options={comparatorOptions}
        labelKey="label"
        valueKey="value"
        isRequired
        isFluid
      />

      <ConditionValueField
        name={`${name}.comparator`}
        question={question}
      />
    </div>
  );
};
