import { useNavigate } from "#routers/hooks";
import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";
import { EquipmentContext } from "#src/contexts/EquipmentContext";
import { linkToEquipmentDetail } from "#src/routes/organization/equipment/[equipmentId]";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import { KeyValuePanel, Link } from "@validereinc/common-components";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useContext } from "react";

export const RecordEquipmentSummaryPanel = () => {
  const navigate = useNavigate();
  const { equipment } = useContext(EquipmentContext) ?? {};
  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ variant: "shaded" }}
      panelProps={{ title: "Details", isFluidY: false }}
      data={[
        {
          title: "Equipment",
          value: (
            <Link
              onClick={() =>
                navigate({
                  pathname: linkToEquipmentDetail(equipment?.id),
                })
              }
              label={equipment?.name ?? "-"}
            />
          ),
        },
        {
          title: "Type",
          value: equipmentTypes?.find(({ id }) => id === equipment?.type?.id)
            ?.name,
        },
        {
          title: "Facility",
          value: (
            <Link
              onClick={() =>
                navigate({
                  pathname: equipment?.facility?.id
                    ? linkToFacilityDetail(equipment?.facility?.id)
                    : "",
                })
              }
              label={
                equipment?.facility?.name
                  ? toStartCaseString(equipment?.facility?.name)
                  : "-"
              }
            />
          ),
        },
      ]}
    />
  );
};
