import { FlowFormPanel } from "#batteries-included-components/Panels/FormPanels/FlowFormPanel/FlowFormPanel";
import { useCreateFlow } from "#hooks/adapters/useFlows";
import { useNavigate } from "#routers/hooks";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { sanitizeCustomAttributeFormInputs } from "#src/utils/customAttributes";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Page } from "@validereinc/common-components";
import { AssetType, type CreateFlowType } from "@validereinc/domain";
import React from "react";
import { FlowListRoute } from "../";
import { FlowDetailRoute } from "../[flowId]/detail";
import { FlowCreateRoute } from "./";
import useLocalization from "#src/hooks/useLocalization";

const CreateFlowPageContent = () => {
  const { localize } = useLocalization();
  const { stepper, footer } = useMultiStepFormContext();
  const { filterConfigStorageKey } = useStorageKey("create-flow-filters");

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowCreateRoute);

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={`${localize(FlowListRoute.title)}`}
      actionRow={stepper}
      footer={footer}
    >
      <FlowFormPanel filterConfigStorageKey={filterConfigStorageKey} />
    </Page>
  );
};

export const CreateFlowPage = () => {
  const navigate = useNavigate();
  const onCancel = () =>
    navigate({
      pathname: FlowListRoute.toLink(),
    });

  const createFlow = useCreateFlow({
    onSuccess: (data) => {
      navigate(
        FlowDetailRoute.toLinkParts({ pathParams: { flowId: data?.id } })
      );
    },
  });

  const customAttributes =
    useListCustomAttributes({ filters: { entity_type: AssetType.FLOW } }).data
      ?.data ?? [];

  const onSubmit = (values: Array<Partial<CreateFlowType>>) => {
    const allValues = values.reduce(
      (total, current) => ({ ...total, ...current }),
      {}
    );

    allValues.custom_attributes = sanitizeCustomAttributeFormInputs(
      allValues?.custom_attributes ?? {},
      customAttributes
    );

    createFlow.mutate(allValues);
  };

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Details",
        },
        {
          label: "Connections",
        },
      ]}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitMutationState={{
        isSubmitting: createFlow.isLoading,
      }}
      submissionActionText="Create"
    >
      <CreateFlowPageContent />
    </MultiStepFormProvider>
  );
};
