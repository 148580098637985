import {
  Button,
  useFieldArray,
  useFormContext,
} from "@validereinc/common-components";
import React, { useEffect } from "react";
import { QuestionCondition } from "./QuestionCondition";
import classNames from "classnames/bind";
import styles from "../../../../CreateFormTemplate.module.scss";

const cx = classNames.bind(styles);

export const QuestionConditions = ({ defaultConditions, questions }) => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "conditions",
  });

  const onAddCondition = () => {
    append({ question: "", operation: "", value: "", type: "" });
  };

  const getConditionOperationFromObject = (condition: {
    operation: string;
  }) => {
    const keys = Object.keys(condition);
    if (keys.length !== 1) {
      return "";
    }
    return keys[0];
  };

  const getConditionTypeFromObject = (condition: { operation: string }) => {
    if (Object.hasOwn(condition, "$eq")) {
      return "$eq";
    } else if (
      Object.hasOwn(condition, "$like") ||
      Object.hasOwn(condition, "$in")
    ) {
      return "partial";
    } else if (
      Object.hasOwn(condition, "$gt") ||
      Object.hasOwn(condition, "$gte") ||
      Object.hasOwn(condition, "$lt") ||
      Object.hasOwn(condition, "$lte")
    ) {
      return "comparative";
    }
    return "";
  };

  const getConditionComparatorFromObject = (condition: {
    operation: string;
  }) => {
    const keys = Object.keys(condition);
    if (keys.length !== 1) {
      return "";
    }
    return condition[keys[0]];
  };

  useEffect(() => {
    if (defaultConditions) {
      for (const [question, condition] of Object.entries(defaultConditions)) {
        append({
          question: question,
          operation:
            condition === true
              ? "$exists"
              : getConditionOperationFromObject(condition),

          value: "",
          type:
            condition === true
              ? "$exists"
              : getConditionTypeFromObject(condition),

          comparator:
            condition === true
              ? undefined
              : getConditionComparatorFromObject(condition),
        });
      }
    }
  }, [defaultConditions]);

  const onRemoveCondition = (conditionIndex: number) => {
    remove(conditionIndex);
  };

  return (
    <>
      <Button
        className={cx("addConditionButton")}
        icon="plus-circle"
        onClick={onAddCondition}
      >
        Add Condition
      </Button>

      {fields.map((_field, index) => (
        <QuestionCondition
          key={index}
          index={index}
          questions={questions}
          onRemoveCondition={onRemoveCondition}
          isRemovable={fields.length > 1}
        />
      ))}
    </>
  );
};
