import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  FileInput,
  Form,
  TextInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  RecordDomain,
  RecordValueType,
  RecordValueUpdateType,
} from "@validereinc/domain";
import { useFile } from "#src/hooks/useFile";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useClearRecordsCache } from "#src/queries/recordQueries";

export const EditRecordValueDialog = ({
  isOpen,
  onClose,
  recordId,
  recordValue,
}: {
  isOpen: boolean;
  onClose: () => void;
  recordId: string;
  recordValue: RecordValueType;
}) => {
  const form = useForm({});
  const { invalidate } = useClearRecordsCache();
  const { status, upload } = useFile();
  const { addAlert } = useAlert();
  const { getTypeName } = useMeasurementTypes();

  const measurementType = getTypeName(recordValue?.measurement_type);

  const handleSubmit = async () => {
    if (!recordId || !recordValue?.measurement_type) {
      return;
    }

    const formValues = form.getValues();
    const note = formValues?.note?.trim();
    const body = {
      measurement_unit: recordValue?.measurement_unit,
      configuration: {
        configuration_type: "manual",
        value: Number(formValues.record_value),
      },
      ...(note ? { note } : {}),
    } as RecordValueType;

    const file = formValues?.attachment?.item(0);

    if (file) {
      try {
        const { name, ref } = await upload({
          file: formValues.attachment.item(0),
          description:
            "A user-uploaded file through record value notes (and attachments) - IN DEVELOPMENT",
        });
        body.attachment_filename = name;
        body.attachment_ref = ref;
      } catch (e) {
        addAlert({
          variant: "error",
          message: "Unable to upload attachment",
        });
        return;
      }
    }

    try {
      await RecordDomain.recordValue.update(
        {
          recordId,
          measurementType: recordValue?.measurement_type,
        },
        body as RecordValueUpdateType
      );
      addAlert({
        variant: "success",
        message: "Success!",
      });
      onClose?.();
      invalidate();
    } catch (err) {
      console.error(err);
      addAlert({
        variant: "error",
        message: "Unable to update record configuration",
      });
    }
  };

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  return (
    <Dialog
      title="Edit Value"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          onClick={form?.handleSubmit(handleSubmit)}
          variant="primary"
          disabled={form?.formState.isSubmitting}
          key="done-button"
        >
          Done
        </Button>,
      ]}
    >
      <Form {...form}>
        <TextInput
          name="record_value"
          label={measurementType}
          unit={recordValue?.measurement_unit}
          isRequired={true}
        />
        <TextInput
          name="note"
          label="Note"
          isRequired={false}
        />

        <FileInput
          name="attachment"
          label="Attachment"
          placeholder="You can drag a file here to use as an attachment (Max. 20MB)"
          showIcon={true}
          isRequired={false}
          uploadStatus={status}
        />
      </Form>
    </Dialog>
  );
};
