import {
  type FormSchemaGetListFilterType,
  type FormSchemaType,
} from "../schemas";
import {
  getFilterObject,
  type FilterObjectType,
  type GetListRequestType,
  type GetListResponseType,
  type ResourceServiceType,
} from "../util";
import { restAPI } from ".";

interface FormAdapterType
  extends Pick<
    ResourceServiceType<FormSchemaType>,
    "getOne" | "getList" | "createOne" | "updateOne"
  > {
  getList: (
    params: GetListRequestType<FilterObjectType<FormSchemaGetListFilterType>>
  ) => Promise<GetListResponseType<FormSchemaType>>;
}

export const FormSchemaAdapter: FormAdapterType = {
  /**
   * Get a single form schema (form template)
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_schemas/get_form_schema
   */
  getOne: async ({ id }) =>
    restAPI.nodeAPI
      .GET<FormSchemaType>({
        endpoint: `/form_schemas/${id}`,
      })
      .then((resp) => ({ data: resp })),
  /**
   * Search form schemas
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_schemas/search_form_schemas
   */
  getList: ({ filters, page, pageSize, sortBy, sortDirection }) =>
    restAPI.nodeAPI.POST({
      endpoint: "/form_schemas/search",
      body: {
        filter: getFilterObject(filters),
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
      },
    }),
  /**
   * Create a form schema
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_schemas/create_form_schema
   */
  createOne: ({ data }) =>
    restAPI.nodeAPI.POST({
      endpoint: "/form_schemas",
      body: data,
    }),
  /**
   * Update a form schema
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_schemas/update_form_schema
   */
  updateOne: ({ id, data }) =>
    restAPI.nodeAPI.PUT({
      endpoint: `/form_schemas/${id}`,
      body: data,
    }),
};
