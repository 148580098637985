import { Button, Modal, Title, useAlert } from "@validereinc/common-components";
import { downloadLink } from "@validereinc/utilities";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DateRangeSelector from "../Common/DateSelector/DateRangeSelector";
import CustomInput from "../Common/Form/CustomInput";
import Form from "../Common/Form/Form";
import SelectInput from "../Common/Form/SelectInput";
import SampleService from "../Services/SampleService";
import "./SampleExportModal.scss";

const STATE_OPTIONS = ["validated", "invalidated", "pending"];

const TITLE_COLUMN = 3;

const DEFAULT_DATE_RANGE = 30;

const DEFAULT_INPUTS = {
  state: [],
  sites: [],
  streams: [],
  from: moment().subtract(DEFAULT_DATE_RANGE, "days").startOf("day").toDate(),
  until: moment().endOf("day").toDate(),
};

const SampleExportModal = ({
  show,
  setShowSampleExportModal,
  sites,
  streams,
}) => {
  const [inputs, setInputs] = useState(DEFAULT_INPUTS);
  const [formState, setFormState] = useState("enabled");
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const { addAlert } = useAlert();

  useEffect(() => {
    if (!show && hasDownloaded) {
      setInputs(DEFAULT_INPUTS);
      setHasDownloaded(false);
    }
  }, [show]);

  const onInputChange = (key, value) => {
    const newInputs = { ...inputs };

    newInputs[key] = value;
    setInputs(newInputs);
  };

  const onDateSelect = (from, to) => {
    setInputs((inputs) => ({
      ...inputs,
      from,
      until: to,
    }));
  };

  const onSubmitClick = () => {
    setFormState("loading");

    SampleService.getSampleCSVExport(inputs)
      .then(({ data }) => {
        downloadLink(data.url);
        setHasDownloaded(true);
        addAlert({
          variant: "success",
          message: "Your files will start downloading shortly.",
        });
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  const sharedProps = {
    titleCol: TITLE_COLUMN,
    pullLeft: true,
    disabled: false,
  };

  return (
    <Modal
      className="sampleExportModal"
      open={show}
      onClose={() => setShowSampleExportModal(false)}
    >
      <Modal.Header>
        <Title>Sample Export</Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <SelectInput
            title="Sites"
            labelKey="name"
            placeholder="All Sites"
            value={inputs?.sites || []}
            options={sites}
            onChange={(value) => onInputChange("sites", value)}
            isDisabled={formState !== "enabled"}
            closeMenuOnSelect={false}
            ignoreCase
            isMulti
            {...sharedProps}
          />

          <SelectInput
            title="Streams"
            labelKey="name"
            placeholder="All Streams"
            value={inputs?.streams || []}
            options={streams}
            onChange={(value) => onInputChange("streams", value)}
            isDisabled={formState !== "enabled"}
            closeMenuOnSelect={false}
            ignoreCase
            isMulti
            {...sharedProps}
          />

          <SelectInput
            title="State"
            placeholder="All States"
            value={inputs?.state || []}
            options={STATE_OPTIONS}
            onChange={(value) => onInputChange("state", value)}
            isDisabled={formState !== "enabled"}
            closeMenuOnSelect={false}
            ignoreCase
            isMulti
            {...sharedProps}
          />

          <CustomInput
            title="Date"
            {...sharedProps}
          >
            <DateRangeSelector
              dateRange={{ from: inputs.from, to: inputs.until }}
              onDateRangeChange={onDateSelect}
              allowTimeSelection
              disabled={formState !== "enabled"}
            />
          </CustomInput>
        </Form>
      </Modal.Body>

      <Modal.Footer className="clearfix">
        <Button
          variant="primary"
          className="pull-right"
          onClick={onSubmitClick}
          disabled={formState !== "enabled"}
          isLoading={formState === "loading"}
        >
          Export Samples
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SampleExportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowSampleExportModal: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
  streams: PropTypes.array.isRequired,
};

export default SampleExportModal;
