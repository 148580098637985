import {
  getComponentShortForm,
  isAdjustableMethod,
  isSampleData,
  isSelected,
} from "#components/Records/Quality/RecordOfQualityHelper";
import { getFormattedMeasurementValueWithUnit } from "#redux/reducers/measurements";
import RecordOfQualityService from "#services/RecordOfQualityService";
import {
  sampleApplicablePeriodRenderer,
  sampleStateRenderer,
  sortBySampleState,
} from "#src/components/Samples/SampleHelper";
import config from "#src/config";
import { AssertIsBeforeDate, AssertIsBeforeOrEqualDate } from "#utils/assert";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  LegacyDataTable,
  Panel,
  Tooltip,
} from "@validereinc/common-components";
import { downloadLink } from "@validereinc/utilities";
import orderBy from "lodash/orderBy";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Button, Checkbox, OverlayTrigger, Popover } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { useRecordSelectionContext } from "../Context/recordSelectionContext";
import "./RecordOfQualitySelectionTable.scss";

const TABLE_PADDING = 10;

/** A mapping from API type to table type */
const SAMPLE_TYPE = {
  composite_sample: "Comp.",
  spot_sample: "Spot",
  virtual_sample: "Virtual",
  record_of_quality: "RoQ",
  unknown_sample: "N/A",
};

const RED = "#EB4639";

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const typeRenderer = (rowData) => {
  return SAMPLE_TYPE[rowData.type];
};

const alarmTooltip = (alarms, title) => (
  <Tooltip
    id="tooltip"
    className="recordOfQualitySelectionTable__tooltip"
  >
    {alarms && (
      <section className="recordOfQualitySelectionTable__alarms">
        <h6 className="recordOfQualitySelectionTable__sectionTitle">{title}</h6>
        <ul>
          {alarms.map((alarm) => (
            <li key={alarm}>{alarm}</li>
          ))}
        </ul>
      </section>
    )}
  </Tooltip>
);

const measurementRenderer = (
  rowData,
  dataKey,
  getFormattedMeasurementValueWithUnit
) => {
  const { value, alarms, unit } = rowData.component_quality?.[dataKey] ?? {};

  const valueString = getFormattedMeasurementValueWithUnit(dataKey, {
    value,
    unit,
  });

  return (
    <div className="recordOfQualitySelectionTable__measurement">
      {alarms?.length ? (
        <Tooltip
          content={alarmTooltip(alarms, "Measurement Alarms")}
          className="recordOfQualitySelectionTable__tooltip"
        >
          <div style={{ color: RED }}>{valueString}</div>
        </Tooltip>
      ) : (
        <div style={valueString === "-" ? { textAlign: "center" } : null}>
          {valueString}
        </div>
      )}
    </div>
  );
};

const alarmsRenderer = (rowData) => {
  const { alarms } = rowData;
  const numAlarms = alarms?.length;

  return (
    <div className="datatable__row">
      {numAlarms ? (
        <Tooltip
          content={alarmTooltip(alarms, "Alerts")}
          className="recordOfQualitySelectionTable__tooltip"
        >
          {`${numAlarms} ${numAlarms > 1 ? "Alarms" : "Alarm"}`}
        </Tooltip>
      ) : null}
    </div>
  );
};

const getSampleRowClassName = (rowData) => {
  return rowData?.state !== "validated"
    ? "recordOfQualitySelectionTable__invalidSample"
    : "";
};

const sortMeasurement = (list, measurementName) => {
  return orderBy(list, [
    (row) => row.component_quality?.[measurementName]?.value ?? -1,
  ]);
};

export const RecordOfQualitySelectionTable = ({
  intervalSelectionIndex,
  interval,
  getFormattedMeasurementValueWithUnit,
}) => {
  const {
    toggleSample,
    displayProperties,
    selectionData,
    selectionDataLoadingState,
    accountingPeriod,
    hasWriteAccess,
  } = useRecordSelectionContext();

  const onSampleSelect = (sample) => {
    toggleSample(sample.id, sample.type, intervalSelectionIndex);
  };

  const selectionDisabled =
    !hasWriteAccess || !isAdjustableMethod(interval.method);

  const onDownloadLinkClick = (sampleId, type) => {
    RecordOfQualityService.getDownloadSampleCompositionalLink(
      sampleId,
      type
    ).then(({ data }) => {
      downloadLink(data.url);
    });
  };

  const dateRenderer = (rowData) => {
    const downloadPopover = () => (
      <Popover id="recordOfQuality__popover">
        <div className="recordOfQuality__popover">
          <a
            className="recordOfQuality__popoverLink link"
            onClick={() => onDownloadLinkClick(rowData.id, "crude_analysis")}
          >
            Crude Analysis
          </a>
          <br />
          <a
            className="recordOfQuality__popoverLink link"
            onClick={() =>
              onDownloadLinkClick(rowData.id, "compositional_analysis")
            }
          >
            Full Compositional Analysis
          </a>
        </div>
      </Popover>
    );

    return (
      <div className="recordOfQualitySelectionTable__checkboxContainer enablePointerEvent">
        <Checkbox
          readOnly
          checked={rowData.checked}
          disabled={selectionDisabled || rowData.state !== "validated"}
          onChange={() => onSampleSelect(rowData)}
        >
          {rowData.date}
        </Checkbox>
        {isSampleData(rowData) ? (
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose
            overlay={downloadPopover()}
          >
            <Button className="buttonLink">
              <FontAwesome name="download" />
            </Button>
          </OverlayTrigger>
        ) : null}
      </div>
    );
  };

  const selectionDataWithChecked = useMemo(() => {
    return selectionData.map((data) => ({
      ...data,
      checked: isSelected(data, interval),
      date: getTimeStringFromDate(data.until, config.DATEMONTHTIME_FORMAT),
    }));
  }, [selectionData, interval]);

  const filteredDataWithChecked = useMemo(() => {
    if (!hasWriteAccess) {
      return selectionDataWithChecked;
    }

    switch (interval?.method) {
      // Volume Weighted Average shows the latest previous roq and the list of
      // sample ids that start before interval end date
      case "volume_weighted_average": {
        const previousROQs = selectionDataWithChecked
          .filter((data) => data.type === "record_of_quality")
          .filter((data) =>
            AssertIsBeforeOrEqualDate(data.until, accountingPeriod.from, "day")
          )
          .sort((a, b) => (AssertIsBeforeDate(a.until, b.until) ? -1 : 1));

        const latestPreviousRoQ = previousROQs.pop();

        return selectionDataWithChecked
          .filter((data) =>
            AssertIsBeforeOrEqualDate(data.from, interval.until, "day")
          )
          .filter(
            (data) => isSampleData(data) || data.id === latestPreviousRoQ?.id
          );
      }
      case "previous_accounting_record":
        return selectionDataWithChecked.filter(
          (data) => data.type === "record_of_quality"
        );
      case "volume_weighted_inline":
        return [];
      case "none":
        return [];
      default:
        return selectionDataWithChecked.filter((data) => isSampleData(data));
    }
  }, [selectionDataWithChecked, interval, hasWriteAccess, accountingPeriod]);

  const sortDate = (list) => {
    return orderBy(list, [(row) => row.until]);
  };

  const headers = [
    {
      label: "Date",
      key: "date",
      width: 180,
      cellRenderer: dateRenderer,
      sort: sortDate,
      fixed: true,
    },
    {
      label: "Type",
      key: "type",
      width: 60,
      cellRenderer: typeRenderer,
      fixed: true,
    },
    {
      label: "Alarms",
      key: "alarms",
      cellRenderer: alarmsRenderer,
      width: 70,
    },
    {
      label: "Status",
      key: "state",
      cellRenderer: sampleStateRenderer,
      sort: sortBySampleState,
      width: 150,
    },
    {
      label: "Applicable Period",
      key: "applicable_period",
      width: 135,
      cellRenderer: sampleApplicablePeriodRenderer,
      disableSort: true,
    },
    ...displayProperties.map((component) => ({
      label: getComponentShortForm(component),
      key: component,
      width: 100,
      cellRenderer: (rowData, columnKey) =>
        measurementRenderer(
          rowData,
          columnKey,
          getFormattedMeasurementValueWithUnit
        ),
      sort: sortMeasurement,
      rightAlign: true,
    })),
  ];

  return (
    <Panel
      className="recordOfQualitySelectionTable"
      loaded={selectionDataLoadingState !== "loading"}
    >
      {selectionDataLoadingState === "loaded" &&
      filteredDataWithChecked.length ? (
        <div className="recordOfQualitySelectionTable__container">
          <AutoSizer>
            {({ width, height }) => (
              <LegacyDataTable
                className="listTable"
                headers={headers}
                list={filteredDataWithChecked}
                width={width - TABLE_PADDING}
                height={height}
                defaultSortBy="date"
                defaultSortDirection="desc"
                rowHeight={50}
                getRowClassName={getSampleRowClassName}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <div className="recordOfQualitySelectionTable__noData">
          {interval.method === "volume_weighted_inline"
            ? "Inline data will be used to calculate the quality for this interval"
            : "No RoQ Selection Data Available"}
        </div>
      )}
    </Panel>
  );
};
RecordOfQualitySelectionTable.propTypes = {
  intervalSelectionIndex: PropTypes.number,
  interval: PropTypes.object,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(RecordOfQualitySelectionTable);
