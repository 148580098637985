import { useFilters } from "#src/resources/filters";
import { DropdownInput } from "@validereinc/common-components";
import React from "react";

export const QuestionFilterDropdown = ({
  variant,
  name,
}: {
  variant: "facility" | "equipment" | "device" | "flow";
  name: string;
}) => {
  const filters = useFilters(variant);

  return (
    <DropdownInput
      label="Attribute to filter on"
      isOptionalTextShown={false}
      name={name}
      options={filters}
      labelKey="display_name"
      valueKey="field_name"
    />
  );
};
