export const NON_PRECISION_UNITS = new Set([
  "unitless",
  "decimal",
  "determined by selected fuel unit",
  "hours",
]);

export const NON_DISPLAY_UNITS = {
  unitless: null,
  decimal: null,
  "determined by selected fuel unit": null,
} as const;

/**
 * Some units are not for display purposes - use this fn to filter them out.
 * @param unit the unit from the back-end
 * @returns the unit or undefined if it shouldn't be displayed
 */
export const getDisplayedUnits = (unit: keyof typeof NON_DISPLAY_UNITS) => {
  if (NON_DISPLAY_UNITS[unit] === null) {
    return undefined;
  }

  return unit;
};
