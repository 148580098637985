import { TemplatedConfigurationRunDrawer } from "#batteries-included-components/Drawers/TemplatedConfigurations";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { ImportDataAction } from "#src/batteries-included-components/Buttons/ImportDataAction";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListTemplatedConfigurationsRun } from "#src/components/hooks/adapters/useTemplatedConfigurations";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import type { ConfigurationTemplateRunsFiltersType } from "#src/routes/data-tools/submissions/configuration-templates/ConfigurationTemplateRunsFilterPanel";
import { linkToAssetDetailPage } from "#utils/links";
import {
  useMutation,
  useQueries,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  ButtonWithPopover,
  DataTable,
  DataTablePanel,
  FilterPills,
  FilterPillVariants,
  HeaderType,
  Link,
  PillProps,
  StorageKeys,
  useAlert,
  type FilterPillProps,
} from "@validereinc/common-components";
import {
  BaseError,
  ResourceDefinitions,
  TemplatedConfigurationRun,
  TemplatedConfigurationRunAdapter,
  TemplatedConfigurationRunCreatedSchema,
  TemplatedConfigurationRunStatus,
  UsersAdapter,
  UserType,
  type TemplatedConfigurationRunCreatedType,
  type TemplatedConfigurationRunResource,
  type TemplatedConfigurationRunStatusType,
} from "@validereinc/domain";
import { downloadLink, toStartCaseString } from "@validereinc/utilities";
import endOfDay from "date-fns/endOfDay";
import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import startCase from "lodash/startCase";
import React, { useMemo, useState } from "react";
import { ConfigurationTemplateSubmissionsRoutePath } from ".";

export const ConfigurationTemplateRunsTablePanel = ({
  viewConfigStorageKey,
  tableConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey" | "tableConfigStorageKey">) => {
  const { localize } = useLocalization();
  const { addAlert } = useAlert();
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const [shownRunDetails, setShownRunDetails] = useState<Pick<
    TemplatedConfigurationRunCreatedType,
    "id" | "templated_configuration_name"
  > | null>(null);
  const [filters, setFilters] =
    useSessionStickyState<ConfigurationTemplateRunsFiltersType>(
      {},
      viewConfigStorageKey
    );
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: TemplatedConfigurationRun.keyof().Enum.created_at,
      sortDirection: "desc",
    },
    filters
  );
  const exportRunsMutation = useMutation({
    mutationFn: async (
      payload: Parameters<typeof TemplatedConfigurationRunAdapter.exportList>[0]
    ) => {
      if (!payload.meta.templated_configuration_name) return;

      const reportData =
        await TemplatedConfigurationRunAdapter.exportList(payload);

      if (!reportData.s3_download_link || !reportData.name) {
        throw new BaseError("Export does not have a download link.", {
          cause: reportData,
        });
      }

      downloadLink(reportData.s3_download_link, reportData.name);
      return reportData;
    },
    onSuccess: (exportData) => {
      addAlert({
        variant: "success",
        message: `Successfully exported submissions as: ${exportData!.name}`,
      });
    },
    onError: () => {
      addAlert({
        variant: "error",
        message: "Failed to export submissions.",
      });
    },
  });

  const { created_at, ...restFilters } = filters;

  const configRunsQuery = useListTemplatedConfigurationsRun({
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...restFilters,
      ...(created_at?.from && created_at?.to
        ? {
            $and: [
              {
                created_at: {
                  $gte: startOfDay(parseISO(created_at.from)),
                },
              },
              {
                created_at: { $lte: endOfDay(parseISO(created_at.to)) },
              },
            ],
          }
        : {}),
    },
  });

  const primaryAssetsMap = useMemo(() => {
    return (
      configRunsQuery.data?.data?.reduce<
        Record<string, TemplatedConfigurationRunResource>
      >((acc, run) => {
        const primaryResource = run?.resources?.find(
          (resource) => resource.is_primary
        );

        if (primaryResource) {
          acc[run?.id] = primaryResource;
        }

        return acc;
      }, {}) ?? {}
    );
  }, [configRunsQuery.data?.data.map((d) => d.id)]);

  const configRunsMetaUsersQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      Array.from(
        configRunsQuery.data?.data?.reduce((userIdsToFetch, run) => {
          if (run.created_by) {
            userIdsToFetch.add(run.created_by);
          }

          if (run.updated_by) {
            userIdsToFetch.add(run.updated_by);
          }

          return userIdsToFetch;
        }, new Set<string>()) ?? new Set<string>()
      ).map((userId) => ({
        queryKey: ["users", userId],
        queryFn: () => {
          if (!userId) return;

          return UsersAdapter.getOne({
            id: userId,
          });
        },
        enabled: Boolean(userId),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp?.data,
      })) ?? [],
  });

  const configRunsMetaUsersMap = useMemo(() => {
    return configRunsMetaUsersQueries.reduce<Record<string, UserType>>(
      (userMap, q) => {
        if (!q.data?.id || userMap[q.data.id]) {
          return userMap;
        }

        userMap[q.data.id] = q.data;
        return userMap;
      },
      {}
    );
  }, [configRunsMetaUsersQueries]);

  const filterPills: FilterPillProps[] = [
    {
      name: "All",
      label: "All",
      value: "",
      isSelected: !filters.status,
      count: configRunsQuery.data?.total_entries ?? 0,
    },
    {
      name: TemplatedConfigurationRunStatus.SUCCESS,
      label: startCase(TemplatedConfigurationRunStatus.SUCCESS),
      value: TemplatedConfigurationRunStatus.SUCCESS,
      variant: FilterPillVariants.GOOD,
      isSelected: filters.status === TemplatedConfigurationRunStatus.SUCCESS,
      count: configRunsQuery.data?.data.length ?? 0,
    },
    {
      name: TemplatedConfigurationRunStatus.SUBMITTED,
      label: startCase(TemplatedConfigurationRunStatus.SUBMITTED),
      value: TemplatedConfigurationRunStatus.SUBMITTED,
      variant: FilterPillVariants.PENDING,
      isSelected: filters.status === TemplatedConfigurationRunStatus.SUBMITTED,
      count: configRunsQuery.data?.data.length ?? 0,
    },
    {
      name: TemplatedConfigurationRunStatus.PARTIAL,
      label: startCase(TemplatedConfigurationRunStatus.PARTIAL),
      value: TemplatedConfigurationRunStatus.PARTIAL,
      variant: FilterPillVariants.ATTENTION,
      isSelected: filters.status === TemplatedConfigurationRunStatus.PARTIAL,
      count: configRunsQuery.data?.data.length ?? 0,
    },
    {
      name: TemplatedConfigurationRunStatus.FAILED,
      label: startCase(TemplatedConfigurationRunStatus.FAILED),
      value: TemplatedConfigurationRunStatus.FAILED,
      variant: FilterPillVariants.FAILURE,
      isSelected: filters.status === TemplatedConfigurationRunStatus.FAILED,
      count: configRunsQuery.data?.data.length ?? 0,
    },
  ];

  const headers: Array<HeaderType<TemplatedConfigurationRunCreatedType>> = [
    {
      key: TemplatedConfigurationRunCreatedSchema.keyof().Enum
        .templated_configuration_name,
      label: "Configuration Template",
      isSortable: true,
      renderComponent: ({ item }) => {
        const name =
          item?.templated_configuration?.display_name ??
          item?.templated_configuration?.name ??
          item?.templated_configuration_name ??
          null;

        return name ? (
          <Link
            onClick={() =>
              setShownRunDetails({
                id: item.id,
                templated_configuration_name: item.templated_configuration_name,
              })
            }
            label={name}
          />
        ) : (
          "-"
        );
      },
    },
    {
      key: `${TemplatedConfigurationRunCreatedSchema.keyof().Enum.templated_configuration}.${
        TemplatedConfigurationRunCreatedSchema.shape.templated_configuration.keyof()
          .Enum.primary_resource_type
      }`,
      label: "Primary Asset Type",
      isSortable: true,
      renderComponent: ({ item }) => {
        return localize(item?.templated_configuration?.primary_resource_type);
      },
    },
    {
      key: `${TemplatedConfigurationRunCreatedSchema.keyof().Enum.resources}.${TemplatedConfigurationRunCreatedSchema.shape.resources.element.keyof().Enum.name}`,
      label: "Primary Asset",
      isSortable: true,
      renderComponent: ({ item }) => {
        if (!primaryAssetsMap[item.id]) return "-";

        const primaryAsset = primaryAssetsMap[item.id];

        if (primaryAsset.id && primaryAsset.type && primaryAsset.name) {
          return (
            <RoutingLink
              to={linkToAssetDetailPage(primaryAsset.type, primaryAsset.id)}
            >
              {primaryAssetsMap[item.id].name}
            </RoutingLink>
          );
        } else if (primaryAsset.name) {
          return primaryAsset.name;
        } else {
          return "-";
        }
      },
    },
    {
      key: TemplatedConfigurationRunCreatedSchema.keyof().Enum.status,
      label: "Status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={
            (
              {
                partial: "warning",
                failed: "error",
                submitted: "info",
                success: "success",
              } satisfies Record<
                (typeof TemplatedConfigurationRunStatus)[keyof typeof TemplatedConfigurationRunStatus],
                PillProps["variant"]
              >
            )[item?.status]
          }
          value={toStartCaseString(item?.status ?? "-")}
        />
      ),
    },
    {
      key: TemplatedConfigurationRunCreatedSchema.keyof().Enum.created_at,
      label: "Created On",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item?.created_at}
          withTime
        />
      ),
    },
    {
      key: TemplatedConfigurationRunCreatedSchema.keyof().Enum.created_by,
      label: "Created By",
      isSortable: true,
      renderComponent: ({ item }) =>
        item.created_by
          ? configRunsMetaUsersMap[item.created_by]?.name ?? "n/a"
          : "-",
    },
  ];

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: ConfigurationTemplateSubmissionsRoutePath.title,
          titleDecorator: (
            <FilterPills
              name="status"
              pills={filterPills}
              onChange={(val) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  status: val as TemplatedConfigurationRunStatusType,
                }))
              }
            />
          ),
        }}
        actionRowWhenNoRowsSelected={[
          <ButtonWithPopover
            key="export"
            buttonProps={{
              icon: "download-simple",
              variant: "outline",
              disabled: !filters.templated_configuration_name,
              isLoading: exportRunsMutation.isLoading,
              onClick: () => {
                if (!filters.templated_configuration_name) return;

                exportRunsMutation.mutate({
                  sortBy: tableState.sortBy,
                  sortDirection: tableState.sortDirection,
                  filters: {
                    ...(filters.status ? { status: filters.status } : {}),
                    ...(created_at?.from && created_at?.to
                      ? {
                          $and: [
                            {
                              created_at: {
                                $gte: startOfDay(parseISO(created_at.from)),
                              },
                            },
                            {
                              created_at: {
                                $lte: endOfDay(parseISO(created_at.to)),
                              },
                            },
                          ],
                        }
                      : {}),
                    isAlreadyFormatted: true,
                  },
                  meta: {
                    templated_configuration_name:
                      filters.templated_configuration_name,
                  },
                });
              },
            }}
            label="Export"
          />,
          ...(isDataIngestionEnabled && filters.templated_configuration_name
            ? [
                <ImportDataAction
                  key="import"
                  resource={ResourceDefinitions.equipment}
                  preselectedTemplatedConfigurationName={
                    filters.templated_configuration_name
                  }
                  modes={["templated-configuration"]}
                />,
              ]
            : []),
        ]}
        dataTableProps={{
          headers,
          items: configRunsQuery.data?.data ?? [],
          isLoading:
            configRunsQuery.isLoading ||
            configRunsMetaUsersQueries.some((q) => q.isLoading),
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: configRunsQuery.data?.total_entries ?? tableState.total ?? 0,
            isTotalKnown:
              typeof configRunsQuery.data?.total_entries !== "undefined",
          },
        }}
      />
      <TemplatedConfigurationRunDrawer
        runDetails={shownRunDetails}
        onClose={() => setShownRunDetails(null)}
      />
    </>
  );
};
