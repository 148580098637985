import { AssetType, AssetTypeType } from "@validereinc/domain";
import React from "react";
import { AssetGroupDropdownInput } from "./AssetGroupDropdownInput";
import { DeviceDropdownInput } from "./DeviceDropdownInput";
import { BaseEntityDropdownProps } from "./EntityDropdownInput";
import { EquipmentDropdownInput } from "./EquipmentDropdownInput";
import { FacilityDropdownInput } from "./FacilityDropdownInput";
import { FlowDropdownInput } from "./FlowDropdownInput";

export const AssetDropdownInput = (
  props: BaseEntityDropdownProps & { assetType: AssetTypeType }
) => {
  switch (props.assetType) {
    case AssetType.FACILITY:
      return <FacilityDropdownInput {...props} />;
    case AssetType.EQUIPMENT:
      return <EquipmentDropdownInput {...props} />;
    case AssetType.DEVICE:
      return <DeviceDropdownInput {...props} />;
    case AssetType.FLOW:
      return <FlowDropdownInput {...props} />;
    case AssetType.ASSET_GROUP:
      return <AssetGroupDropdownInput {...props} />;
    default:
      return <></>;
  }
};
