import { z } from "zod";
import { DomainModelMetaSchema, DomainModelSchema } from "./DomainModelSchemas";
import { AssetTypeSchema } from "./AssetSchemas";
import { FormSubmissionStatus } from "./FormSubmissionSchemas.shared";

export const WorkflowStatus = {
  IN_PROGRESS: "in_progress",
  COMPLETE: "complete",
  OVERDUE: "overdue",
  DISMISSED: "dismissed",
  MISSED: "missed",
} as const;

export type WorkflowStatusType =
  (typeof WorkflowStatus)[keyof typeof WorkflowStatus];

const CreateEventActionSchema = z.object({
  type: z.literal("create_event"),
  event_schema_id: z.string(),
  event_category_id: z.string(),
});

const FormSubmissionTaskSchema = z.object({
  type: z.union([
    z.literal("manual_task"),
    z.literal("submit_form"),
    z.literal("complete_event"),
  ]),
  form_category_id: z.string(),
  form_schema_id: z.string(),
  form_required: z.boolean().optional(),
  input: z.record(z.string(), z.string()).nullish(),
});

const ManualTaskSchema = z.object({
  type: z.union([
    z.literal("manual_task"),
    z.literal("submit_form"),
    z.literal("complete_event"),
  ]),
});

const CompleteEventTaskSchema = z.object({
  type: z.union([
    z.literal("manual_task"),
    z.literal("submit_form"),
    z.literal("complete_event"),
  ]),
  event_id: z.string(),
});

export const WorkflowUserTaskSchema = z.object({
  type: z.union([z.literal("system_action"), z.literal("user_task")]),
  next: z.string(),
  end: z.boolean(),
  name: z.string(),
  description: z.string(),
  assignees: z.array(z.string()),
  task: z.union([
    FormSubmissionTaskSchema,
    ManualTaskSchema,
    CompleteEventTaskSchema,
  ]),
});

export const WorkflowSystemActionSchema = z.object({
  type: z.union([z.literal("system_action"), z.literal("user_task")]),
  task: CreateEventActionSchema,
  next: z.string(),
  end: z.boolean(),
});

export const WorkflowSchema = z.object({
  created_at: z.string(),
  updated_at: z.string(),
  created_by: z.string(),
  updated_by: z.string(),
  id: z.string(),
  name: z.string(),
  description: z.string(),
  company_id: z.string(),
  workflow_template_id: z.string(),
  workflow_template: z.object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    workflow_category: z.object({
      id: z.string(),
      name: z.string(),
    }),
  }),
  facility_id: z.string().nullish(),
  asset_id: z.string().nullish(),
  time_period: z.string().nullish(),
  duration: z.number().nullish(),
  status: z.enum([
    WorkflowStatus.COMPLETE,
    WorkflowStatus.DISMISSED,
    WorkflowStatus.IN_PROGRESS,
    WorkflowStatus.MISSED,
    WorkflowStatus.OVERDUE,
  ]),
  due_date: z.string(),
  facility: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullish(),
  asset: z
    .object({ id: z.string(), name: z.string(), asset_type: AssetTypeSchema })
    .nullish(),
  step_states: z.any(),
  config: z.object({
    start: z.string(),
    steps: z.record(
      z.union([WorkflowSystemActionSchema, WorkflowUserTaskSchema])
    ),
  }),
  variables: z.record(z.string(), z.string()),
  workflow_trigger: z
    .object({
      triggered_workflow_id: z.string(),
      triggering_workflow_id: z.string(),
      triggering_workflow_name: z.string().optional(),
      metadata: z.object({
        triggering_workflow_id: z.string(),
        triggering_workflow_template_id: z.string(),
      }),
    })
    .nullable()
    .optional(),
});

export type WorkflowType = z.infer<typeof WorkflowSchema>;

export type WorkflowSystemActionType = z.infer<
  typeof WorkflowSystemActionSchema
>;

export const WorkflowFormSubmissionSchema = z
  .object({
    created_by: z.string(),
    form_schema_id: z.string(),
    form_schema_version: z.number(),
    status: z.enum([
      FormSubmissionStatus.DRAFT,
      FormSubmissionStatus.INVALIDATED,
      FormSubmissionStatus.PENDING,
      FormSubmissionStatus.SUBMITTED,
      FormSubmissionStatus.VALIDATED,
    ]),
    form_schema: z.object({
      id: z.string(),
      name: z.string(),
      form_category: z
        .object({
          id: z.string(),
          name: z.string(),
        })
        .nullable(),
    }),
    associated_tasks: z.array(
      z.object({
        workflow_task_name: z.string(),
        workflow_task_id: z.string(),
        form_submission_id: z.string(),
      })
    ),
    is_workflow_trigger: z.boolean(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema);

export type WorkflowFormSubmissionType = z.infer<
  typeof WorkflowFormSubmissionSchema
>;

export const CreateWorkflowSchema = z.object({
  asset_ids: z.array(z.string()),
  has_due_date: z.boolean(),
  time_period: z.string(),
  duration: z.number(),
});

export type CreateWorkflowType = z.infer<typeof CreateWorkflowSchema>;
export type WorkflowFormSubmissionTaskType = z.infer<
  typeof FormSubmissionTaskSchema
>;
