import { useParams } from "#src/Routers/hooks";
import { CreateWorkflowDialog } from "#src/batteries-included-components/Dialogs/CreateWorkflowDialog";
import { WorkflowsListLayout } from "#src/batteries-included-components/Layouts/Workflows/List";
import { useGetOneWorkflowTemplate } from "#src/components/hooks/adapters/useWorkflowTemplates";
import config from "#src/config";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { WorkflowsRoutePath } from "#src/routes/workflows";
import { WorkflowCategoriesRoutePath } from "#src/routes/workflows/categories";
import { WorkflowCategoryDetailsRoutePath } from "#src/routes/workflows/categories/[workflowCategoryId]";
import { WorkflowTemplateStatusToPillVariant } from "#src/routes/workflows/templates/[workflowTemplateId]/WorkflowTemplatePage.helpers";
import { RoutePath, useBreadcrumbsFromRoutes } from "#src/utils/route";
import { getTimeStringFromDate } from "#src/utils/timeFormatter";
import { useQuery } from "@tanstack/react-query";
import { Button, Page, StatusPanel } from "@validereinc/common-components";
import { UsersAdapter } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import { WorkflowTemplateDetailsRoutePath } from ".";
import { WorkflowTemplateAssetTable } from "./WorkflowTemplateAssetTable";
import { WorkflowTemplateKeyValuePanel } from "./WorkflowTemplateKeyValuePanel";
import styles from "./WorkflowTemplatePage.module.scss";

const cx = classNames.bind(styles);

export const WorkflowTemplatePage = () => {
  const [templateIdForDialogOpen, setTemplateIdForDialogOpen] =
    useState<string>("");

  const storageKeys = useStorageKey("workflows-template");
  const { workflowTemplateId } = useParams<{
    workflowTemplateId: string;
  }>();

  const { data, isLoading } = useGetOneWorkflowTemplate({
    id: workflowTemplateId,
  });

  const templateDetails = data?.data;

  const { data: createdByUser, isLoading: createdByUserIsLoading } = useQuery(
    ["users", templateDetails?.created_by],
    async () => {
      if (!templateDetails?.created_by) {
        return;
      }

      return UsersAdapter.getOne({ id: templateDetails?.created_by });
    },
    {
      enabled: Boolean(templateDetails?.created_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const { data: updatedByUser } = useQuery(
    ["users", templateDetails?.updated_by],
    async () => {
      if (!templateDetails?.updated_by) {
        return;
      }

      return UsersAdapter.getOne({ id: templateDetails?.updated_by });
    },
    {
      enabled: Boolean(templateDetails?.updated_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const { data: ownerUser } = useQuery(
    ["users", templateDetails?.workflow_owner_id],
    async () => {
      if (!templateDetails?.workflow_owner_id) {
        return;
      }

      return UsersAdapter.getOne({
        id: templateDetails?.workflow_owner_id,
      });
    },
    {
      enabled: Boolean(templateDetails?.workflow_owner_id),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const [breadcrumbs] = useBreadcrumbsFromRoutes(
    [
      [WorkflowsRoutePath, {}],
      [WorkflowCategoriesRoutePath, {}],
      [
        WorkflowCategoryDetailsRoutePath,
        {
          title: templateDetails?.workflow_category.name,
          pathParams: {
            workflowCategoryId: templateDetails?.workflow_category.id ?? "",
          },
        },
      ],
      [
        new RoutePath({
          path: "",
          isPresentational: true,
          title: "Workflow Templates",
        }),
        {},
      ],
      [
        WorkflowTemplateDetailsRoutePath,
        {
          title: templateDetails?.name,
        },
      ],
    ],
    !!templateDetails
  );

  const onOpenCreateWorkflowDialog = () =>
    setTemplateIdForDialogOpen(workflowTemplateId);

  const onCloseCreateWorkflowDialog = () => setTemplateIdForDialogOpen("");

  return (
    <>
      <Page
        isLoading={isLoading}
        category={WorkflowTemplateDetailsRoutePath.previous?.title}
        title={templateDetails?.name ?? WorkflowTemplateDetailsRoutePath.title}
        breadcrumbs={breadcrumbs}
        actionRow={[
          <Button
            key="create-workflow-action"
            variant="primary"
            onClick={onOpenCreateWorkflowDialog}
          >
            Create Workflow
          </Button>,
        ]}
      >
        <div className={cx("container")}>
          <div className={cx("statusPanelContainer")}>
            <StatusPanel
              isFluidY={false}
              isLoading={isLoading || createdByUserIsLoading}
              data={[
                {
                  label: "Updated At",
                  value: getTimeStringFromDate(
                    templateDetails?.updated_at,
                    config.DATETIME_FORMAT_READABLE
                  ),
                },
                {
                  label: "Updated By",
                  value: updatedByUser?.name ?? "-",
                },
                {
                  label: "Created At",
                  value: getTimeStringFromDate(
                    templateDetails?.created_at,
                    config.DATETIME_FORMAT_READABLE
                  ),
                },
                {
                  label: "Created By",
                  value: createdByUser?.name ?? "-",
                },
              ]}
              statusText={templateDetails?.status ?? ""}
              statusVariant={
                templateDetails?.status
                  ? WorkflowTemplateStatusToPillVariant[templateDetails?.status]
                  : "default"
              }
            />
            <WorkflowTemplateAssetTable
              assetType={templateDetails?.asset_type}
              assetsQueryFilters={templateDetails?.asset_filter}
            />
          </div>
          <div>
            <WorkflowTemplateKeyValuePanel
              templateDetails={templateDetails}
              isLoading={isLoading}
              workflowOwnerName={ownerUser?.name}
            />
            <div className={cx("allWorkflowsContainer")}>
              <WorkflowsListLayout
                tablePanelProps={{
                  ...storageKeys,
                  isWithinWorkflowTemplateDetails: true,
                  overrideFilters: {
                    templateId: templateDetails?.id,
                  },
                }}
                defaultAssetType={templateDetails?.asset_type}
              />
            </div>
          </div>
        </div>
      </Page>
      <CreateWorkflowDialog
        onClose={onCloseCreateWorkflowDialog}
        templateId={templateIdForDialogOpen}
      />
    </>
  );
};
