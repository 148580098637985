import { useRemoveMemberFromUserGroup } from "#src/components/hooks/adapters/useUserGroups";
import { Button, Dialog } from "@validereinc/common-components";
import { UserGroupType, UserType } from "@validereinc/domain";
import React from "react";

export const DeleteUserGroupMembershipDialog = ({
  isOpen,
  onClose,
  groupToDeleteFrom,
  user,
}: {
  isOpen: boolean;
  onClose: () => void;
  groupToDeleteFrom: UserGroupType;
  user: UserType;
}) => {
  const onSuccess = () => {
    onClose?.();
  };
  const removeMemberFromUserGroup = useRemoveMemberFromUserGroup({ onSuccess });
  const handleSubmit = () => {
    removeMemberFromUserGroup.mutate({ user, groupId: groupToDeleteFrom?.id });
  };

  return (
    <Dialog
      //   title={`Remove ${user.name} from ${groupToDeleteFrom?.name}`}
      title={`Remove User from Group?`}
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="remove-user-group-membership"
          variant="primary"
          onClick={handleSubmit}
        >
          Remove
        </Button>,
      ]}
    >
      You will remove {user.name} from {groupToDeleteFrom?.name}. You may re-add
      the user later if you choose.
    </Dialog>
  );
};
