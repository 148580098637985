import { useNavigate } from "#src/Routers/hooks";
import { FormCategoriesTableFilterArea } from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { useQuery } from "@tanstack/react-query";
import {
  EmptyState,
  Flex,
  GridCard,
  Pagination,
  Panel,
  type StorageKeys,
} from "@validereinc/common-components";
import { FormCategoryAdapter } from "@validereinc/domain";
import React from "react";

const { Container, Item } = Flex;

export const FormCategoriesPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const navigate = useNavigate();
  const [filters] = useSessionStickyState<{ name: string }>(
    {},
    filterConfigStorageKey
  );
  const [
    { header: _header, direction: _direction, ...pagination },
    setPagination,
  ] = useTableSortingAndPagination(
    {
      itemsPerPage: 30,
      isTotalKnown: false,
      entityPerPage: "Cards per page:",
    },
    filters
  );

  const queryFn = async ({ queryKey }) => {
    const [_, { dataKey: _dataKey, ...restFilters }, { page, itemsPerPage }] =
      queryKey;

    const { data: newFormCategories, total_entries } =
      await FormCategoryAdapter.getList({
        filters: {
          status: "active",
          ...restFilters,
        },
        page,
        pageSize: itemsPerPage,
      });

    setPagination({ total: total_entries, isTotalKnown: true });

    return newFormCategories;
  };

  const query = useQuery({
    queryKey: ["formCategories", filters, pagination],
    queryFn,
  });

  const onCardClick = (formCategoryId: string) => {
    navigate({
      pathname: linkToFormCategoryDetails(formCategoryId),
    });
  };

  return (
    <Panel
      title="Categories"
      loaded={!query?.isLoading}
      actionRow={[
        <FormCategoriesTableFilterArea
          key="filters"
          filterConfigStorageKey={filterConfigStorageKey}
          filterDrawerContentSlot={null}
        />,
      ]}
    >
      {query?.data?.length === 0 ? (
        <EmptyState
          title="There are no categories to display"
          suggestion="Please try adjusting your filters"
        />
      ) : (
        <>
          <Container variant="grid">
            {query.data?.map(({ id, name, updated_at }) => (
              <Item key={`form-category-${id}`}>
                <GridCard
                  title={name}
                  createdAt={new Date(updated_at)}
                  onClick={() => onCardClick(id)}
                  isLoading={query?.isLoading}
                />
              </Item>
            ))}
          </Container>

          <Pagination
            {...pagination}
            onChange={setPagination}
          />
        </>
      )}
    </Panel>
  );
};
