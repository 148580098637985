import {
  useDownloadErrors,
  useGetOneTransactionDetail,
} from "#hooks/adapters/useTransactions";
import {
  useClientSideSortingAndPagination,
  useTableSortingAndPagination,
} from "#redux/reducers/tableStateReducer";
import { useParams } from "#routers/hooks";
import {
  DataSetTransactionDetailItemType,
  extractItemsFromTransactionDetails,
  extractTransactionItemData,
  getTransactionDetailStatusDisplayLabel,
  getTransactionDetailStatusFilterPillVariant,
  getTransactionDetailStatusVariant,
  showErrorDetails,
  useDataSetRowTableEmptyStateProps,
} from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetDetail.helpers";
import { DataSetErrorDrawer } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetErrorDrawer";
import { DataSetDetailPageParamType } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/index";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  Button,
  DataTable,
  DataTablePanel,
  FilterPills,
  HeaderType,
  Link,
  SortingType,
} from "@validereinc/common-components";
import {
  SortDirection,
  TransactionDetailItemStatus,
} from "@validereinc/domain";
import React, { useState } from "react";

const sorting: SortingType = {
  sortBy: "index",
  sortDirection: SortDirection.ASCENDING,
};

const DEFAULT_FILTER_KEY = "all";

export const DataSetRowTable = () => {
  const { transactionId } = useParams<DataSetDetailPageParamType>();
  const [statusFilter, setStatusFilter] = useState(DEFAULT_FILTER_KEY);
  const [errorDrawerIndex, setErrorDrawerIndex] = useState<number>();
  const [tableState, updateTableState] = useTableSortingAndPagination(sorting);

  const { data, isLoading } = useGetOneTransactionDetail();
  const detailItems = extractItemsFromTransactionDetails(data);
  const { tableConfigStorageKey } = useStorageKey("dataset-submission-table");

  const { items, pagination } = useClientSideSortingAndPagination(
    detailItems.reduce((total: DataSetTransactionDetailItemType[], item) => {
      if ([DEFAULT_FILTER_KEY, item.status].includes(statusFilter)) {
        total.push({
          ...item,
          index: parseInt(item.index), // Casting is necessary for client-side sorting using lodash.orderBy
        });
      }
      return total;
    }, []),
    tableState
  );
  const errors = items.filter(
    ({ status }) => status === TransactionDetailItemStatus.FAILED
  );

  const showErrorColumns = showErrorDetails(detailItems);
  const showStatusColumn = true;

  // Assume the first row contains all the headers
  const parsedHeaders: Array<HeaderType<DataSetTransactionDetailItemType>> =
    Object.keys(extractTransactionItemData(items[0]?.data ?? "{}")).map(
      (label) => ({
        key: `data.${label}`,
        label: label,
        renderComponent: ({ item }) =>
          extractTransactionItemData(item.data)[label] ?? "-",
      })
    );

  const headers: Array<HeaderType<DataSetTransactionDetailItemType>> = [
    {
      key: "index",
      label: "Row",
      isSortable: true,
      isFixed: true,
      renderComponent: ({ item }) => (
        <Link
          label={`#${item.index + 1}`}
          onClick={() => {
            setErrorDrawerIndex(
              errors.findIndex(({ index }) => index == item.index)
            );
          }}
        />
      ),
    },
    ...(showStatusColumn
      ? [
          {
            key: "status",
            label: "Import Status",
            isSortable: true,
            renderComponent: ({
              item,
            }: {
              item: DataSetTransactionDetailItemType;
            }) => (
              <DataTable.DataRow.PillCell
                variant={getTransactionDetailStatusVariant(item.status)}
                value={getTransactionDetailStatusDisplayLabel(item.status)}
              />
            ),
          },
        ]
      : []),
    ...(showErrorColumns
      ? [
          { key: "error_msg", label: "Error Message", isSortable: true },
          { key: "error", label: "Error Type", isSortable: true },
        ]
      : []),
    ...parsedHeaders,
  ];

  const filterPills = detailItems?.length
    ? [
        {
          name: DEFAULT_FILTER_KEY,
          label: "All",
          value: DEFAULT_FILTER_KEY,
          count: detailItems.length,
          isSelected: !statusFilter || statusFilter === DEFAULT_FILTER_KEY,
        },
        ...Object.values(TransactionDetailItemStatus).map((status) => ({
          name: status,
          label: getTransactionDetailStatusDisplayLabel(status),
          value: status,
          count: detailItems.filter((item) => item.status === status).length,
          variant: getTransactionDetailStatusFilterPillVariant(status),
          isSelected: statusFilter === status,
        })),
      ]
    : [];

  const { mutate: downloadErrors, isLoading: areErrorsDownloading } =
    useDownloadErrors();

  const actionRow = showErrorColumns
    ? [
        <Button
          key="download-records"
          variant="outline"
          onClick={() => {
            downloadErrors({
              transactionId,
              fileName: `submission_${transactionId}_errors.csv`,
            });
          }}
          isLoading={areErrorsDownloading}
        >
          Download Errors
        </Button>,
      ]
    : [];

  return (
    <>
      <DataSetErrorDrawer
        items={errors}
        itemIndex={errorDrawerIndex}
        setItemIndex={setErrorDrawerIndex}
      />
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: "All Rows",
          titleDecorator: (
            <FilterPills
              name="status"
              pills={filterPills}
              onChange={setStatusFilter}
              isLoading={isLoading}
            />
          ),
          actionRow,
        }}
        dataTableProps={{
          headers: items.length || isLoading ? headers : [],
          items,
          isLoading,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          sorting,
          pagination,
          emptyStateProps: useDataSetRowTableEmptyStateProps(),
        }}
      />
    </>
  );
};
